import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Empty,
  Typography,
  message,
  Steps,
  Card,
  Divider,
  Space,
  Modal,
  Radio, Select,
} from "antd";
import "./Scorecard.less";
import {
  CloseCircleTwoTone,
  EditOutlined,
  ExclamationCircleOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import { QRReader } from "./QRReader";
import QRCode from "qrcode";
import _ from "lodash";
import { ManualEntry } from "./ManualEntry";
import { EnterScores } from "./EnterScores";
import moment from "moment";
import Swal from "sweetalert2";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { GenderTag } from "artemis-shared/tag/GenderTag";
import {
  decodeScorecard,
  encodeScorecard,
  getScore,
} from "artemis-shared/scorecard/ScorecardUtils";
import { createTraining } from "../training/TrainingUtils";
import { Training } from "../training/Training";

const { Title } = Typography;

export const Scorecard = () => {
  const { confirm } = Modal;
  const [entries, setEntries] = useState([]);
  const [activePage, setActivePage] = useState("home");
  const [scoring, setScoring] = useState([]);
  const [targetCount, setTargetCount] = useState(0);
  const [firstTarget, setFirstTarget] = useState(1);
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0);
  const [storedDataLoaded, setStoredDataLoaded] = useState(false);
  const [trainingId, setTrainingId] = useState(null);

  const latestEntries = useRef(entries);

  useEffect(() => {
    if (activePage === "teamQr") {
      console.log("render");
      QRCode.toCanvas(
        document.getElementById("qrcanvas"),
        encodeScorecard(targetCount, scoring, entries, firstTarget),
        { width: 330 }
      );
    }
  }, [activePage]);

  useEffect(() => {
    latestEntries.current = entries;
  });

  useEffect(() => {
    if (storedDataLoaded) {
      localStorage.setItem(
        "scorecard",
        JSON.stringify({
          entries,
          activePage,
          currentEntryIndex,
          scoring,
          trainingId,
          targetCount,
          firstTarget,
          timestamp: Date.now(),
        })
      );
    }
  }, [entries, activePage, currentEntryIndex]);

  useEffect(() => {
    let storedData = JSON.parse(localStorage.getItem("scorecard"));
    if (storedData) {
      if (moment(storedData.timestamp).isBefore(moment(), "days")) {
        localStorage.removeItem("scorecard");
      } else {
        setEntries(storedData.entries);
        setActivePage(storedData.activePage);
        setCurrentEntryIndex(storedData.currentEntryIndex);
        setScoring(storedData.scoring);
        setTargetCount(storedData.targetCount);
        setFirstTarget(storedData.firstTarget);
        setTrainingId(storedData.trainingId);
      }
    }

    setStoredDataLoaded(true);
  }, []);

  const parseQrValue = (value) => {
    console.log("parse", value);

    const exploded = value.split(";");

    const parsedScoring = exploded[0].split(",");

    setScoring(parsedScoring);

    const parsedTargetCount = exploded[7];

    setTargetCount(parseInt(parsedTargetCount));

    const parsed = {
      targetCount: parseInt(exploded[7]),
      arrowsPerTarget: parseInt(exploded[8]),
      arrowCount: parseInt(exploded[7]) * parseInt(exploded[8]),
      id: exploded[2],
      name: exploded[3],
      category: exploded[4],
      ageGroup: exploded[5],
      gender: exploded[6] === "0" ? "Férfi" : "Nő",
    };

    let scoreObj = {};
    scoreObj["0"] = "";

    parsedScoring.forEach((scoringValue) => {
      scoreObj[scoringValue] = "";
    });

    parsed.scores = scoreObj;

    console.log("parsed", parsed);

    return parsed;
  };

  const addEntry = (entry) => {
    const currentEntries = latestEntries.current;
    console.log("entries", currentEntries);
    if (!_.find(currentEntries, { id: entry.id })) {
      setEntries([...currentEntries, entry]);
      Swal.fire({
        title: entry.name,
        text: entry.category + " " + entry.ageGroup + " " + entry.gender,
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      window.navigator.vibrate(200);
    }
  };

  const setEntryValue = (entryId, valueToSet, value) => {
    const newEntries = [...entries];

    _.find(newEntries, { id: entryId })["scores"][valueToSet] = value;

    setEntries(newEntries);
  };

  let readerCallback = (value) => addEntry(parseQrValue(value.text));

  const generateQRValue = () => {
    let serializedEntries = entries.map((entry) => {
      let id = entry.id;
      let mergedScores = {};
      scoring.forEach((scoringValue) => (mergedScores[scoringValue] = 0));
      mergedScores = { ...mergedScores, ...entry.scores };
      let scoreString = Object.entries(mergedScores)
        .sort((a, b) => b[0] - a[0])
        .map(([scoringValue, score]) => score || 0)
        .join(",");

      return `${id},${scoreString}`;
    });

    return serializedEntries.join(";");
  };

  const getCurrentPage = () => {
    switch (activePage) {
      case "home":
      case "qrReader":
      case "manual":
        return 0;
      case "fullScorecard":
      case "summary":
      case "modeSelect":
        return 1;
      case "teamQr":
        return 2;
      default:
        return 0;
    }
  };

  return (
    <Card bodyStyle={{ padding: "16px 3px 40px 3px" }}>
      <Steps
        size="small"
        current={getCurrentPage()}
        percent={
          getCurrentPage() === 1
            ? (currentEntryIndex / entries.length) * 100
            : undefined
        }
        labelPlacement={"vertical"}
        style={{ marginBottom: "16px", marginLeft: "-10px" }}
      >
        <Steps.Step
          title="Versenyzők felvétele"
          onClick={() => {
            if (activePage === "fullScorecard" || activePage === "summary") {
              confirm({
                title: "Biztos hogy visszalépsz?",
                icon: <ExclamationCircleOutlined />,
                content: (
                  <span>
                    Ha visszalépsz <b>törlődnek a rögzített eredmények</b>!
                  </span>
                ),
                okType: "danger",
                cancelText: "Maradok",
                okText: "Igen, visszalépek",
                onOk() {
                  setActivePage("home");
                },
              });
            }
          }}
        />
        <Steps.Step title="Eredmények rögzítése" />
        <Steps.Step title="Leadás az irodában" />
      </Steps>
      {
        {
          home: (
            <div className={"add-button-container"}>
              <h2>Versenyzők felvétele</h2>
              {entries.length === 0 && activePage === "home" && (
                <Empty
                  description={
                    <>
                      <Title level={5} className={"empty-message"}>
                        A beírólapokon lévő QR kódokat beolvasva, vedd fel az
                        összes versenyzőt a csapatodba!
                      </Title>
                      <Typography.Paragraph>
                        A kódok beolvasásához szükség van a telefon kamerájára.
                        Amikor a készülék rákérdez, engedélyezd a használatát!
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        A QR kóddal nem rendelkező beírólapokat a rajta lévő
                        azonosító számmal lehet a következő lépésben rögzíteni.
                      </Typography.Paragraph>
                    </>
                  }
                />
              )}
              <div>
                {entries.length > 0 && (
                  <div className={"entry-list"}>
                    {entries.map((entry, index) => (
                      <div className={"entry"} key={entry.id}>
                        <div>
                          <div>
                            {index + 1}. {entry.name} {entry.manual && entry.id}
                          </div>
                          {!entry.manual && (
                            <div>
                              <CategoryTag category={entry.category} />
                              <AgeGroupTag ageGroup={entry.ageGroup} />
                              <GenderTag gender={entry.gender} />
                            </div>
                          )}
                        </div>
                        <CloseCircleTwoTone
                          twoToneColor={"#ff0000"}
                          onClick={() =>
                            setEntries(_.without(entries, entries[index]))
                          }
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={"add-button"} style={{ marginTop: "15px" }}>
                <Button
                  block
                  type={"primary"}
                  icon={<QrcodeOutlined />}
                  size={"large"}
                  onClick={() => setActivePage("qrReader")}
                >
                  QR kódok beolvasása
                </Button>
              </div>
              {entries.length > 0 && (
                <>
                  <Divider />
                  <Title level={5} className={"empty-message"}>
                    Ha van olyan beírólap amin nincs QR matrica, add meg
                    manuálisan az azonosítóját.
                  </Title>
                  <div className={"add-button"}>
                    <Button
                      block
                      type={"primary"}
                      icon={<EditOutlined />}
                      size={"large"}
                      onClick={() => setActivePage("manual")}
                    >
                      Versenyző manuális felvétele
                    </Button>
                  </div>
                </>
              )}
              {entries.length > 0 && (
                <>
                  <Divider />
                  <Title level={5} className={"empty-message"}>
                    Hányadik célon kezdtek?
                  </Title>
                  <Select style={{width: "60px"}} defaultValue={firstTarget} onChange={setFirstTarget}>
                    {[...Array(targetCount)].map((e, index) => <Select.Option key={index + 1} value={index + 1}>{index + 1}</Select.Option>)}
                  </Select>
                  <Divider />
                  <Title level={5} className={"empty-message"}>
                    Ha MINDEN beírót felvettél, kezdd el az eredmények
                    rögzítését!
                  </Title>
                  <Button
                    block
                    type={"primary"}
                    size={"large"}
                    //onClick={() => setActivePage("modeSelect")}
                    onClick={() => {
                      createTraining({
                        name: "Scorecard Training " + Date.now(),
                        targetCount: targetCount,
                        firstTargetNumber: firstTarget,
                        maxDistance: 0,
                        scoring: scoring.join(","),
                        scorecardTraining: true,
                        teamScores: entries.map((entry) => ({
                          ...entry,
                          scores: [...Array(targetCount)].map((e, index) => ({
                            target: index,
                            scores: [],
                          })),
                        })),
                      }).then((id) => {
                        setTrainingId(id);
                        setActivePage("fullScorecard");
                      });
                    }}
                  >
                    Eredmények rögzítése
                  </Button>
                </>
              )}
            </div>
          ),
          qrReader: (
            <>
              <QRReader
                key={"qr-reader"}
                onClose={() => setActivePage("home")}
                onRead={(value) => readerCallback(value)}
                showDescription={true}
              />
            </>
          ),
          teamQr: (
            <div className={"add-button-container"}>
              <h2>Leadás az irodában</h2>
              <h5>
                Az eredmények sikeresen rögzítve! Mutasd be ezt a kódot a
                versenyirodában!
              </h5>
              <Button style={{marginTop: "10px"}} type={"danger"} onClick={() => {
                confirm({
                  title: "Biztos hogy törlöd a beírólapot?",
                  icon: <ExclamationCircleOutlined />,
                  content: (
                      <span>
                        Csak akkor töröld a beírólapot, ha már leadtad az eredményt a versenyirodában. A törölt beírólapot <b>nem lehet visszaállítani!</b>
                  </span>
                  ),
                  okType: "danger",
                  cancelText: "Mégsem",
                  okText: "Igen, törlöm",
                  onOk() {
                    localStorage.removeItem("scorecard");
                    setEntries([]);
                    setScoring([]);
                    setTargetCount(0);
                    setFirstTarget(1);
                    setCurrentEntryIndex(0);
                    setStoredDataLoaded(false);
                    setTrainingId(null);
                    setActivePage("home");
                  },
                })
              }}>Beírólap visszaállítása</Button>
              <canvas id={"qrcanvas"} width={300} height={300} />
              <div className={"enty-list"}>
                {entries.map((entry, index) => (
                  <h3 key={entry.id}>
                    <div>
                      {index + 1}. {entry.name} {entry.manual && entry.id}
                    </div>
                    {!entry.manual && (
                      <div>
                        <CategoryTag category={entry.category} />
                        <AgeGroupTag ageGroup={entry.ageGroup} />
                        <GenderTag gender={entry.gender} />
                      </div>
                    )}
                    <Space split={<span>-</span>}>
                      {scoring.map((scoringValue) => (
                        <span key={scoringValue}>
                          {entry.scores[scoringValue] || 0}
                        </span>
                      ))}
                      <span>{getScore(entry, scoring)}</span>
                    </Space>
                  </h3>
                ))}
              </div>
            </div>
          ),
          manual: (
            <ManualEntry
              onSubmit={(id) => {
                setActivePage("home");

                if (id) {
                  let scoreObj = {};
                  scoreObj["0"] = "";

                  scoring.forEach((scoringValue) => {
                    scoreObj[scoringValue] = "";
                  });

                  addEntry({
                    id: id.substr(3),
                    name: "Manuális " + id.substr(3),
                    category: "-",
                    ageGroup: "-",
                    gender: "-",
                    targetCount: parseInt(id.substr(1, 3)),
                    arrowsPerTarget: parseInt(id.substr(0, 1)),
                    arrowCount:
                      parseInt(id.substr(0, 1)) * parseInt(id.substr(1, 3)),
                    scores: scoreObj,
                    manual: true,
                  });
                }
              }}
            />
          ),
          summary: (
            <EnterScores
              entries={entries}
              setEntryValue={setEntryValue}
              setActivePage={setActivePage}
              currentEntryIndex={currentEntryIndex}
              setCurrentEntryIndex={setCurrentEntryIndex}
              scoring={scoring}
            />
          ),
          fullScorecard: (
            <div>
              {trainingId && (
                <Training
                  trainingId={trainingId}
                  onComplete={(teamScores) => {
                    let newEntries = teamScores.map((ts) => ({
                      ...ts,
                      scores: _.countBy(
                        _.flattenDeep(ts.scores.map((s) => s.scores))
                      ),
                      flatScores: _.flattenDeep(ts.scores.map((s) => s.scores)),
                    }));
                    setEntries(newEntries);
                    setActivePage("teamQr");
                  }}
                />
              )}
            </div>
          ),
          modeSelect: (
            <div className={"add-button-container"}>
              <Title level={4}>
                Hogyan szeretnéd rögzíteni az eredményeket?
              </Title>
              <Typography.Paragraph>
                Lehetőséged van teljes értékű beírólapként használnod a
                telefont, a verseny elejétől a végéig, vagy összesítheted a
                verseny végén a találatokat.
              </Typography.Paragraph>
              <Typography.Paragraph>
                <strong>
                  Menet közben nincs lehetőség változtatni a rögzítés módján
                </strong>
                , szóval válassz bölcsen!
              </Typography.Paragraph>
              <Button
                block
                type={"primary"}
                size={"large"}
                onClick={() => {
                  createTraining({
                    name: "Scorecard Training " + Date.now(),
                    targetCount: targetCount,
                    maxDistance: 0,
                    scoring: scoring.join(","),
                    scorecardTraining: true,
                    teamScores: entries.map((entry) => ({
                      ...entry,
                      scores: [...Array(targetCount)].map((e, index) => ({
                        target: index,
                        scores: [],
                      })),
                    })),
                  }).then((id) => {
                    setTrainingId(id);
                    setActivePage("fullScorecard");
                  });
                }}
              >
                Végig a verseny közben, teljes beírólappal
              </Button>
              <Divider>vagy</Divider>
              <Button
                block
                type={"primary"}
                size={"large"}
                onClick={() => setActivePage("summary")}
              >
                Csak a verseny végén összesítek
              </Button>
            </div>
          ),
        }[activePage]
      }

      {/*<Button
        onClick={() =>
          readerCallback({
            text:
              "11,10,8,5,0;40;" +
              Math.floor(Math.random() * 9999) +
              ";Árvíztűrő Tükörfúrógép" +
              Math.floor(Math.random() * 20) +
              ";PBHB;Fiatal felnőtt;0;20;2",
          })
        }
      >
        s
      </Button>
      <Button
        onClick={() =>
          readerCallback({
            text:
              "11,10,8,5,0;20;" +
              Math.floor(Math.random() * 9999) +
              ";CRB János;CRB;Felnőtt;0;20;1",
          })
        }
      >
        crb
      </Button>
      <Button onClick={() => localStorage.removeItem("scorecard")}>r</Button>*/}
    </Card>
  );
};
