import React from "react";
import { Card } from "antd";
import "./ResultsBanner.less";
import {TrophyFilled} from "@ant-design/icons";

export const ResultsBanner = ({ competition }) => {
  return (
    <a
      href={competition.externalScoreUrl}
      target={"_blank"}
      rel={"noreferrer"}
    >
      <Card className={"results-banner"}>
        <div className={"results-banner-content"}>
          <div className={"results-logo"}>
            <TrophyFilled />
          </div>
          <div className={"results-banner-text"}>
            <h3>Részletes eredmények</h3>
            <h4>
              A szervezők által feltöltött egyéb részleteket, pl döntők eredményeit itt találod!
            </h4>
          </div>
        </div>
      </Card>
    </a>
  );
};
