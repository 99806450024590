import React from "react";
import {
  Col,
  Popconfirm,
  Popover,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import {
  CheckCircleTwoTone,
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./TeamFilter.less";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { GenderTag } from "artemis-shared/tag/GenderTag";
import { hasTeams } from "../ManagerUtils";
import {useTranslation} from "react-i18next";

const { Title } = Typography;

export const TeamFilter = ({
  localCompetitionData,
  localEntries,
  teamFilter,
  setTeamFilter,
  setFilterExpression,
  createNewTeam,
  checkProperty,
}) => {
    const { t } = useTranslation();
  return (
    hasTeams(localEntries) && (
      <Row>
        <Title level={5}>{t("manager.teamFilter")}</Title>
        <Col span={24}>
          <div className={"team-filter"}>
            <div className={"teams-row"}>
              {[...Array(localCompetitionData.teamCount > 28 ? Math.floor(localCompetitionData.teamCount / 2) + 1 : localCompetitionData.teamCount + 1).keys()].map(
                (teamNum) => (
                  <TeamBox
                      key={"team-" + teamNum}
                      teamNum={teamNum}
                      localEntries={localEntries}
                      setFilterExpression={setFilterExpression}
                      checkProperty={checkProperty}
                      teamFilter={teamFilter}
                      setTeamFilter={setTeamFilter}
                      localCompetitionData={localCompetitionData}
                  />
                )
              )}
              {createNewTeam && localCompetitionData.teamCount <= 28 && (
                <Popconfirm
                  title={t("manager.teamCreateConfirm")}
                  onConfirm={() => {
                    createNewTeam();
                  }}
                  okText={t("manager.yes")}
                  cancelText={t("manager.cancel")}
                  placement={"topRight"}
                >
                  <div className={"team-indicator"}>
                    <PlusOutlined />
                  </div>
                </Popconfirm>
              )}
            </div>
            {localCompetitionData.teamCount > 28 && <div className={"teams-row"}>
                {createNewTeam && (
                    <Popconfirm
                        title={t("manager.teamCreateConfirm")}
                        onConfirm={() => {
                            createNewTeam();
                        }}
                        okText={t("manager.yes")}
                        cancelText={t("manager.cancel")}
                        placement={"topRight"}
                    >
                        <div className={"team-indicator"}>
                            <PlusOutlined />
                        </div>
                    </Popconfirm>
                )}
                {!createNewTeam && <div className={"team-indicator"} />}
              {[...Array(localCompetitionData.teamCount - Math.floor(localCompetitionData.teamCount / 2)).keys()].map(
                (teamNum) => (
                  <TeamBox
                    key={"team-" + 1 + teamNum + Math.floor(localCompetitionData.teamCount / 2)}
                    teamNum={1 + teamNum + Math.floor(localCompetitionData.teamCount / 2)}
                    localEntries={localEntries}
                    setFilterExpression={setFilterExpression}
                    checkProperty={checkProperty}
                    teamFilter={teamFilter}
                    setTeamFilter={setTeamFilter}
                    localCompetitionData={localCompetitionData}
                  />
                )
                )}
            </div>}
          </div>
        </Col>
      </Row>
    )
  );
};

const TeamBox = ({teamNum, localEntries, setFilterExpression, checkProperty, teamFilter, setTeamFilter, localCompetitionData}) => {
    const { t } = useTranslation();
  return <Popover
      content={
        <table cellPadding={5} className={"team-table"}>
          {_.filter(localEntries, { team: teamNum })
              .sort((a, b) => a.teamIndex - b.teamIndex)
              .map((entry) => (
                  <tr
                      key={entry.id}
                      onClick={() => setFilterExpression(entry.name)}
                  >
                    <td>
                      {_.get(entry, checkProperty) ? (
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                      ) : (
                          <CloseCircleOutlined />
                      )}
                    </td>
                    <td>{entry.name}</td>
                    <td>
                      <Tooltip
                          placement="right"
                          title={entry.category.description}
                      >
                        <div>
                          <CategoryTag
                              category={entry.category.name}
                          />
                        </div>
                      </Tooltip>
                    </td>
                    <td>
                      <AgeGroupTag
                          ageGroup={entry.ageGroup.ageGroup.name}
                      />
                    </td>
                    <td>
                      <GenderTag gender={entry.gender} />
                    </td>
                  </tr>
              ))}
        </table>
      }
      title={t("manager.teamMembers")}
      placement="bottom"
  >
    <div
        className={
            "team-indicator " +
            (teamFilter === teamNum ? "selected " : "") +
            (_.filter(localEntries, { team: teamNum }).some(
                (entry) => _.get(entry, checkProperty)
            )
                ? "in-progress "
                : "") +
            (_.filter(localEntries, { team: teamNum }).every(
                (entry) => _.get(entry, checkProperty)
            )
                ? "complete "
                : "")
        }
        onClick={() => {
          setFilterExpression("");
          setTeamFilter(teamFilter === teamNum ? null : teamNum);
        }}
    >
      {(localCompetitionData.teamLabels?.split(";")[teamNum - 1] || teamNum)}
    </div>
  </Popover>
}