import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getRanking = async (key, params) => {
  const { data } = await axios(`${API_URL}/ranking`, { params: params });
  return data;
};

export const getRankingByPermitNumber = async (key, permitNumber) => {
  const { data } = await axios(`${API_URL}/ranking/${permitNumber}`);
  return data;
};

export const getRankedEntries = async (key, permitNumber) => {
  const { data } = await axios(`${API_URL}/ranked-entries/${permitNumber}`);
  return data;
};

export const getFirstRanked = async () => {
  const { data } = await axios(`${API_URL}/ranking/first`);
  return data;
};
