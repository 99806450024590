import {List, Skeleton, Tooltip} from "antd";
import React, { useContext } from "react";

import "./EntriesList.less";
import { GenderTag } from "../tag/GenderTag";
import { CategoryTag } from "../tag/CategoryTag";
import { AgeGroupTag } from "../tag/AgeGroupTag";
import { UrlContext } from "../utils/UrlContext";
import { ExternalAwareLink } from "../utils/ExternalAwareLink";
import {useTranslation} from "react-i18next";

export const EntriesList = ({
  entries,
  isFetchingEntries,
  highlightedEntry,
  competition,
}) => {
  const {t} = useTranslation();
  const urls = useContext(UrlContext);
  return (
    <div
      className={
        "competition-entries-list " +
        (competition.type === "HISTORICAL" ? "historical" : "")
      }
    >
      <List
        dataSource={entries}
        loading={isFetchingEntries}
        renderItem={(entry) => (
          <List.Item>
            <Skeleton avatar title={false} loading={isFetchingEntries} active>
              <List.Item.Meta
                title={
                  <div
                    className={
                      "entry " +
                      (highlightedEntry && highlightedEntry.id === entry.id
                        ? "highlighted"
                        : "")
                    }
                  >
                    <div className={"entry-left"}>
                      <div className={"entry-name"}>
                        <div>
                          {entry.permitNumber > 0 ? (
                            <ExternalAwareLink
                              to={`${urls.athlete}/${entry.permitNumber}`}
                            >
                              {entry.name}
                            </ExternalAwareLink>
                          ) : (
                            entry.name
                          )}
                        </div>
                        <div className={"entry-club"}>{competition.international ? entry.country && t("country." + entry.country) || "-" : entry.club.name}</div>
                      </div>
                    </div>
                    <div className={"entry-right"}>
                      <Tooltip placement="left" title={entry.category.description} trigger={"click"}>
                        <div>
                            <CategoryTag category={entry.category.name} />
                        </div>
                      </Tooltip>
                      <div>
                        <AgeGroupTag ageGroup={entry.ageGroup.ageGroup.name} />
                      </div>
                      <div>
                        <GenderTag gender={entry.gender} />
                      </div>
                    </div>
                  </div>
                }
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </div>
  );
};
