import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  Col,
  Input,
  Row,
  Table,
  Tooltip,
  Switch,
  Space,
  Button,
  Card, Popconfirm,
} from "antd";
import _ from "lodash";
import {calculateArrowCount, calculateScore, generateScorecardPdf, hasTeams, sumRounds} from "../ManagerUtils";

import "./ScoreTab.less";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { GenderTag } from "artemis-shared/tag/GenderTag";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { ValueKeepingInputNumber } from "./ValueKeepingInputNumber";
import { TeamFilter } from "../TeamFilter/TeamFilter";
import XLSX from "xlsx";
import jsPDF from "jspdf";
import {downloadFile} from "../../utils/DownloadFile";
import {groupEntries} from "artemis-shared/utils/CompetitionEntryUtils";
import {DeleteTwoTone, FileTextOutlined, PrinterFilled, QuestionCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {getArrowCount} from "artemis-shared/utils/CompetitionUtils";
import QRCode from "qrcode";
import {RobotoRegular} from "../../fonts/Roboto-Regular-normal";

const { Column } = Table;

export const MultiRoundScoreTab = ({
  localCompetitionData,
  localEntries,
  updateFullEntry,
  getLocalEntries,
  calculateAndSetPlacement,
  roundData,
  filter,
  setFilter,
  filterExpression,
  setFilterExpression,
  teamFilter,
  setTeamFilter,
  displayScoringValues,
  createEntry,
    deleteEntry,
  autoPlacement,
  setAutoPlacement,
   setScorecardString,
   setScorecardEntryId,
    sk
}) => {
  const { t } = useTranslation();
  const [scoreAutofill, setScoreAutofill] = useState(true);

  const overHundred = localCompetitionData?.rounds?.length === 5;

  const [inputEntry, setInputEntry] = useState(null);

  const excelInputRef = useRef();

  const handleFocus = (event) => event.target.select();

  const [broadcastChannel, setBroadcastChannel] = useState(null);

  useEffect(() => {
    const bc = new BroadcastChannel("manager_display_channel");
    setBroadcastChannel(bc);
    bc.postMessage({ valami: 1 });
  }, []);

  const deferredChangeEntry = useMemo(
    () =>
      _.debounce(async (entryId, entry, calculatePlacement) => {
        console.log("deferredChangeEntry", entryId, entry, calculatePlacement);
        await updateFullEntry(entryId, entry);
        await getLocalEntries();
        if (calculatePlacement) {
          deferredCalculateAndSetPlacement();
        }
      }, 1000),
    []
  );

  const deferredCalculateAndSetPlacement = useMemo(
    () =>
      _.debounce(async () => {
        let local = await getLocalEntries();
        await calculateAndSetPlacement(local);
      }, 3000),
    []
  );

  const sumFinals = (entry, round, arrowCount, scoring, autofill) => {
    let arrowArray = [];

    for (let i = 0; i < arrowCount; i++) {
      arrowArray.push(entry.roundEntries[round]["f" + i]);
    }

    entry.roundEntries[round].finals = arrowArray.join(";");

    if (autofill) {
      let count = _.countBy(arrowArray);

      scoring.forEach((scoringValue) => {
        entry.roundEntries[round]["s" + scoringValue] =
          count[scoringValue] || null;
      });

      entry.roundEntries[round].score = _.sum(arrowArray);
    }
  };

  const delayedInputChange = (entryId, property, value) => {
    setAutoPlacement((currentAutoPlacement) => {
      setScoreAutofill((currentScoreAutofill) => {
        setInputEntry((currentInputEntry) => {
          let newInputEntry = currentInputEntry && { ...currentInputEntry };
          if (!newInputEntry) {
            newInputEntry = { ..._.find(localEntries, { id: entryId }) };

            if (property === "tiebreaker") {
              newInputEntry.tiebreaker = value;
            } else if (property === "placement") {
              newInputEntry.placement = value;
            } else {
              newInputEntry.roundEntries[roundData.round][property] = value;
            }

            if (
              currentScoreAutofill &&
              property.startsWith("s") &&
              property !== "score"
            ) {
              newInputEntry.roundEntries[roundData.round].score =
                calculateScore(
                  roundData.scoring,
                  newInputEntry,
                  roundData.round
                );
            }
            if (roundData.finals) {
              sumFinals(
                newInputEntry,
                roundData.round,
                roundData.arrowCount,
                roundData.scoring,
                scoreAutofill
              );
            }
            sumRounds(newInputEntry, localCompetitionData.subtype, finalsType);

            deferredChangeEntry(entryId, newInputEntry, currentAutoPlacement);
          } else if (newInputEntry && newInputEntry.id === entryId) {
            if (property === "tiebreaker") {
              newInputEntry.tiebreaker = value;
            } else if (property === "placement") {
              newInputEntry.placement = value;
            } else {
              newInputEntry.roundEntries[roundData.round][property] = value;
            }

            if (
              currentScoreAutofill &&
              property.startsWith("s") &&
              property !== "score"
            ) {
              newInputEntry.roundEntries[roundData.round].score =
                calculateScore(
                  roundData.scoring,
                  newInputEntry,
                  roundData.round
                );
            }
            if (roundData.finals) {
              sumFinals(
                newInputEntry,
                roundData.round,
                roundData.arrowCount,
                roundData.scoring,
                scoreAutofill
              );
            }
            sumRounds(newInputEntry, localCompetitionData.subtype, finalsType);
            deferredChangeEntry(entryId, newInputEntry, currentAutoPlacement);
          } else {
            deferredChangeEntry.flush();
            newInputEntry = { ..._.find(localEntries, { id: entryId }) };

            if (property === "tiebreaker") {
              newInputEntry.tiebreaker = value;
            } else if (property === "placement") {
              newInputEntry.placement = value;
            } else {
              newInputEntry.roundEntries[roundData.round][property] = value;
            }

            if (
              currentScoreAutofill &&
              property.startsWith("s") &&
              property !== "score"
            ) {
              newInputEntry.roundEntries[roundData.round].score =
                calculateScore(
                  localCompetitionData.scoring,
                  newInputEntry,
                  roundData.round
                );
            }

            if (roundData.finals) {
              sumFinals(
                newInputEntry,
                roundData.round,
                roundData.arrowCount,
                roundData.scoring,
                scoreAutofill
              );
            }
            sumRounds(newInputEntry, localCompetitionData.subtype, finalsType);
            deferredChangeEntry(entryId, newInputEntry, currentAutoPlacement);
          }

          return newInputEntry;
        });
        return currentScoreAutofill;
      });
      return currentAutoPlacement;
    });
  };

  const shouldCellUpdate = (record, prevRecord) => {
    return !_.isEqual(record, prevRecord);
  };

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

  const processExcelRows = (rows) => {
    let success = 0;
    let newEntries = 0;

    rows.forEach(async (entry) => {
      let updatedEntry = localEntries.find((e) => e.id == entry.id);

      console.log("found", updatedEntry);

      if (updatedEntry?.id) {
        if (entry.placement) {
          updatedEntry.placement = entry.placement;
        }

        updatedEntry.ageGroup = localCompetitionData.ageGroups.find(
          (ageGroup) => ageGroup.ageGroup.name === entry.ageGroup
        );

        updatedEntry.category = localCompetitionData.categories.find(
          (category) => category.name === entry.category
        );

        updatedEntry.gender = entry.gender;
        updatedEntry.name = entry.name;

        updatedEntry.roundEntries[0].score = entry.round1score || 0;
        updatedEntry.roundEntries[1].score = entry.round2score || 0;

          updatedEntry.roundEntries[0]["sx"] = entry["round1sx"] || 0;
          updatedEntry.roundEntries[1]["sx"] = entry["round2sx"] || 0;
          updatedEntry.roundEntries[0]["s10"] = entry["round1s10"] || 0;
          updatedEntry.roundEntries[1]["s10"] = entry["round2s10"] || 0;
          updatedEntry.roundEntries[0]["s6"] = entry["round1s6"] || 0;
          updatedEntry.roundEntries[1]["s6"] = entry["round2s6"] || 0;
          updatedEntry.roundEntries[1]["s5"] = entry["round1s5"] || 0;
          updatedEntry.roundEntries[1]["s5"] = entry["round2s5"] || 0;

        if (roundData.finals) {
          sumFinals(
            updatedEntry,
            roundData.round,
            roundData.arrowCount,
            roundData.scoring
          );
        }
        sumRounds(updatedEntry, localCompetitionData.subtype, finalsType);

        console.log(updatedEntry);
        await updateFullEntry(entry.id, updatedEntry);
        success += 1;
      } else {
        newEntries += 1;
      }
    });

    console.log("success: ", success);
    console.log("new entries: ", newEntries);
    getLocalEntries();
  };

  const generateFinalsPdf = () => {
    var doc = new jsPDF("landscape");

    let groupedEntries = groupEntries(localEntries);
    let sortedGroupEntries = Object.entries(groupedEntries).sort((a, b) => {
      if (a[1].category.name.localeCompare(b[1].category.name) == 1) return 1;
      if (a[1].category.name.localeCompare(b[1].category.name) == -1) return -1;

      return a[1].ageGroup.fromAge - b[1].ageGroup.fromAge;
    });

    Object.entries(sortedGroupEntries).forEach(([key, v]) => {
      let value = v[1];
      console.log("v", value);

      let styles = {
        0: { cellWidth: 10 },
        1: { cellWidth: 70 },
        2: { cellWidth: 15 },
      };

      let head = [
        {
          content: `${value.category.name} ${value.ageGroup.ageGroup.name} ${
              value.gender === "MALE" ? "Férfi" : "Nő"
          }`,
          colSpan: 2,
          styles: { halign: "left", fontSize: 14 },
        },
        {content: "Hozott"}
      ];

      for(let i = 0; i < roundData.arrowCount; i++){
        styles[3 + i] = { cellWidth: 20 };
        head.push({ content: i + 1 + "." });
      }

      head.push({ content: "Záró" });
      head.push({ content: "Hely" });



      doc.autoTable({
        pageBreak: "avoid",
        bodyStyles: { font: "Roboto-Regular", fontSize: "15" },
        headStyles: { font: "Roboto-Bold" },
        columnStyles: styles,
        styles: {
          rowHeight: 15,
          lineWidth: 1
        },
        head: [head],
        body: value.entries.map((entry) => {
          let body = [entry.placement + ".", entry.name, entry.roundEntries[0].score + entry.roundEntries[1].score];

          return body;
        }),
        //startY: parseInt(team) == 1 ? 30 : undefined,
      });
    });
    downloadFile(
        doc.output("blob"),
        `${localCompetitionData.slug}_donto.pdf`
    );
  }

  const generateFinalsQRPdf = async () => {
    var doc = new jsPDF("portrait");
    doc.setFontSize(12);
    doc.addFileToVFS("RobotoRegular.ttf", RobotoRegular);
    doc.addFont("RobotoRegular.ttf", "Roboto", "normal");
    doc.setFont("Roboto");

    let groupedEntries = groupEntries(localEntries);
    let sortedGroupEntries = Object.entries(groupedEntries).sort((a, b) => {
      if (a[1].category.name.localeCompare(b[1].category.name) == 1) return 1;
      if (a[1].category.name.localeCompare(b[1].category.name) == -1) return -1;

      return a[1].ageGroup.fromAge - b[1].ageGroup.fromAge;
    });

    let i = 0;
    for(let v of Object.entries(sortedGroupEntries)) {
      let value = v[1][1];

      let qrText = `F;${roundData.scoring};${roundData.arrowCount};${localCompetitionData.arrowCount};${value.category.name};${value.ageGroup.ageGroup.name};${value.gender === "MALE" ? "0" : "1"};`;

      qrText += value.entries.map(entry =>
        `${entry.id}#${entry.name}#${entry.score}`
      ).join(",");

      const qr = await QRCode.toDataURL(
        qrText
      );

      const coords = calculateCoordinates(i);

      doc.text(`${value.category.name} ${value.ageGroup.ageGroup.name} ${value.gender === "MALE" ? "Férfi" : "Nő"}`, coords.x + 10, coords.y + 8);

      doc.addImage(
        qr,
        "PNG",
        coords.x + 5,
        coords.y + 10,
        60,
        60
      );

      i++;
    }

    downloadFile(
      doc.output("blob"),
      `${localCompetitionData.slug}_donto_qr.pdf`
    );
  };

  const calculateCoordinates = (index) => {
    const gridWidth = 210;
    const itemWidth = 70;
    const itemHeight = 70;

    // Calculate the number of items per row
    const itemsPerRow = Math.floor(gridWidth / itemWidth);

    // Calculate the row and column of the item
    const row = Math.floor(index / itemsPerRow);
    const column = index % itemsPerRow;

    // Calculate the X and Y coordinates
    const x = column * itemWidth;
    const y = row * itemHeight;

    return { x, y };
  }

  console.log("filter", filter);

  const rowKeyFn = useMemo(() => (row) => row.id, []);

  const finalsType = localCompetitionData.type === "HISTORICAL" ? "SCORE" : "HITS";

  return (
    <div className={"score-tab"}>
      {!roundData.finals && <TeamFilter
        localCompetitionData={localCompetitionData}
        localEntries={localEntries.filter(entry => entry.roundEntries[roundData.round]?.active)}
        teamFilter={teamFilter}
        setTeamFilter={setTeamFilter}
        setFilterExpression={setFilterExpression}
        checkProperty={`roundEntries[${roundData.round}].score`}
      />}
      {roundData.finals && <><Button onClick={generateFinalsPdf}>{t("manager.print")}</Button><Button onClick={generateFinalsQRPdf}>Döntő QR-ek</Button></>}
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <Input
            id={"filter-expression-input"}
            placeholder={t("manager.search")}
            onChange={(e) => {
              setTeamFilter(null);
              setFilter({});
              setFilterExpression(e.target.value);
            }}
            onFocus={handleFocus}
            onPressEnter={() => {
              let scoreInput = document.querySelector(
                ".score-row-0 .score-col-0 input"
              );
              if (scoreInput) {
                scoreInput.focus();
              }
            }}
            value={filterExpression}
            style={{ width: 250 }}
          />
        </Col>
        <Col>
          <Space>
            <Switch
              defaultChecked={scoreAutofill}
              onChange={setScoreAutofill}
            ></Switch>
            <span>{t("manager.automaticScoreFill")}</span>
            {!roundData.finals && <Button key={"skResults"} onClick={() => generateScorecardPdf(localEntries.map(entry => ({
              name: entry.name,
              category: entry.category,
              ageGroup: entry.ageGroup,
              gender: entry.gender,
              scorecard: entry.roundEntries[roundData.round]?.scorecard
            })), localCompetitionData, t)}><PrinterFilled />{t("manager.scorecards")}</Button>}
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={_.filter(
          localEntries,
          (entry) => entry.roundEntries[roundData.round].active
        )}
        size={"small"}
        rowKey={rowKeyFn}
        rowClassName={(record, index) => "score-row score-row-" + index}
        pagination={
          teamFilter ? false : { pageSizeOptions: [10, 20, 100, 999], defaultPageSize: roundData?.finals ? roundData.topCount : 10 }
        }
        onChange={(paginate, filters, sorter, extra) => {
          setFilter(filters)

          let currentPageData = extra.currentDataSource.slice((paginate.current - 1) * paginate.pageSize, paginate.current * paginate.pageSize);

          let message = {
            category: currentPageData[0].category.id,
                ageGroup: currentPageData[0].ageGroup.id,
              gender: currentPageData[0].gender,
              topCount: roundData.topCount
          }

          broadcastChannel.postMessage({
            selectFinals: message
          });
        }}
      >
        {hasTeams(localEntries) && (
          <Column
            title={t("manager.teamShort")}
            dataIndex={"team"}
            width={20}
            onFilter={(value, record) => {
              return record.team === parseInt(value);
            }}
            filteredValue={isNumeric(teamFilter) ? [teamFilter] : []}
            sorter={(a, b) => a.team - b.team || a.groupIndex - b.groupIndex || a.teamIndex - b.teamIndex}
          />
        )}
        <Column
          title={t("manager.idShort")}
          dataIndex="id"
          key="id"
          width={50}
          render={(colValue, record) => {
            return (
              "" +
              getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, record.category.id, record.ageGroup.ageGroup.id) +
              localCompetitionData.targetCount +
              colValue
            );
          }}
          onFilter={(value, record) => {
            return (
              parseInt(
                "" +
                  getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, record.category.id, record.ageGroup.ageGroup.id) +
                  localCompetitionData.targetCount +
                  record.id
              ) === parseInt(value)
            );
          }}
          filteredValue={isNumeric(filterExpression) ? [filterExpression] : []}
          shouldCellUpdate={shouldCellUpdate}
        />
        <Column
          title={t("manager.name")}
          width={250}
          dataIndex="name"
          key="name"
          sorter={(a, b) => a.name.localeCompare(b.name)}
          onFilter={(value, record) => {
            return record.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
          }}
          filteredValue={!isNumeric(filterExpression) ? [filterExpression] : []}
          shouldCellUpdate={shouldCellUpdate}
        />
        <Column
          title={t("manager.permitShort")}
          width={75}
          dataIndex="permitNumber"
          key="permitNumber"
          shouldCellUpdate={shouldCellUpdate}
        />
        {!localCompetitionData.international && <Column
          title={t("manager.club")}
          dataIndex={["club", "name"]}
          key="club"
          sorter={(a, b) => a.club.name.localeCompare(b.club.name)}
          shouldCellUpdate={shouldCellUpdate}
          responsive={["xxl"]}
        />}
        {localCompetitionData.international && <Column
            title={t("manager.country")}
            dataIndex={["country"]}
            key="country"
            shouldCellUpdate={shouldCellUpdate}
            responsive={["xxl"]}
            render={(text) => text && t("country." + text) || "-"}
        />}
        <Column
          title={t("manager.categoryShort")}
          dataIndex="category"
          width={75}
          key="category"
          sorter={{
            compare: (a, b) => a.category.name.localeCompare(b.category.name),
            multiple: 4,
          }}
          defaultSortOrder={"ascend"}
          filters={localCompetitionData.categories.map((category) => ({
            text: category.name,
            value: category.name,
          }))}
          filteredValue={filter.category}
          onFilter={(value, record) => record.category.name === value}
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => (
            <Tooltip placement="right" title={entry.category?.description}>
              <div>
                <CategoryTag category={colValue.name} />
              </div>
            </Tooltip>
          )}
        />
        <Column
          title={t("manager.ageGroupShort")}
          dataIndex="ageGroup"
          key="ageGroup"
          sorter={{
            compare: (a, b) =>
              a.ageGroup.ageGroup.name.localeCompare(b.ageGroup.ageGroup.name),
            multiple: 3,
          }}
          defaultSortOrder={"ascend"}
          filters={localCompetitionData.ageGroups.map((ageGroup) => ({
            text: ageGroup.ageGroup.name,
            value: ageGroup.ageGroup.name,
          }))}
          filteredValue={filter.ageGroup}
          onFilter={(value, record) => record.ageGroup.ageGroup.name === value}
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => (
            <AgeGroupTag ageGroup={colValue.ageGroup.name} />
          )}
        />
        <Column
          title={t("manager.gender")}
          dataIndex="gender"
          key="gender"
          sorter={{
            compare: (a, b) => a.gender.localeCompare(b.gender),
            multiple: 2,
          }}
          defaultSortOrder={"ascend"}
          filters={[
            {
              text: "Férfi",
              value: "MALE",
            },
            {
              text: "Nő",
              value: "FEMALE",
            },
          ]}
          filteredValue={filter.gender}
          onFilter={(value, record) => record.gender === value}
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => <GenderTag gender={colValue} />}
        />
        {localCompetitionData &&
          !roundData.finals &&
          [...roundData.scoring]
            .reverse()
            .filter((sv) => displayScoringValues.indexOf(sv) > -1)
            .map((scoreProperty, colIndex) => {
              return (
                <Column
                  className={"score-input"}
                  width={40}
                  title={scoreProperty}
                  dataIndex={[
                    "roundEntries",
                    roundData.round,
                    "s" + scoreProperty,
                  ]}
                  key={roundData.round + "s" + scoreProperty}
                  shouldCellUpdate={shouldCellUpdate}
                  render={(colValue, entry) => {
                    let countError = entry.roundEntries[roundData.round].score &&
                        calculateArrowCount(
                            roundData.scoring,
                            entry,
                            roundData.round
                        ) !==
                        (localCompetitionData.rounds[roundData.round]
                                .arrowCount *
                            getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, entry.category.id, entry.ageGroup.ageGroup.id)
                            );
                    return (
                        <Tooltip
                            title={
                              t("manager.total") +": " +
                              calculateArrowCount(
                                  roundData.scoring,
                                  entry,
                                  roundData.round
                              ) +
                              " / " +
                              (localCompetitionData.rounds[roundData.round]
                                  .arrowCount * getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, entry.category.id, entry.ageGroup.ageGroup.id)
                                  ) +
                              "  " +t("manager.shots")
                            }
                        >
                          <ValueKeepingInputNumber
                              id={`score-col-${roundData.round}-${entry.id}-${colIndex}`}
                              className={`score-col-${colIndex}`}
                              value={colValue}
                              max={999}
                              style={{
                                width: overHundred ? "50px" : "40px",
                                borderColor:
                                    countError
                                        ? "#ff0000"
                                    : "",
                                backgroundColor:
                                    countError
                                        ? "rgb(255,0,0,0.2)"
                                        : "",
                              }}
                              onFocus={handleFocus}
                              onKeyDown={(e) => {
                                setTimeout(() => {
                                  let value = e.target.value;
                                  if (value.length >= (overHundred ? 3 : 2)) {
                                    let nextInput = document.getElementById(
                                        `score-col-${roundData.round}-${entry.id}-${
                                            colIndex + 1
                                        }`
                                    );
                                    if (nextInput) {
                                      nextInput.focus();
                                    } else {
                                      let scoreInput = document.getElementById(
                                          `score-input-${roundData.round}-${entry.id}`
                                      );

                                      scoreInput && scoreInput.focus();
                                    }
                                  }
                                }, 0);
                              }}
                              onChange={(value) =>
                                  delayedInputChange(
                                      entry.id,
                                      "s" + scoreProperty,
                                      value
                                  )
                              }
                          />
                        </Tooltip>
                    )
                  }}
                />
              );
            })}

        {/* FINALS COLUMNS */}

        {localCompetitionData && roundData.finals && <Column
            title={"Sum"}
            sorter={{
              compare: (a, b) => _.sumBy(a.roundEntries.filter(re => !localCompetitionData.rounds[re.round].finals), "score") - _.sumBy(b.roundEntries.filter(re => !localCompetitionData.rounds[re.round].finals), "score"),
              multiple: 1,
            }}
            defaultSortOrder={"descend"}
            key={"score"}
            render={(value, entry) => {
              return _.sumBy(entry.roundEntries.filter(re => !localCompetitionData.rounds[re.round].finals), "score");
            }}
        />}

        {localCompetitionData &&
          roundData.finals && _.range(roundData.arrowCount).map((arrowIndex, colIndex) => {
            return (
              <Column
                className={"score-input"}
                width={40}
                title={arrowIndex + 1 + ". löv"}
                dataIndex={["roundEntries", roundData.round, "f" + arrowIndex]}
                key={roundData.round + "finals" + arrowIndex}
                shouldCellUpdate={shouldCellUpdate}
                render={(colValue, entry) => {
                  return (
                    <ValueKeepingInputNumber
                      id={`finals-col-${roundData.round}-${entry.id}-${colIndex}`}
                      className={`score-col-${colIndex}`}
                      value={colValue}
                      max={roundData.finals && finalsType === "SCORE" ? 99 : 20}
                      style={{
                        width: "40px",
                      }}
                      onFocus={handleFocus}
                      onKeyDown={(e) => {
                        setTimeout(() => {
                          let value = e.target.value;
                          if (value.length >= 2) {
                            let nextInput = document.getElementById(
                              `finals-col-${roundData.round}-${entry.id}-${
                                colIndex + 1
                              }`
                            );
                            if (nextInput) {
                              nextInput.focus();
                            } else {
                              let scoreInput = document.getElementById(
                                `score-input-${roundData.round}-${entry.id}`
                              );

                              scoreInput && scoreInput.focus();
                            }
                          }
                        }, 0);
                      }}
                      onChange={(value) => {
                        delayedInputChange(entry.id, "f" + arrowIndex, value);
                      }}
                    />
                  );
                }}
              />
            );
          })}

        <Column
          width={50}
          title={t("manager.totalShort")}
          dataIndex={["roundEntries", roundData.round, "score"]}
          key={"score"}
          sorter={(a, b) => a.score - b.score}
          render={(colValue, entry, index) => (
              <div style={{display: "flex", alignItems: "center"}}>
                <Tooltip
                  title={
                    t("manager.calculated")+": " +
                    calculateScore(roundData.scoring, entry, roundData.round)
                  }
                >
                  <ValueKeepingInputNumber
                    id={`score-input-${roundData.round}-${entry.id}`}
                    style={{
                      width: "50px",
                      borderColor:
                          !(roundData.finals && finalsType === "SCORE") && entry.roundEntries[roundData.round].score &&
                        calculateScore(
                          roundData.scoring,
                          entry,
                          roundData.round
                        ) !== entry.roundEntries[roundData.round].score
                          ? "#ff0000"
                          : "",
                    }}
                    value={colValue}
                    onFocus={handleFocus}
                    onChange={(value) =>
                      delayedInputChange(entry.id, "score", value)
                    }
                    onPressEnter={() => {
                      document.getElementById("filter-expression-input").focus();
                    }}
                    readOnly={roundData.finals}
                  />
                </Tooltip>
                {entry.roundEntries[roundData.round]?.scorecard && <span
                    onClick={() => {
                      setScorecardString(entry.roundEntries[roundData.round].scorecard)
                      setScorecardEntryId(entry.id)
                    }}
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                >
                <FileTextOutlined />
              </span>}
              </div>
          )}
        />

        {localCompetitionData && roundData.finals && <Column
            title={t("manager.score")}
            key={"score-final"}
            dataIndex={"score"}
            sorter={(a, b) => a.score - b.score}
        />}

        <Column
          width={55}
          title={t("manager.tiebreakerScore")}
          dataIndex={"tiebreaker"}
          key={"tiebreaker"}
          render={(colValue, entry) => (
            <ValueKeepingInputNumber
              style={{ width: "55px",
                backgroundColor:
                    entry.tie
                        ? "rgb(255,0,0,0.2)"
                        : "",}}
              value={colValue}
              onChange={(value) =>
                delayedInputChange(entry.id, "tiebreaker", value)
              }
            />
          )}
        />
        {roundData.finals && <Column
            title={"#"}
            width={35}
            dataIndex="placement"
            key="placement"
            shouldCellUpdate={shouldCellUpdate}
            render={(colValue) => colValue + "."}
        />}
        {!autoPlacement && (
          <Column
            width={55}
            title={t("manager.placement")}
            dataIndex={"placement"}
            key={"placement"}
            render={(colValue, entry) => (
              <ValueKeepingInputNumber
                style={{ width: "55px" }}
                value={colValue}
                onChange={(value) =>
                  delayedInputChange(entry.id, "placement", value)
                }
              />
            )}
          />
        )}
        <Column
            title={""}
            width={20}
            render={(colValue, entry, index) => (
                <Popconfirm
                    title={t("manager.deleteFullEntryWarning")}
                    okText={t("manager.yes")}
                    cancelText={t("manager.cancel")}
                    onConfirm={() => deleteEntry(entry.id)}
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                >
                  <DeleteTwoTone twoToneColor="#ff0000" />
                </Popconfirm>
            )}
        />
      </Table>
      {!sk && <Card title={"Excel beolvasása"}>
        <input type={"file"} ref={excelInputRef} />
        <Button
          onClick={() => {
            let reader = new FileReader();
            reader.onload = function (e) {
              let workbook = XLSX.read(e.target.result, {
                type: "binary",
              });

              let processResult = processWorkbook(workbook, localCompetitionData, autoPlacement);

              console.log(processResult);
              setScoreAutofill(false);
              setAutoPlacement(false);
              processExcelRows(processResult);
            };
            reader.readAsBinaryString(excelInputRef.current.files[0]);
          }}
        >
          Beolvasás
        </Button>
      </Card>}
    </div>
  );
};

const processWorkbook = (workbook, competition, autoPlacement) => {
   if(competition.type === "TARGET") {

    //Read all rows from First Sheet into an JSON array.
    let excelRows = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: 1,
        }
    );

    console.log("rows", excelRows);

    let entries = excelRows.slice(1).filter((row) => row.length > 0).map((row) => ({
      id: row[0],
      permitNumber: row[1],
      placement: row[2],
      name: row[3],
      club: row[4],
      gender: row[5].toLowerCase() === "férfi" ? "MALE" : "FEMALE",
      ageGroup: row[6],
      category: row[7],
      round1score: row[8],
      round1sx: row[9],
      round1s10: row[10],
      round2score: row[11],
      round2sx: row[12],
      round2s10: row[13],
      score: row[14]
    }));

    console.log("entries", entries);


    return entries;
  } else if(competition.type === "FIELD") {

    //Read all rows from First Sheet into an JSON array.
    let excelRows = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: 1,
        }
    );

    console.log("rows", excelRows);

    let entries = excelRows.slice(1).filter((row) => row.length > 0).map((row) => ({
      id: row[0],
      permitNumber: row[1],
      placement: row[2],
      name: row[3],
      club: row[4],
      gender: row[5].toLowerCase() === "férfi" ? "MALE" : "FEMALE",
      ageGroup: row[6],
      category: row[7],
      round1score: row[8],
      round1s6: row[9],
      round1s5: row[10],
      round2score: row[11],
      round2s6: row[12],
      round2s5: row[13],
      score: row[14]
    }));

    console.log("entries", entries);


    return entries;
  } else {
    console.error("UNKNOWN COMPETITION TYPE: ", competition.type);
    return [];
  }

};
