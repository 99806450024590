import React from "react";
import {Select} from "antd";

export const CountrySelect = (props) => {
    return <Select {...props}>
        <Select.Option value="HUN">Hungary</Select.Option>
        <Select.Option value="AFG">Afghanistan</Select.Option>
        <Select.Option value="ALB">Albania</Select.Option>
        <Select.Option value="ALG">Algeria</Select.Option>
        <Select.Option value="AND">Andorra</Select.Option>
        <Select.Option value="ANG">Angola</Select.Option>
        <Select.Option value="ANT">Antigua and Barbuda</Select.Option>
        <Select.Option value="ARG">Argentina</Select.Option>
        <Select.Option value="ARM">Armenia</Select.Option>
        <Select.Option value="ARU">Aruba</Select.Option>
        <Select.Option value="AUS">Australia</Select.Option>
        <Select.Option value="ANZ">Australasia</Select.Option>
        <Select.Option value="AUT">Austria</Select.Option>
        <Select.Option value="AZE">Azerbaijan</Select.Option>
        <Select.Option value="BAH">The Bahamas</Select.Option>
        <Select.Option value="BRN">Bahrain</Select.Option>
        <Select.Option value="BAN">Bangladesh</Select.Option>
        <Select.Option value="BAR">Barbados</Select.Option>
        <Select.Option value="BLR">Belarus</Select.Option>
        <Select.Option value="BEL">Belgium</Select.Option>
        <Select.Option value="BIZ">Belize</Select.Option>
        <Select.Option value="BER">Bermuda</Select.Option>
        <Select.Option value="BEN">Benin</Select.Option>
        <Select.Option value="BHU">Bhutan</Select.Option>
        <Select.Option value="BOH">Bohemia</Select.Option>
        <Select.Option value="BOL">Bolivia</Select.Option>
        <Select.Option value="BIH">Bosnia and Herzegovina</Select.Option>
        <Select.Option value="BOT">Botswana</Select.Option>
        <Select.Option value="BRA">Brazil</Select.Option>
        <Select.Option value="IVB">British Virgin Islands</Select.Option>
        <Select.Option value="BWI">British West Indies</Select.Option>
        <Select.Option value="BRU">Brunei</Select.Option>
        <Select.Option value="BUL">Bulgaria</Select.Option>
        <Select.Option value="BUR">Burkina Faso</Select.Option>
        <Select.Option value="BDI">Burundi</Select.Option>
        <Select.Option value="CAM">Cambodia</Select.Option>
        <Select.Option value="CMR">Cameroon</Select.Option>
        <Select.Option value="CAN">Canada</Select.Option>
        <Select.Option value="CPV">Cape Verde</Select.Option>
        <Select.Option value="CAY">Cayman Islands</Select.Option>
        <Select.Option value="CAF">Central African Republic</Select.Option>
        <Select.Option value="CHA">Chad</Select.Option>
        <Select.Option value="CHI">Chile</Select.Option>
        <Select.Option value="CHN">China</Select.Option>
        <Select.Option value="COL">Colombia</Select.Option>
        <Select.Option value="COM">Comoros</Select.Option>
        <Select.Option value="CGO">Congo, Republic of the</Select.Option>
        <Select.Option value="COD">Congo, Democratic</Select.Option>
        <Select.Option value="COK">Republic of the Cook Islands</Select.Option>
        <Select.Option value="CRC">Costa Rica</Select.Option>
        <Select.Option value="CIV">Cote d'Ivoire</Select.Option>
        <Select.Option value="CRO">Croatia</Select.Option>
        <Select.Option value="CUB">Cuba</Select.Option>
        <Select.Option value="CYP">Cyprus</Select.Option>
        <Select.Option value="CZE">Czechia</Select.Option>
        <Select.Option value="TCH">Czechoslovakia</Select.Option>
        <Select.Option value="DEN">Denmark</Select.Option>
        <Select.Option value="DJI">Djibouti</Select.Option>
        <Select.Option value="DMA">Dominica</Select.Option>
        <Select.Option value="DOM">Dominican Republic</Select.Option>
        <Select.Option value="GDR">East Germany</Select.Option>
        <Select.Option value="TLS">East Timor (Timor-Leste)</Select.Option>
        <Select.Option value="ECU">Ecuador</Select.Option>
        <Select.Option value="EGY">Egypt</Select.Option>
        <Select.Option value="ESA">El Salvador</Select.Option>
        <Select.Option value="GEQ">Equatorial Guinea</Select.Option>
        <Select.Option value="ERI">Eritrea</Select.Option>
        <Select.Option value="EST">Estonia</Select.Option>
        <Select.Option value="SWZ">Eswatini</Select.Option>
        <Select.Option value="ETH">Ethiopia</Select.Option>
        <Select.Option value="FIJ">Fiji</Select.Option>
        <Select.Option value="FIN">Finland</Select.Option>
        <Select.Option value="FRA">France</Select.Option>
        <Select.Option value="GAB">Gabon</Select.Option>
        <Select.Option value="GAM">The Gambia</Select.Option>
        <Select.Option value="GEO">Georgia</Select.Option>
        <Select.Option value="GER">Germany</Select.Option>
        <Select.Option value="GHA">Ghana</Select.Option>
        <Select.Option value="GRE">Greece</Select.Option>
        <Select.Option value="GRN">Grenada</Select.Option>
        <Select.Option value="GUM">Guam</Select.Option>
        <Select.Option value="GUA">Guatemala</Select.Option>
        <Select.Option value="GUI">Guinea</Select.Option>
        <Select.Option value="GBS">Guinea-Bissau</Select.Option>
        <Select.Option value="GUY">Guyana</Select.Option>
        <Select.Option value="HAI">Haiti</Select.Option>
        <Select.Option value="HON">Honduras</Select.Option>
        <Select.Option value="HKG">Hong Kong</Select.Option>
        <Select.Option value="ISL">Iceland</Select.Option>
        <Select.Option value="IND">India</Select.Option>
        <Select.Option value="INA">Indonesia</Select.Option>
        <Select.Option value="IRI">Iran</Select.Option>
        <Select.Option value="IRQ">Iraq</Select.Option>
        <Select.Option value="IRL">Ireland</Select.Option>
        <Select.Option value="ISR">Israel</Select.Option>
        <Select.Option value="ITA">Italy</Select.Option>
        <Select.Option value="JAM">Jamaica</Select.Option>
        <Select.Option value="JPN">Japan</Select.Option>
        <Select.Option value="JOR">Jordan</Select.Option>
        <Select.Option value="KAZ">Kazakhstan</Select.Option>
        <Select.Option value="KEN">Kenya</Select.Option>
        <Select.Option value="KIR">Kiribati</Select.Option>
        <Select.Option value="PRK">Korea, North (PDR of Korea)</Select.Option>
        <Select.Option value="KOR">Korea, South</Select.Option>
        <Select.Option value="KOS">Kosovo</Select.Option>
        <Select.Option value="KUW">Kuwait</Select.Option>
        <Select.Option value="KGZ">Kyrgyzstan</Select.Option>
        <Select.Option value="LAO">Laos</Select.Option>
        <Select.Option value="LAT">Latvia</Select.Option>
        <Select.Option value="LIB">Lebanon</Select.Option>
        <Select.Option value="LES">Lesotho</Select.Option>
        <Select.Option value="LBR">Liberia</Select.Option>
        <Select.Option value="LBA">Libya</Select.Option>
        <Select.Option value="LIE">Liechtenstein</Select.Option>
        <Select.Option value="LTU">Lithuania</Select.Option>
        <Select.Option value="LUX">Luxembourg</Select.Option>
        <Select.Option value="MAD">Madagascar</Select.Option>
        <Select.Option value="MAW">Malawi</Select.Option>
        <Select.Option value="MAL">Malaya</Select.Option>
        <Select.Option value="MAS">Malaysia</Select.Option>
        <Select.Option value="MDV">Maldives</Select.Option>
        <Select.Option value="MLI">Mali</Select.Option>
        <Select.Option value="MLT">Malta</Select.Option>
        <Select.Option value="MHL">Marshall Islands</Select.Option>
        <Select.Option value="MTN">Mauritania</Select.Option>
        <Select.Option value="MRI">Mauritius</Select.Option>
        <Select.Option value="MEX">Mexico</Select.Option>
        <Select.Option value="FSM">Micronesia (Federated States of)</Select.Option>
        <Select.Option value="MDA">Moldova</Select.Option>
        <Select.Option value="MON">Monaco</Select.Option>
        <Select.Option value="MGL">Mongolia</Select.Option>
        <Select.Option value="MNE">Montenegro</Select.Option>
        <Select.Option value="MAR">Morocco</Select.Option>
        <Select.Option value="MOZ">Mozambique</Select.Option>
        <Select.Option value="MYA">Myanmar (Burma)</Select.Option>
        <Select.Option value="NAM">Namibia</Select.Option>
        <Select.Option value="NRU">Nauru</Select.Option>
        <Select.Option value="NEP">Nepal</Select.Option>
        <Select.Option value="NED">Netherlands</Select.Option>
        <Select.Option value="AHO">Netherlands Antilles</Select.Option>
        <Select.Option value="NZL">New Zealand</Select.Option>
        <Select.Option value="NCA">Nicaragua</Select.Option>
        <Select.Option value="NIG">Niger</Select.Option>
        <Select.Option value="NGR">Nigeria</Select.Option>
        <Select.Option value="NBO">North Borneo</Select.Option>
        <Select.Option value="MKD">North Macedonia</Select.Option>
        <Select.Option value="YAR">North Yemen</Select.Option>
        <Select.Option value="NOR">Norway</Select.Option>
        <Select.Option value="OMA">Oman</Select.Option>
        <Select.Option value="PAK">Pakistan</Select.Option>
        <Select.Option value="PLW">Palau</Select.Option>
        <Select.Option value="PLE">Palestine</Select.Option>
        <Select.Option value="PAN">Panama</Select.Option>
        <Select.Option value="PNG">Papua New Guinea</Select.Option>
        <Select.Option value="PAR">Paraguay</Select.Option>
        <Select.Option value="PER">Peru</Select.Option>
        <Select.Option value="PHI">Philippines</Select.Option>
        <Select.Option value="POL">Poland</Select.Option>
        <Select.Option value="POR">Portugal</Select.Option>
        <Select.Option value="PUR">Puerto Rico</Select.Option>
        <Select.Option value="QAT">Qatar</Select.Option>
        <Select.Option value="ROC">Republic of China</Select.Option>
        <Select.Option value="ROU">Romania</Select.Option>
        <Select.Option value="RUS">Russia</Select.Option>
        <Select.Option value="RU1">Russian Empire</Select.Option>
        <Select.Option value="RWA">Rwanda</Select.Option>
        <Select.Option value="SAA">Saar</Select.Option>
        <Select.Option value="SKN">Saint Kitts and Nevis</Select.Option>
        <Select.Option value="LCA">Saint Lucia</Select.Option>
        <Select.Option value="VIN">Saint Vincent and the Grenadines</Select.Option>
        <Select.Option value="SAM">Samoa</Select.Option>
        <Select.Option value="SMR">San Marino</Select.Option>
        <Select.Option value="STP">Sao Tome and Principe</Select.Option>
        <Select.Option value="KSA">Saudi Arabia</Select.Option>
        <Select.Option value="SEN">Senegal</Select.Option>
        <Select.Option value="SRB">Serbia</Select.Option>
        <Select.Option value="SCG">Serbia and Montenegro</Select.Option>
        <Select.Option value="SEY">Seychelles</Select.Option>
        <Select.Option value="SLE">Sierra Leone</Select.Option>
        <Select.Option value="SIN">Singapore</Select.Option>
        <Select.Option value="SVK">Slovakia</Select.Option>
        <Select.Option value="SLO">Slovenia</Select.Option>
        <Select.Option value="SOL">Solomon Islands</Select.Option>
        <Select.Option value="SOM">Somalia</Select.Option>
        <Select.Option value="RSA">South Africa</Select.Option>
        <Select.Option value="SSD">South Sudan</Select.Option>
        <Select.Option value="YMD">South Yemen</Select.Option>
        <Select.Option value="URS">Soviet Union</Select.Option>
        <Select.Option value="ESP">Spain</Select.Option>
        <Select.Option value="SRI">Sri Lanka</Select.Option>
        <Select.Option value="SUD">Sudan</Select.Option>
        <Select.Option value="SUR">Suriname</Select.Option>
        <Select.Option value="SWE">Sweden</Select.Option>
        <Select.Option value="SUI">Switzerland</Select.Option>
        <Select.Option value="SYR">Syria</Select.Option>
        <Select.Option value="TPE">Taiwan (Chinese Taipei)</Select.Option>
        <Select.Option value="TJK">Tajikistan</Select.Option>
        <Select.Option value="TAN">Tanzania</Select.Option>
        <Select.Option value="THA">Thailand</Select.Option>
        <Select.Option value="TOG">Togo</Select.Option>
        <Select.Option value="TGA">Tonga</Select.Option>
        <Select.Option value="TRI">Trinidad and Tobago</Select.Option>
        <Select.Option value="TUN">Tunisia</Select.Option>
        <Select.Option value="TUR">Türkiye</Select.Option>
        <Select.Option value="TKM">Turkmenistan</Select.Option>
        <Select.Option value="TUV">Tuvalu</Select.Option>
        <Select.Option value="UGA">Uganda</Select.Option>
        <Select.Option value="UKR">Ukraine</Select.Option>
        <Select.Option value="EUN">Unified Team</Select.Option>
        <Select.Option value="EUA">Unified Team of Germany</Select.Option>
        <Select.Option value="UAE">United Arab Emirates</Select.Option>
        <Select.Option value="RAU">United Arab Republic</Select.Option>
        <Select.Option value="GBR">United Kingdom (Great Britain)</Select.Option>
        <Select.Option value="USA">United States</Select.Option>
        <Select.Option value="URU">Uruguay</Select.Option>
        <Select.Option value="UZB">Uzbekistan</Select.Option>
        <Select.Option value="VAN">Vanuatu</Select.Option>
        <Select.Option value="VEN">Venezuela</Select.Option>
        <Select.Option value="VIE">Vietnam</Select.Option>
        <Select.Option value="ISV">Virgin Islands</Select.Option>
        <Select.Option value="FRG">West Germany</Select.Option>
        <Select.Option value="YEM">Yemen</Select.Option>
        <Select.Option value="YUG">Yugoslavia</Select.Option>
        <Select.Option value="ZAM">Zambia</Select.Option>
        <Select.Option value="ZIM">Zimbabwe</Select.Option>
    </Select>
}