import { useState, useEffect } from "react";

export const useNetworkStatus = () => {
  const [isOnline, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const goOnline = () => {
      setOnline(true);
    };

    const goOffline = () => {
      setOnline(false);
    };

    window.addEventListener("offline", goOffline);
    window.addEventListener("online", goOnline);

    return () => {
      window.removeEventListener("offline", goOffline);
      window.removeEventListener("online", goOnline);
    };
  }, []);

  return isOnline;
};
