import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getResults = async (key, permitNumber) => {
  const { data } = await axios.get(`${API_URL}/athlete/${permitNumber}/result`);
  return data;
};

export const getMiszResults = async (key, permitNumber) => {
  const { data } = await axios.get(`${API_URL}/miszresults/${permitNumber}`);
  return data;
};
