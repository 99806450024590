import React from "react";
import { Col, Form, Input, Modal, Rate, Row, Typography } from "antd";
import { useMutation, useQueryCache } from "react-query";
import Swal from "sweetalert2";
import { postReview } from "../api/Review";

export const ReviewModal = ({
  competition,
  uuid,
  isVisible,
  onOk,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const cache = useQueryCache();

  const [postReviewMutate, { isLoading }] = useMutation(postReview, {
    onSuccess: () => {
      cache.invalidateQueries(["reviews", competition.id]);
      Swal.fire({
        title: "Sikeres értékelés!",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    },
    onError: (error) => {
      Swal.fire({
        title:
          error.response.data.message === "INVALID_UUID"
            ? "Ezzel a linkkel már adtak le szavazatot!"
            : "Hiba történt!",
        icon: "error",
        timer: 4000,
        showConfirmButton: false,
      });
    },
  });

  return (
    <Modal
      title={`${competition.name} értékelése`}
      visible={isVisible}
      onOk={() => form.submit()}
      onCancel={onCancel}
      okText={"Értékelés beküldése"}
      cancelText={"Nem szeretnék értékelni"}
      confirmLoading={isLoading}
    >
      <Form
        name="review"
        form={form}
        layout={"vertical"}
        requiredMark={false}
        initialValues={{
          applicationSite: null,
          parking: null,
          office: null,
          practiceField: null,
          competitionField: null,
          targets: null,
          lunch: null,
          resultAnnouncement: null,
          comment: "",
        }}
        onFinish={(values) => {
          console.log("onfinish", values);
          postReviewMutate({
            competitionId: competition.id,
            review: { ...values, uuid },
          }).then(() => onOk());
        }}
        onFinishFailed={(error) => console.log("onfinishfailed", error)}
      >
        <Row>
          <Col xs={24} sm={12}>
            <Form.Item label="Nevezési oldal" name="applicationSite">
              <Rate />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Megközelíthetőség / parkolás" name="parking">
              <Rate />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Versenyiroda működése" name="office">
              <Rate />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Belövőpálya" name="practiceField">
              <Rate />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Versenypálya / célok kihelyezése"
              name="competitionField"
            >
              <Rate />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Célok minősége / állapota" name="targets">
              <Rate />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Ebéd" name="lunch">
              <Rate />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Eredményhírdetés" name="resultAnnouncement">
              <Rate />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Üzenet a szervezőknek (nem nyilvános)"
              name="comment"
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Typography.Paragraph>
        Az értékelés teljes mértékben anoním. A szöveges értékelést csak a
        szervezők kapják meg, a csillagos értékeléseket összesítve jelenítjük
        meg.
      </Typography.Paragraph>
    </Modal>
  );
};
