import React from "react";
import "./Home.less";
import { Card, Row, Col, Table } from "antd";
import { useQuery } from "react-query";
import { UpcomingCompetitions } from "./UpcomingCompetitions";
import { LatestScores } from "./LatestScores";
import { SocialButtons } from "./SocialButtons";
import { getFirstRanked } from "../api/Ranking";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { GenderTag } from "artemis-shared/tag/GenderTag";
import _ from "lodash";
import { Link, NavLink } from "react-router-dom";

const { Column } = Table;

export const Home = () => {
  const { data: firstRanked } = useQuery(["firstRanked"], getFirstRanked);

  return (
    <div className={"home-page"}>
      <Row gutter={[16, 16]}>
        <Col xs={24} lg={12}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card
                title={"Versenyek a héten"}
                extra={[
                  <Link key="c" to={"/competitions"}>
                    Többi verseny &gt;
                  </Link>,
                ]}
              >
                <UpcomingCompetitions />
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Card title={"Legfrissebb eredmények"}>
                <LatestScores />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          <SocialButtons />
          {firstRanked && (
            <Card title={"Elsők a ranglistán"}>
              <Table
                rowClassName={"ranking-table-row"}
                dataSource={_.sortBy(firstRanked, [
                  "category.name",
                  "ageGroup.name",
                  "gender",
                ])}
                pagination={false}
                showHeader={false}
              >
                <Column
                  dataIndex={["category", "name"]}
                  key="category"
                  render={(text) => <CategoryTag category={text} />}
                  width={10}
                />
                <Column
                  dataIndex={["ageGroup", "name"]}
                  key="ageGroup"
                  render={(text) => <AgeGroupTag ageGroup={text} />}
                  width={10}
                />
                <Column
                  dataIndex={["gender"]}
                  key="gender"
                  render={(text) => <GenderTag gender={text} />}
                  width={10}
                />
                <Column
                  dataIndex={"name"}
                  render={(text, record) => (
                    <NavLink to={"/athlete/" + record.permitNumber}>
                      {text}
                    </NavLink>
                  )}
                />
                <Column
                  dataIndex={["club", "name"]}
                  responsive={["md"]}
                  render={(text, record) => (
                    <NavLink to={"/club/" + record.club.id}>{text}</NavLink>
                  )}
                />
              </Table>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};
