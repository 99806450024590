import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const sendSubscription = async (subscription) => {
  const { data } = await axios.put(
    `${API_URL}/notification/subscription`,
    subscription
  );
  return data;
};

export const getSubscription = async (key, subKey) => {
  const { data } = await axios(
    `${API_URL}/notification/subscription/${subKey}`
  );
  return data;
};
