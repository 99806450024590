import { Select } from "antd";
import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const { Option } = Select;

export const AgeGroupInput = ({ ageGroups, ...restProps }) => {
  const {t} = useTranslation();
  return (
    <Select {...restProps} dropdownMatchSelectWidth={170}>
      {ageGroups
        .sort((a, b) => a.fromAge - b.fromAge)
        .map((ageGroup) => (
          <Option key={ageGroup.id} value={ageGroup.id}>
            {t(ageGroup.ageGroup.name)} ({ageGroup.fromAge}-{ageGroup.toAge})
          </Option>
        ))}
    </Select>
  );
};

AgeGroupInput.propTypes = {
  ageGroups: PropTypes.array,
};
