import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getQualification = async (key, id) => {
  const { data } = await axios.get(`${API_URL}/qualification/${id}`);
  return data;
};

export const getQualificationSets = async () => {
  const { data } = await axios.get(`${API_URL}/qualification-sets`);
  return data;
};
