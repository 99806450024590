import React, { useEffect, useState } from "react";
import {
  Button,
  PageHeader,
  Tabs,
  Empty,
  Popconfirm,
  Statistic,
  Space,
  Divider,
  Modal,
  Alert,
  Table,
  message,
  Select,
  Tooltip,
  Switch,
  Checkbox, Collapse,
} from "antd";
import { useMutation, useQuery, useQueryCache } from "react-query";
import {
  getManagedCompetition,
  getManagedCompetitionEntries,
  updateEntries,
  updateTeamCount,
} from "../api/Competition";
import { useParams } from "react-router-dom";
import { ScoreTab } from "./scoreTab/ScoreTab";
import { PlacementTab } from "./placementTab/PlacementTab";
import { getClubs } from "artemis-shared/api/Club";
import { db } from "../utils/LocalDB";
import {useHistory, useLocation} from "react-router";
import {
  CloudDownloadOutlined,
  CloudSyncOutlined,
  CloudUploadOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined, PrinterFilled,
  QrcodeOutlined,
} from "@ant-design/icons";
import moment from "moment";
import XLSX from "xlsx";
import { useNetworkStatus } from "../networkStatus/NetworkStatus";
import _ from "lodash";
import {calculateArrowCount, calculateScore, sumRounds} from "./ManagerUtils";
import { QRReader } from "../scorecard/QRReader";

import "./Manager.less";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { GenderTag } from "artemis-shared/tag/GenderTag";
import { EntryTab } from "./entryTab/EntryTab";
import { MultiRoundScoreTab } from "./scoreTab/MultiRoundScoreTab";
import Swal from "sweetalert2";
import { decodeScorecard } from "artemis-shared/scorecard/ScorecardUtils";
import { CategorySelectorModal } from "./display/CategorySelectorModal";
import { getLiveData } from "../api/Live";
import {groupEntries} from "artemis-shared/utils/CompetitionEntryUtils";
import {ScorecardDisplay} from "artemis-shared/scorecard/ScorecardDisplay";
import TextArea from "antd/lib/input/TextArea";
import {StickerModal} from "../manageCompetition/StickerModal";
import {useTranslation} from "react-i18next";
import {downloadFile} from "../utils/DownloadFile";
import {exportDB} from "dexie-export-import";
import {getArrowCount} from "artemis-shared/utils/CompetitionUtils";
import {ParallelModal} from "./ParallelModal";

const { TabPane } = Tabs;
const { Column } = Table;
const { confirm } = Modal;

export const Manager = ({sk}) => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if(sk) {
      i18n.changeLanguage("sk");
    }
  }, []);

  let networkStatus = useNetworkStatus();
  let history = useHistory();
  let location = useLocation();
  const cache = useQueryCache();
  let { competitionId, slug } = useParams();
  const [liveEnabled, setLiveEnabled] = useState(false);
  const [autoPlacement, setAutoPlacement] = useState(true);
  const [filter, setFilter] = useState({});
  const [filterExpression, setFilterExpression] = useState("");
  const [teamFilter, setTeamFilter] = useState(null);
  const [localCompetitionData, setlocalCompetitionData] = useState(null);
  const [broadcastChannel, setBroadcastChannel] = useState(null);
  const [localEntries, setLocalEntries] = useState([]);
  const [localClubs, setLocalClubs] = useState([]);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [qrDecoded, setQrDecoded] = useState(null);
  const [qrControlResult, setQrControlResult] = useState(null);
  const [stickerModalVisible, setStickerModalVisible] = useState(false);
  const [selectedDisplay, setSelectedDisplay] = useState(null);
  const [qrData, setQrData] = useState(null);
  const [qrControlData, setQrControlData] = useState(null);
  const [selectedTab, setSelectedTab] = useState("1");
  const [displayScoringValues, setDisplayScoringValues] = useState([]);
  const [possibleScoringValues, setPossibleScoringValues] = useState([]);

  const [scorecardString, setScorecardString] = useState(null);
  const [scorecardEntryId, setScorecardEntryId] = useState(null);
  const [manualQrValue, setManualQrValue] = useState("");

  const searchParams = new URLSearchParams(location.search);
  let devMode = searchParams.get("devmode")

  const groupedEntries = groupEntries(localEntries);

  const { refetch } = useQuery(
    ["managed-competition-entries", parseInt(competitionId)],
    getManagedCompetitionEntries,
    {
      onSuccess: async (data) => {
        await backupDB();
        console.log("delete entries begin");
        await db.entries
          .where("competition")
          .equals(parseInt(competitionId))
          .delete();
        console.log("delete entries end");
        saveEntriesToLocal(data);
      },
      enabled: false,
    }
  );

  const { data: competition } = useQuery(
    ["managed-competition", parseInt(competitionId)],
    getManagedCompetition,
      {
        enabled: !sk
      }
  );

  const { data: clubs } = useQuery("clubs", getClubs);

  const [updateEntriesMutation] = useMutation(updateEntries, {
    onSuccess: () => {
      cache.invalidateQueries([
        "managed-competition-entries",
        parseInt(competitionId),
      ]);
      Swal.fire({
        title: t("manager.uploadSuccess"),
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    },
    onError: () => {
      Swal.fire({
        title: t("manager.uploadFailed"),
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    },
  });

  const [updateTeamCountMutation] = useMutation(updateTeamCount, {
    onSuccess: () => {
      cache.invalidateQueries(["managed-competition", parseInt(competitionId)]);
    },
  });

  /*const { data: liveData } = useQuery(
    ["live-data", parseInt(competitionId)],
    getLiveData,
    {
      enabled: liveEnabled,
      refetchInterval: 3000,
      onSuccess: async (result) => {
        console.log("live", result);

        let updatedEntries = [];

        result.forEach((teamData) => {
          let decoded = decodeScorecard(teamData.scorecards, true);

          console.log("decoded", teamData.id, decoded);

          Object.values(decoded.decodedScorecards).forEach(
            (decodedScorecard) => {
              let scoresSum = _.countBy(
                _.flatten(decodedScorecard.scores.map((sc) => sc.scores))
              );
              console.log("sc", scoresSum);

              let localEntry = _.find(localEntries, {
                liveToken: decodedScorecard.token,
              });

              if (localEntry) {
                console.log("found", decodedScorecard.token);
                let newEntry = _.cloneDeep(localEntry);

                Object.entries(scoresSum).forEach(([key, value]) => {
                  newEntry["s" + key] = value;
                });

                newEntry.score = calculateScore(
                  localCompetitionData.scoring,
                  newEntry
                );

                updatedEntries.push(newEntry);
              } else {
                console.log("local entry not found ", decodedScorecard.token);
              }
            }
          );
        });

        if (updatedEntries.length > 0) {
          await db.entries.bulkPut(updatedEntries);
          console.log("updated live data");
          let local = await getLocalEntries();
          calculateAndSetPlacement(local).then(() =>
            broadcastChannel.postMessage({ selectChanged: true })
          );
        }
      },
    }
  );*/

  useEffect(() => {
    const bc = new BroadcastChannel("manager_display_channel");
    setBroadcastChannel(bc);
    bc.postMessage({ valami: 1 });
  }, []);

  const getLocalCompetition = async () => {
    let localCompetition = await db.competitions
      .where("id")
      .equals(parseInt(competitionId))
      .toArray();

    console.log("got", localCompetition);

    if (localCompetition[0]) {
      setlocalCompetitionData({
        ...localCompetition[0],
        // a már letöltött de még roundsal nem rendelkező competitionok miatt
        rounds: localCompetition[0].rounds || [],
      });

      if (displayScoringValues.length === 0) {
        if((localCompetition[0].rounds || []).length > 1) {
          const psv = localCompetition[0].rounds.reduce((acc, round) => {
            return _.union(acc, round.scoring);
          }, []);
          setPossibleScoringValues(psv)
          setDisplayScoringValues(psv);
        } else {
          setPossibleScoringValues(localCompetition[0].scoring);
          setDisplayScoringValues(localCompetition[0].scoring);
        }
      }
    }
  };

  const createNewTeam = async () => {
    await db.competitions
      .where("id")
      .equals(parseInt(competitionId))
      .modify({
        teamCount: localCompetitionData.teamCount + 1,
        teamCountChanged: true,
      });

    getLocalCompetition();
  };

  const getLocalEntries = async () => {
    let localEntries = await db.entries
      .where("competition")
      .equals(parseInt(competitionId))
      .toArray();

    console.log("got", localEntries);

    if (localEntries) {
      setLocalEntries(localEntries);
    }

    return localEntries;
  };

  const getLocalClubs = async () => {
    let localClubs = await db.clubs.toArray();

    console.log("got", localClubs);

    if (localClubs) {
      setLocalClubs(localClubs);
    }

    return localClubs;
  };

  const clearSelection = async () => {
    let entries = _.filter(localEntries, "selected");
    console.log(entries);
    await db.entries
      .where("id")
      .anyOf(entries.map((entry) => entry.id))
      .modify({ selected: false });
    broadcastChannel.postMessage({ selectChanged: true });
    getLocalEntries();
  };

  const saveEntriesToLocal = (entries) => {
    db.competitions.put({
      id: parseInt(competitionId),
      name: competition.name,
      type: competition.type,
      scoring: competition.scoring,
      tiebreaking: competition.tiebreaking,
      categories: competition.categories,
      ageGroups: competition.ageGroups,
      location: competition.location,
      startDate: competition.startDate,
      syncDate: new Date(),
      teamsPublished: competition.teamsPublished,
      teamCount: competition.teamCount,
      lunch: competition.lunch,
      lunchFee: competition.lunchFee,
      targetCount: competition.targetCount,
      arrowCount: competition.arrowCount,
      rounds: competition.rounds,
      description: competition.description,
      additionalData: competition.additionalData,
      teamLabels: competition.teamLabels,
      slug: competition.slug,
      chooseRound: competition.chooseRound,
      subtype: competition.subtype,
      international: competition.international,
      arrowCountExceptions: competition.arrowCountExceptions,
      singleRoundEntryFee: competition.singleRoundEntryFee
    });
    db.entries.bulkPut(
      entries.map((entry) => {
        entry.roundEntries.forEach((roundEntry, roundIndex) => {
          let splitFinals =
            roundEntry.finals === null ? [] : roundEntry.finals.split(";");

          splitFinals.forEach((value, arrowIndex) => {
            entry.roundEntries[roundIndex]["f" + arrowIndex] =
              value === "" ? null : parseInt(value);
          });
        });

        return {
          ...entry,
          competition: parseInt(competitionId),
          team: !entry.team ? 0 : entry.team,
        };
      })
    );

    db.entries.where("id").above(999999999).delete();

    db.clubs.bulkPut(clubs);

    getLocalEntries();
    getLocalCompetition();
    getLocalClubs();
  };


  const finalsType = localCompetitionData?.type === "HISTORICAL" ? "SCORE" : "HITS";

  const readQr = (value) => {
    let decodedScorecard = decodeScorecard(value.text);
    let entriesString = decodedScorecard.entriesString;

    let round = selectedTab.startsWith("round") ? selectedTab.substr(5) : null;

    let explodedValues = entriesString.split(";");
    let updatedEntries = explodedValues.map((entryValue) => {
      let explodedValue = entryValue.split(",");
      let id = parseInt(explodedValue[0]);
      console.log("id", id);

      let localEntry = _.find(localEntries, { id: id });

      if (!localEntry) {
        console.error("Hiányzó entry!! : " + localEntry);
        return { id: id, error: true };
      }

      let newEntry = _.cloneDeep(localEntry);

      let score = 0;

      const scoringValues = round ? localCompetitionData.rounds[round].scoring : localCompetitionData.scoring;

      const maxScoringValue = _.max(
        scoringValues.map((value) =>
          value === "x" ? -1 : parseInt(value)
        )
      );

      [...scoringValues]
        .sort((a, b) => (b == "x" ? 15 : b) - (a == "x" ? 15 : a))
        .map((scoringValue, index) => {
          let countOfScoringValue = parseInt(explodedValue[1 + index]);

          if (round) {
            newEntry.roundEntries[round]["s" + scoringValue] =
              countOfScoringValue;
          } else {
            newEntry["s" + scoringValue] = countOfScoringValue;
          }

          score +=
            (scoringValue === "x" ? maxScoringValue : parseInt(scoringValue)) *
            countOfScoringValue;
        });

      if (round) {
        const isFinals = localCompetitionData.rounds[round]?.finals;
        if(isFinals) {
          const finalsScores = decodedScorecard.decodedScorecards[id].scores.map((sc) => {
            return sc.scores.reduce((acc, currentValue) => {
              return acc + parseInt(currentValue)
            }, 0)
          })

          finalsScores.forEach((score, ai) => newEntry.roundEntries[round]["f" + ai] = parseInt(score));

          newEntry.roundEntries[round].finals = finalsScores.join(";");
        }

        newEntry.roundEntries[round].score = score;
        newEntry.roundEntries[round].scorecard =
          decodedScorecard.scorecards[id];
        sumRounds(newEntry, localCompetitionData.subtype, finalsType);
      } else {
        newEntry.score = score;
        newEntry.scorecard = decodedScorecard.scorecards[id];
      }

      return newEntry;
    });

    console.log("updatedentries", updatedEntries);

    if(qrModalVisible === "control") {
      let oldEntries = qrDecoded.entriesString.split(";");
      let newEntries = decodedScorecard.entriesString.split(";");

      if(oldEntries.length !== newEntries.length) {
        setQrControlResult("length");
        setQrModalVisible(true);
        return;
      }

      let mismatching = [];

      oldEntries.forEach(entry => {
        let entryId = entry.split(",")[0];
        let foundEntry = _.find(newEntries, newEntry => entryId === newEntry.split(",")[0]);

        if(!foundEntry) {
          mismatching.push(entryId);
        } else {
          if(entry !== foundEntry) {
            mismatching.push(entryId);
          }
        }
      });

      setQrControlData(updatedEntries);
      setQrControlResult(mismatching);
      setQrModalVisible(true);
    } else {
      setQrData(updatedEntries);
      setQrDecoded(decodedScorecard);
    }
  };

  const saveQrData = async () => {
    await db.entries.bulkPut(_.filter(qrData, (value) => !value.error).map(entry => ({...entry, lastUpdated: Date.now()})));
    message.success(t("manager.scorecardsLoaded"));
    let local = await getLocalEntries();
    calculateAndSetPlacement(local);
    setQrData(null);
    setQrControlData(null);
    setQrControlResult(null);
    setQrDecoded(null);
  };

  const saveSecondQrData = async () => {
    await db.entries.bulkPut(_.filter(qrControlData, (value) => !value.error).map(entry => ({...entry, lastUpdated: Date.now()})));
    message.success(t("manager.scorecardsLoaded"));
    let local = await getLocalEntries();
    calculateAndSetPlacement(local);
    setQrData(null);
    setQrControlData(null);
    setQrControlResult(null);
    setQrDecoded(null);
  };

  const onMountEffect = () => {
    getLocalCompetition();
    getLocalEntries();
    getLocalClubs();
  };

  useEffect(onMountEffect, []);

  const updateEntry = async (entryId, property, value) => {
    console.log("updateentry");
    await db.entries
      .where("id")
      .equals(entryId)
      .modify({ [property]: value, lastUpdated: Date.now() });

    broadcastChannel.postMessage({ selectChanged: true });

    await getLocalEntries();
  };

  const updateFullEntry = (entryId, entry) => {
    console.log("updatefullentry", entry);
    let promise = db.entries.where("id").equals(entryId).modify({...entry, lastUpdated: Date.now()});
    promise.then(() => broadcastChannel.postMessage({ selectChanged: true }));
    return promise;
  };

  const deleteEntry = async (entryId) => {
    console.log("deleteentry", entryId);
    await db.entries.where("id").equals(entryId).delete();
    let local = await getLocalEntries();
    calculateAndSetPlacement(local);
  };

  const createEntry = async (entry) => {
    console.log("createEntry", entry);
    entry.competition = parseInt(competitionId);
    entry.lastUpdated = Date.now();

    if (_.isNumber(entry.rounds)) {
      entry.roundEntries = [];
      localCompetitionData.rounds.forEach((round, index) => {
        entry.roundEntries.push({
          round: index,
          active:
            entry.rounds === 0 ||
            index === entry.rounds - 1 ||
            (index === localCompetitionData.rounds.length - 1 &&
              entry.rounds === 0),
          s0: null,
          s1: null,
          s2: null,
          s3: null,
          s4: null,
          s5: null,
          s6: null,
          s7: null,
          s8: null,
          s9: null,
          s10: null,
          s11: null,
          s12: null,
          s13: null,
          s14: null,
          s15: null,
          s16: null,
          s17: null,
          s18: null,
          s19: null,
          s20: null,
          sx: null,
          score: null,
        });
      });
    }

    entry.id = _.maxBy(localEntries, "id")
      ? _.maxBy(localEntries, "id").id + 1
      : 1;
    entry.withTempId = true;
    entry.comment = "";
    await db.entries.put(entry);

    let local = await getLocalEntries();
    calculateAndSetPlacement(local);

    Swal.fire({
      title: t("manager.registerSuccess"),
      text:
        entry.name +
        " - " +
        entry.category.name +
        " " +
        entry.ageGroup.ageGroup.name +
        " " +
        entry.gender,
      icon: "success",
      timer: 2000,
      showConfirmButton: false,
    });
  };

  const calculateAndSetPlacement = async (localEntries) => {
    console.log("calculateAndSetPlacement", autoPlacement);

    if (!autoPlacement) {
      return;
    }

    let entries = [...localEntries];

    let categories = {};

    entries.forEach((entry) => {
      entry.tie = false;
      let categoryId =
        "" + entry.category.id + entry.ageGroup.id + entry.gender;
      if (!categories[categoryId]) {
        categories[categoryId] = [];
      }

      categories[categoryId].push(entry);
    });

    let categoryIdList = Object.keys(categories);

    categoryIdList.forEach((categoryId) => {
      categories[categoryId].sort((a, b) => {
        if (a.score > b.score) {
          return 1;
        }
        if (a.score < b.score) {
          return -1;
        }

        //////// TIE IN NON LDTS FINALS
        if(localCompetitionData.subtype !== "LDTS" && localCompetitionData.rounds?.find(round => round.finals)) {
          if(a.roundEntries[a.roundEntries.length - 1]?.score && b.roundEntries[b.roundEntries.length - 1]?.score) {
            if (a.tiebreaker > b.tiebreaker) {
              return 1;
            }
            if (a.tiebreaker < b.tiebreaker) {
              return -1;
            }

            a.tie = true;
            b.tie = true;
            return 0;
          }
        }
        ////////////

        for (let i = 0; i < localCompetitionData.tiebreaking.length; i++) {
          let tiebreakerValue = localCompetitionData.tiebreaking[i];

          if (tiebreakerValue === "0") {
            if (a.s0 < b.s0) {
              return 1;
            }
            if (a.s0 > b.s0) {
              return -1;
            }
          } else if (tiebreakerValue === "T") {
            if (a.tiebreaker > b.tiebreaker) {
              return 1;
            }
            if (a.tiebreaker < b.tiebreaker) {
              return -1;
            }
          } else {
            if (a["s" + tiebreakerValue] > b["s" + tiebreakerValue]) {
              return 1;
            }
            if (a["s" + tiebreakerValue] < b["s" + tiebreakerValue]) {
              return -1;
            }
          }
        }

        a.tie = true;
        b.tie = true;
        return 0;
      });

      categories[categoryId].reverse();

      categories[categoryId].forEach((e, index) => {
        e.placement = index + 1;
      });
    });


    //////////////////// LDTS /////////////////////

    if(localCompetitionData.subtype === "LDTS" && localCompetitionData.rounds.find(round => round.finals)) {
      categoryIdList.forEach((categoryId) => {

        let subList = categories[categoryId].length >= 6 ? categories[categoryId].slice(0,6) : [];

        subList.forEach((entry) => {
          entry.tie = false;
        });

        subList.sort((a, b) => {
          if (a.roundEntries[a.roundEntries.length - 1].score > b.roundEntries[b.roundEntries.length - 1].score) {
            return 1;
          }
          if (a.roundEntries[a.roundEntries.length - 1].score < b.roundEntries[b.roundEntries.length - 1].score) {
            return -1;
          }

          if (a.score > b.score) {
            return 1;
          }
          if (a.score < b.score) {
            return -1;
          }

          if (a.tiebreaker > b.tiebreaker) {
            return 1;
          }
          if (a.tiebreaker < b.tiebreaker) {
            return -1;
          }

          console.log("TIE");

          a.tie = true;
          b.tie = true;
          return 0;
        });

        subList.reverse();

        subList.forEach((e, index) => {
          e.placement = index + 1;
        });
      });
    }

    /////////////////////////// LDTS ///////////////////////


    await db.entries.bulkPut(entries);
    await getLocalEntries();

    broadcastChannel.postMessage({});
  };

  const showSyncConfirm = () => {
    confirm({
      title: t("manager.updateWarning"),
      icon: <ExclamationCircleOutlined />,
      content: (
        <span>
          Erre a készülékre már letöltötted a nevezéseket. Az adatok ismételt
          letöltésével a készüléken lévő nevezések{" "}
          <strong>frissülni fognak azzal ami az Íjász.NET-en szerepel</strong>.
          Az ezen a készüléken megadott, és még nem feltöltött pontszámok{" "}
          <strong>törlődnek</strong>! Emellett a csak ezen a készüléken meglévő,
          azonosító nélküli, <strong>új nevezések is törlésre kerülnek</strong>! A letöltés előtt egy biztonsági mentés fog készülni a jelenlegi állapotról.
        </span>
      ),
      cancelText: t("manager.cancel"),
      okText: t("manager.downloadAgain"),
      okType: "danger",
      onOk() {
        cache.invalidateQueries([
          "managed-competition-entries",
          parseInt(competitionId),
        ]);
        refetch();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const getQrDataTable = (source) => {
    let round = selectedTab.startsWith("round") ? selectedTab.substr(5) : null;
    const scoringValues = round ? localCompetitionData.rounds[round].scoring : localCompetitionData.scoring;
    return (
      <div className={"qr-data-table"}>
        {_.some(source, { error: true }) && (
          <Alert
            message={t("manager.scanQrError")}
            type="error"
          />
        )}
        <Table
          dataSource={source}
          size={"small"}
          pagination={false}
          rowClassName={(record) => (record.error || (_.isArray(qrControlResult) && qrControlResult.indexOf(record.id?.toString()) > -1) ? "error" : "")}
        >
          <Column title={"#"} dataIndex={"id"} />
          <Column title={t("manager.teamShort")} dataIndex={"team"} />
          <Column title={t("manager.entryName")} dataIndex={"name"} />
          <Column
            title={t("manager.categoryShort")}
            dataIndex={["category", "name"]}
            render={(colValue, record) => (
              <Tooltip placement="right" title={record.category?.description}>
                <div>
                  <CategoryTag category={colValue} />
                </div>
              </Tooltip>
            )}
          />
          <Column
            title={t("manager.ageGroupShort")}
            dataIndex={["ageGroup", "ageGroup", "name"]}
            render={(colValue) => <AgeGroupTag ageGroup={colValue} />}
          />
          <Column
            title={t("manager.gender")}
            dataIndex={"gender"}
            render={(colValue) => <GenderTag gender={colValue} />}
          />
          {[...scoringValues]
            .sort((a, b) => (b == 'x' ? 15 : b) - (a == 'x' ? 15 : a))
            .map((scoringValue) => (
              <Column
                key={"s" + scoringValue}
                title={scoringValue}
                dataIndex={round ? ["roundEntries", round, "s" + scoringValue] : "s" + scoringValue}
              />
            ))}
          <Column title={t("manager.score")} dataIndex={round ? ["roundEntries", round, "score"] : "score"} />
        </Table>
      </div>
    );
  };

  const generateExcel = () => {
    let allScoringValues = [];

    if(localCompetitionData.rounds.length > 0) {
      localCompetitionData.rounds.forEach(round => {
        [...round.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(
            (scoringValue) => {
              allScoringValues.push(scoringValue);
            }
        );
      })
    }

    allScoringValues = _.uniq(allScoringValues);

    let flatEntries = [...localEntries].map((entry) => {
      let newEntry = {
        id: entry.id,
        name: entry.name,
        permitNumber: entry.permitNumber,
        club: entry.club.name,
        team: entry.team,
        lunch: entry.lunch,
        category: entry.category.name,
        ageGroup: entry.ageGroup.ageGroup.name,
        gender: entry.gender === "MALE" ? t("genderTag.MALE") : t("genderTag.FEMALE"),
        score: entry.score,
        tiebreaker: entry.tiebreaker,
        placement: entry.placement,
        comment: entry.comment,
        adminComment: entry.adminComment,
        email: entry.email,
        lunchFee: entry.lunchFee,
        entryFee: entry.entryFee,
        additionalData0: entry.additionalData0,
        additionalData1: entry.additionalData1,
        additionalData2: entry.additionalData2,
        additionalData3: entry.additionalData3,
        additionalData4: entry.additionalData4,
        s12: entry.s12,
        scorecard: entry.scorecard,
        country: entry.country
      };

      if(allScoringValues.length > 0) {
        allScoringValues.forEach(
            (scoringValue) =>
                (newEntry["s" + scoringValue] = entry["s" + scoringValue])
        );
      } else {
        localCompetitionData.scoring.forEach(
            (scoringValue) =>
                (newEntry["s" + scoringValue] = entry["s" + scoringValue])
        );
      }

      if(localCompetitionData.rounds.length > 0) {
        localCompetitionData.rounds.forEach(round => {
          [...round.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(
              (scoringValue) =>
                  (newEntry["r" + round.round + "_s" + scoringValue] = entry.roundEntries[round.round]["s" + scoringValue])
          );
          newEntry["r" + round.round + "_score"] = entry.roundEntries[round.round]["score"];
        })
      }

      return newEntry;
    });
    let workBook = XLSX.utils.book_new();
    let headers = [
      "id",
      "name",
      "permitNumber",
      localCompetitionData.international ? "country" : "club",
      "team",
      "lunch",
      "category",
      "ageGroup",
      "gender",
    ];

    if(localCompetitionData.rounds.length > 0) {
      localCompetitionData.rounds.forEach(round => {
        [...round.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(
            (scoringValue) => {
              headers = headers.concat(
                  ["r" + round.round + "_s" + scoringValue]
              );
            }
        );
        headers = headers.concat(
            ["r" + round.round + "_score"]
        );
      })
    }

    if(allScoringValues.length > 0) {
      headers = headers.concat(
          [...allScoringValues]
              .sort((a,b) => parseInt(b)-parseInt(a))
              .map((scoringValue) => "s" + scoringValue)
      );
    } else {
      headers = headers.concat(
          [...localCompetitionData.scoring, 12]
              .sort((a,b) => parseInt(b)-parseInt(a))
              .map((scoringValue) => "s" + scoringValue)
      );
    }

    headers = headers.concat([
      "score",
      "tiebreaker",
      "placement",
      "entryFee",
      "lunchFee",
      "comment",
      "adminComment",
      "email",
      "additionalData0",
      "additionalData1",
      "additionalData2",
      "additionalData3",
      "additionalData4",
        "scorecard"
    ]);

    let workSheet = XLSX.utils.json_to_sheet(flatEntries, {
      header: headers,
    });
    XLSX.utils.book_append_sheet(workBook, workSheet, "Adatok");
    XLSX.writeFile(workBook, localCompetitionData.name + ".xls");
  };

  const generateExcelSK = () => {
    let groupedEntries = groupEntries([...localEntries]);
    let sortedGroupEntries = Object.entries(groupedEntries).sort((a, b) => {
      if (a[1].category.name.localeCompare(b[1].category.name) == 1) return 1;
      if (a[1].category.name.localeCompare(b[1].category.name) == -1) return -1;

      return a[1].ageGroup.fromAge - b[1].ageGroup.fromAge;
    });

    let finalsRoundIndex = _.findIndex(localCompetitionData.rounds, {finals: true});

    let headers = [
        "Poradie",
      "Priezvisko",
      "Meno",
      "Licencia",
      "Pohlavie",
      "Kategória",
      "Divízia",
      "Klub"
    ];

    console.log("localCompetitionData.rounds.length", localCompetitionData.rounds.length);

    if(localCompetitionData.rounds.length > 0) {
      localCompetitionData.rounds.forEach(round => {
        [...round.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(sv => {
          headers.push(sv);
        })
      })
    } else {
      [...localCompetitionData.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(sv => {
        headers.push("a"+sv);
      });

      [...localCompetitionData.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(sv => {
        headers.push("b"+sv);
      });

      [...localCompetitionData.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(sv => {
        headers.push(sv);
      })
    }

    if(finalsRoundIndex > -1) {
      headers.push("12", "11", "10", "8", "5", "0");
    } else {
      if(localCompetitionData.rounds.length > 0) {
        headers.push("11", "10", "8", "5", "0");
      }
    }


    headers.push(
        "Počet šípov",
        "Spolu Bodov",
        "PRIEMER",
        "Body do Série",
        "",
        finalsRoundIndex > -1 ? "Finals" : "",
    );

    let aoa = [headers];

    Object.values(sortedGroupEntries).map(group => {
      group[1].entries.map(entry => {
        let newEntry = {
          id: entry.id,
          name: entry.name,
          firstName: entry.firstName,
          lastName: entry.lastName,
          permitNumber: entry.permitNumber,
          club: entry.club.name,
          team: entry.team,
          lunch: entry.lunch,
          category: entry.category.name,
          ageGroup: entry.ageGroup.skName,
          gender: entry.gender === "MALE" ? "m" : "z",
          score: entry.score,
          tiebreaker: entry.tiebreaker,
          placement: entry.placement,
          comment: entry.comment,
          adminComment: entry.adminComment,
          email: entry.email,
          lunchFee: entry.lunchFee,
          entryFee: entry.entryFee,
          additionalData0: entry.additionalData0,
          additionalData1: entry.additionalData1,
          additionalData2: entry.additionalData2,
          additionalData3: entry.additionalData3,
          additionalData4: entry.additionalData4,
          s12: entry.s12,
          toAge: entry.ageGroup.skToAge
        };
        localCompetitionData.scoring.forEach(
            (scoringValue) =>
                (newEntry["s" + scoringValue] = entry["s" + scoringValue])
        );

        if(localCompetitionData.rounds.length > 0) {
          localCompetitionData.rounds.forEach(round => {
            [...round.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(
                (scoringValue) =>
                    (newEntry["r" + round.round + "_s" + scoringValue] = entry.roundEntries[round.round]["s" + scoringValue])
            );
            newEntry["r" + round.round + "_score"] = entry.roundEntries[round.round]["score"];
          })
        }

        let arrowCount = 0;

        let vals = [newEntry.placement,
          newEntry.lastName,
          newEntry.firstName,
          newEntry.permitNumber,
          newEntry.gender,
          newEntry.ageGroup,
          newEntry.category,
          newEntry.club];

        if(localCompetitionData.rounds.length > 0) {
          localCompetitionData.rounds.forEach(round => {
            [...round.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(sv => {
              let value = entry.roundEntries[round.round]["s" + sv];
              vals.push(value);
              arrowCount += value;
            })
          })
        } else {
          [...localCompetitionData.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(sv => {
            let value = entry["s" + sv];
            vals.push(value);
          });

          [...localCompetitionData.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(sv => {
            vals.push(0);
          });


          [...localCompetitionData.scoring].sort((a,b) => parseInt(b)-parseInt(a)).forEach(sv => {
            let value = entry["s" + sv];
            vals.push(value);
            arrowCount += value;
          })
        }

        if(finalsRoundIndex > -1) {
          vals.push(entry.s12, entry.s11, entry.s10, entry.s8, entry.s5, entry.s0);
        } else {
          if(localCompetitionData.rounds.length > 0) {
            vals.push(entry.s11, entry.s10, entry.s8, entry.s5, entry.s0);
          }
        }

        vals.push(
            arrowCount || 0,
            newEntry.score || 0,
            +((newEntry.score / (arrowCount || 1)).toFixed(2)),
            [30, 25, 21, 18, 15, 12, 9, 6, 3, 1][newEntry.placement - 1] || 0,
            newEntry.category + newEntry.toAge + newEntry.gender,
            finalsRoundIndex > -1 ? entry.roundEntries[finalsRoundIndex].score : "",
        );

        aoa.push(vals);

      });

      aoa.push([]);
    });

    let workBook = XLSX.utils.book_new();


    let workSheet = XLSX.utils.aoa_to_sheet(aoa);
    XLSX.utils.book_append_sheet(workBook, workSheet, "Adatok");
    XLSX.writeFile(workBook, localCompetitionData.name + ".xls");
  };

  const backupDB = async () => {
    const blob = await exportDB(db, {prettyJson: true});
    downloadFile(blob, "ijasznet_backup_" + moment().format("YYYY_MM_DD_HH_mm_ss") +".json");
    console.log("backup downloaded");
  }

  console.log("local", localCompetitionData);

  return (
    <div>
      {!sk && <Alert
        message={t("manager.offlineWarning")}
        type="warning"
        showIcon
        closable
      />}
      <br /><PageHeader
        className="site-page-header"
        onBack={() => sk ? history.push("/manager-dashboard-sk") : history.push("/manager-dashboard")}
        title={
          (localCompetitionData && localCompetitionData.name) ||
          `${competitionId} - ${slug}`
        }
        subTitle={
          localCompetitionData &&
          `${localCompetitionData.location} - ${localCompetitionData.startDate}`
        }
        extra={<>
        {!sk && <Button
        onClick={() => Modal.info({
          title: t("manager.competitionDescription"),
          width: 1000,
          okText: t("manager.close"),
          maskClosable: true,
          autoFocusButton: null,
          closable: true,
          content:(
              <div dangerouslySetInnerHTML={{__html: localCompetitionData?.description}}></div>
          )
        })}>{t("manager.competitionDescription")}</Button>}
          <Button key={"backup"} onClick={() => backupDB()}>
            <DownloadOutlined />
            {t("manager.backupButton")}
          </Button>
        {!sk && <ParallelModal competitionId={competitionId} getLocalEntries={getLocalEntries} calculateAndSetPlacement={calculateAndSetPlacement} />}
        </>}
      />

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Space>
          {[
            /*localCompetitionData && (
              <Switch
                checked={liveEnabled}
                onChange={setLiveEnabled}
                checkedChildren={"Élő eredmények"}
                unCheckedChildren={"Élő eredmények"}
              />
            ),*/
            localCompetitionData && (
              <>
                <Checkbox
                  checked={autoPlacement}
                  onChange={(e) => setAutoPlacement(e.target.checked)}
                >
                  {t("manager.automaticScoring")}
                </Checkbox>
                {!sk && <Select
                  mode="multiple"
                  style={{ width: "200px" }}
                  placeholder={t("manager.visibleColumns")}
                  defaultValue={possibleScoringValues}
                  onChange={setDisplayScoringValues}
                  maxTagCount={"responsive"}
                >
                  {possibleScoringValues.map((sv) => (
                    <Select.Option key={sv} value={sv}>
                      {sv}
                    </Select.Option>
                  ))}
                </Select>}
                {selectedDisplay === "score" && (
                  <CategorySelectorModal
                    onSelect={() => {}}
                    entries={localEntries}
                  />
                )}
                <div>
                  <Select
                      key="display"
                      placeholder={t("manager.secondDisplay")}
                      onChange={(value) => {
                        if (!selectedDisplay) {
                          window.open(
                              "/manager-display/" + localCompetitionData.id + "/" + value
                          );
                        }
                        setSelectedDisplay(value);
                        broadcastChannel.postMessage({ selectDisplay: value });
                      }}
                  >
                    <Select.Option value="entries">{t("manager.entriesDisplay")}</Select.Option>
                    <Select.Option value="teams">{t("manager.progressDisplay")}</Select.Option>
                    <Select.Option value="score">{t("manager.resultsDisplay")}</Select.Option>
                    <Select.Option value="finals">{t("manager.finalsDisplay")}</Select.Option>
                  </Select>
                </div>

                <Button key={"excel"} onClick={() => generateExcel()}>
                  <DownloadOutlined />
                  {t("manager.exportExcel")}
                </Button>

                <Tooltip placement="bottom" title={localCompetitionData?.rounds?.length > 0 && !selectedTab.startsWith("round") ? "Válassz fordulót!" : null}>
                  <Button key={"qr"} onClick={() => setQrModalVisible(true)} disabled={localCompetitionData?.rounds?.length > 0 && !selectedTab.startsWith("round")}>
                    <QrcodeOutlined /> {t("manager.scanQr")}
                  </Button>
                </Tooltip>
              </>
            ),
            competition && (
              <>
                {localCompetitionData && (
                  <Button
                    key={"sync"}
                    disabled={!networkStatus}
                    onClick={showSyncConfirm}
                  >
                    <CloudSyncOutlined /> {t("manager.downloadData")}
                  </Button>
                )}
                {!localCompetitionData && (
                  <Button
                    key={"sync"}
                    onClick={() => {
                      cache.invalidateQueries([
                        "managed-competition-entries",
                        parseInt(competitionId),
                      ]);
                      refetch();
                    }}
                    disabled={!networkStatus}
                  >
                    <CloudDownloadOutlined /> {t("manager.downloadDataToDevice")}
                  </Button>
                )}
              </>
            ),
            <>
              {localCompetitionData && !sk && (
                <Popconfirm
                  disabled={!networkStatus}
                  title={
                    <span>
                      A feltöltés <strong>FELÜLÍRJA</strong> az Íjász.NET-en
                      szereplő eredményeket az eszközön lévő eredményekkel, majd{" "}
                      <strong>PUBLIKÁLJA</strong> azokat. <br />
                      Biztosan folytatod?
                    </span>
                  }
                  onConfirm={async () => {
                    await backupDB();
                    if (localCompetitionData.teamCountChanged) {
                      await updateTeamCountMutation({
                        competitionId: localCompetitionData.id,
                        teamCount: localCompetitionData.teamCount,
                      });
                    }

                    await updateEntriesMutation({
                      competitionId: localCompetitionData.id,
                      entries: localEntries,
                    });
                  }}
                  okText="Igen, publikálom az eredményeket"
                  cancelText="Mégsem"
                >
                  <Button key={"update"} disabled={!networkStatus}>
                    <CloudUploadOutlined /> Feltöltés
                  </Button>
                </Popconfirm>
              )}
            </>,
            <Button key={"stickermodal"} onClick={() => setStickerModalVisible(true)}><PrinterFilled /> {t("manager.qrStickers")}</Button>,
            sk && <Button key={"skResults"} onClick={() => generateExcelSK()}><PrinterFilled /> {t("manager.skResults")}</Button>
          ]}
        </Space>
      </div>
      <br />
      {!localCompetitionData && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("manager.noData")}
        />
      )}
      {localCompetitionData && (
        <>
          <Space split={<Divider type="vertical" />} size={"large"}>
            <Statistic
              title={t("manager.downloadedData")}
              value={moment(localCompetitionData.syncDate).format(
                "YYYY-MM-DD HH:mm:ss"
              )}
            />
            <Statistic
              title={t("manager.scoring")}
              value={localCompetitionData.scoring.join(",")}
            />
            <Statistic
              title={t("manager.tiebreaker")}
              value={localCompetitionData.tiebreaking.join(",")}
            />
            <Statistic title={t("manager.lunch")} value={_.sumBy(localEntries, "lunch")} />
            <Statistic
              title={t("manager.arrived")}
              value={_.filter(localEntries, "checkedIn").length}
              suffix={"/ " + localEntries.length}
            />
            {selectedTab.startsWith("round") &&
              <>
                <Statistic
                    title={t("manager.processed")}
                    value={_.filter(localEntries, (entry) => {
                        return entry.roundEntries[selectedTab.slice(5)]?.score
                    }).length}
                    suffix={"/ " + _.filter(localEntries, (entry) => {
                      return entry.roundEntries[selectedTab.slice(5)]?.active;
                    }).length}
                />
                <Statistic
                    title={t("manager.invalidResults")}
                    value={
                        _.filter(
                            localEntries,
                            (entry) => {
                              let roundNum = selectedTab.slice(5);
                              let roundData = localCompetitionData.rounds[roundNum];
                              let roundEntry = entry.roundEntries[roundNum];
                              return roundEntry?.active && ((roundEntry?.score &&
                                  roundEntry?.score !==
                              calculateScore(roundData.scoring, entry, parseInt(roundNum))) ||
                                  calculateArrowCount(roundData.scoring, entry, parseInt(roundNum)) !== roundData.arrowCount * localCompetitionData.arrowCount)
                            }

                        ).length || "-"
                    }
                />
              </>
            }
            {!selectedTab.startsWith("round") &&
              <>
                <Statistic
                    title={t("manager.processed")}
                    value={_.filter(localEntries, "score").length}
                    suffix={"/ " + localEntries.length}
                />
                <Statistic
                    title={t("manager.invalidResults")}
                    value={
                        _.filter(
                            localEntries,
                            (entry) =>
                                (entry.score &&
                                entry.score !==
                                calculateScore(localCompetitionData.scoring, entry))
                                || (calculateArrowCount(localCompetitionData.scoring, entry) !== localCompetitionData.targetCount * getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, entry.category.id, entry.ageGroup.ageGroup.id))
                        ).length || "-"
                    }
                />
              </>
           }
          </Space>
          <Tabs defaultActiveKey="1" onChange={(key) => setSelectedTab(key)}>
            <TabPane tab={t("manager.entries")} key="1">
              {localCompetitionData && (
                <EntryTab
                  localCompetitionData={localCompetitionData}
                  localEntries={localEntries}
                  localClubs={localClubs}
                  calculateAndSetPlacement={calculateAndSetPlacement}
                  updateEntry={updateEntry}
                  deleteEntry={deleteEntry}
                  updateFullEntry={updateFullEntry}
                  createEntry={createEntry}
                  getLocalEntries={getLocalEntries}
                  clearSelection={clearSelection}
                  createNewTeam={createNewTeam}
                  broadcastChannel={broadcastChannel}
                  filter={filter}
                  setFilter={setFilter}
                  filterExpression={filterExpression}
                  setFilterExpression={setFilterExpression}
                  teamFilter={teamFilter}
                  setTeamFilter={setTeamFilter}
                  sk={sk}
                />
              )}
            </TabPane>
            {localCompetitionData.rounds.length === 0 && (
              <TabPane tab={t("manager.scores")} key="2">
                {localCompetitionData && (
                  <ScoreTab
                    autoPlacement={autoPlacement}
                    setAutoPlacement={setAutoPlacement}
                    localCompetitionData={localCompetitionData}
                    localEntries={localEntries}
                    localClubs={localClubs}
                    calculateAndSetPlacement={calculateAndSetPlacement}
                    updateFullEntry={updateFullEntry}
                    createEntry={createEntry}
                    deleteEntry={deleteEntry}
                    getLocalEntries={getLocalEntries}
                    filter={filter}
                    setFilter={setFilter}
                    filterExpression={filterExpression}
                    setFilterExpression={setFilterExpression}
                    teamFilter={teamFilter}
                    setTeamFilter={setTeamFilter}
                    disabled={liveEnabled}
                    displayScoringValues={displayScoringValues}
                    setScorecardString={setScorecardString}
                    setScorecardEntryId={setScorecardEntryId}
                    sk={sk}
                  />
                )}
              </TabPane>
            )}
            {localCompetitionData.rounds.map((roundData) => (
              <>
                <TabPane tab={roundData.name} key={"round" + roundData.round}>
                  {localCompetitionData && (
                    <MultiRoundScoreTab
                      autoPlacement={autoPlacement}
                      setAutoPlacement={setAutoPlacement}
                      localCompetitionData={localCompetitionData}
                      localEntries={_.filter(localEntries, (le) => {
                        if(sk) {
                          return (
                              _.find(le.roundEntries, {
                                round: roundData.round,
                              }) && le.placement <= roundData.topCount
                              && (!roundData.finals || (roundData.finals && groupedEntries["" + le.category.id + le.ageGroup.id + le.gender]?.entries.length >= roundData.topCount && (le.ageGroup.ageGroup.id === 1 || le.ageGroup.ageGroup.id === 2)) )
                          );
                        } else {
                          return (
                              _.find(le.roundEntries, {
                                round: roundData.round,
                              }) && le.placement <= roundData.topCount
                              && (!roundData.finals || (roundData.finals && groupedEntries["" + le.category.id + le.ageGroup.id + le.gender]?.entries.length >= roundData.topCount) )
                          );
                        }

                      })}
                      localClubs={localClubs}
                      calculateAndSetPlacement={calculateAndSetPlacement}
                      updateFullEntry={updateFullEntry}
                      createEntry={createEntry}
                      deleteEntry={deleteEntry}
                      getLocalEntries={getLocalEntries}
                      roundData={roundData}
                      filter={filter}
                      setFilter={setFilter}
                      filterExpression={filterExpression}
                      setFilterExpression={setFilterExpression}
                      teamFilter={teamFilter}
                      setTeamFilter={setTeamFilter}
                      disabled={liveEnabled}
                      displayScoringValues={displayScoringValues}
                      setScorecardString={setScorecardString}
                      setScorecardEntryId={setScorecardEntryId}
                      sk={sk}
                    />
                  )}
                </TabPane>
              </>
            ))}
            <TabPane tab={t("manager.results")} key="3" forceRender={true}>
              {localCompetitionData && (
                <PlacementTab
                  localCompetitionData={localCompetitionData}
                  localEntries={localEntries}
                  displayScoringValues={displayScoringValues}
                  sk={sk}
                />
              )}
            </TabPane>
          </Tabs>
        </>
      )}
      <Modal
        visible={qrModalVisible}
        onCancel={() => {
          setQrModalVisible(false);
          setQrData(null);
          setQrControlData(null);
          setQrControlResult(null);
          setQrDecoded(null);
        }}
        footer={null}
        maskClosable={false}
        closable={false}
        destroyOnClose={true}
        width={760}
      >
        {(!qrData || qrModalVisible === "control") && (
            <>
              <QRReader
                  key={"qrReader"}
                  onRead={readQr}
                  onClose={() => setQrModalVisible(false)}
                  mirrored={true}
              />
              {devMode && <Collapse ghost>
                <Collapse.Panel header="Kód megadása manuálisan" key="1">
                  <TextArea value={manualQrValue} onChange={(e) => setManualQrValue(e.target.value)}/> <Button onClick={() => readQr({text: manualQrValue})}>Beolvasás</Button>
                </Collapse.Panel>
              </Collapse>}
            </>
        )}
        {qrData && qrModalVisible !== "control" && (
          <div>
            <h3>{t("manager.scannedValues")} {selectedTab.startsWith("round") ? "(" + (parseInt(selectedTab.substr(5)) + 1) + ". " + t("manager.round") + ")" : ""}:</h3>
            {getQrDataTable(qrData)}
            {_.isArray(qrControlResult) && qrControlResult.length === 0 && <Alert message="A két beírólap megegyezik" type="success" />}
            {_.isArray(qrControlResult) && qrControlResult.length > 0 && <Alert message="Nem egyezik a két beírólap! Az hibás versenyző pirossal jelölve." type="error" />}
            {qrControlResult === "length" && <Alert message="Eltérő versenyzőszám a két beírón!" type="error" />}
            {qrControlData && qrControlResult && getQrDataTable(qrControlData)}


            <div className={"footer-buttons"}>
              <Space>
                <Button onClick={() => setQrModalVisible("control")}>{t("manager.controlQR")}</Button>
                <Button onClick={() => {
                  setQrData(null);
                  setQrControlData(null);
                  setQrControlResult(null);
                  setQrDecoded(null);
                }}>{t("manager.back")}</Button>
                <Button onClick={saveQrData} type={"primary"}>
                  {t("manager.record")}
                </Button>
                {(qrControlResult === "length" || qrControlResult?.length > 0) && <Button onClick={saveSecondQrData} type={"primary"}>
                  {t("manager.recordSecond")}
                </Button>}
              </Space>
            </div>
          </div>
        )}
      </Modal>
      <Modal
          visible={scorecardString}
          onCancel={() => {
            setScorecardEntryId(null)
            setScorecardString(null)
          }}
          footer={null}
      >
        <ScorecardDisplay scorecardString={scorecardString} entryId={scorecardEntryId} />
      </Modal>
      {localEntries.length > 0 && localCompetitionData && <StickerModal
          competition={localCompetitionData}
          entries={localEntries}
          isModalVisible={stickerModalVisible}
          setIsModalVisible={setStickerModalVisible}
      />}
    </div>
  );
};
