import React, { useState } from "react";
import { useQuery } from "react-query";
import { getQualification, getQualificationSets } from "../api/Qualification";
import { Table, PageHeader, Space, Modal, Tooltip, Tabs, Input } from "antd";
import _ from "lodash";
import { CategoryTag } from "../../../shared/tag/CategoryTag";
import { GenderTag } from "../../../shared/tag/GenderTag";
import "./Qualification.scss";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { QualificationTag } from "./QualificationTag";

const { TabPane } = Tabs;

export const Qualification = () => {
  const [selectedSet, setSelectedSet] = useState(null);
  const [nameFilter, setNameFilter] = useState("");

  const { data, isLoading } = useQuery(
    ["qualification", selectedSet],
    getQualification,
    { enabled: !!selectedSet }
  );
  const { data: qualificationSets } = useQuery(
    "qualification-sets",
    getQualificationSets,
    {
      onSuccess: (data) => {
        setSelectedSet(_.sortBy(data, "order")[0].id);
      },
    }
  );
  const [filter, setFilter] = useState({ qualification: [] });
  const [modalOpen, setModalOpen] = useState(false);

  const selectedSetObj = _.find(qualificationSets, { id: selectedSet });
  console.log("s", selectedSetObj);

  return (
    <div className={"qualification"}>
      <PageHeader
        title={"Minősítések"}
        extra={
          <a onClick={() => setModalOpen(true)}>
            <Space>
              <InfoCircleOutlined />
              Minősítési szintek
            </Space>
          </a>
        }
      />
      {qualificationSets && (
        <Tabs
          defaultActiveKey={selectedSet}
          onChange={(key) => setSelectedSet(parseInt(key))}
        >
          {_.orderBy(qualificationSets, "order").map((set) => (
            <TabPane tab={set.name} key={set.id} />
          ))}
        </Tabs>
      )}
      <Input
        onChange={(e) => setNameFilter(e.target.value)}
        defaultValue={nameFilter}
        style={{ width: 280, marginBottom: "16px" }}
        prefix={<SearchOutlined />}
        allowClear={true}
        placeholder={"Keresés név szerint..."}
      />
      {selectedSetObj && (
        <Table
          dataSource={_.sortBy(
            data?.filter((d) =>
              nameFilter.length > 0
                ? d.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1
                : true
            ),
            [
              "category.name",
              "ageGroup.name",
              "gender",
              "qualification",
              "name",
            ]
          )}
          size={"small"}
          onChange={(pagination, filters) => {
            setFilter(filters);
          }}
          pagination={{ pageSize: 50 }}
          rowClassName={(record) => selectedSetObj?.timesRequired > -1 ? "qualification-" + record.qualification : ""}
        >
          <Table.Column
            dataIndex={"name"}
            title={"Név"}
            sorter={(a, b) => a.name.localeCompare(b.name)}
            render={(value, record) =>
              record.permitNumber > 0 ? (
                <Link to={"/athlete/" + record.permitNumber}>{value}</Link>
              ) : (
                value
              )
            }
          />
          <Table.Column
            dataIndex={["category", "name"]}
            title={"Kateg."}
            width={50}
            render={(value, record) => (
              <Tooltip placement="right" title={record.category.description}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setFilter({ ...filter, category: [record.category] });
                  }}
                >
                  <CategoryTag category={value} />
                </span>
              </Tooltip>
            )}
          />
          <Table.Column
            dataIndex={["ageGroup", "name"]}
            title={"Kor."}
            width={50}
            render={(value) => <AgeGroupTag ageGroup={value} />}
          />
          <Table.Column
            dataIndex={"gender"}
            title={"Nem"}
            width={50}
            render={(value) => <GenderTag gender={value} />}
          />
            {selectedSetObj?.timesRequired > -1 && <Table.Column
            dataIndex={"qualification"}
            title={"Osztály"}
            filteredValue={filter.qualification}
            filters={[
              {
                text: "Csak minősített",
                value: "qualified",
              },
            ]}
            onFilter={(value, record) =>
              value === "qualified"
                ? nameFilter.length > 0
                  ? true
                  : record.qualification
                : false
            }
            align={"center"}
          />}
          {selectedSetObj.competitions
            .sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))
            .map((competition) => {
              if (competition.multiRound && !competition.qualificationRule) {
                return competition.rounds.map((round) => {
                  if (!round.finals) {
                    return (
                      <Table.Column
                        key={competition.id + "_" + round.round}
                        dataIndex={[
                          "competitionQualification",
                          competition.id + "_" + round.round,
                        ]}
                        title={
                          <Link
                            to={`/competition/${competition.id}/${competition.slug}`}
                          >
                            {competition.name} {round.name}
                          </Link>
                        }
                        responsive={["xl"]}
                        align={"center"}
                        render={(value) =>
                          value != null ? (
                            <QualificationTag qualification={value} />
                          ) : null
                        }
                      />
                    );
                  }
                });
              } else {
                return (
                  <Table.Column
                    key={competition.id}
                    dataIndex={["competitionQualification", competition.id]}
                    title={
                      <Link
                        to={`/competition/${competition.id}/${competition.slug}`}
                      >
                        {competition.name}
                      </Link>
                    }
                    responsive={["xl"]}
                    align={"center"}
                    render={(value) =>
                      value != null ? (
                        <QualificationTag qualification={value} />
                      ) : null
                    }
                  />
                );
              }
            })}
        </Table>
      )}
      <Modal
        title="Minősítési szintek"
        visible={modalOpen}
        footer={false}
        onCancel={() => setModalOpen(false)}
      >
          {selectedSetObj?.timesRequired > -1 && <h4>
          Minimum {selectedSetObj?.timesRequired} eredmény szükséges a minősítés
          megszerzéséhez
        </h4>}
        <Table
          size={"small"}
          pagination={false}
          dataSource={
            selectedSetObj?.competitions[0]?.qualificationRule.ruleEntries
          }
        >
          <Table.Column
            dataIndex={["category", "name"]}
            title={"Kateg."}
            render={(value) => <CategoryTag category={value} />}
          />
          <Table.Column
            dataIndex={["ageGroup", "name"]}
            title={"Kor"}
            render={(value) => <AgeGroupTag ageGroup={value} />}
          />{" "}
          <Table.Column
            dataIndex={"gender"}
            title={"Nem"}
            render={(value) => <GenderTag gender={value} />}
          />
          <Table title={"Pontszám"}>
            <Table.Column
              dataIndex={"firstClassScore"}
              title={"I."}
              align={"center"}
            />
            <Table.Column
              dataIndex={"secondClassScore"}
              title={"II."}
              align={"center"}
            />
            <Table.Column
              dataIndex={"thirdClassScore"}
              title={"III."}
              align={"center"}
            />
          </Table>
        </Table>
      </Modal>
    </div>
  );
};
