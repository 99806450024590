import Dexie from "dexie";
import "dexie-export-import";

const db = new Dexie("artemisdb");
db.version(1).stores({
  competitions: "id",
  entries: "id,competition",
  clubs: "id",
  tournaments: "id",
  tournamentEntries: "id,tournament",
});

export { db };


const tempDb = new Dexie("artemisdb_temp");
tempDb.version(1).stores({
  competitions: "id",
  entries: "id,competition",
  clubs: "id",
  tournaments: "id",
  tournamentEntries: "id,tournament",
});

export { tempDb };