import React, { useState } from "react";
import { LeftMenu } from "./LeftMenu";
import { RightMenu } from "./RightMenu";
import { Drawer, Button } from "antd";
import "./NavBar.less";
import { Link } from "react-router-dom";

export const Navbar = ({ serviceWorker }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const isBrowser = () => {
    let displayMode = "browser";
    const mqStandAlone = "(display-mode: standalone)";
    if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
      displayMode = "standalone";
    }

    return displayMode === "browser";
  };

  return (
    <nav className="menuBar">
      <div className="logo">
        <img alt={"logo"} src={"/logo_100x100.png"} />
        <Link to={"/"}>ÍJÁSZ.NET</Link>
      </div>
      <div className="menuCon">
        <div className="leftMenu">
          <LeftMenu onClose={onClose} />
        </div>
        <div className="rightMenu">
          <RightMenu onClose={onClose} serviceWorker={serviceWorker} />
        </div>
        <Button className="barsMenu" type="primary" onClick={showDrawer}>
          <span className="barsBtn"></span>
        </Button>
        <Drawer
          title="Menü"
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <LeftMenu onClose={onClose} />
          <RightMenu onClose={onClose} serviceWorker={serviceWorker} />
          {isBrowser() && (
            <div className={"play-store-button"}>
              <a
                href={
                  "https://play.google.com/store/apps/details?id=net.ijasz.app"
                }
              >
                <img
                  alt={"get on google play store"}
                  src={"/google-play-logo.png"}
                />
              </a>
            </div>
          )}
        </Drawer>
      </div>
    </nav>
  );
};
