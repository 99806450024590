import React from "react";
import { Card, Col, Image, Row, Typography } from "antd";

import "./Introduction.less";

const { Title } = Typography;

export const Introduction = () => {
  return (
    <Row>
      <Col xs={{ span: 24, offset: 0 }} xxl={{ span: 20, offset: 2 }}>
        <Title level={3}>Miért érdemes itt lebonyolítanod a nevezést?</Title>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Card title="Minden verseny, eredmény egy helyen" bordered={false}>
              <div className={"item"}>
                <Image width={200} src="/intro/01.png" />
                <p>
                  Igyekszem összegyűjteni az ország összes versenyét, fajtától,
                  létszámtól függetlenül. A versenyek eredményeinek
                  elérhetőségét közzéteszem az oldalon, így minden egy helyen
                  megtalálható.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Áttekinthető nevező lista" bordered={false}>
              <div className={"item"}>
                <Image width={200} src="/intro/02.png" />
                <p>
                  Az oldalon szervezett versenyeknél a nevezők listája bármikor
                  megtekinthető a versenyzők számára. Szűrhető kategória,
                  korosztály, egyesület szerint. Így előre látják hogy hányan
                  lesznek a kategóriájukban, indul-e a rég nem látott ismerős és
                  hogy milyen mezőnyre számíthatnak.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Módosítható, visszavonható nevezések" bordered={false}>
              <div className={"item"}>
                <Image width={200} src="/intro/03.png" />
                <p>
                  Nevezés leadható regisztrációval vagy anélkül is. A profillal
                  nem rendelkező nevezők emailben kapnak egy linket amivel
                  módosíthatják, törölhetik a nevezésüket. Nem kell
                  telefonálgatni, emailben megkeresni a szervezőket.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Nevezési, lemondási határidő" bordered={false}>
              <div className={"item"}>
                <Image width={200} src="/intro/10.png" />
                <p>A nevezés automatikusan lezárul a megadott időpontban.</p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Csapatbeosztás" bordered={false}>
              <div className={"item"}>
                <Image width={200} src="/intro/04.png" />
                <p>
                  Az oldalon elkészíthető a csapatbeosztás, letölthető PDF
                  formában. Publikálás után megjelenik a verseny oldalán, így a
                  versenyzők előre tudják hogy melyik csapatba kerültek.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Eredmények közzététele" bordered={false}>
              <div className={"item"}>
                <Image width={200} src="/intro/05.png" />
                <p>
                  Az eredmények felöltése után versenyzők email értesítést
                  kapnak az elért helyezéseikről, ezután különböző szempontok
                  alapján értékelhetik a versenyt. A szervező feltöltheti a
                  célokat, és azok távolságát.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Kupák, versenysorozatok" bordered={false}>
              <div className={"item"}>
                <Image width={200} src="/intro/06.png" />
                <p>
                  Lehetőség van kupák, versenysorozatok szervezésére is. A kupák
                  oldalán megtekinthető a kupa szabályzata, kapcsolati adatok,
                  korábbi évek eredménye.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Összesített eredmények" bordered={false}>
              <div className={"item"}>
                <Image width={200} src="/intro/07.png" />
                <p>A kupák összesített eredménye is megtekinthető.</p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Ranglista" bordered={false}>
              <div className={"item"}>
                <Image width={200} src="/intro/08.png" />
                <p>
                  Minden verseny eredménye, ami az Íjász.NET-en kerül
                  megszervezésre, bekerül egy országos ranglistába. A lista
                  minden verseny után frissül.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Versenyzői profilok, statisztikák" bordered={false}>
              <div className={"item"}>
                <Image width={200} src="/intro/11.png" />
                <p>
                  A versenyzők megtekinthetik a saját, és mások versenyzői
                  profilját, amiben az összes korábbi eredménye szerepel,
                  vesszőátlaggal, találat eloszlással.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="PC-n, tableten, mobilon" bordered={false}>
              <div className={"item"}>
                <Image width={200} src="/intro/09.png" />
                <p>
                  Minden fontos funkció elérhető bármilyen eszközről. Nem számít
                  hogy mobilon, pc-n vagy tableten használják a versenyzők az
                  oldalt.
                </p>
              </div>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Ingyen :)" bordered={false}>
              <p>
                Az oldal használata mind a versenyzők, mind a versenyszervezők
                számára ingyenes. Szeretném megkönnyíteni a szervezők munkáját,
                így bárkinek szívesen segítek a nevezés lebonyolításában és az
                eredmények publikálásában. Egyeztetjük a versenykiírást, a
                nevezés zárása után emailben/facebookon elküldöm a nevezők
                listáját, ezt az excelt a verseny után kitöltve visszakülditek
                nekem, én pedig intézem a többit :) Keressetek az info@ijasz.net
                emailcímen, vagy facebookon (Király Dávid).
              </p>
            </Card>
          </Col>
        </Row>
      </Col>
      <div style={{ clear: "both" }}></div>
    </Row>
  );
};
