import React from "react";
import { Card, Rate, Typography } from "antd";

export const Reviews = ({ reviews }) => {
  return (
    <Card title={"Értékelés"} extra={reviews && reviews.count + " értékelés"}>
      {reviews && (
        <div className={"rating-container"}>
          <div className={"rating"}>
            <Typography.Text strong>
              Megközelíthetőség / parkolás
            </Typography.Text>
            <div className={"stars"}>
              <Rate
                disabled
                allowHalf
                value={Math.round(reviews.parking * 2) / 2}
              />
              <Typography.Text strong>
                {reviews.parking !== 0 ? reviews.parking.toFixed(2) : "-"}
              </Typography.Text>
            </div>
          </div>
          <div className={"rating"}>
            <Typography.Text strong>Versenyiroda működése</Typography.Text>
            <div className={"stars"}>
              <Rate
                disabled
                allowHalf
                value={Math.round(reviews.office * 2) / 2}
              />
              <Typography.Text strong>
                {reviews.office !== 0 ? reviews.office.toFixed(2) : "-"}
              </Typography.Text>
            </div>
          </div>
          <div className={"rating"}>
            <Typography.Text strong>Belövőpálya</Typography.Text>
            <div className={"stars"}>
              <Rate
                disabled
                allowHalf
                value={Math.round(reviews.practiceField * 2) / 2}
              />
              <Typography.Text strong>
                {reviews.practiceField !== 0
                  ? reviews.practiceField.toFixed(2)
                  : "-"}
              </Typography.Text>
            </div>
          </div>
          <div className={"rating"}>
            <Typography.Text strong>
              Versenypálya / célok kihelyezése
            </Typography.Text>
            <div className={"stars"}>
              <Rate
                disabled
                allowHalf
                value={Math.round(reviews.competitionField * 2) / 2}
              />
              <Typography.Text strong>
                {reviews.competitionField !== 0
                  ? reviews.competitionField.toFixed(2)
                  : "-"}
              </Typography.Text>
            </div>
          </div>
          <div className={"rating"}>
            <Typography.Text strong>Célok minősége / állapota</Typography.Text>
            <div className={"stars"}>
              <Rate
                disabled
                allowHalf
                value={Math.round(reviews.targets * 2) / 2}
              />
              <Typography.Text strong>
                {reviews.targets !== 0 ? reviews.targets.toFixed(2) : "-"}
              </Typography.Text>
            </div>
          </div>
          <div className={"rating"}>
            <Typography.Text strong>Ebéd</Typography.Text>
            <div className={"stars"}>
              <Rate
                disabled
                allowHalf
                value={Math.round(reviews.lunch * 2) / 2}
              />
              <Typography.Text strong>
                {reviews.lunch !== 0 ? reviews.lunch.toFixed(2) : "-"}
              </Typography.Text>
            </div>
          </div>
          <div className={"rating"}>
            <Typography.Text strong>Eredményhírdetés</Typography.Text>
            <div className={"stars"}>
              <Rate
                disabled
                allowHalf
                value={Math.round(reviews.resultAnnouncement * 2) / 2}
              />
              <Typography.Text strong>
                {reviews.resultAnnouncement !== 0
                  ? reviews.resultAnnouncement.toFixed(2)
                  : "-"}
              </Typography.Text>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};
