import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Divider,
  InputNumber,
  PageHeader,
  Popconfirm,
  Space,
  Statistic,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryCache } from "react-query";
import {
  getTournament,
  getTournamentEntries,
  updateEntries,
} from "../api/Tournament";
import { db } from "../utils/LocalDB";
import { groupEntries } from "artemis-shared/utils/CompetitionEntryUtils";
import _ from "lodash";
const { Column } = Table;

export const TournamentManager = () => {
  let { tournamentId } = useParams();
  const cache = useQueryCache();

  const [localTournamentData, setLocalTournamentData] = useState(null);
  const [localEntries, setLocalEntries] = useState([]);
  const [competitionSyncStatus, setCompetitionSyncStatus] = useState(null);

  const { data: tournament } = useQuery(
    ["tournament", parseInt(tournamentId)],
    getTournament
  );

  const { data: tournamentEntries } = useQuery(
    ["tournament-entries", parseInt(tournamentId)],
    getTournamentEntries
  );

  const [updateEntriesMutation] = useMutation(updateEntries, {
    onSuccess: () => {
      cache.invalidateQueries(["tournament-entries", parseInt(tournamentId)]);
    },
  });

  const getLocalTournament = async () => {
    let localTournament = await db.tournaments
      .where("id")
      .equals(parseInt(tournamentId))
      .toArray();

    console.log("got", localTournament);

    if (localTournament[0]) {
      setLocalTournamentData(localTournament[0]);
      getCompetitionSyncStatus(localTournament[0]);
    }
  };

  const updateEntry = async (entryId, property, value) => {
    console.log("updateentry", entryId, property, value);
    await db.tournamentEntries
      .where("id")
      .equals(entryId)
      .modify({ [property]: value });

    await getLocalEntries();
  };

  const onInputChange = async (entryId, property, value) => {
    console.log("oninputchange");
    await updateEntry(entryId, property, value);
    let local = await getLocalEntries();
    generateTournamentEntries(local);
  };

  const getMedalCount = (placement) => {
    return _.filter(localEntries, { placement: placement }).length;
  };

  const saveToLocal = async () => {
    db.tournaments.put({
      id: tournament.id,
      name: tournament.name,
      calculatedRounds: tournament.calculatedRounds,
      qualificationRule: tournament.qualificationRule,
      minRounds: tournament.minRounds,
      tiebreaking: tournament.tiebreaking.split(","),
      scoring: tournament.scoring.split(",").map((score) => parseInt(score)),
      competitions: tournament.competitions.map(
        (competition) => competition.id
      ),
      syncDate: new Date(),
    });

    console.log("remoteentries", tournamentEntries);

    await db.tournamentEntries.bulkPut(
      tournamentEntries.map((entry) => {
        return { ...entry, tournament: parseInt(tournamentId) };
      })
    );

    await db.tournamentEntries.where("id").above(999999999).delete();

    getLocalEntries();
    getLocalTournament();
  };

  const getLocalEntries = async () => {
    let localEntries = await db.tournamentEntries
      .where("tournament")
      .equals(parseInt(tournamentId))
      .toArray();

    console.log("got", localEntries);

    if (localEntries) {
      setLocalEntries(localEntries);
    }

    return localEntries;
  };

  const getCompetitionSyncStatus = async (localTournamentData) => {
    let status = await Promise.all(
      localTournamentData.competitions.map(async (competitionId) => {
        let localCompetition = await db.competitions
          .where("id")
          .equals(competitionId)
          .toArray();

        console.log("comp", competitionId, localCompetition);
        if (localCompetition[0]) {
          return {
            competitionId: competitionId,
            syncDate: localCompetition[0].syncDate,
          };
        } else {
          return { competitionId: competitionId, syncDate: null };
        }
      })
    );

    console.log("compsyncstatus", status);
    setCompetitionSyncStatus(status);
  };

  const removeLocalTournamentEntries = async () => {
    await db.tournamentEntries
      .where("tournament")
      .equals(parseInt(tournamentId))
      .delete();
  };

  const renderValue = (value) => {
    if (value === undefined) {
      return "-";
    } else {
      if (value) {
        return value;
      } else {
        return 0;
      }
    }
  };

  const filterTSByQualification = (tournamentScore, entry) => {
    const ruleEntry = localTournamentData.qualificationRule.ruleEntries.find(re => re.category.id === entry.category.id && re.ageGroup.id === entry.ageGroup.id && re.gender === entry.gender);
    return !ruleEntry || tournamentScore.competitionScore >= ruleEntry.thirdClassScore;
  };

  const generateTournamentEntries = async (localEntries) => {
    let tournamentScores = localTournamentData.scoring;
    let sumResult = localEntries || [];

    let syncedCompetitionCount = _.filter(
      competitionSyncStatus,
      (status) => status.syncDate
    ).length;

    let isLastRound =
      syncedCompetitionCount === localTournamentData.competitions.length;

    let minRounds = isLastRound ? localTournamentData.minRounds : 1;

    let calculatedRounds;

    if(localTournamentData.calculatedRounds) {
      console.log("localTournamentData.calculatedRounds", localTournamentData.calculatedRounds);
      calculatedRounds = Math.min(
          localTournamentData.calculatedRounds,
          syncedCompetitionCount
      );
    } else {
      calculatedRounds = Math.min(
          localTournamentData.minRounds,
          syncedCompetitionCount
      );
    }

    console.log("GEDNE");

    if(localTournamentData.qualificationRule) {
      console.log("qualificationRule", localTournamentData.qualificationRule);
    }

    console.log("calculated rounds", calculatedRounds);

    await Promise.all(
      localTournamentData.competitions.map(async (competitionId) => {
        console.log("generate comp", competitionId);
        let competitionEntries = await db.entries
          .where("competition")
          .equals(competitionId)
          .toArray();

        console.log("compentries", competitionEntries);

        let categories = {};

        //categorize entries
        competitionEntries.forEach((entry) => {
          let categoryId = `${entry.category.id}_${entry.ageGroup.ageGroup.id}_${entry.gender}`;
          if (!categories[categoryId]) {
            categories[categoryId] = [];
          }

          categories[categoryId].push({
            ...entry,
            ageGroup: entry.ageGroup.ageGroup,
          });
        });

        console.log("categories", categories);

        let categoryIdList = Object.keys(categories);

        categoryIdList.forEach((categoryId) => {
          console.log("generate category", categoryId);
          categories[categoryId].sort((a, b) => {
            return a.placement - b.placement;
          });

          categories[categoryId].forEach((e, index) => {
            let sumResultEntry = sumResult.filter(
              (resultEntry) =>
                resultEntry.name.toLowerCase().trim() ===
                  e.name.toLowerCase().trim() &&
                resultEntry.gender === e.gender &&
                resultEntry.ageGroup.id === e.ageGroup.id &&
                resultEntry.category.id === e.category.id
            );
            if (sumResultEntry.length > 0) {
              let tsIndex = sumResultEntry[0].tournamentScores.findIndex(
                (ts) => ts && ts.competition === competitionId
              );
              sumResultEntry[0].tournamentScores[
                tsIndex > -1
                  ? tsIndex
                  : sumResultEntry[0].tournamentScores.length + 1
              ] = {
                tournamentEntry: sumResultEntry[0].id,
                competition: competitionId,
                competitionScore: e.score,
                competitionMisses: e.s0,
                competitionPlacement: e.placement,
                tournamentScore: tournamentScores[e.placement - 1] || 0,
              };
            } else {
              let tournamentEntryId =
                Math.floor(Math.random() * 1000000) + 1000000000;
              let tempSumResultEntry = {
                id: tournamentEntryId,
                tournament: parseInt(tournamentId),
                name: e.name,
                permitNumber: e.permitNumber,
                gender: e.gender,
                ageGroup: e.ageGroup,
                category: e.category,
                club: e.club,
                tournamentScore: 0,
                sumScore: 0,
                tournamentScores: [
                  {
                    tournamentEntry: tournamentEntryId,
                    competition: competitionId,
                    competitionScore: e.score,
                    competitionMisses: e.s0,
                    competitionPlacement: e.placement,
                    tournamentScore: tournamentScores[e.placement - 1] || 0,
                  },
                ],
              };

              sumResult.push(tempSumResultEntry);
            }
          });
        });
      })
    );

    console.log("sumres", sumResult);

    sumResult.forEach((entry) => {
      entry.tie = false;
      entry.tournamentScores.sort((a, b) => {
        if (a.tournamentScore > b.tournamentScore) {
          return -1;
        }
        if (a.tournamentScore < b.tournamentScore) {
          return 1;
        }
        if (a.competitionScore > b.competitionScore) {
          return -1;
        }
        if (a.competitionScore < b.competitionScore) {
          return 1;
        }

        return 0;
      });

      console.log("tsorder", entry.tournamentScores);

      console.log("minr", minRounds);
      console.log("calculatedrounds", calculatedRounds);

      let filteredTournamentScores = entry.tournamentScores.filter(Boolean);

      if(localTournamentData.qualificationRule) {
        filteredTournamentScores = filteredTournamentScores.filter(ts => filterTSByQualification(ts, entry));
      }

      if (entry.tournamentScores.filter(Boolean).length >= minRounds) {
        entry.tournamentScore = entry.tournamentScores
          .slice(0, calculatedRounds)
          .reduce((prev, curr) => prev + curr.tournamentScore, 0);

        entry.sumScore = entry.tournamentScores
          .slice(0, calculatedRounds)
          .reduce((prev, curr) => prev + curr.competitionScore, 0);

        console.log("ent", entry);
      } else {
        entry.tournamentScore = null;
        entry.sumScore = null;
        console.log("doesn't have enough rounds, set to null");
      }
    });

    let categories = {};
    sumResult.forEach((entry) => {
      let categoryId = `${entry.category.id}_${entry.ageGroup.id}_${entry.gender}`;
      if (!categories[categoryId]) {
        categories[categoryId] = [];
      }

      categories[categoryId].push(entry);
    });

    let categoryIdList = Object.keys(categories);

    categoryIdList.forEach((categoryId) => {
      categories[categoryId].sort((a, b) => {
        if (a.tournamentScore > b.tournamentScore) {
          return 1;
        }
        if (b.tournamentScore > a.tournamentScore) {
          return -1;
        }

        for (let i = 0; i < localTournamentData.tiebreaking.length; i++) {
          let tiebreakerValue = localTournamentData.tiebreaking[i];

          if (tiebreakerValue.startsWith("R")) {
            let roundIndex = parseInt(tiebreakerValue.split("_")[0][1]) - 1;
            let competitionId = localTournamentData.competitions[roundIndex];
            let scoreValue = tiebreakerValue.split("_")[1];

            let aTournamentScores =
              a.tournamentScores[
                a.tournamentScores.findIndex(
                  (ts) => ts && ts.competition === competitionId
                )
              ];
            let bTournamentScores =
              b.tournamentScores[
                b.tournamentScores.findIndex(
                  (ts) => ts && ts.competition === competitionId
                )
              ];

            if (!aTournamentScores && bTournamentScores) {
              return -1;
            } else if (!bTournamentScores && aTournamentScores) {
              return 1;
            } else if (aTournamentScores && bTournamentScores) {
              if (scoreValue === "S0") {
                if (aTournamentScores.s0 < bTournamentScores.s0) {
                  return 1;
                }
                if (aTournamentScores.s0 > bTournamentScores.s0) {
                  return -1;
                }
              } else {
                if (
                  aTournamentScores[scoreValue] > bTournamentScores[scoreValue]
                ) {
                  return 1;
                }
                if (
                  aTournamentScores[scoreValue] < bTournamentScores[scoreValue]
                ) {
                  return -1;
                }
              }
            }
          } else if (tiebreakerValue === "T") {
            if (a.tiebreaker > b.tiebreaker) {
              return 1;
            }
            if (a.tiebreaker < b.tiebreaker) {
              return -1;
            }
          } else if (tiebreakerValue === "S") {
            if (a.sumScore > b.sumScore) {
              return 1;
            }
            if (a.sumScore < b.sumScore) {
              return -1;
            }
          }
        }

        a.tie = true;
        b.tie = true;

        return 0;
      });

      categories[categoryId].reverse();

      categories[categoryId].forEach((entry) => {
        entry.placement = null;
      });

      categories[categoryId]
        .filter((entry) => {
          if(localTournamentData.qualificationRule) {
            return entry.tournamentScores.filter((e) => e).length >= minRounds && entry.tournamentScores.filter((e) => e).filter(ts => filterTSByQualification(ts, entry)).length >= calculatedRounds;
          } else {
            return entry.tournamentScores.filter((e) => e).length >= minRounds;
          }

        })
        .forEach((entry, index) => {
          entry.placement = index + 1;
        });
    });

    await db.tournamentEntries.bulkPut(sumResult);

    getLocalEntries();
  };

  const onMountEffect = () => {
    getLocalEntries();
    getLocalTournament();
  };

  useEffect(onMountEffect, []);

  console.log("localentries", localEntries);

  let groupedEntries = groupEntries(localEntries);
  let sortedGroupEntries = Object.entries(groupedEntries).sort((a, b) => {
    if (a[1].category.name.localeCompare(b[1].category.name) == 1) return 1;
    if (a[1].category.name.localeCompare(b[1].category.name) == -1) return -1;

    return a[1].ageGroup.fromAge - b[1].ageGroup.fromAge;
  });

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title={localTournamentData && localTournamentData.name}
        subTitle={
          localTournamentData
            ? "Szinkronizálva: " + localTournamentData.syncDate.toISOString()
            : ""
        }
        extra={[
          <Button key={"sync"} onClick={() => saveToLocal()}>
            Sync
          </Button>,
          <Button
            key={"update"}
            onClick={() => {
              updateEntriesMutation({
                tournamentId: localTournamentData.id,
                entries: localEntries,
              });
            }}
          >
            Update
          </Button>,
          <Button
            key={"gen"}
            onClick={() => {
              generateTournamentEntries(localEntries);
            }}
          >
            Generate
          </Button>,
          <Popconfirm key={"rem"} title={"Biztos törlöd?"}>
            <Button
              key={"remove"}
              onClick={() => {
                removeLocalTournamentEntries();
              }}
            >
              Remove local entries
            </Button>
          </Popconfirm>,
        ]}
      />
      <div>
        <div>Min forduló: {localTournamentData?.minRounds} | Értékelt forduló: {localTournamentData?.calculatedRounds} | Minősítés: {localTournamentData?.qualificationRule ? "Van" : "Nincs"}</div>
      </div>
      {competitionSyncStatus &&
        competitionSyncStatus.map(({ competitionId, syncDate }) => (
          <div key={competitionId}>
            <a
              href={"/manager/" + competitionId + "/asd"}
              target={"_blank"}
              rel="noreferrer"
            >
              {competitionId} - {syncDate && syncDate.toString()}
            </a>
          </div>
        ))}

      <Card>
        <Space split={<Divider type="vertical" />}>
          <Statistic title="Arany" value={getMedalCount(1)} />
          <Statistic title="Ezüst" value={getMedalCount(2)} />
          <Statistic title="Bronz" value={getMedalCount(3)} />
          <Statistic
            title="Összesen"
            value={getMedalCount(1) + getMedalCount(2) + getMedalCount(3)}
          />
        </Space>
      </Card>

      {sortedGroupEntries.map(([key, value]) => {
        value.entries.sort(
          (a, b) => (a.placement || 999) - (b.placement || 999)
        );
        return (
          <Card
            key={key}
            title={`${value.category.name} ${value.ageGroup.name} ${
              value.gender === "MALE" ? "Férfi" : "Nő"
            }`}
            style={{ marginBottom: "16px" }}
          >
            <Table
              bordered
              dataSource={value.entries}
              pagination={false}
              size={"small"}
              rowKey={(row) => row.id}
              rowClassName={(record) => {
                if (_.isNumber(record.tournamentScore)) {
                  return record.tie ? "tie" : "placement-" + record.placement;
                } else {
                  return "no-score";
                }
              }}
            >
              <Column title="Név" dataIndex="name" key="name" />
              <Column
                title="Egyesület"
                dataIndex={["club", "name"]}
                key="club"
                responsive={["xxl"]}
              />

              {localTournamentData &&
                localTournamentData.competitions.map((competitionId, index) => (
                  <Column
                    key={"r" + competitionId}
                    title={index + 1 + " forduló"}
                    className={"round-column"}
                  >
                    <Column
                      title={"#"}
                      key={"placement"}
                      width={50}
                      className={"placement-column"}
                      render={(text, record) => {
                        let tsIndex = record.tournamentScores.findIndex(
                          (ts) => ts && ts.competition === competitionId
                        );

                        return tsIndex > -1
                          ? renderValue(
                              record.tournamentScores[tsIndex]
                                .competitionPlacement
                            )
                          : "-";
                      }}
                    />
                    <Column
                      title={"P"}
                      key={"cs"}
                      width={50}
                      render={(text, record) => {
                        let tsIndex = record.tournamentScores.findIndex(
                          (ts) => ts && ts.competition === competitionId
                        );

                        return tsIndex > -1
                          ? renderValue(
                              record.tournamentScores[tsIndex].competitionScore
                            )
                          : "-";
                      }}
                    />
                    <Column
                      title={"M"}
                      key={"misses"}
                      width={50}
                      render={(text, record) => {
                        let tsIndex = record.tournamentScores.findIndex(
                          (ts) => ts && ts.competition === competitionId
                        );

                        return tsIndex > -1
                          ? renderValue(
                              record.tournamentScores[tsIndex].competitionMisses
                            )
                          : "-";
                      }}
                    />
                    <Column
                      title={"TS"}
                      key={"ts"}
                      width={50}
                      render={(text, record) => {
                        let tsIndex = record.tournamentScores.findIndex(
                          (ts) => ts && ts.competition === competitionId
                        );

                        return tsIndex > -1
                          ? renderValue(
                              record.tournamentScores[tsIndex].tournamentScore
                            )
                          : "-";
                      }}
                    />
                  </Column>
                ))}

              <Column title={"Összesített"} key={"sumScore"}>
                <Column
                  title="#"
                  width={50}
                  dataIndex="placement"
                  key="placement"
                  className={"placement-column"}
                />
                <Column
                  title="TS"
                  width={50}
                  dataIndex="tournamentScore"
                  key="ts"
                />
                <Column title="P" width={50} dataIndex="sumScore" key="ss" />
                <Column
                  title={"Szétlövés"}
                  dataIndex={"tiebreaker"}
                  key={"tiebreaker"}
                  width={50}
                  render={(colValue, entry) => (
                    <InputNumber
                      style={{ width: "50px" }}
                      value={colValue}
                      onChange={_.debounce(
                        (value) => onInputChange(entry.id, "tiebreaker", value),
                        300
                      )}
                    />
                  )}
                />
              </Column>
            </Table>
          </Card>
        );
      })}
    </>
  );
};
