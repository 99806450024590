import React, { useRef, useEffect } from "react";
import { Card, Col, Divider, Row, Space, Table, Typography } from "antd";
import moment from "moment";
import { ApplicationForm } from "./ApplicationForm";
import { CategoriesBarChart } from "./CategoriesBarChart";
import { EntriesTable } from "./EntriesTable";
import { Description } from "./Description";
import { Reviews } from "./Reviews";
import { Targets } from "./Targets";
import { PublicPlacementTab } from "./PublicPlacementTab";
import { MiszBanner } from "./MiszBanner";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {ResultsBanner} from "./ResultsBanner";
import DOMPurify from "dompurify";

const { Column } = Table;

export const CompetitionXXL = ({
  competition,
  entries,
  form,
  onSubmitEntry,
  userData,
  clubs,
  isLoading,
  isFetchingEntries,
  highlightedEntry,
  reviews,
  entryToModify,
  getProfileInput,
  permitRequired,
  miszApprovedFilter,
    multiRound,
    multiRoundFinished
}) => {

  const { t } = useTranslation();
  const bottomOfEntriesRef = useRef(null);

  useEffect(() => {
    if (highlightedEntry) {
      let top = bottomOfEntriesRef.current.getBoundingClientRect().top;
      console.log("top", top);
      window.scrollTo({
        top: top - 300,
        behavior: "smooth",
      });
    }
  }, [highlightedEntry]);

  return (
    <Row gutter={[16, 16]}>
      {competition.stage !== "INFO" && (
        <Col xs={14}>
          {competition.stage === "SCORED" && entries && (
            <Col xs={24} className="gutter-row">
              {competition.headerText !== null && <Card>
                <div
                  dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(competition.headerText),
                }}
                ></div>
              </Card>}
              <PublicPlacementTab
                localEntries={entries}
                localCompetitionData={competition}
              />
            </Col>
          )}
          {competition.stage === "APPLICATION" && (
            <>
              <Card
                title={t("competition.applicationCardTitle")}
                style={{ marginBottom: "16px" }}
                extra={
                  <Space split={<Divider type="vertical" />}>
                    {competition.maxParticipants < 999 && (
                      <span>
                        <strong>{t("competition.maxParticipants")}:</strong>{" "}
                        {competition.maxParticipants}
                      </span>
                    )}
                    <span>
                      <strong>{t("competition.applicationDeadline")}: </strong>
                      {moment(competition.applicationDeadline).format(
                        "YYYY.MM.DD. - HH:mm"
                      )}
                    </span>
                  </Space>
                }
              >
                <ApplicationForm
                  form={form}
                  onSubmitEntry={onSubmitEntry}
                  userData={userData}
                  competition={competition}
                  clubs={clubs}
                  isLoading={isLoading}
                  entriesNumber={entries ? entries.length : 0}
                  entryToModify={entryToModify}
                  getProfileInput={getProfileInput}
                  permitRequired={permitRequired}
                  misz={miszApprovedFilter}
                />
              </Card>
              <Card
                title={t("competition.participants")}
                extra={
                  <Typography.Text strong>
                    {(entries || []).length}{" "}
                    {competition.maxParticipants < 999
                      ? " / " + competition.maxParticipants
                      : ""}{" "}
                    {t("competition.participant")}
                  </Typography.Text>
                }
              >
                {competition.headerText !== null && <Card>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(competition.headerText),
                    }}
                  ></div>
                </Card>}
                <CategoriesBarChart entries={entries} />
                <EntriesTable
                  entries={entries}
                  isFetchingEntries={isFetchingEntries}
                  competition={competition}
                  highlightedEntry={highlightedEntry}
                />
                <div ref={bottomOfEntriesRef}></div>
              </Card>
            </>
          )}
        </Col>
      )}

      <Col xs={competition.stage === "INFO" ? 16 : 10}>

        {competition.externalScoreUrl && <ResultsBanner competition={competition} />}
        {!miszApprovedFilter && competition.miszApproved && (
          <MiszBanner competition={competition} />
        )}
        {competition.stage === "SCORED" && multiRound && !multiRoundFinished && competition.teamsPublished && (
            <Card title={"Csapatbeosztás a következő napra"}>
                <EntriesTable
                  entries={entries}
                  isFetchingEntries={isFetchingEntries}
                  competition={competition}
                  highlightedEntry={highlightedEntry}
              />
            </Card>

        )}
        {competition.stage === "SCORED" && (!multiRound || multiRoundFinished) &&  (
          <>
            <Reviews reviews={reviews} />
            <br />
          </>
        )}
        {competition.targets.length > 0 && entries && (
          <>
            <Targets competition={competition} entries={entries} />
            <br />
          </>
        )}
        <Card title={t("competition.description")}>
          <Description competition={competition} />
        </Card>
      </Col>
    </Row>
  );
};
