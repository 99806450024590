import React from "react";
import { groupEntries } from "artemis-shared/utils/CompetitionEntryUtils";
import {
  Grid,
  Card,
  Table,
  PageHeader,
  Skeleton,
  Empty,
  Typography,
} from "antd";
import { useQuery } from "react-query";
import { getTournament, getTournamentEntries } from "../api/Tournament";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import "./TournamentScoreSummary.less";
import { useBreakpoint } from "artemis-shared/utils/Breakpoint";

const { Column } = Table;

export const TournamentScoreSummary = () => {
  let history = useHistory();
  const { lg, xl } = useBreakpoint();

  let { tournamentId } = useParams();

  const { data: tournament } = useQuery(
    ["tournament", parseInt(tournamentId)],
    getTournament
  );

  const { data: tournamentEntries, isLoading: isLoadingTournamentEntries } =
    useQuery(
      ["tournament-entries", parseInt(tournamentId)],
      getTournamentEntries
    );

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() =>
          history.push(`/tournament/${tournament.id}/${tournament.slug}`)
        }
        title={tournament && tournament.name}
      />

      {isLoadingTournamentEntries && (
        <Skeleton active paragraph={{ rows: 10 }} />
      )}

      {!isLoadingTournamentEntries && tournamentEntries.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"Még nem zajlott le az első forduló!"}
        />
      )}

      {tournamentEntries &&
        Object.entries(groupEntries(tournamentEntries))
          .sort((a, b) => {
            if (a[1].category.name.localeCompare(b[1].category.name) == 1)
              return 1;
            if (a[1].category.name.localeCompare(b[1].category.name) == -1)
              return -1;

            return a[1].ageGroup.fromAge - b[1].ageGroup.fromAge;
          })
          .map(([key, value]) => {
            value.entries.sort(
              (a, b) => (a.placement || 999) - (b.placement || 999)
            );
            return (
              <Card
                key={key}
                title={`${value.category.name} ${value.ageGroup.name} ${
                  value.gender === "MALE" ? "Férfi" : "Nő"
                }`}
                style={{ marginBottom: "16px" }}
                bodyStyle={{ padding: xl ? "16px" : 0 }}
              >
                <Table
                  bordered
                  dataSource={value.entries}
                  pagination={false}
                  size={"small"}
                  rowKey={(row) => row.id}
                  rowClassName={(record) =>
                    record.tie ? "tie" : "placement-" + record.placement
                  }
                  scroll={lg ? { x: 1300 } : false}
                >
                  <Column
                    width={40}
                    title="#"
                    dataIndex="placement"
                    key="placement"
                    fixed={true}
                  />
                  <Column
                    title="Név"
                    dataIndex="name"
                    key="name"
                    width={130}
                    fixed={true}
                    render={(text, record) =>
                      record.permitNumber > 0 ? (
                        <Link to={`/athlete/${record.permitNumber}`}>
                          {text}
                        </Link>
                      ) : (
                        text
                      )
                    }
                  />
                  <Column
                    title="Egyesület"
                    dataIndex={["club", "name"]}
                    width={130}
                    ellipsis={true}
                    key="club"
                  />

                  {tournament &&
                    tournament.competitions.map((competition, index) => {
                      let competitionId = competition.id;
                      return (
                        <Column
                          key={"r" + competitionId}
                          title={
                            <Link
                              to={`/competition/${competition.id}/${competition.slug}`}
                            >
                              {index + 1} forduló
                            </Link>
                          }
                          responsive={["lg"]}
                        >
                          <Column
                            width={50}
                            title={"#"}
                            key={"placement"}
                            className={index % 2 === 1 ? "odd-column" : ""}
                            render={(text, record) => {
                              let tsIndex = record.tournamentScores.findIndex(
                                (ts) => ts && ts.competition === competitionId
                              );

                              return tsIndex > -1 ? (
                                <Typography.Text strong>
                                  {
                                    record.tournamentScores[tsIndex]
                                      .competitionPlacement
                                  }
                                </Typography.Text>
                              ) : (
                                "-"
                              );
                            }}
                          />
                          <Column
                            width={50}
                            title={"P"}
                            key={"cs"}
                            className={index % 2 === 1 ? "odd-column" : ""}
                            render={(text, record) => {
                              let tsIndex = record.tournamentScores.findIndex(
                                (ts) => ts && ts.competition === competitionId
                              );

                              return tsIndex > -1
                                ? record.tournamentScores[tsIndex]
                                    .competitionScore
                                : "-";
                            }}
                          />
                          <Column
                            width={50}
                            title={"0"}
                            key={"misses"}
                            className={index % 2 === 1 ? "odd-column" : ""}
                            render={(text, record) => {
                              let tsIndex = record.tournamentScores.findIndex(
                                (ts) => ts && ts.competition === competitionId
                              );

                              return tsIndex > -1
                                ? record.tournamentScores[tsIndex]
                                    .competitionMisses || "-"
                                : "-";
                            }}
                          />
                          {tournament.scoring !== "0" && (
                            <Column
                              width={50}
                              title={"KP"}
                              key={"ts"}
                              className={index % 2 === 1 ? "odd-column" : ""}
                              render={(text, record) => {
                                let tsIndex = record.tournamentScores.findIndex(
                                  (ts) => ts && ts.competition === competitionId
                                );

                                return tsIndex > -1
                                  ? record.tournamentScores[tsIndex]
                                      .tournamentScore
                                  : "-";
                              }}
                            />
                          )}
                        </Column>
                      );
                    })}

                  <Column
                    title={"Összesített"}
                    key={"sumScore"}
                    responsive={["xxl", "xl", "lg", "md", "sm"]}
                  >
                    {tournament.scoring !== "0" && (
                      <Column
                        width={50}
                        title="KP"
                        dataIndex="tournamentScore"
                        key="ts"
                      />
                    )}
                    <Column
                      width={50}
                      title="P"
                      dataIndex="sumScore"
                      key="ss"
                      render={(text) => text || "-"}
                    />
                    <Column
                      width={50}
                      title={"Szétl"}
                      dataIndex={"tiebreaker"}
                      key={"tiebreaker"}
                      render={(text) => text || "-"}
                    />
                  </Column>
                </Table>
              </Card>
            );
          })}
    </>
  );
};
