import React from "react";
import { Group } from "./Group";

const InnerTeamComponent = ({ entries, unlockGroup }) => {
  return (
    <>
      {entries &&
        entries.map((group) => (
          <Group key={group.id} group={group} unlockGroup={unlockGroup} />
        ))}
    </>
  );
};

export const InnerTeam = React.memo(InnerTeamComponent);
