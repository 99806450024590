import React from "react";
import { Tag } from "antd";
import "./CategoryTag.less";
import {useTranslation} from "react-i18next";

export const CategoryTag = ({ category }) => {
  const {t} = useTranslation();
  let color = "blue";

  switch (category) {
    case "CRB":
    case "Történelmi számszeríj":
    case "Historical crossbows":
      color = "red";
      break;
    case "CU":
    case "C":
    case "FU":
    case "FS(C)":
    case "Csigás íj":
      color = "orange";
      break;
    case "HU":
    case "BH(C)":
    case "BU":
      color = "gold";
      break;
    case "CB":
      color = "volcano";
      break;
    case "OL":
    case "R":
    case "FS(R)":
    case "BB(R)":
    case "Modern reflexíj":
      color = "green";
      break;
    case "BB":
    case "BB(C)":
    case "B":
    case "Történelmi (természetes)":
      color = "cyan";
      break;
    case "TRRB":
    case "HR":
    case "BH(R)":
    case "Vadászíj":
    case "Hunting bows":
      color = "blue";
      break;
    case "TRLB":
    case "LB":
    case "BL":
    case "Történelmi hosszúíjak és egyéb botíjak":
    case "Historical longbow":
      color = "magenta";
      break;
    case "PBHB":
    case "HB":
    case "TR":
    case "TRB":
    case "Történelmi íj":
    case "Történelmi (modern)":
    case "Historical (modern)":
      color = "purple";
      break;
    default:
      color = "blue";
      break;

  }

  return  <>
    <Tag color={color} className={"category-tag"}>
      {t(category)}
    </Tag>
  </>;
};
