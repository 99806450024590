import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  getManagedCompetition,
  getManagedCompetitionEntries,
} from "../api/Competition";
import { useParams } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
import { Button, Card, Col, Row, Skeleton } from "antd";
import "./Teams.less";
import { EntriesCol } from "./EntriesCol";
import { TeamsCol } from "./TeamsCol";
import { TeamsPageHeader } from "./TeamsPageHeader";
import { Prompt } from "react-router";
import { getTournamentEntries } from "../api/Tournament";
import { TournamentEntriesContext } from "./TournamentEntriesContext";
import XLSX from "xlsx";
import _ from "lodash";
import {CompetitionContext} from "./CompetitionContext";

export const Teams = () => {
  let { competitionId } = useParams();
  const excelInputRef = useRef();

  const { data: competition, isLoading: isLoadingCompetition } = useQuery(
    ["managed-competition", parseInt(competitionId)],
    getManagedCompetition,
    {
      onSuccess: (data) => {
        setPublished(data.teamsPublished);
        setTeamLabels(data.teamLabels ? data.teamLabels.split(";") : []);
      },
    }
  );
  const { data: rawEntries, isLoading: isLoadingEntries } = useQuery(
    ["managed-competition-entries", parseInt(competitionId)],
    getManagedCompetitionEntries,
    {
      enabled: competition,
      onSuccess: (data) => {
        let entryMap = {};
        [...Array((competition.teamCount || 20) + 1).keys()].map(
          (teamNum) => (entryMap[teamNum] = [])
        );
        data.forEach((entry) => {
          if (!entry.groupId) {
            let newGroupId = Math.floor(Math.random() * 1000000000);
            entry.groupId = newGroupId;
            let newGroup = {
              id: newGroupId,
              groupIndex: entry.groupIndex,
              entries: [entry],
              team: 0,
            };
            entryMap[0].push(newGroup);
          } else {
            let placed = false;
            Object.keys(entryMap).forEach((team) => {
              let containingGroup = entryMap[team].find(
                (group) => group.id === entry.groupId
              );

              if (containingGroup) {
                containingGroup.entries.splice(entry.teamIndex, 0, entry);
                placed = true;
              }
            });

            if (!placed) {
              let newGroup = {
                id: entry.groupId,
                groupIndex: entry.groupIndex,
                entries: [entry],
                team: entry.team || 0,
              };
              entryMap[entry.team || 0].push(newGroup);
            }
          }
        });

        Object.keys(entryMap).forEach((team) => {
          entryMap[team].sort((a, b) => a.groupIndex - b.groupIndex);
          entryMap[team].forEach((group) =>
            group.entries.sort((a, b) => a.teamIndex - b.teamIndex)
          );
        });

        setTeamIndexes(entryMap);
        setEntries(entryMap);
      },
    }
  );

  const { data: tournamentEntries, isLoading: isLoadingTournamentEntries } =
    useQuery(
      [
        "tournament-entries",
        parseInt(competition ? competition.tournamentId : null),
      ],
      getTournamentEntries,
      { enabled: competition }
    );

  const [entries, setEntries] = useState({});
  const [teamLabels, setTeamLabels] = useState([]);
  const [published, setPublished] = useState(false);
  const [orderBy, setOrderByState] = useState(false);

  const setOrderBy = (value) => {
    setTeamIndexes(entries, value);
    setOrderByState(value);
  };

  useEffect(() => {
    const handleBeforeunload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, []);

  const setTeamIndexes = (entryMap, orderBy) => {
    Object.keys(entryMap).forEach((teamNumber) => {
      if (parseInt(teamNumber) === 0) {
        entryMap[teamNumber].sort((a, b) => {
          if (orderBy) {
            if (a.entries[0].category.name > b.entries[0].category.name)
              return 1;
            if (a.entries[0].category.name < b.entries[0].category.name)
              return -1;
            if (
              a.entries[0].ageGroup.ageGroup.name >
              b.entries[0].ageGroup.ageGroup.name
            )
              return 1;
            if (
              a.entries[0].ageGroup.ageGroup.name <
              b.entries[0].ageGroup.ageGroup.name
            )
              return -1;
            if (a.entries[0].gender > b.entries[0].gender) return 1;
            if (a.entries[0].gender < b.entries[0].gender) return -1;

            const tournamentEntryA = _.find(
                tournamentEntries,
                (te) =>
                    te.name === a.entries[0].name &&
                    te.ageGroup.id === a.entries[0].ageGroup.ageGroup.id &&
                    te.category.id === a.entries[0].category.id &&
                    te.gender === a.entries[0].gender
            );

            const tournamentEntryB = _.find(
                tournamentEntries,
                (te) =>
                    te.name === b.entries[0].name &&
                    te.ageGroup.id === b.entries[0].ageGroup.ageGroup.id &&
                    te.category.id === b.entries[0].category.id &&
                    te.gender === b.entries[0].gender
            );

            if((tournamentEntryA || tournamentEntryB) && !competition.multiRound) {
              if ((tournamentEntryA?.placement || 999) > (tournamentEntryB?.placement || 999)) return 1;
              if ((tournamentEntryA?.placement || 999) < (tournamentEntryB?.placement || 999)) return -1;
            } else {
              if (a.entries[0].placement > b.entries[0].placement) return 1;
              if (a.entries[0].placement < b.entries[0].placement) return -1;
            }
          } else {
            return a.entries[0].name.localeCompare(b.entries[0].name);
          }
        });
      }

      let teamIndex = 0;
      entryMap[teamNumber].forEach((group, index) => {
        group.groupIndex = index;
        group.entries.forEach((e) => {
          e.team = teamNumber;
          e.teamIndex = teamIndex;
          e.groupIndex = index;
          teamIndex++;
        });
      });
    });
  };

  const resetTeams = () => {
    let entryMap = { 0: [] };
    [...Array((competition.teamCount || 20) + 1).keys()].map(
      (teamNum) => (entryMap[teamNum] = [])
    );
    rawEntries.forEach((entry) => {
      let newGroupId = Math.floor(Math.random() * 1000000000);
      entry.groupId = newGroupId;
      let newGroup = {
        id: newGroupId,
        groupIndex: entry.groupIndex,
        entries: [entry],
        team: 0,
      };
      entryMap[0].push(newGroup);
    });

    entryMap[0].sort((a, b) => a.groupIndex - b.groupIndex);
    entryMap[0].forEach((group) =>
      group.entries.sort((a, b) => a.teamIndex - b.teamIndex)
    );

    setTeamIndexes(entryMap, orderBy);
    setEntries(entryMap);
  };

  const changeTeamOfGroup = (fromTeam, fromIndex, toTeam, toIndex) => {
    let tempEntriesMap = { ...entries };
    let newEntry = {
      ...tempEntriesMap[fromTeam][fromIndex],
      team: toTeam,
      groupIndex: toIndex,
    };

    newEntry.entries.forEach((entry) => (entry.team = toTeam));

    tempEntriesMap[fromTeam].splice(fromIndex, 1);
    tempEntriesMap[toTeam].splice(toIndex, 0, newEntry);

    setTeamIndexes(tempEntriesMap, orderBy);

    setEntries(tempEntriesMap);
  };

  const putEntryIntoGroup = (entryIndex, fromTeam, groupId, toTeam) => {
    console.log(entryIndex, fromTeam, groupId, toTeam);

    let tempEntriesMap = { ...entries };

    let movedEntries = tempEntriesMap[fromTeam][entryIndex].entries;
    movedEntries.forEach((entry) => (entry.groupId = groupId));

    tempEntriesMap[toTeam]
      .find((group) => group.id == groupId)
      .entries.push(...movedEntries);

    tempEntriesMap[fromTeam].splice(entryIndex, 1);

    setTeamIndexes(tempEntriesMap, orderBy);

    setEntries(tempEntriesMap);
  };

  const unlockGroup = (group) => {
    console.log("unlock group", group);

    let tempEntriesMap = { ...entries };

    let newGroups = group.entries.map((entry) => {
      let newGroupId = Math.floor(Math.random() * 1000000000);
      entry.groupId = newGroupId;
      return {
        id: newGroupId,
        entries: [entry],
        team: group.team,
      };
    });

    tempEntriesMap[group.team].splice(group.groupIndex, 1);

    tempEntriesMap[group.team].push(...newGroups);

    setTeamIndexes(tempEntriesMap, orderBy);

    setEntries(tempEntriesMap);
  };

  const handleDragEnd = (event) => {
    console.log("drop", event);
    if (event.combine) {
      let fromTeam = event.source.droppableId.split("-")[1];
      let groupId = event.combine.draggableId;
      let toTeam = event.combine.droppableId.split("-")[1];

      putEntryIntoGroup(
        event.source.index,
        fromTeam,
        groupId.split("-")[1],
        toTeam
      );
    } else {
      if (!event.destination) {
        return;
      }

      let fromTeam = event.source.droppableId.split("-")[1];
      let toTeam = event.destination.droppableId.split("-")[1];
      changeTeamOfGroup(
        parseInt(fromTeam),
        event.source.index,
        parseInt(toTeam),
        event.destination.index
      );
    }
  };

  const processExcelRows = (rows) => {
    console.log("rows", rows);

    let flatEntries = [];

    Object.values(entries).forEach((team) => {
      team.forEach((group) => {
        console.log("group", group);
        flatEntries.push(...group.entries);
      });
    });

    console.log("flatentries", flatEntries);

    let grouped = _.groupBy(rows, "groupNumber");

    console.log("grouped", grouped);

    let groupIdOrder = [];

    Object.keys(grouped).forEach((groupNumber) => {
      let firstEntryOfGroup = grouped[groupNumber][0];
      console.log("firstEntryOfGroup", groupNumber, firstEntryOfGroup);
      let firstEntry = _.find(flatEntries, { id: firstEntryOfGroup.id });
      let groupIdOfFirstEntry = firstEntry.groupId;

      console.log("groupIdOfFirstEntry", groupIdOfFirstEntry);

      groupIdOrder.push(groupIdOfFirstEntry);

      grouped[groupNumber].forEach((groupMember, index) => {
        console.log("find", groupMember);
        let entry = _.find(flatEntries, { id: groupMember.id });
        entry.team = 0;
        entry.groupId = groupIdOfFirstEntry;
        entry.groupIndex = index;
      });
    });

    console.log("groupIdOrder", groupIdOrder);

    let regrouped = _.groupBy(flatEntries, "groupId");

    console.log("regrouped", regrouped);

    let newEntries = Object.entries(regrouped).map(([groupId, entries]) => ({
      id: groupId,
      entries: entries,
      team: 0,
      groupIndex: groupIdOrder.indexOf(parseInt(groupId)),
    }));

    console.log("new", newEntries);

    let entryMap = {};
    [...Array((competition.teamCount || 20) + 1).keys()].map(
      (teamNum) => (entryMap[teamNum] = [])
    );

    entryMap[0] = _.sortBy(newEntries, "groupIndex");

    setEntries(entryMap);
  };

  return (
    <div className={"teams-page"}>
      <Prompt message="Biztos hogy elnavigálsz? A nem mentett módosítások elvesznek!" />
      <Skeleton
        title={false}
        loading={isLoadingEntries || isLoadingCompetition}
        paragraph={5}
        active
      />
      {competition && (
        <>
          <TeamsPageHeader
            competition={competition}
            entries={entries}
            published={published}
            setPublished={setPublished}
            teamLabels={teamLabels}
          />

          <DragDropContext
            onDragEnd={(event) => {
              handleDragEnd(event);
            }}
          >
            <CompetitionContext.Provider value={competition}>
              <TournamentEntriesContext.Provider value={tournamentEntries}>
                <Row gutter={[8, 8]}>
                  <Col xs={12} lg={16} xxl={18}>
                    <TeamsCol
                      competition={competition}
                      entries={entries}
                      unlockGroup={unlockGroup}
                      setEntries={setEntries}
                      setTeamIndexes={(value) => setTeamIndexes(value, orderBy)}
                      teamLabels={teamLabels}
                      setTeamLabel={(index, label) => {
                        let newLabels = [...teamLabels];
                        newLabels[index] = label;
                        setTeamLabels(newLabels);
                      }}
                    />
                  </Col>
                  <Col xs={12} lg={8} xxl={6}>
                    <EntriesCol
                      entries={entries}
                      rawEntries={rawEntries}
                      unlockGroup={unlockGroup}
                      competition={competition}
                      resetTeams={resetTeams}
                      orderBy={orderBy}
                      setOrderBy={setOrderBy}
                    />
                  </Col>
                </Row>
              </TournamentEntriesContext.Provider>
            </CompetitionContext.Provider>
          </DragDropContext>
        </>
      )}
      <Card title={"Excel beolvasása (egyelőre csak a Letizia excelével)"}>
        <input type={"file"} ref={excelInputRef} />
        <Button
          onClick={() => {
            let reader = new FileReader();
            reader.onload = function (e) {
              let workbook = XLSX.read(e.target.result, {
                type: "binary",
              });

              //Fetch the name of First Sheet.
              let firstSheet = "Csoportok";

              //Read all rows from First Sheet into an JSON array.
              let excelRows = XLSX.utils.sheet_to_json(
                workbook.Sheets[firstSheet],
                {
                  header: [
                    "id",
                    "permitNumber",
                    "name",
                    "club",
                    "category",
                    "gender",
                    "ageGroup",
                    "groupNumber",
                  ],
                }
              );

              console.log(excelRows);
              processExcelRows(excelRows.slice(2));
            };
            reader.readAsBinaryString(excelInputRef.current.files[0]);
          }}
        >
          Beolvasás
        </Button>
      </Card>
    </div>
  );
};
