import React from "react";
import { Droppable } from "react-beautiful-dnd";
import {
  Button,
  Card,
  Col,
  Dropdown, Input,
  Menu,
  Popconfirm,
  Row,
  Space,
  Tooltip,
} from "antd";
import { CardExtras } from "./CardExtras";
import { PlusCircleOutlined, SwapOutlined } from "@ant-design/icons";
import { InnerTeam } from "./InnerTeam";
import { useMutation, useQueryCache } from "react-query";
import { updateTeamCount, updateTeams } from "../api/Competition";
import Swal from "sweetalert2";

export const TeamsCol = ({
  entries,
  unlockGroup,
  setEntries,
  setTeamIndexes,
  competition,
    teamLabels,
    setTeamLabel
}) => {
  const cache = useQueryCache();

  const [updateTeamCountMutation] = useMutation(updateTeamCount, {
    onSuccess: () => {
      cache.invalidateQueries(["managed-competition", competition.id]);
      setEntries({
        ...entries,
        [Object.keys(entries).length]: []
      });
    },
  });

  const swapTeams = (fromTeam, toTeam) => {
    console.log("swap teams", fromTeam, toTeam);

    let tempEntriesMap = { ...entries };

    tempEntriesMap[fromTeam].forEach((group) => (group.team = toTeam));
    tempEntriesMap[toTeam].forEach((group) => (group.team = fromTeam));

    let temp = tempEntriesMap[fromTeam];
    tempEntriesMap[fromTeam] = tempEntriesMap[toTeam];
    tempEntriesMap[toTeam] = temp;

    setTeamIndexes(tempEntriesMap);

    setEntries(tempEntriesMap);
  };

  const getSwapMenu = (fromTeam) => (
    <Menu style={{ height: "200px", overflowY: "auto" }}>
      {[...Array(competition.teamCount || 20).keys()].map((teamNumber) => (
        <Menu.Item
          key={teamNumber}
          onClick={() => swapTeams(fromTeam, teamNumber + 1)}
        >
          {(competition.teamLabels?.split(";")[teamNumber] || teamNumber + 1)}. csapat
        </Menu.Item>
      ))}
    </Menu>
  );

  const swapButton = (team) => {
    return (
      <Tooltip key={2} title={"Csapat megcserélése"}>
        <Dropdown overlay={getSwapMenu(team)} trigger={"click"}>
          <Button style={{ marginLeft: "20px" }}>
            <SwapOutlined />
          </Button>
        </Dropdown>
      </Tooltip>
    );
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        {[...Array(competition.teamCount || 20).keys()].map((teamNumber) => (
          <Droppable
            droppableId={`team-${teamNumber + 1}`}
            key={teamNumber + 1}
            isCombineEnabled
          >
            {(provided, snapshot) => (
              <Col xs={24} lg={12} xxl={8}>
                <Card
                  key={teamNumber + 1}
                  title={<div style={{paddingRight: "20px"}}><Input maxLength={7} placeholder={`${teamNumber + 1}. csapat`} value={teamLabels[teamNumber]} onChange={(e) => setTeamLabel(teamNumber, e.target.value)}/></div>}
                  extra={[
                    <CardExtras
                      key={1}
                      team={teamNumber + 1}
                      entries={entries}
                    />,
                    swapButton(teamNumber + 1),
                  ]}
                  style={{
                    //height: "600px",
                    backgroundColor: snapshot.isDraggingOver
                      ? "#f3fff3"
                      : "white",
                  }}
                >
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <InnerTeam
                      entries={entries[teamNumber + 1]}
                      unlockGroup={unlockGroup}
                    />
                    {provided.placeholder}
                  </div>
                </Card>
              </Col>
            )}
          </Droppable>
        ))}
        <Col xs={24} lg={12} xxl={8}>
          <Popconfirm
            title="Biztos hogy módosítod a versenyt és létrehozol egy új csapatot?"
            onConfirm={() => {
              updateTeamCountMutation({
                competitionId: competition.id,
                teamCount: competition.teamCount + 1,
              });
            }}
            okText="Igen"
            cancelText="Mégsem"
            placement={"bottom"}
          >
            <Card
              key={999}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "24px",
                cursor: "pointer",
              }}
            >
              <h2>
                <Space>
                  <PlusCircleOutlined />
                  <span>Új csapat létrehozása</span>
                </Space>
              </h2>
            </Card>
          </Popconfirm>
        </Col>
      </Row>
    </>
  );
};
