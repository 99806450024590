import React, { useState } from "react";
import { Modal, Space, Table } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import chroma from "chroma-js";
import _ from "lodash";
import "./Results.less";
import { DistributionBar } from "./DistributionBar";
import { FileTextOutlined } from "@ant-design/icons";
import { ScorecardDisplay } from "artemis-shared/scorecard/ScorecardDisplay";

const { Column } = Table;

export const Results = ({ entries, mode }) => {
  const colorScale = chroma.scale(["#930000", "#d4b800", "#3e7900"]);

  const [scorecardEntry, setScorecardEntry] = useState(null);

  const avgFn = (entry) =>
    entry.scoreWoFinals /
    (entry.targetCount * entry.arrowCount);

  const percentageByProperty = (entry, property) =>
    (entry[property] /
      (entry.targetCount * entry.arrowCount)) *
    100;

  return (
    <>
      <Table
        dataSource={_.orderBy(entries, ["startDate"], ["desc"])}
        expandable={{
          // eslint-disable-next-line react/display-name
          expandedRowRender: (record) => (
            <DistributionBar entry={record} mode={mode} />
          ),
          defaultExpandAllRows: true,
          expandIconColumnIndex: -1,
          expandedRowClassName: () => "distribution-chart-row",
        }}
      >
        <Column
          title="Dátum"
          dataIndex="startDate"
          key="startDate"
          responsive={["md"]}
          render={(value) => moment(value).format("YYYY.MM.DD.")}
          width={100}
        />
        <Column
          title="Verseny"
          dataIndex="competitionName"
          key="competitionName"
          render={(text, record) => (
            <Link to={`/competition/${record.competitionId}`}>{text}</Link>
          )}
        />
        <Column title="Hely" dataIndex="placement" key="placement" width={50} />
        <Column
          title="Kat."
          dataIndex="category"
          key="category"
          width={70}
          responsive={["md"]}
        />
        <Column
          title="Kor"
          dataIndex="ageGroup"
          key="ageGroup"
          width={70}
          responsive={["md"]}
        />
        <Column
          title="Pont"
          dataIndex="score"
          key="score"
          width={70}
          render={(value, record) => {
            return (
              <>
                {record.scorecard && (
                  <span
                    onClick={() => setScorecardEntry(record)}
                    style={{ cursor: "pointer" }}
                  >
                    <Space>
                      <span>{value}</span>
                      <span>{<FileTextOutlined />}</span>
                    </Space>
                  </span>
                )}
                {!record.scorecard && <span>{value}</span>}
              </>
            );
          }}
        />
        <Column
          title="11"
          dataIndex="s11"
          key="s11"
          responsive={["lg"]}
          width={50}
          render={(value, row) =>
            mode ? percentageByProperty(row, "s11").toFixed() + "%" : row.s11
          }
        />
        <Column
          title="10"
          dataIndex="s10"
          key="s10"
          responsive={["lg"]}
          width={50}
          render={(value, row) =>
            mode ? percentageByProperty(row, "s10").toFixed() + "%" : row.s10
          }
        />
        <Column
          title="8"
          dataIndex="s8"
          key="s8"
          responsive={["lg"]}
          width={50}
          render={(value, row) =>
            mode ? percentageByProperty(row, "s8").toFixed() + "%" : row.s8
          }
        />
        <Column
          title="5"
          dataIndex="s5"
          key="s5"
          responsive={["lg"]}
          width={50}
          render={(value, row) =>
            mode ? percentageByProperty(row, "s5").toFixed() + "%" : row.s5
          }
        />
        <Column
          title="0"
          dataIndex="s0"
          key="s0"
          responsive={["lg"]}
          width={50}
          render={(value, row) =>
            mode ? percentageByProperty(row, "s0").toFixed() + "%" : row.s0
          }
        />
        <Column
          title="V.átlag"
          key="avg"
          responsive={["md"]}
          render={(value, row) => {
            let minEntry = _.minBy(entries, avgFn);
            let minAvg = avgFn(minEntry);
            let maxEntry = _.maxBy(entries, avgFn);
            let maxAvg = avgFn(maxEntry);

            let avg = avgFn(row);

            console.log(minEntry, maxEntry);
            console.log(minAvg, maxAvg, avg);

            let scale = (avg - minAvg) / (maxAvg - minAvg);

            console.log("scale", scale);

            return (
              <div
                style={{
                  backgroundColor: colorScale(scale).hex(),
                  color: "#ffffff",
                  textAlign: "center",
                }}
              >
                {avg.toFixed(2)}
              </div>
            );
          }}
          width={50}
        />
      </Table>
      <Modal
        visible={scorecardEntry}
        onCancel={() => setScorecardEntry(null)}
        footer={null}
      >
        <ScorecardDisplay scorecardString={scorecardEntry?.scorecard} entryId={scorecardEntry?.id} />
      </Modal>
    </>
  );
};
