import React, { useMemo, useRef, useState } from "react";
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Tooltip,
  Card,
  Switch,
  Space, Popconfirm,
} from "antd";
import _ from "lodash";
import XLSX from "xlsx";
import { calculateArrowCount, calculateScore, hasTeams, generateScorecardPdf } from "../ManagerUtils";

import "./ScoreTab.less";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { GenderTag } from "artemis-shared/tag/GenderTag";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { ValueKeepingInputNumber } from "./ValueKeepingInputNumber";
import { TeamFilter } from "../TeamFilter/TeamFilter";
import {DeleteTwoTone, FileTextOutlined, PrinterFilled, QuestionCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {getArrowCount} from "artemis-shared/utils/CompetitionUtils";

const { Column } = Table;

export const ScoreTab = ({
  localCompetitionData,
  localEntries,
  updateFullEntry,
  createEntry,
    deleteEntry,
  getLocalEntries,
  calculateAndSetPlacement,
  filter,
  setFilter,
  filterExpression,
  setFilterExpression,
  teamFilter,
  setTeamFilter,
  displayScoringValues,
  disabled,
  autoPlacement,
  setAutoPlacement,
  setScorecardString,
  setScorecardEntryId,
    sk
}) => {
  const { t } = useTranslation();
  const [scoreAutofill, setScoreAutofill] = useState(true);

  const [inputEntry, setInputEntry] = useState(null);

  const excelInputRef = useRef();

  const handleFocus = (event) => event.target.select();

  const deferredChangeEntry = useMemo(
    () =>
      _.debounce(async (entryId, entry, calculatePlacement) => {
        console.log("deferredChangeEntry", entryId, entry, calculatePlacement);
        await updateFullEntry(entryId, entry);
        await getLocalEntries();
        if (calculatePlacement) {
          deferredCalculateAndSetPlacement();
        }
      }, 1000),
    []
  );

  const deferredCalculateAndSetPlacement = useMemo(
    () =>
      _.debounce(async () => {
        let local = await getLocalEntries();
        await calculateAndSetPlacement(local);
      }, 3000),
    []
  );

  const delayedInputChange = (entryId, property, value) => {
    setAutoPlacement((currentAutoPlacement) => {
      setScoreAutofill((currentScoreAutofill) => {
        setInputEntry((currentInputEntry) => {
          let newInputEntry = currentInputEntry && { ...currentInputEntry };
          if (!newInputEntry) {
            newInputEntry = { ..._.find(localEntries, { id: entryId }) };

            newInputEntry[property] = value;
            if (
              currentScoreAutofill &&
              property.startsWith("s") &&
              property !== "score"
            ) {
              newInputEntry.score = calculateScore(
                localCompetitionData.scoring,
                newInputEntry
              );
            }
            deferredChangeEntry(entryId, newInputEntry, currentAutoPlacement);
          } else if (newInputEntry && newInputEntry.id === entryId) {
            newInputEntry[property] = value;
            if (
              currentScoreAutofill &&
              property.startsWith("s") &&
              property !== "score"
            ) {
              newInputEntry.score = calculateScore(
                localCompetitionData.scoring,
                newInputEntry
              );
            }
            deferredChangeEntry(entryId, newInputEntry, currentAutoPlacement);
          } else {
            deferredChangeEntry.flush();
            newInputEntry = { ..._.find(localEntries, { id: entryId }) };

            newInputEntry[property] = value;
            if (
              currentScoreAutofill &&
              property.startsWith("s") &&
              property !== "score"
            ) {
              newInputEntry.score = calculateScore(
                localCompetitionData.scoring,
                newInputEntry
              );
            }
            deferredChangeEntry(entryId, newInputEntry, currentAutoPlacement);
          }

          return newInputEntry;
        });
        return currentScoreAutofill;
      });
      return currentAutoPlacement;
    });
  };

  const shouldCellUpdate = (record, prevRecord) => {
    return !_.isEqual(record, prevRecord);
  };

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

  const processExcelRows = (rows) => {
    let success = 0;
    let newEntries = [];

    rows.forEach(async (entry) => {
      let updatedEntry = {
        ...entry,
        ageGroup: localCompetitionData.ageGroups.find(
          (ageGroup) => ageGroup.ageGroup.name === entry.ageGroup
        ),
        category: localCompetitionData.categories.find(
          (category) => category.name === entry.category
        ),
        gender: entry.gender === "férfi" ? "MALE" : "FEMALE"
      };

      if (entry.id) {
        console.log(updatedEntry);
        await updateFullEntry(entry.id, updatedEntry);
        success += 1;
      } else {
        updatedEntry.id = Math.floor(Math.random() * 1000000) + 1000000000;
        updatedEntry.club = { id: 999, name: "Egyéb" };
        newEntries.push(updatedEntry);
        console.log("NEW", updatedEntry);
        await createEntry(updatedEntry);
      }
    });

    console.log("success: ", success);
    console.log("new entries: ", newEntries);
    getLocalEntries();
  };

  console.log("filter", filter);

  const rowKeyFn = useMemo(() => (row) => row.id, []);

  return (
    <div className={"score-tab"}>
      <TeamFilter
        localCompetitionData={localCompetitionData}
        localEntries={localEntries}
        teamFilter={teamFilter}
        setTeamFilter={setTeamFilter}
        setFilterExpression={setFilterExpression}
        checkProperty={"score"}
      />
      <Row justify="space-between" align="middle" gutter={[16, 16]}>
        <Col>
          <Input
            id={"filter-expression-input"}
            placeholder={t("manager.search")}
            onChange={(e) => {
              setTeamFilter(null);
              setFilter({});
              setFilterExpression(e.target.value);
            }}
            onFocus={handleFocus}
            onPressEnter={() => {
              let scoreInput = document.querySelector(
                ".score-row-0 .score-col-0 input"
              );
              if (scoreInput) {
                scoreInput.focus();
              }
            }}
            value={filterExpression}
            style={{ width: 250 }}
          />
        </Col>
        <Col>
          <Space>
            <Switch
              defaultChecked={scoreAutofill}
              onChange={setScoreAutofill}
            ></Switch>
            <span>{t("manager.automaticScoreFill")}</span>
            <Button key={"skResults"} onClick={() => generateScorecardPdf(localEntries, localCompetitionData, t)}><PrinterFilled />{t("manager.scorecards")}</Button>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={localEntries}
        size={"small"}
        rowKey={rowKeyFn}
        rowClassName={(record, index) => "score-row score-row-" + index}
        pagination={
          teamFilter ? false : { pageSizeOptions: [10, 20, 100, 999] }
        }
        onChange={(paginate, filters) => setFilter(filters)}
      >
        {hasTeams(localEntries) && (
          <Column
            title={t("manager.teamShort")}
            dataIndex={"team"}
            width={20}
            onFilter={(value, record) => {
              return record.team === parseInt(value);
            }}
            filteredValue={isNumeric(teamFilter) ? [teamFilter] : []}
            sorter={(a, b) => a.team - b.team || a.groupIndex - b.groupIndex || a.teamIndex - b.teamIndex}
          />
        )}
        <Column
          title={t("manager.idShort")}
          dataIndex="id"
          key="id"
          width={50}
          render={(colValue, record) => {
            return (
              "" +
              getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, record.category.id, record.ageGroup.ageGroup.id) +
              localCompetitionData.targetCount +
              colValue
            );
          }}
          onFilter={(value, record) => {
            return (
              parseInt(
                "" +
                  getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, record.category.id, record.ageGroup.ageGroup.id) +
                  localCompetitionData.targetCount +
                  record.id
              ) === parseInt(value)
            );
          }}
          filteredValue={isNumeric(filterExpression) ? [filterExpression] : []}
          shouldCellUpdate={shouldCellUpdate}
        />
        <Column
          title={t("manager.name")}
          width={250}
          dataIndex="name"
          key="name"
          sorter={(a, b) => a.name.localeCompare(b.name)}
          onFilter={(value, record) => {
            return record.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
          }}
          filteredValue={!isNumeric(filterExpression) ? [filterExpression] : []}
          shouldCellUpdate={shouldCellUpdate}
        />
        <Column
          title={t("manager.permitShort")}
          width={75}
          dataIndex="permitNumber"
          key="permitNumber"
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue) => <span style={{color: colValue > 90000 || colValue < 1000 ? "#ff0000" : "#000000"}}>{colValue}</span>}
        />
        {!localCompetitionData.international &&<Column
          title={t("manager.club")}
          dataIndex={["club", "name"]}
          key="club"
          sorter={(a, b) => a.club.name.localeCompare(b.club.name)}
          shouldCellUpdate={shouldCellUpdate}
          responsive={["xxl"]}
        />}
        {localCompetitionData.international && <Column
            title={t("manager.country")}
            dataIndex={["country"]}
            key="country"
            sorter={(a, b) => (a.country || "-").localeCompare(b.country || "-")}
            shouldCellUpdate={shouldCellUpdate}
            responsive={["xxl"]}
            render={(text) => text && t("country." + text) || "-"}
        />}
        <Column
          title={t("manager.categoryShort")}
          dataIndex="category"
          width={75}
          key="category"
          sorter={{
            compare: (a, b) => a.category.name.localeCompare(b.category.name),
            multiple: 3,
          }}
          defaultSortOrder={"ascend"}
          filters={localCompetitionData.categories.map((category) => ({
            text: category.name,
            value: category.name,
          }))}
          filteredValue={filter.category}
          onFilter={(value, record) => record.category.name === value}
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => (
            <Tooltip placement="right" title={entry.category?.description}>
              <div>
                <CategoryTag category={colValue.name} />
              </div>
            </Tooltip>
          )}
        />
        <Column
          title={t("manager.ageGroupShort")}
          dataIndex="ageGroup"
          key="ageGroup"
          sorter={{
            compare: (a, b) =>
              a.ageGroup.ageGroup.name.localeCompare(b.ageGroup.ageGroup.name),
            multiple: 2,
          }}
          defaultSortOrder={"ascend"}
          filters={localCompetitionData.ageGroups.map((ageGroup) => ({
            text: ageGroup.ageGroup.name,
            value: ageGroup.ageGroup.name,
          }))}
          filteredValue={filter.ageGroup}
          onFilter={(value, record) => record.ageGroup.ageGroup.name === value}
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => (
            <AgeGroupTag ageGroup={colValue.ageGroup.name} />
          )}
        />
        <Column
          title={t("manager.gender")}
          dataIndex="gender"
          key="gender"
          sorter={{
            compare: (a, b) => a.gender.localeCompare(b.gender),
            multiple: 1,
          }}
          defaultSortOrder={"ascend"}
          filters={[
            {
              text: t("manager.man"),
              value: "MALE",
            },
            {
              text: t("manager.woman"),
              value: "FEMALE",
            },
          ]}
          filteredValue={filter.gender}
          onFilter={(value, record) => record.gender === value}
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => <GenderTag gender={colValue} />}
        />
        {localCompetitionData &&
          [...localCompetitionData.scoring]
            .reverse()
            .filter((sv) => displayScoringValues.indexOf(sv) > -1)
            .map((scoreProperty, colIndex) => {
              return (
                <Column
                  className={"score-input"}
                  width={40}
                  title={scoreProperty}
                  dataIndex={"s" + scoreProperty}
                  key={"s" + scoreProperty}
                  shouldCellUpdate={shouldCellUpdate}
                  render={(colValue, entry) => {
                    let countError = entry.score &&
                        calculateArrowCount(
                            localCompetitionData.scoring,
                            entry
                        ) !==
                        (localCompetitionData.targetCount *
                            getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, entry.category.id, entry.ageGroup.ageGroup.id));
                    return (
                        <Tooltip
                            title={
                              t("manager.total") + ": " +
                              calculateArrowCount(
                                  localCompetitionData.scoring,
                                  entry
                              ) +
                              " / " +
                              (localCompetitionData.targetCount *
                                  getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, entry.category.id, entry.ageGroup.ageGroup.id)) +
                              " " + t("manager.shots")
                            }
                        >
                          <ValueKeepingInputNumber
                              id={`score-col-${entry.id}-${colIndex}`}
                              className={`score-col-${colIndex}`}
                              value={colValue}
                              max={99}
                              style={{
                                width: "40px",
                                borderColor:
                                    countError
                                        ? "#ff0000"
                                        : "",
                                backgroundColor:
                                    countError
                                        ? "rgb(255,0,0,0.2)"
                                        : ""
                              }}
                              onFocus={handleFocus}
                              onKeyDown={(e) => {
                                setTimeout(() => {
                                  let value = e.target.value;
                                  if (value.length >= 2) {
                                    let nextInput = document.getElementById(
                                        `score-col-${entry.id}-${colIndex + 1}`
                                    );
                                    if (nextInput) {
                                      nextInput.focus();
                                    } else {
                                      let scoreInput = document.getElementById(
                                          `score-input-${entry.id}`
                                      );

                                      scoreInput && scoreInput.focus();
                                    }
                                  }
                                }, 0);
                              }}
                              onChange={(value) =>
                                  delayedInputChange(
                                      entry.id,
                                      "s" + scoreProperty,
                                      value
                                  )
                              }
                          />
                        </Tooltip>
                    )
                  }}
                />
              );
            })}
        <Column
          width={50}
          title={"Pont"}
          dataIndex={"score"}
          key={"score"}
          sorter={(a, b) => a.score - b.score}
          render={(colValue, entry, index) => (
            <div style={{display: "flex", alignItems: "center"}}>
              <Tooltip
                title={
                  t("manager.calculated") + ": " +
                  calculateScore(localCompetitionData.scoring, entry)
                }
              >
                <ValueKeepingInputNumber
                  id={`score-input-${entry.id}`}
                  style={{
                    width: "50px",
                    borderColor:
                      entry.score &&
                      calculateScore(localCompetitionData.scoring, entry) !==
                        entry.score
                        ? "#ff0000"
                        : "",
                  }}
                  value={colValue}
                  onFocus={handleFocus}
                  onChange={(value) =>
                    delayedInputChange(entry.id, "score", value)
                  }
                  onPressEnter={() => {
                    document.getElementById("filter-expression-input").focus();
                  }}
                />
              </Tooltip>
              {entry.scorecard && <span
                onClick={() => {
                setScorecardString(entry.scorecard)
                setScorecardEntryId(entry.id)
              }}
                style={{ cursor: "pointer", marginLeft: "5px" }}
                >
                <FileTextOutlined />
              </span>}
            </div>
          )}
        />
        <Column
          width={55}
          title={t("manager.tiebreakerScore")}
          dataIndex={"tiebreaker"}
          key={"tiebreaker"}
          render={(colValue, entry) => (
            <ValueKeepingInputNumber
                style={{ width: "55px",
                  backgroundColor:
                      entry.tie
                          ? "rgb(255,0,0,0.2)"
                          : "",}}
              value={colValue}
              onChange={(value) =>
                delayedInputChange(entry.id, "tiebreaker", value)
              }
            />
          )}
        />
        {!autoPlacement && (
          <Column
            width={55}
            title={t("manager.placement")}
            dataIndex={"placement"}
            key={"placement"}
            render={(colValue, entry) => (
              <ValueKeepingInputNumber
                style={{ width: "55px" }}
                value={colValue}
                onChange={(value) =>
                  delayedInputChange(entry.id, "placement", value)
                }
              />
            )}
          />
        )}
        <Column
            title={""}
            width={20}
            render={(colValue, entry, index) => (
                <Popconfirm
                    title={t("manager.deleteEntryConfirm")}
                    okText={t("manager.yes")}
                    cancelText={t("manager.cancel")}
                    onConfirm={() => deleteEntry(entry.id)}
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                >
                  <DeleteTwoTone twoToneColor="#ff0000" />
                </Popconfirm>
            )}
        />
      </Table>
      {!sk && <Card title={"Excel beolvasása"}>
        <input type={"file"} ref={excelInputRef} />
        <Button
          onClick={() => {
            let reader = new FileReader();
            reader.onload = function (e) {
              let workbook = XLSX.read(e.target.result, {
                type: "binary",
              });

              let processResult = processWorkbook(workbook, localCompetitionData, autoPlacement);

              console.log(processResult);
              setScoreAutofill(false);
              setAutoPlacement(false);
              processExcelRows(processResult);
            };
            reader.readAsBinaryString(excelInputRef.current.files[0]);
          }}
        >
          Beolvasás
        </Button>
      </Card>}
    </div>
  );
};

const processWorkbook = (workbook, competition, autoPlacement) => {
  if(competition.type === "THREED" || competition.type === "HISTORICAL" || competition.type === "FUN" || competition.type === "OTHER") {
    //Fetch the name of First Sheet.
    let firstSheet = "Pontszámok";

    let headers = ["id", "permitNumber", "placement", "name", "club", "gender", "ageGroup", "category"];

    headers.push(
        ...competition.scoring
            .sort((a, b) => parseInt(b) - parseInt(a))
            .map((scoreValue) => "s" + scoreValue)
    );

    headers.push("score");

    console.log("headers", headers);

    //Read all rows from First Sheet into an JSON array.
    let excelRows = XLSX.utils.sheet_to_json(
        workbook.Sheets[firstSheet],
        {
          header: headers,
        }
    );

    return excelRows;
  } else {
    console.error("UNKNOWN COMPETITION TYPE: ", competition.type);
    return [];
  }

};
