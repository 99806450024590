import {Card, List, Skeleton, Tooltip} from "antd";
import React, { useContext } from "react";

import "./EntriesList.less";
import { GenderTag } from "../tag/GenderTag";
import { CategoryTag } from "../tag/CategoryTag";
import { AgeGroupTag } from "../tag/AgeGroupTag";

import _ from "lodash";
import { UrlContext } from "../utils/UrlContext";
import { ExternalAwareLink } from "../utils/ExternalAwareLink";

export const TeamedEntriesList = ({
  entries,
  isFetchingEntries,
  competition,
}) => {
  const urls = useContext(UrlContext);
  const sortedEntries = [...entries];
  sortedEntries.sort((a, b) => a.teamIndex - b.teamIndex);

  const groupedEntries = _.groupBy(sortedEntries, (value) => value.team || 0);

  return (
    <div
      className={
        "competition-entries-list " +
        (competition.type === "HISTORICAL" ? "historical" : "")
      }
    >
      {Object.keys(groupedEntries)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .map((team) => (
          <Card
            key={team}
            title={`${
              competition.teamLabels?.split(";")[team - 1] || `${team}.`
            } csapat`}
            headStyle={{ textAlign: "center" }}
            bodyStyle={{ padding: "4px 0px 4px 8px" }}
          >
            <List
              key={team}
              dataSource={groupedEntries[team]}
              loading={isFetchingEntries}
              renderItem={(entry) => (
                <List.Item>
                  <Skeleton
                    avatar
                    title={false}
                    loading={isFetchingEntries}
                    active
                  >
                    <List.Item.Meta
                      title={
                        <div className={"entry"}>
                          <div className={"entry-left"}>
                            <div className={"entry-name"}>
                              <div>
                                {entry.permitNumber > 0 ? (
                                  <ExternalAwareLink
                                    to={`${urls.athlete}/${entry.permitNumber}`}
                                  >
                                    {entry.name}
                                  </ExternalAwareLink>
                                ) : (
                                  entry.name
                                )}
                              </div>
                              <div className={"entry-club"}>
                                {entry.club.name}
                              </div>
                            </div>
                          </div>
                          <div className={"entry-right"}>
                            <Tooltip placement="left" title={entry.category.description} trigger={"click"}>
                              <div>
                                <CategoryTag category={entry.category.name} />
                              </div>
                            </Tooltip>
                            <div>
                              <AgeGroupTag
                                ageGroup={entry.ageGroup.ageGroup.name}
                              />
                            </div>
                            <div>
                              <GenderTag gender={entry.gender} />
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Card>
        ))}
    </div>
  );
};
