import _ from "lodash";

export const getArrowCount = (defaultArrowCount, exceptions, category, ageGroup) => {
    let exception = _.find(exceptions, {category: category, ageGroup: ageGroup});

    if(exception) {
        return exception.arrowCount;
    } else {
        return defaultArrowCount;
    }
};

export const scorePercentage = (localCompetitionData, entry) => {
    const multiRound = localCompetitionData?.rounds?.length > 1;
    const maxValue = [...localCompetitionData.scoring].reverse()[0] === "x" ? [...localCompetitionData.scoring].reverse()[1] : [...localCompetitionData.scoring].reverse()[0];
    const maxScoringValue = Math.min(maxValue, 10);

    if(multiRound) {

        let targetCount = 0;
        let score = 0;

        localCompetitionData.rounds.forEach(roundData => {
            let roundScore = entry.roundEntries[roundData.round]?.score;

            if(!roundData.finals && roundScore) {
                targetCount += roundData.arrowCount;
                score += roundScore;
            }
        })

        const arrowCount = getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, entry.category.id, entry.ageGroup.ageGroup.id);
        return ((score / (targetCount * arrowCount * maxScoringValue)) * 100).toFixed(1)
    } else {
        const targetCount = localCompetitionData.targetCount;
        const arrowCount = getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, entry.category.id, entry.ageGroup.ageGroup.id);
        const score = entry.score;
        return ((score / (targetCount * arrowCount * maxScoringValue)) * 100).toFixed(1)
    }
}