import React, { useState, useEffect, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { getCompetitions } from "../api/Competition";
import {
  Button,
  Space,
  Row,
  Col,
  PageHeader,
  Menu,
  Switch,
  Card,
  Select,
} from "antd";
import { Map } from "../map/Map";
import { NavLink, useParams } from "react-router-dom";
import { useHistory } from "react-router";
import moment from "moment";
import _ from "lodash";

import "./Competitions.less";
import { useBreakpoint } from "../utils/Breakpoint";
import { UrlContext } from "../utils/UrlContext";
import { CategoryTabs } from "./CategoryTabs";
import { TableView } from "./TableView";
import { FilterRow } from "./FilterRow";
import { ListView } from "./ListView";

import { Tabs } from "antd";

const { TabPane } = Tabs;

export const Competitions = ({
  authenticated,
  calendar = [],
  miszApprovedFilter = false,
  hardLink = false,
}) => {
  let history = useHistory();
  const urls = useContext(UrlContext);
  const { xxl } = useBreakpoint();
  let { page: pageFromUrl, pagingType } = useParams();
  const { data, isFetching, isLoading } = useQuery(
    "competitions",
    getCompetitions,
    {
      onSuccess: (result) => {
        if (!pagingType) {
          if (weekFilter === "week") {
            setPage(moment().isoWeek());
            history.replace(`${urls.competitions}/${moment().isoWeek()}/week`);
          }

          if (weekFilter === "month") {
            setPage(moment().month() + 1);
            history.replace(
              `${urls.competitions}/${moment().month() + 1}/month`
            );
          }
        }
      },
    }
  );

  const [categoryFilter, setCategoryFilter] = useState(
    miszApprovedFilter ? "CENTRAL" : "ALL"
  );

  const [resultsFilter, setResultsFilter] = useState("COMPETITIONS");

  const [filters, setFilters] = useState({});
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [animatedCompetition, setAnimatedCompetition] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [page, setPage] = useState(1);
  const [calendarFilter, setCalendarFilter] = useState(false);
  const [filterExpression, setFilterExpression] = useState("");

  console.log(
    'localStorage.getItem("week-filter")',
    localStorage.getItem("week-filter")
  );

  const [weekFilterState, setWeekFilter] = useState(
    localStorage.getItem("week-filter")
      ? localStorage.getItem("week-filter") === "true"
        ? "week"
        : localStorage.getItem("week-filter") === "false"
        ? "all"
        : localStorage.getItem("week-filter")
      : "all"
  );

  console.log("weekfilterstate", weekFilterState);

  let weekFilter = resultsFilter === "RESULTS" ? "all" : weekFilterState;

  useEffect(() => {
    setPage(parseInt(pageFromUrl) || 1);
    if (!weekFilter) {
      setWeekFilter(pagingType);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("week-filter", weekFilterState.toString());
  }, [weekFilterState]);

  const getFilteredCompetitions = () => {
    let filtered = data || [];

    if (weekFilter === "all") {
      filtered = filtered.filter(
        (competition) => moment(competition.startDate).year() === selectedYear
      );
    }

    if (miszApprovedFilter) {
      filtered = filtered.filter((competition) => competition.miszApproved);
    }

    if (filters.type) {
      filtered = filtered.filter(
        (competition) => filters.type.indexOf(competition.type) > -1
      );
    }

    if (filterExpression) {
      filtered = _.filter(
        filtered,
        (competition) =>
          competition.name
            .toLowerCase()
            .indexOf(filterExpression.toLowerCase()) > -1 ||
          competition.location
            .toLowerCase()
            .indexOf(filterExpression.toLowerCase()) > -1
      );
    }

    if (calendarFilter && filterExpression === "") {
      let calendarIds = calendar.map((competition) => competition.id);
      filtered = _.filter(
        filtered,
        (competition) => calendarIds.indexOf(competition.id) > -1
      );
    }

    if (weekFilter === "week" && filterExpression === "") {
      const fromDate = moment(new Date(selectedYear + "-01-20"))
        .isoWeek(page)
        .startOf("isoWeek");
      const toDate = moment(new Date(selectedYear + "-01-20"))
        .isoWeek(page)
        .endOf("isoWeek");

      filtered = _.filter(filtered, (competition) =>
        moment(competition.startDate).isBetween(fromDate, toDate, "day", "[]")
      );
    }

    if (weekFilter === "month" && filterExpression === "") {
      const fromDate = moment(new Date(selectedYear + "-01-20"))
        .month(page - 1)
        .startOf("month");
      const toDate = moment(new Date(selectedYear + "-01-20"))
        .month(page - 1)
        .endOf("month");

      filtered = _.filter(filtered, (competition) =>
        moment(competition.startDate).isBetween(fromDate, toDate, "day", "[]")
      );
    }

    console.log("category", categoryFilter);

    if (categoryFilter !== "ALL") {
      filtered = filtered.filter((competition) => {
        console.log("competition.category", competition.category);
        return categoryFilter === competition.category;
      });
    }

    filtered = filtered.filter((competition) => {
      if (resultsFilter && resultsFilter === "RESULTS") {
        return (
          competition.stage === "SCORED" ||
          competition.stage === "EXTERNAL_SCORED" ||
          moment(competition.startDate).isBefore(moment(), "day")
        );
      } else {
        return (
          (competition.stage === "APPLICATION" ||
            competition.stage === "INFO") &&
          moment(competition.startDate).isSameOrAfter(moment(), "day")
        );
      }
    });

    return _.orderBy(
      filtered,
      ["startDate", "createdDate"],
      [resultsFilter === "RESULTS" ? "desc" : "asc", "asc"]
    );
  };

  const getCompetitionsForMap = () => {
    let filtered = getFilteredCompetitions();

    if (weekFilter === "all") {
      filtered = filtered.filter((competition) =>
        moment(competition.startDate).isSameOrAfter(moment(), "day")
      );
    }

    return filtered;
  };

  const findAndSetCurrentPage = (competitions) => {
    let filteredComps = miszApprovedFilter
      ? _.filter(competitions, { miszApproved: true })
      : competitions;
    filteredComps = filteredComps.filter(
      (competition) => moment(competition.startDate).year() === selectedYear
    );
    let index = filteredComps.findIndex((competition) =>
      moment(competition.startDate).isAfter(moment())
    );
    let pageNum = Math.floor(index / 6) + 1;

    setPage(pageNum);
    history.replace(`${urls.competitions}/${pageNum}`);
  };

  const getDetailsMenuItem = (record) => {
    if (record.miszUrl) {
      if (moment().isAfter(moment(record.startDate), "days")) {
        return (
          <Menu.Item key={record.id + "detailsbutton"}>
            <a href={"https://misz.hu/versenysite/" + record.miszUrl}>
              Eredmények
            </a>
          </Menu.Item>
        );
      } else {
        return (
          <Menu.Item key={record.id + "detailsbutton"}>
            <a href={"https://misz.hu/versenysite/" + record.miszUrl}>
              Nevezés
            </a>
          </Menu.Item>
        );
      }
    } else {
      if (record.stage !== "SCORED" && record.stage !== "EXTERNAL_SCORED") {
        return (
          <Menu.Item key={record.id + "detailsbutton"}>
            {!hardLink && (
              <NavLink to={`${urls.competition}/${record.id}/${record.slug}`}>
                {record.detailLevel === 1 ? "Részletek" : "Nevezés"}
              </NavLink>
            )}
            {hardLink && (
              <a href={`${urls.competition}/${record.id}/${record.slug}`}>
                {record.detailLevel === 1 ? "Részletek" : "Nevezés"}
              </a>
            )}
          </Menu.Item>
        );
      }

      if (record.stage === "SCORED") {
        return (
          <Menu.Item key={record.id + "detailsbutton"}>
            {!hardLink && (
              <NavLink to={`${urls.competition}/${record.id}/${record.slug}`}>
                Eredmények
              </NavLink>
            )}
            {hardLink && (
              <a href={`${urls.competition}/${record.id}/${record.slug}`}>
                Eredmények
              </a>
            )}
          </Menu.Item>
        );
      }

      if (record.stage === "EXTERNAL_SCORED") {
        return (
          <Menu.Item key={record.id + "detailsbutton"}>
            <a
              href={`${record.externalScoreUrl}`}
              target={"_blank"}
              rel={"noreferrer"}
            >
              Eredmények
            </a>
          </Menu.Item>
        );
      }
    }
  };

  const getDetailsButton = (record) => {
    if (record.miszUrl) {
      if (moment().isAfter(moment(record.startDate), "days")) {
        return (
          <a href={"https://misz.hu/versenysite/" + record.miszUrl}>
            <Button
              type={"primary"}
              style={{ width: "110px" }}
              onClick={(event) => event.stopPropagation()}
            >
              Eredmények
            </Button>
          </a>
        );
      } else {
        return (
          <a href={"https://misz.hu/versenysite/" + record.miszUrl}>
            <Button
              type={"primary"}
              style={{ width: "110px" }}
              onClick={(event) => event.stopPropagation()}
            >
              Nevezés
            </Button>
          </a>
        );
      }
    } else {
      if (record.stage !== "SCORED" && record.stage !== "EXTERNAL_SCORED") {
        return (
          <>
            {record.detailLevel === 1 && (
              <Button style={{ width: "110px" }}>Részletek</Button>
            )}
            {record.detailLevel === 2 && (
              <Button type={"primary"} style={{ width: "110px" }}>
                Nevezés
              </Button>
            )}
          </>
        );
      }

      if (record.stage === "SCORED") {
        return (
          <>
            <Button style={{ width: "110px" }} type={"primary"}>
              Eredmények
            </Button>
          </>
        );
      }

      if (record.stage === "EXTERNAL_SCORED") {
        return (
          <a
            href={`${record.externalScoreUrl}`}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <Button
              style={{ width: "110px" }}
              onClick={(event) => event.stopPropagation()}
            >
              Eredmények
            </Button>
          </a>
        );
      }
    }
  };

  const menu = (record) => (
    <Menu>
      {getDetailsMenuItem(record)}
      <Menu.Item
        key={3}
        onClick={() =>
          window.open(
            `https://www.google.com/maps/search/?api=1&query=${record.lat},${record.lng}`
          )
        }
      >
        Megtekintés Google Maps-en
      </Menu.Item>
      <Menu.Item
        key={4}
        onClick={() => {
          setAnimatedCompetition(record.id);
          setSelectedCenter({ lat: record.lat, lng: record.lng });
          setTimeout(() => setAnimatedCompetition(null), 1000);
        }}
      >
        Mutasd a térképen
      </Menu.Item>
    </Menu>
  );

  const debouncedFilterExpressionChange = useMemo(
    () => _.debounce(setFilterExpression, 500),
    []
  );

  return (
    <div className={"competitions"}>
      <div className={"title-row"}>
        <Tabs
          defaultActiveKey={resultsFilter}
          size={"large"}
          onChange={(value) => {
            setResultsFilter(value);
            if (weekFilterState === "week" && value === "COMPETITIONS") {
              setPage(moment().isoWeek());
              history.replace(
                `${urls.competitions}/${moment().isoWeek()}/week`
              );
            } else if (
              weekFilterState === "month" &&
              value === "COMPETITIONS"
            ) {
              setPage(moment().month() + 1);
              history.replace(
                `${urls.competitions}/${moment().month() + 1}/month`
              );
            } else {
              setPage(1);
              history.replace(`${urls.competitions}/1`);
            }
          }}
        >
          <TabPane tab={<span>Versenyek</span>} key="COMPETITIONS" />
          <TabPane tab={<span>Eredmények</span>} key="RESULTS" />
        </Tabs>
        <Space key={"switches"}>
          <Select
            style={{ width: "85px" }}
            value={weekFilterState}
            onChange={(value) => {
              setWeekFilter(value);

              if (value === "week") {
                setPage(moment().isoWeek());
                history.replace(
                  `${urls.competitions}/${moment().isoWeek()}/week`
                );
              } else if (value === "month") {
                setPage(moment().month() + 1);
                history.replace(
                  `${urls.competitions}/${moment().month() + 1}/month`
                );
              } else {
                setPage(1);
                history.replace(`${urls.competitions}/1`);
              }
            }}
          >
            <Select.Option value={"all"}>Lista</Select.Option>
            <Select.Option value={"week"}>Hét</Select.Option>
            <Select.Option value={"month"}>Hónap</Select.Option>
          </Select>
          {authenticated && (
            <Switch
              key={"calendar"}
              checked={calendarFilter}
              checkedChildren={"Naptár"}
              unCheckedChildren={"Naptár"}
              onChange={(checked) => {
                setCalendarFilter(checked);
              }}
            />
          )}
        </Space>
      </div>

      <Row gutter={[16, 16]} className={"competitions-xs"}>
        <Col xs={24} sm={0}>
          <Card>
            <FilterRow
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              filterExpression={filterExpression}
              debouncedFilterExpressionChange={debouncedFilterExpressionChange}
            />
            <CategoryTabs
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
            />
            <ListView
              filteredCompetitions={getFilteredCompetitions()}
              isLoading={isLoading}
              weekFilter={weekFilter}
              filterExpression={filterExpression}
              page={page}
              setPage={setPage}
              calendar={calendar}
              menu={menu}
              selectedYear={selectedYear}
              miszApprovedFilter={miszApprovedFilter}
            />
          </Card>
        </Col>
        <Col xs={0} sm={24} lg={0} xl={0}>
          <Card>
            <FilterRow
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              filterExpression={filterExpression}
              debouncedFilterExpressionChange={debouncedFilterExpressionChange}
            />
            <CategoryTabs
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
            />
            <TableView
              filteredCompetitions={getFilteredCompetitions()}
              isFetching={isFetching}
              isLoading={isLoading}
              setFilters={setFilters}
              weekFilter={weekFilter}
              filterExpression={filterExpression}
              calendar={calendar}
              page={page}
              setPage={setPage}
              setAnimatedCompetition={setAnimatedCompetition}
              setSelectedCenter={setSelectedCenter}
              getDetailsButton={getDetailsButton}
              menu={menu}
              selectedYear={selectedYear}
              miszApprovedFilter={miszApprovedFilter}
            />
          </Card>
        </Col>

        <Col xs={0} sm={0} xl={12} xxl={0}>
          <Card>
            <FilterRow
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              filterExpression={filterExpression}
              debouncedFilterExpressionChange={debouncedFilterExpressionChange}
            />
            <CategoryTabs
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
            />
            <ListView
              filteredCompetitions={getFilteredCompetitions()}
              isLoading={isLoading}
              weekFilter={weekFilter}
              filterExpression={filterExpression}
              page={page}
              setPage={setPage}
              calendar={calendar}
              menu={menu}
              selectedYear={selectedYear}
              miszApprovedFilter={miszApprovedFilter}
            />
          </Card>
        </Col>

        <Col xs={0} sm={0} lg={24} xl={0} xxl={15}>
          <Card>
            <FilterRow
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              filterExpression={filterExpression}
              debouncedFilterExpressionChange={debouncedFilterExpressionChange}
            />
            <CategoryTabs
              categoryFilter={categoryFilter}
              setCategoryFilter={setCategoryFilter}
            />
            <TableView
              filteredCompetitions={getFilteredCompetitions()}
              isFetching={isFetching}
              isLoading={isLoading}
              setFilters={setFilters}
              weekFilter={weekFilter}
              filterExpression={filterExpression}
              calendar={calendar}
              page={page}
              setPage={setPage}
              setAnimatedCompetition={setAnimatedCompetition}
              setSelectedCenter={setSelectedCenter}
              getDetailsButton={getDetailsButton}
              menu={menu}
              selectedYear={selectedYear}
              miszApprovedFilter={miszApprovedFilter}
            />
          </Card>
        </Col>

        <Col xs={{ span: 24 }} xl={{ span: 12 }} xxl={9}>
          <Map
            competitions={getFilteredCompetitions() || []}
            animatedCompetition={animatedCompetition}
            center={selectedCenter}
            zoom={selectedCenter ? 9 : null}
          />
        </Col>
      </Row>
    </div>
  );
};
