import React from "react";
import { Competitions } from "artemis-shared/competitions/Competitions";

import { getCalendar } from "../api/User";
import { useAuth } from "../auth/Auth";
import { useQuery } from "react-query";

export const CompetitionsWithCalendar = () => {
  const auth = useAuth();

  const { data: calendar } = useQuery("calendar", getCalendar, {
    enabled: auth.user,
  });

  return <Competitions authenticated={!!auth.user} calendar={calendar} />;
};
