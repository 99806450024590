import React, { useState } from "react";
import "./CookieConsent.less";
import { Button, Space } from "antd";
import badge from "./hu_badge_web_generic.png"

export const AppPromotion = () => {
  const [accepted, setAccepted] = useState(
    localStorage.getItem("app-promotion")
  );
  if (!accepted) {
    return (
      <div className={"cookie-consent"}>
        <span className={"consent-text"}>
          Mobilról az oldal kényelmesebben használható az Android alkalmazással. A letöltéshez, frissítéshez kattints a gombra!
        </span>
          <div>
          <a href={"https://play.google.com/store/apps/details?id=net.ijasz.app"} target={"_blank"} rel="noreferrer"> <img src={badge} className={"dl-badge"} /></a>
          </div>
        <Button
          onClick={() => {
            setAccepted(true);
            localStorage.setItem("app-promotion", "dismissed");
          }}
        >
          Bezárás
        </Button>
      </div>
    );
  } else {
    return null;
  }
};
