import React from "react";
import {Input} from "antd";

export const ArrowCountExceptionModal = ({categories, ageGroups, exceptions, setExceptions}) => {

    if(!ageGroups || !categories) {
        return null;
    }

    return <table>
        <tr>
            <td></td>
            {ageGroups.map(ageGroup => <td key={ageGroup?.id} style={{
                transformOrigin: "0 0",
                transform: "rotate(-90deg)"
            }}>{ageGroup?.name.substring(0,8)}</td>)}
        </tr>
        {categories.map(category => <tr key={category?.id}>
            <td style={{textAlign: "right"}}>{category?.name}</td>
            {ageGroups.map(ageGroup => <td key={category?.id + "_" + ageGroup?.id}>
                <Input style={{width: "40px"}}
                    defaultValue={exceptions.find(ex => ex.category === category?.id && ex.ageGroup === ageGroup?.id)?.arrowCount}
                       onChange={(e) => {
                           let newExceptions = [...exceptions];

                           let existing = newExceptions.find(ex => ex.category === category?.id && ex.ageGroup === ageGroup?.id);

                           if(existing) {
                               existing.arrowCount = e.target.value;
                           } else {
                               newExceptions.push({
                                   category: category?.id,
                                   ageGroup: ageGroup?.id,
                                   arrowCount: e.target.value
                               })
                           }

                           setExceptions(newExceptions);
                       }}
                />
            </td>)}
        </tr>)}
    </table>
}