import React from "react";
import { LockTwoTone } from "@ant-design/icons";
import { GroupElement } from "./GroupElement";
import { Draggable } from "react-beautiful-dnd";
import _ from "lodash";

const GroupComponent = ({ group, unlockGroup }) => {
  return (
    <Draggable
      key={group.id}
      draggableId={`group-${group.id}`}
      index={group.groupIndex}
    >
      {(provided, snapshot) => (
        <div
          className={
            "group-border " + (group.entries.length > 1 ? "grouped" : "")
          }
          key={group.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={"lock-button"} onClick={() => unlockGroup(group)}>
            <LockTwoTone twoToneColor={"#0b8200"} />
          </div>
          <div
            className={"group-body"}
            style={{
              backgroundColor: snapshot.combineTargetFor ? "#0b8200" : "white",
            }}
          >
            {_.sortBy(group.entries, "groupIndex").map((entry) => (
              <GroupElement key={entry.id} entry={entry} />
            ))}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export const Group = GroupComponent;
