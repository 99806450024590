import React, { useState } from "react";
import { Numpad } from "./Numpad";
import { Typography } from "antd";

import "./ManualEntry.less";

const { Title } = Typography;

export const ManualEntry = ({ onSubmit }) => {
  const [value, setValue] = useState("");

  return (
    <div>
      <Title level={4}>Kézi felvétel</Title>
      <Title level={5}>Add meg a beírólapon szereplő számot</Title>
      <div className={"custom-input"}>{value}</div>
      <Numpad
        onType={(digit) => setValue(value + digit)}
        onDelete={() => (value ? setValue(value.slice(0, -1)) : onSubmit(null))}
        onSubmit={() => {
          setValue("");
          onSubmit(value);
        }}
      />
    </div>
  );
};
