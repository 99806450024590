import jsPDF from "jspdf";
import _ from "lodash";
import QRCode from "qrcode";

import { RobotoRegular } from "../fonts/Roboto-Regular-normal";
import "../fonts/Roboto-Bold-bold";
import {getArrowCount} from "artemis-shared/utils/CompetitionUtils";

export const generatePDF = async (
  entries,
  competition,
  config,
  download,
  qrPerEntry,
  sortBy,
  t
) => {
  let doc = new jsPDF();

  let qrEntries = [];

  let sortedEntries =
    sortBy === "team"
      ? _.sortBy(entries, ["team", "teamIndex"])
      : _.sortBy(entries, "name");

  for (let entry of sortedEntries) {
    let qrEntry = {
      id: entry.id,
      name: entry.name,
      category: entry.category.name,
      ageGroup: entry.ageGroup.ageGroup.name,
      gender: entry.gender === "MALE" ? 0 : 1,
      club: entry.club.name,
      country: entry.country,
      team: competition.teamLabels ? (competition.teamLabels.split(";")[entry.team - 1] || entry.team) : entry.team || "",
      targetCount: competition.targetCount,
      arrowCount: getArrowCount(competition.arrowCount, competition.arrowCountExceptions, entry.category.id, entry.ageGroup.ageGroup.id),
      competitionId: competition.id,
      liveToken: entry.liveToken,
    };

    qrEntry.qr = await QRCode.toDataURL(
      `${competition.scoring
        .sort(
          (a, b) => parseInt(b === "x" ? 15 : b) - parseInt(a === "x" ? 15 : a)
        )
        .join(",")};${qrEntry.targetCount * qrEntry.arrowCount};${qrEntry.id};${
        qrEntry.name
      };${qrEntry.category};${qrEntry.ageGroup};${qrEntry.gender};${
        qrEntry.targetCount
      };${qrEntry.arrowCount}${
        competition.live
          ? ";" + qrEntry.competitionId + ";" + qrEntry.liveToken
          : ""
      }`
    );

    for (let i = 0; i < qrPerEntry; i++) {
      qrEntries.push(qrEntry);
    }
  }

  let {
    stickerWidth,
    stickerHeight,
    qrWidth,
    qrRightMargin,
    stickerTopPadding,
    stickerLeftPadding,
    rowsPerPage,
    colsPerPage,
    titleFontSize,
    subtitleFontSize,
    titleLineHeight,
    subtitleLineHeight,
    bordered,
      showTeam,
      skip
  } = config;

  let pageWidth = 210;
  let pageHeight = 297;

  let qrTopPadding = (stickerHeight - qrWidth) / 2 - 1;
  let pageTopPadding = (pageHeight - rowsPerPage * stickerHeight) / 2;
  let pageLeftPadding = (pageWidth - colsPerPage * stickerWidth) / 2;

  let index = 0;
  doc.addFileToVFS("RobotoRegular.ttf", RobotoRegular);
  doc.addFont("RobotoRegular.ttf", "Roboto", "normal");
  doc.setFont("Roboto");

  outloop: for (
    let page = Math.floor(skip / (colsPerPage * rowsPerPage));
    page < (entries.length * qrPerEntry) / (rowsPerPage * colsPerPage);
    page++
  ) {
    if (page > 0) doc.addPage();
    for (let row = page === Math.floor(skip / (colsPerPage * rowsPerPage)) ? Math.floor(skip / colsPerPage) : 0; row < rowsPerPage; row++) {
      for (let col = page === Math.floor(skip / (colsPerPage * rowsPerPage)) && row === Math.floor(skip / colsPerPage) ? skip % colsPerPage : 0; col < colsPerPage; col++) {
        let qrEntry = qrEntries[index];

        if (!qrEntry) {
          break outloop;
        }

        if (bordered) {
          doc.setDrawColor(0, 0, 0);
        } else {
          doc.setDrawColor(255, 255, 255);
        }

        doc.setFillColor(255, 255, 255);
        doc.rect(
          pageLeftPadding + col * stickerWidth,
          pageTopPadding + row * stickerHeight,
          stickerWidth,
          stickerHeight,
          "FD"
        );

        doc.setFontSize(titleFontSize);

        let nameLine = doc.splitTextToSize(
          qrEntry.name,
          stickerWidth - stickerLeftPadding - qrWidth - 5
        );
        doc.text(
          nameLine,
          pageLeftPadding +
            col * stickerWidth +
            stickerLeftPadding +
            qrWidth +
            qrRightMargin,
          pageTopPadding + row * stickerHeight + stickerTopPadding + 5
        );

        doc.setFontSize(subtitleFontSize);

        doc.text(
          `${qrEntry.arrowCount}${String(qrEntry.targetCount).padStart(2, "0")}${qrEntry.id}`,
          pageLeftPadding + col * stickerWidth + stickerLeftPadding + 3,
          pageTopPadding + row * stickerHeight + qrTopPadding + qrWidth + 3
        );

        doc.text(
          `${t(qrEntry.category)} ${t(qrEntry.ageGroup)} ${
            qrEntry.gender === 0 ? t("manager.stickerman") : t("manager.stickerwoman")
          }`,
          pageLeftPadding +
            col * stickerWidth +
            stickerLeftPadding +
            qrWidth +
            qrRightMargin,
          pageTopPadding +
            row * stickerHeight +
            stickerTopPadding +
            titleLineHeight
        );

        doc.text(
          `${(competition.international ? qrEntry.country && t("country." + qrEntry.country) : qrEntry.club) || ""}`.substring(0, 22),
          pageLeftPadding +
            col * stickerWidth +
            stickerLeftPadding +
            qrWidth +
            qrRightMargin,
          pageTopPadding +
            row * stickerHeight +
            stickerTopPadding +
            titleLineHeight +
            subtitleLineHeight
        );

        if(showTeam) {
          doc.text(
              `${qrEntry.team}`,
              pageLeftPadding + col * stickerWidth + stickerWidth - 7,
              pageTopPadding +
              row * stickerHeight +
              stickerTopPadding +
              titleLineHeight +
              subtitleLineHeight * 2
          );
        }

        doc.addImage(
          qrEntry.qr,
          "PNG",
          pageLeftPadding + col * stickerWidth + stickerLeftPadding,
          pageTopPadding + row * stickerHeight + qrTopPadding,
          qrWidth,
          qrWidth
        );

        index++;
      }
    }
  }

  if (download) {
    doc.save(`${competition.slug}_qr.pdf`);
  }

  return doc.output("bloburl");
};
