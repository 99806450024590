import React, { useState, useEffect } from "react";
import { Card, Col, Empty, Form, PageHeader, Row, Select } from "antd";
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { getRanking } from "../api/Ranking";
import { getCategories } from "../api/Category";
import { getAgeGroups } from "../api/AgeGroup";
import _ from "lodash";
import moment from "moment";
import { RankingItem } from "./RankingItem";
import { useParams } from "react-router-dom";
import "./Ranking.less";

export const Ranking = () => {
  let history = useHistory();
  let {
    category: categoryFromUrl,
    ageGroup: ageGroupFromUrl,
    gender: genderFromUrl,
  } = useParams();

  const [category, setCategory] = useState();
  const [ageGroup, setAgeGroup] = useState();
  const [gender, setGender] = useState();

  const { data: ranking } = useQuery(
    [
      "ranking",
      {
        competitionType: "THREED",
        category: category,
        ageGroup: ageGroup,
        gender: gender,
      },
    ],
    getRanking,
    {
      enabled: category && ageGroup && gender,
    }
  );

  const { data: categories } = useQuery(["categories"], getCategories, {
    onSuccess: (data) =>
      !categoryFromUrl && setCategory(_.find(data, { name: "PBHB" }).id),
  });

  const { data: ageGroups } = useQuery(["age-groups"], getAgeGroups, {
    onSuccess: (data) =>
      !ageGroupFromUrl && setAgeGroup(_.find(data, { name: "Felnőtt" }).id),
  });

  let grouped = _.groupBy(ranking, "permitNumber");
  let processedRanking = Object.entries(grouped).map(([key, value]) => {
    let sorted = value.sort((a, b) => moment(a.issued).diff(moment(b.issued)));
    let diff = sorted[1] ? sorted[0].rank - sorted[1].rank : 0;

    return sorted[1] ? { ...sorted[1], diff } : { ...sorted[0], diff };
  });

  const setUrl = () => {
    if (category && ageGroup && gender) {
      history.replace(`/ranking/${category}/${ageGroup}/${gender}`);
    }
  };

  useEffect(() => {
    setUrl();
  }, [category, ageGroup, gender]);

  useEffect(() => {
    if (categoryFromUrl) {
      setCategory(parseInt(categoryFromUrl));
    }

    if (ageGroupFromUrl) {
      setAgeGroup(parseInt(ageGroupFromUrl));
    }

    if (genderFromUrl) {
      setGender(genderFromUrl);
    } else {
      setGender("MALE");
    }
  }, []);

  return (
    <div className={"ranking-page"}>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/")}
        title={"Ranglista"}
        extra={
          category &&
          categories &&
          ageGroup &&
          ageGroups && (
            <Form
              layout={"inline"}
              initialValues={{ category, ageGroup, gender }}
            >
              <Form.Item label="Kategória" name="category">
                <Select
                  style={{ width: 90 }}
                  listHeight={320}
                  value={category}
                  onChange={setCategory}
                >
                  {categories
                    .filter(
                      (category) =>
                        category.type === "THREED" &&
                        [
                          "CRB",
                          "CU",
                          "HU",
                          "CB",
                          "OL",
                          "BB",
                          "TRRB",
                          "TRLB",
                          "PBHB",
                          "BG",
                        ].indexOf(category.name) > -1
                    )
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((category) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Korosztály" name="ageGroup">
                <Select
                  style={{ width: 90 }}
                  value={ageGroup}
                  onChange={setAgeGroup}
                >
                  {ageGroups
                    .filter((ageGroup) => ageGroup.type === "THREED")
                    .map((ageGroup) => (
                      <Select.Option key={ageGroup.id} value={ageGroup.id}>
                        {ageGroup.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label="Nem" name="gender">
                <Select
                  style={{ width: 90 }}
                  value={gender}
                  onChange={setGender}
                >
                  <Select.Option key={"male"} value={"MALE"}>
                    Férfi
                  </Select.Option>
                  <Select.Option key={"female"} value={"FEMALE"}>
                    Nő
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form>
          )
        }
      />
      <Row>
        {!(category && ageGroup && gender) && (
          <Col xs={24}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"Válassz kategóriát, korosztályt, nemet!"}
            />
          </Col>
        )}
        {ranking && ranking.length > 0 && (
          <Col
            xs={24}
            xl={{ span: 18, offset: 3 }}
            xxl={{ span: 12, offset: 6 }}
          >
            <Card>
              {processedRanking
                .sort((a, b) => a.rank - b.rank)
                .map((ranking) => (
                  <RankingItem
                    key={ranking.id}
                    ranking={ranking}
                    competitionType={"THREED"}
                    category={category}
                    ageGroup={ageGroup}
                    gender={gender}
                  />
                ))}
            </Card>
          </Col>
        )}
        {ranking && ranking.length === 0 && (
          <Col xs={24}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"Nincs versenyző"}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
