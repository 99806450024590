import React from "react";
import { Tag } from "antd";

export const QualificationTag = ({ qualification }) => {
  let color = "white";

  switch (qualification) {
    case 1:
      color = "#FFC600";
      break;
    case 2:
      color = "#949494";
      break;
    case 3:
      color = "#A75E00";
      break;
    default:
      color = "#e8e8e8";
      break;
  }

  return (
    <>
      <Tag color={color} className={"qualification-tag"}>
        {qualification}
      </Tag>
    </>
  );
};
