import React, {useContext} from "react";
import { Button, List, Skeleton, Typography } from "antd";
import "./CompetitionList.less";
import { CompetitionTypeTag } from "../tag/CompetitionTypeTag";
import { RightOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import {UrlContext} from "../utils/UrlContext";

export const CompetitionList = ({ competitions, isFetching, hardLink }) => {
  const urls = useContext(UrlContext);

  return (
    <List
      dataSource={competitions}
      loading={isFetching}
      renderItem={(competition) => (
        <List.Item>
          <div className={"competition-item"}>
            <div>
              <div className={"title-row"}>
                <Typography.Text strong>{competition.name}</Typography.Text>
              </div>
              <div className={"content-row"}>
                <CompetitionTypeTag type={competition.type} />
                <div>
                  {competition.startDate} - {competition.location}
                </div>
              </div>
            </div>
            <div>
              {!hardLink && (
                  <NavLink to={`${urls.competition}/${competition.id}/${competition.slug}`}>
                    <Button>
                      <RightOutlined />
                    </Button>
                  </NavLink>
              )}
              {hardLink && (
                  <a href={`${urls.competition}/${competition.id}/${competition.slug}`}>
                    <Button>
                      <RightOutlined />
                    </Button>
                  </a>
              )}
            </div>
          </div>
        </List.Item>
      )}
    />
  );
};
