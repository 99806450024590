import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const postReview = async ({ competitionId, review }) => {
  return axios.post(`${API_URL}/competition/${competitionId}/review`, review);
};

export const getSummarizedReview = async (key, competitionId) => {
  const { data } = await axios.get(
    `${API_URL}/competition/${competitionId}/review`
  );
  return data;
};
