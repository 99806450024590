import _ from "lodash";
import {
  Button,
  Table,
  Space,
  Col,
  Tooltip,
  Dropdown,
  Pagination,
  Tag,
} from "antd";
import { CompetitionTypeTag } from "../tag/CompetitionTypeTag";
import React, { useContext } from "react";
import { useBreakpoint } from "../utils/Breakpoint";
import { UrlContext } from "../utils/UrlContext";
import moment from "moment";
import { useHistory } from "react-router";
import {
  AimOutlined,
  MenuOutlined,
  CarOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

const { Column } = Table;

export const TableView = ({
  filteredCompetitions,
  isFetching,
  isLoading,
  setFilters,
  weekFilter,
  filterExpression,
  calendar,
  page,
  setPage,
  setAnimatedCompetition,
  setSelectedCenter,
  getDetailsButton,
  menu,
  selectedYear,
  miszApprovedFilter,
}) => {
  const { xxl } = useBreakpoint();
  let history = useHistory();
  const urls = useContext(UrlContext);

  return (
    <>
      <Table
        dataSource={filteredCompetitions}
        loading={isFetching || isLoading}
        onChange={(pagination, filters, sorter, extra) => {
          setFilters(filters);
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (record.miszUrl) {
                window.open("https://misz.hu/versenysite/" + record.miszUrl);
              } else {
                if (record.stage === "EXTERNAL_SCORED") {
                  window.open(`${record.externalScoreUrl}`);
                } else {
                  history.push(
                    `${urls.competition}/${record.id}/${record.slug}`
                  );
                }
              }
            },
          };
        }}
        pagination={
          weekFilter !== "all" || filterExpression
            ? false
            : {
                pageSize: 6,
                current: page,
                showSizeChanger: false,
                onChange: (page) => {
                  setPage(page);
                  history.replace(`${urls.competitions}/${page}`);
                },
              }
        }
        rowClassName={(record) => {
          return _.find(calendar, { id: record.id }) ? "in-calendar-row" : "";
        }}
        onSelect
      >
        <Column
          width={50}
          className={"type-col"}
          title={xxl ? "Típus" : ""}
          dataIndex="type"
          key="type"
          render={(type) => <CompetitionTypeTag type={type} />}
          filters={[
            {
              text: "3D",
              value: "THREED",
            },
            {
              text: "Terep",
              value: "FIELD",
            },
            {
              text: "Pálya",
              value: "TARGET",
            },
            {
              text: "Történelmi",
              value: "HISTORICAL",
            },
            {
              text: "Örömíjász",
              value: "FUN",
            },
            {
              text: "Egyéb",
              value: "OTHER",
            },
          ]}
          onFilter={(value, record) => record.type.indexOf(value) === 0}
        />
        <Column
          title="Név"
          dataIndex="name"
          key="name"
          render={(text, record) => (
            <Space>
              <span>
                {record.miszApproved && !miszApprovedFilter && (
                  <Tag color={"red"}>MISZ</Tag>
                )}{" "}
                {text}
              </span>
            </Space>
          )}
        />
        <Column
          title="Helyszín"
          dataIndex="location"
          key="location"
          responsive={["sm"]}
        />
        <Column
          title="Dátum"
          dataIndex="startDate"
          key="startDate"
          width={130}
          render={(value, record) => (
            <span>
              {moment(value).isoWeekday() === 7 ? (
                <span style={{ color: "#e80000" }}>{value}</span>
              ) : (
                <span>{value}</span>
              )}
              {_.find(calendar, { id: record.id }) && (
                <Tooltip placement="rightTop" title={"Szerepel a naptáradban"}>
                  &nbsp;
                  <CalendarOutlined />
                </Tooltip>
              )}
            </span>
          )}
        />
        <Column
          title=""
          key="action"
          align={"right"}
          className={"action-col"}
          render={(text, record) => (
            <>
              <Col xs={{ span: 24 }} xxl={{ span: 0 }}>
                <Dropdown
                  overlay={menu(record)}
                  placement="bottomRight"
                  trigger={"click"}
                >
                  <Button
                    type={record.detailLevel === 2 ? "primary" : ""}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <MenuOutlined />
                  </Button>
                </Dropdown>
              </Col>
              <Col xs={{ span: 0 }} xxl={{ span: 24 }}>
                <Space size="middle">
                  {getDetailsButton(record)}
                  <Tooltip title={"Megnyitás a Google Maps-en"}>
                    <Button
                      href={`https://www.google.com/maps/search/?api=1&query=${record.lat},${record.lng}`}
                      target={"_blank"}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <CarOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title={"Mutasd a térképen"}>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        setAnimatedCompetition(record.id);
                        setSelectedCenter({
                          lat: record.lat,
                          lng: record.lng,
                        });
                        setTimeout(() => setAnimatedCompetition(null), 1000);
                      }}
                    >
                      <AimOutlined />
                    </Button>
                  </Tooltip>
                </Space>
              </Col>
            </>
          )}
        />
      </Table>
      {weekFilter !== "all" && !filterExpression && (
        <div className={"week-paginator"}>
          <Pagination
            current={page}
            pageSize={1}
            itemRender={(page, type, originalElement) => {
              if (type === "page" && weekFilter === "month") {
                return (
                  <span>
                    {moment()
                      .month(page - 1)
                      .format("MMM")}
                  </span>
                );
              } else {
                return originalElement;
              }
            }}
            total={
              weekFilter === "week"
                ? moment(new Date(selectedYear + "-01-20")).isoWeeksInYear()
                : 12
            }
            showSizeChanger={false}
            onChange={(page) => {
              setPage(page);
              history.replace(`${urls.competitions}/${page}/${weekFilter}`);
            }}
          />
        </div>
      )}
    </>
  );
};
