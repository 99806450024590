import React from "react";

import { Select } from "antd";

export const YearSelector = ({ selectedYear, setSelectedYear }) => {
  return (
    <div className={"year-selector"}>
      <Select
        style={{ width: 80 }}
        defaultValue={selectedYear}
        onChange={setSelectedYear}
      >
        <Select.Option value={2025}>2025</Select.Option>
        <Select.Option value={2024}>2024</Select.Option>
        <Select.Option value={2023}>2023</Select.Option>
        <Select.Option value={2022}>2022</Select.Option>
        <Select.Option value={2021}>2021</Select.Option>
        <Select.Option value={2020}>2020</Select.Option>
      </Select>
    </div>
  );
};
