import React from "react";
import _ from "lodash";
import { Divider, Space, Tooltip } from "antd";
import {
  AimOutlined,
  EyeOutlined,
  FormOutlined,
  SkinOutlined,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";

const CardExtrasComponent = ({ team, entries }) => {
  let entriesOfTeam = (entries[team] || []).reduce(
    (acc, group) => [...acc, ...group.entries],
    []
  );

  let sight = entriesOfTeam.filter((entry) => {
    return entry.category.sight;
  }).length;

  let adults = entriesOfTeam.filter(
    (entry) => entry.ageGroup.fromAge >= 18
  ).length;

  let clubs = Object.keys(_.countBy(entriesOfTeam, "club.id")).length;

  let minorScorer =
    entriesOfTeam.length >= 2 && entriesOfTeam[1].ageGroup.fromAge < 18;

  let minorCaptain =
    entriesOfTeam.length >= 1 && entriesOfTeam[0].ageGroup.fromAge < 18;

  return (
    <>
      <span key={1}>
        <Space split={<Divider type="vertical" />}>
          {entriesOfTeam.length > 1 && clubs == 1 && (
            <Tooltip title={"Mindenki egy egyesületből van!"}>
              <Space>
                <SkinOutlined style={{ color: "red" }} />
              </Space>
            </Tooltip>
          )}
          {entriesOfTeam.length > 0 && adults == 0 && (
            <Tooltip title={"Nincs felnőtt a csapatban!"}>
              <Space>
                <EyeOutlined style={{ color: "red" }} />
              </Space>
            </Tooltip>
          )}
          {entriesOfTeam.length > 0 && minorScorer && (
            <Tooltip title={"A beíró 18 év alatti!"}>
              <Space>
                <FormOutlined style={{ color: "red" }} />
              </Space>
            </Tooltip>
          )}
          {entriesOfTeam.length > 0 && minorCaptain && (
            <Tooltip title={"A csapatkapitány 18 év alatti!"}>
              <Space>
                <StarOutlined style={{ color: "red" }} />
              </Space>
            </Tooltip>
          )}
          <Tooltip title={"Irányzékosok"}>
            <Space>
              <AimOutlined />
              {sight}
            </Space>
          </Tooltip>
          <Tooltip title={"Összesen"}>
            <Space>
              <UserOutlined />
              {entriesOfTeam.length}
            </Space>
          </Tooltip>
        </Space>
      </span>
    </>
  );
};

export const CardExtras = React.memo(CardExtrasComponent);
