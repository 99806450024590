import React, { useState } from "react";
import { useMutation, useQuery, useQueryCache } from "react-query";
import {
  deleteEntry,
  downloadExcel,
  getManagedCompetition,
  getManagedCompetitionEntries,
  managedInsertEntry,
  managedUpdateEntry,
} from "../api/Competition";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Form,
  PageHeader,
  Popconfirm,
  Space,
  Table, Tag,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined, InfoCircleOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import { getClubs } from "artemis-shared/api/Club";
import { EditableCell } from "./EditableCell";
import { useHistory } from "react-router";
import { GenderTag } from "artemis-shared/tag/GenderTag";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { EntryForm } from "artemis-shared/entryForm/EntryForm";

import _ from "lodash";
import { StickerModal } from "./StickerModal";
import {useTranslation} from "react-i18next";

export const ManageCompetition = () => {
  const {t} = useTranslation();
  let history = useHistory();
  let { competitionId } = useParams();

  const { data: competition } = useQuery(
    ["managed-competition", parseInt(competitionId)],
    getManagedCompetition
  );

  const { data: entries, isFetching: isFetchingEntries } = useQuery(
    ["managed-competition-entries", parseInt(competitionId)],
    getManagedCompetitionEntries
  );
  const cache = useQueryCache();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: clubs } = useQuery("clubs", getClubs);

  const [insertEntryMutate, { isLoading: isLoadingInsertEntry }] = useMutation(
    managedInsertEntry,
    {
      onSuccess: () => {
        Swal.fire({
          title: "Sikeres nevezés!",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        cache.invalidateQueries([
          "managed-competition-entries",
          parseInt(competitionId),
        ]);
      },
    }
  );

  const [updateEntryMutate, { isLoading: isLoadingUpdateEntry }] = useMutation(
    managedUpdateEntry,
    {
      onSuccess: () => {
        Swal.fire({
          title: "Sikeres módosítás!",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        cache.invalidateQueries([
          "managed-competition-entries",
          parseInt(competitionId),
        ]);
      },
    }
  );

  const [deleteEntryMutate, { isLoading: isLoadingDeleteEntry }] = useMutation(
    deleteEntry,
    {
      onSuccess: () => {
        Swal.fire({
          title: "A nevezés sikeresen visszavonva!",
          icon: "success",
          timer: 1000,
        });
        cache.invalidateQueries([
          "managed-competition-entries",
          parseInt(competitionId),
        ]);
      },
    }
  );

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      category: record.category.id,
      ageGroup: record.ageGroup.id,
      club: record.club.id,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      await form.validateFields();

      const index = entries.findIndex((item) => id === item.id);
      const oldData = entries[index];

      let formValues = form.getFieldsValue();
      let updatedEntry = {
        ...oldData,
        ...formValues,
        category: competition.categories.find(
          (category) => category.id === formValues.category
        ),
        ageGroup: competition.ageGroups.find(
          (ageGroup) => ageGroup.id === formValues.ageGroup
        ),
        club: clubs.find((club) => club.id === formValues.club),
      };
      console.log("updatedentry", updatedEntry);
      await updateEntryMutate({
        competitionId: parseInt(competitionId),
        entry: updatedEntry,
      });
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
      Swal.fire({
        title: "Hiba!",
        text: "Az egyik mező hibás értéket tartalmaz, ellenőrizd hogy mindent megadtál-e!",
        icon: "error"
      });
    }
  };

  const categoryTag = (text, record) => (
    <Tooltip placement="right" title={record.category.description}>
      <div>
        <CategoryTag category={text} />
      </div>
    </Tooltip>
  );
  const ageGroupTag = (text) => <AgeGroupTag ageGroup={text} />;
  const genderTag = (text) => <GenderTag gender={text} />;
  const actionsComp = (_, record) => {
    const editable = isEditing(record);
    return editable ? (
      <Space>
        <Button onClick={cancel}>
          <CloseOutlined />
        </Button>
        <Popconfirm
          title="Biztos hogy módosítod a nevezésedet?"
          onConfirm={() => save(record.id)}
        >
          <Button>
            <CheckOutlined />
          </Button>
        </Popconfirm>
      </Space>
    ) : (
      <Space>
        <Button disabled={editingKey !== ""} onClick={() => edit(record)}>
          <EditOutlined />
        </Button>
        <Popconfirm
          title="Biztos hogy visszavonod a nevezésedet?"
          onConfirm={() => deleteEntryMutate(record.id)}
        >
          <Button danger disabled={editingKey !== ""}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </Space>
    );
  };

  const nameRowRender = (value, row) => {
    return <>{row.comment ? <Tooltip
        key={row.id}
        placement="topLeft"
        title={row.comment}
        mouseEnterDelay={0.3}
        mouseLeaveDelay={0}
    >
          <span>
            <InfoCircleOutlined
                style={{
                  color: "#258f17",
                  marginRight: "5px",
                }}
            />
            {value}
          </span>
    </Tooltip> : value}</>
  };

  const columns = [
    {
      title: "Azon",
      dataIndex: "id",
      editable: false,
      width: 75,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Név",
      dataIndex: "name",
      editable: false,
      width: 220,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: nameRowRender
    },
    {
      title: "V.eng.",
      dataIndex: "permitNumber",
      editable: true,
      width: 110,
      sorter: (a, b) => a.permitNumber - b.permitNumber,
    },
    {
      title: "Kateg.",
      dataIndex: ["category", "name"],
      editable: true,
      competition: competition,
      render: categoryTag,
      width: 100,
      sorter: {
        compare: (a, b) => a.category.name.localeCompare(b.category.name),
        multiple: 3,
      },
    },
    {
      title: "Korosztály",
      dataIndex: ["ageGroup", "ageGroup", "name"],
      editable: true,
      competition: competition,
      render: ageGroupTag,
      width: 130,
      sorter: {
        compare: (a, b) =>
          a.ageGroup.ageGroup.name.localeCompare(b.ageGroup.ageGroup.name),
        multiple: 2,
      },
    },
    {
      title: "Nem",
      dataIndex: "gender",
      editable: true,
      render: genderTag,
      width: 90,
      sorter: {
        compare: (a, b) => a.gender.localeCompare(b.gender),
        multiple: 1,
      },
    },
    {
      title: "Egyesület",
      dataIndex: ["club", "name"],
      editable: true,
      ellipsis: true,
      width: 190,
      sorter: (a, b) => a.club.name.localeCompare(b.club.name),
    },
    {
      title: "Ország",
      dataIndex: ["country"],
      editable: true,
      ellipsis: true,
      width: 190,
      sorter: (a, b) => a.country.localeCompare(b.country),
      render: (text) => text && t("country." + text) || "-"
    },
    {
      title: `Ebéd (${_.sumBy(entries, "lunch")})`,
      dataIndex: "lunch",
      editable: true,
      width: 85,
    },
    {
      title: "Email",
      dataIndex: "email",
      editable: true,
      width: 220,
    },
    {
      title: "Fiz.",
      dataIndex: "paymentStatus",
      editable: true,
      width: 70,
      render: (value) => {
        if(value === "PAID") {
          return <Tag color={"green"}>Igen</Tag>
        } else {
          return <Tag color={"red"}>Nem</Tag>
        }
      }
    },
    {
      title: "Megjegyzés",
      dataIndex: "comment",
      editable: true,
      width: 500
    },
  ];

  const additionalDataColumns =
    competition?.additionalData.map((ad) => ({
      title: ad.label,
      dataIndex: ["additionalData" + ad.pos],
      editable: true,
      competition: competition,
    })) || [];

  const roundColumns = competition?.multiRound ? [
    {
      title: "1",
      dataIndex: ["roundEntries",0, "active"],
      render: (value) => value ? "X" : "",
      width: 40
    },
    {
      title: "2",
      dataIndex: ["roundEntries",1, "active"],
      render: (value) => value ? "X" : "",
      width: 40
    }
  ] : [];

  const actionColumns = [
    {
      title: "Műveletek",
      width: 130,
      dataIndex: "operation",
      fixed: "right",
      render: actionsComp,
    },
  ];

  const mergedColumns = [
    ...columns,
    ...roundColumns,
    ...additionalDataColumns,
    ...actionColumns,
  ].map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        clubs: clubs,
        competition: col.competition,
      }),
    };
  });

  return (
    <>
      {competition && (
        <>
          <PageHeader
            className="site-page-header"
            onBack={() => history.push("/manager-dashboard")}
            title={competition.name}
            extra={[
              <Button
                key="1"
                type="primary"
                onClick={() => downloadExcel(competition.id)}
              >
                Letöltés excel formátumban
              </Button>,
              <Button
                key="2"
                type="primary"
                onClick={() => setIsModalVisible(true)}
              >
                QR Matricák letöltése
              </Button>,
            ]}
          />
          <Card title={"Új versenyző felvétele"}>
            <EntryForm
              competition={competition}
              clubs={clubs}
              onSubmit={(entry) => {
                console.log(entry);
                insertEntryMutate({
                  competitionId: parseInt(competitionId),
                  entry,
                });
              }}
            />
          </Card>
          <Form form={form} component={false}>
            <Table
              scroll={{ x: 1700 }}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={entries}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={false}
              loading={
                isLoadingUpdateEntry ||
                isFetchingEntries ||
                isLoadingDeleteEntry
              }
            />
          </Form>
        </>
      )}
      {entries && (
        <StickerModal
          competition={competition}
          entries={entries}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </>
  );
};
