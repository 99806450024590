import React, { useEffect, useRef } from "react";
import { Card, Col, Divider, Row, Space, Typography } from "antd";
import moment from "moment";
import { ApplicationForm } from "./ApplicationForm";
import { CategoriesBarChart } from "./CategoriesBarChart";
import { EntriesTable } from "./EntriesTable";
import { Description } from "./Description";
import { Reviews } from "./Reviews";
import { Targets } from "./Targets";
import { PublicPlacementTab } from "./PublicPlacementTab";
import { MiszBanner } from "./MiszBanner";
import {useTranslation} from "react-i18next";
import {ResultsBanner} from "./ResultsBanner";
import DOMPurify from "dompurify";

export const CompetitionMD = ({
  competition,
  entries,
  form,
  onSubmitEntry,
  userData,
  clubs,
  isLoading,
  isFetchingEntries,
  highlightedEntry,
  reviews,
  entryToModify,
  getProfileInput,
  permitRequired,
  miszApprovedFilter,
  multiRound,
  multiRoundFinished
}) => {
  const { t } = useTranslation();
  const bottomOfEntriesRef = useRef(null);

  useEffect(() => {
    if (highlightedEntry) {
      let top = bottomOfEntriesRef.current.getBoundingClientRect().top;
      console.log("top", top);
      window.scrollTo({
        top: top - 300,
        behavior: "smooth",
      });
    }
  }, [highlightedEntry]);

  return (
    <Row gutter={[16, 16]}>
      {competition.stage === "APPLICATION" && (
        <Col xs={24} className="gutter-row">
          <Card
            title={t("competition.applicationCardTitle")}
            extra={
              <Space split={<Divider type="vertical" />}>
                {competition.maxParticipants < 999 && (
                  <span>
                    <strong>{t("competition.maxParticipants")}:</strong>{" "}
                    {competition.maxParticipants}
                  </span>
                )}
                <span>
                  <strong>{t("competition.applicationDeadline")}:</strong>{" "}
                  {moment(competition.applicationDeadline).format(
                    "YYYY.MM.DD. - HH:mm"
                  )}
                </span>
              </Space>
            }
          >
            <ApplicationForm
              form={form}
              onSubmitEntry={onSubmitEntry}
              userData={userData}
              competition={competition}
              clubs={clubs}
              isLoading={isLoading}
              entriesNumber={entries ? entries.length : 0}
              entryToModify={entryToModify}
              getProfileInput={getProfileInput}
              permitRequired={permitRequired}
              misz={miszApprovedFilter}
            />
          </Card>
        </Col>
      )}
      {competition.stage === "SCORED" && entries && (
        <Col xs={24} xl={14} className="gutter-row">
          {competition.headerText !== null && <Card>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(competition.headerText),
              }}
            ></div>
          </Card>}
          <PublicPlacementTab
            localEntries={entries}
            localCompetitionData={competition}
          />
        </Col>
      )}
      {competition.stage === "APPLICATION" && (
        <Col xs={24} xl={14}>
          <Card
            title={t("competition.participants")}
            className="gutter-row"
            extra={
              <Typography.Text strong>
                {(entries || []).length}{" "}
                {competition.maxParticipants < 999
                  ? " / " + competition.maxParticipants
                  : ""}{" "}
                {t("competition.participant")}
              </Typography.Text>
            }
          >
            {competition.headerText !== null && <Card>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(competition.headerText),
                }}
              ></div>
            </Card>}
            <CategoriesBarChart entries={entries} />
            <EntriesTable
              entries={entries}
              isFetchingEntries={isFetchingEntries}
              competition={competition}
              highlightedEntry={highlightedEntry}
            />
            <div ref={bottomOfEntriesRef}></div>
          </Card>
        </Col>
      )}
      <Col md={24} xl={competition.stage === "INFO" ? 24 : 10}>
        {competition.externalScoreUrl && <ResultsBanner competition={competition} />}
        {competition.stage === "SCORED" && multiRound && !multiRoundFinished && competition.teamsPublished && (
            <Card title={"Csapatbeosztás a következő napra"}>
              <EntriesTable
                  entries={entries}
                  isFetchingEntries={isFetchingEntries}
                  competition={competition}
                  highlightedEntry={highlightedEntry}
                  hideClub={true}
              />
            </Card>
        )}
        {competition.stage === "SCORED" && multiRound && !multiRoundFinished && !competition.teamsPublished && (
            <Card>
              Még készül a csapatbeosztás a következő napra!
            </Card>
        )}
        {competition.stage === "SCORED" && (!multiRound || multiRoundFinished) &&  (
          <>
            <Reviews reviews={reviews} />
            <br />
          </>
        )}
        {competition.targets.length > 0 && entries && (
          <>
            <Targets competition={competition} entries={entries} />
            <br />
          </>
        )}

        {!miszApprovedFilter && competition.miszApproved && (
          <MiszBanner competition={competition} />
        )}
        <Card title={t("competition.description")}>
          <Description competition={competition} />
        </Card>
      </Col>
    </Row>
  );
};
