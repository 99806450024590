import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getClubStats = async () => {
  const { data } = await axios.get(`${API_URL}/clubstats`);
  return data;
};

export const getClubAthletes = async (key, clubId) => {
  const { data } = await axios(`${API_URL}/clubathlete/${clubId}`);
  return data;
};
