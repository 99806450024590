import React from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export const CategoryTabs = ({ categoryFilter, setCategoryFilter }) => {
  return (
    <Tabs
      defaultActiveKey={categoryFilter}
      type="card"
      size={4}
      onChange={setCategoryFilter}
    >
      <TabPane tab="Összes" key="ALL"></TabPane>
      <TabPane tab="Központi" key="CENTRAL"></TabPane>
      <TabPane tab="Regionális" key="REGIONAL"></TabPane>
      <TabPane tab="Nemzetközi" key="INTERNATIONAL"></TabPane>
    </Tabs>
  );
};
