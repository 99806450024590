import React, { useContext, useState } from "react";
import { Card, Table, Grid, Col, Affix, Radio, Space, Modal } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import "./PublicPlacementTab.less";
import { groupEntries } from "../utils/CompetitionEntryUtils";
import { useBreakpoint } from "../utils/Breakpoint";
import { UrlContext } from "../utils/UrlContext";
import { ExternalAwareLink } from "../utils/ExternalAwareLink";
import _ from "lodash";
import { ScorecardDisplay } from "../scorecard/ScorecardDisplay";
import {useTranslation} from "react-i18next";
import {scorePercentage} from "../utils/CompetitionUtils";

const { Column } = Table;

export const PublicPlacementTab = ({ localCompetitionData, localEntries }) => {
  const {t} = useTranslation();
  const { md, lg, xl, xxl } = useBreakpoint();
  const urls = useContext(UrlContext);

  let nonFinalRounds = _.filter(localCompetitionData?.rounds || [], {finals: false});
  let manyRounds = nonFinalRounds.length > 3;

  const [scorecardString, setScorecardString] = useState(null);
  const [scorecardEntryId, setScorecardEntryId] = useState(null);

  let groupedEntries = groupEntries(localEntries);
  let sortedGroupEntries = Object.entries(groupedEntries).sort((a, b) => {
    if (a[1].category.name.localeCompare(b[1].category.name) == 1) return 1;
    if (a[1].category.name.localeCompare(b[1].category.name) == -1) return -1;

    return a[1].ageGroup.fromAge - b[1].ageGroup.fromAge;
  });

  const unionScoring = () => {
    let acc = [];
    localCompetitionData.rounds.forEach(
      (round) => (acc = [...acc, ...round.scoring])
    );
    return _.uniq(acc);
  };

  const getQualification = entry => {
      if(entry.qualification != null) {
          switch (entry.qualification) {
              case 1:
                  return "(I.)";
              case 2:
                  return "(II.)";
              case 3:
                  return "(III.)";
              default:
                  return "";
          }
      } else {
          return "";
      }
  }

  const getScoreColumns = () => {
      let competitionScoring = [...localCompetitionData.scoring].reverse();
      if(localCompetitionData.multiRound) {
          if(localCompetitionData.type === "TARGET") {
              return [{
                  label: "1. táv",
                  propertyName: ["roundEntries", 0, "score"],
                  width: 70
              },
                  {
                      label: "2. táv",
                      propertyName: ["roundEntries", 1, "score"],
                      width: 70
                  },
                  {
                      label: "X",
                      propertyName: "sx",
                      width: 50
                  },
                  {
                      label: "10",
                      propertyName: "s10",
                      width: 50
                  },
                  {
                      label: "9",
                      propertyName: "s9",
                      width: 50
                  }]
          }

          if(localCompetitionData.type === "FIELD") {
              if(manyRounds) {
                  return nonFinalRounds.map((round) => ({
                      label: round.name,
                      propertyName: ["roundEntries", round.round, "score"],
                      width: 70
                  }))
              } else {
                  return [{
                      label: "1. táv",
                      propertyName: ["roundEntries", 0, "score"],
                      width: 70
                  },
                      {
                          label: "2. táv",
                          propertyName: ["roundEntries", 1, "score"],
                          width: 70
                      },
                      {
                          label: "1+2",
                          propertyName: "sx",
                          width: 70,
                          render: (row) => {
                              return row.roundEntries[0].score + row.roundEntries[1].score
                          }
                      },
                      {
                          label: "6",
                          propertyName: "s6",
                          width: 50
                      },
                      {
                          label: "5",
                          propertyName: "s5",
                          width: 50
                      }]
              }

          }

          if(localCompetitionData.type === "HISTORICAL" && localCompetitionData.subtype === "LDTS") {
              return [{
                  label: "Pontszám",
                  propertyName: ["roundEntries", 0, "score"],
                  width: 80
              },
                  {
                      label: "Döntő",
                      propertyName: ["roundEntries", 1, "score"],
                      width: 60
                  }]
          }

          if(localCompetitionData.type === "HISTORICAL" && localCompetitionData.rounds.length === 3 &&!_.find(localCompetitionData.rounds, {finals: true})) {
            return [
                {
                    label: "1. ford",
                    propertyName: ["roundEntries", 0, "score"],
                    width: 70,
                    render: (row) => row.roundEntries[0].score || "-"
                },
                {
                    label: "2. ford",
                    propertyName: ["roundEntries", 1, "score"],
                    width: 70,
                    render: (row) => row.roundEntries[1].score || "-"
                },
                {
                    label: "3. ford",
                    propertyName: ["roundEntries", 2, "score"],
                    width: 70,
                    render: (row) => row.roundEntries[2].score || "-"
                },
            ]
          }

          if(localCompetitionData.type === "THREED") {

              if(localCompetitionData.rounds.length === 4 && localCompetitionData.rounds[3].finals) {
                  return [
                      {
                          label: localCompetitionData.rounds[0].name,
                          propertyName: ["roundEntries", 0, "score"],
                          width: 70,
                          render: (row) => row.roundEntries[0].score || "-"
                      },
                      {
                          label: localCompetitionData.rounds[1].name,
                          propertyName: ["roundEntries", 1, "score"],
                          width: 70,
                          render: (row) => row.roundEntries[1].score || "-"
                      },
                      {
                          label: localCompetitionData.rounds[2].name,
                          propertyName: ["roundEntries", 2, "score"],
                          width: 70,
                          render: (row) => row.roundEntries[2].score || "-"
                      },
                      {
                          label: localCompetitionData.rounds[3].name,
                          propertyName: ["roundEntries", 3, "score"],
                          width: 70,
                          render: (row) => row.roundEntries[3].score || "-"
                      }
                  ]
              }

              let cols = [
                  {
                      label: "1. nap",
                      propertyName: ["roundEntries", 0, "score"],
                      width: 70,
                      render: (row) => row.roundEntries[0].score || "-"
                  },
                  {
                      label: "2. nap",
                      propertyName: ["roundEntries", 1, "score"],
                      width: 70,
                      render: (row) => row.roundEntries[1].score || "-"
                  }
              ]
              if(localCompetitionData.rounds.length === 3) {
                  cols.push(
                      {
                          label: "1 + 2.",
                          propertyName: ["roundEntries", 1, "score"],
                          width: 70,
                          render: (row) => {
                              console.log("row", row)
                              return row.roundEntries[0].score + row.roundEntries[1].score
                          }
                      });
                  cols.push({
                      label: "Döntő",
                      propertyName: ["roundEntries", 2, "score"],
                      width: 60,
                      render: (row) => row.roundEntries[2].score || "-"
                  })
              }

              return cols;
          }

          if(localCompetitionData.type === "HISTORICAL" && localCompetitionData.rounds.length === 2 && localCompetitionData.rounds[1].finals) {
              return [...(competitionScoring.map(sv => ({
                  label: sv,
                  propertyName: ["roundEntries", 0, "s"+sv],
                  width: 50,
              }))), {
                  label: "Döntő",
                  propertyName: ["roundEntries", 1, "score"],
                  width: 60,
                  render: (row) => row.roundEntries[1].score || "-"
              }];
          }

          return competitionScoring.map(sv => ({
              label: sv,
              propertyName: "s"+sv,
              width: 50,
          }));
      } else {

          if(localCompetitionData.type === "OTHER" && localCompetitionData.subtype === "FS") {
              return []
          } else {
              return competitionScoring.map(sv => ({
                  label: sv,
                  propertyName: "s"+sv,
                  width: 50,
              }));
          }
      }
  }

  console.log("entries", sortedGroupEntries);
  console.log("localCompetitionData.scoring", [...localCompetitionData.scoring].reverse()[0]);

  return (
    <div className={"public-placement-tab"}>
      <div className={"results"}>
        {sortedGroupEntries.map(([key, value]) => (
          <Card
            key={key}
            id={key}
            title={`${value.category.name} ${value.ageGroup.ageGroup.name} ${
              value.gender === "MALE" ? "Férfi" : "Nő"
            }`}
            style={{ marginBottom: "16px" }}
            bodyStyle={{ padding: xl ? "16px" : 0 }}
          >
            <Table
              dataSource={value.entries}
              pagination={false}
              size={"small"}
              rowClassName={(record) =>
                record.score
                  ? record.tie
                    ? "tie"
                    : "placement-" + record.placement
                  : ""
              }
              rowKey={entry => entry.id}
              expandable={ localCompetitionData.multiRound && {
                  expandedRowRender: (entry) => <ExpandTable entry={entry} scoring={unionScoring()}
                  setScorecardString={setScorecardString} setScorecardEntryId={setScorecardEntryId}/>,
                  expandRowByClick: true,
                  indentSize: 0,
                  columnWidth: 25
              }}
            >
              <Column
                title="#"
                dataIndex="placement"
                key="placement"
                width={35}
              />
              <Column
                title="Név"
                dataIndex="name"
                key="name"
                responsive={["xs", "sm"]}
                render={(text, record) => (
                  <>
                    {record.permitNumber > 0 ? (
                      <ExternalAwareLink
                        to={`${urls.athlete}/${record.permitNumber}`}
                      >
                        {text}
                      </ExternalAwareLink>
                    ) : (
                      text
                    )}
                    {(!md || (xl && !xxl)) && (
                      <div className={"club-name"}>{localCompetitionData.international ? record.country && t("country." + record.country) || "-" : record.club.name}</div>
                    )}
                  </>
                )}
              />
              {((md && !xl) || xxl) && !localCompetitionData.international && (
                <Column
                  title="Egyesület"
                  dataIndex={["club", "name"]}
                  key="club"
                  ellipsis={true}
                />
              )}
                {((md && !xl) || xxl) && localCompetitionData.international && (
                    <Column
                        title="Ország"
                        dataIndex={["country"]}
                        key="country"
                        ellipsis={true}
                        render={(text) => text && t("country." + text) || "-"}
                    />
                )}
              {localCompetitionData &&
                  getScoreColumns()
                  .map((scoreProperty) => (
                    <Column
                      title={scoreProperty.label}
                      dataIndex={scoreProperty.propertyName
                      }
                      key={scoreProperty.propertyName}
                      responsive={["sm"]}
                      width={scoreProperty.width}
                      render={scoreProperty.render ? (value, row) => scoreProperty.render(row) : null}
                    />
                  ))}
              <Column
                title={"Pont"}
                dataIndex={"score"}
                render={(value, record) => {
                  return (
                    <div className={"score-col"}>
                      {record.scorecard && (
                        <span
                          onClick={() => {
                              setScorecardString(record.scorecard)
                              setScorecardEntryId(record.id)
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <Space>
                            <span className={"score-value"}>{value}</span>
                            <span>{<FileTextOutlined />}</span>
                          </Space>
                        </span>
                      )}
                      {!record.scorecard && <span className={"score-value"}>{value}</span>}
                        {localCompetitionData.subtype !== "FS" && !manyRounds && <div className={"qualification"}>{scorePercentage(localCompetitionData, record)}% {getQualification(record)}</div>}
                    </div>
                  );
                }}
                key={"score"}
                width={72}
              />
            </Table>
          </Card>
        ))}
      </div>
      <Modal
        visible={scorecardString}
        onCancel={() => {
            setScorecardEntryId(null)
            setScorecardString(null)
        }}
        footer={null}
      >
        <ScorecardDisplay scorecardString={scorecardString} entryId={scorecardEntryId} />
      </Modal>
    </div>
  );
};

const ExpandTable = ({entry, scoring, setScorecardString, setScorecardEntryId}) => {
    const { md } = useBreakpoint();
    return <div style={{overflowX: "auto", width: !md ? "310px" : "100%"}}>
        <Table dataSource={entry.roundEntries} pagination={false}>
             <Column title={"#"} dataIndex={"round"} fixed={"left"} render={(value) => `${value + 1}.`}/>
            {[...scoring].reverse().map((sv) => {
                console.log("sv", sv)
                return (
                    <Column
                        title={sv}
                        dataIndex={"s"+sv}
                        key={"s"+sv}
                        width={40}
                        render={(value) => value || "0"}
                    />
                )
            })}
            <Column title={"Pont"} dataIndex={"score"} width={60}
                    render={(value, record) => {
                        return (
                            <div>
                                {record.scorecard && (
                                    <span
                                        onClick={() => {
                                            setScorecardString(record.scorecard)
                                            setScorecardEntryId(entry.id)
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                          <Space>
                            <span>{value}</span>
                            <span>{<FileTextOutlined />}</span>
                          </Space>
                        </span>
                                )}
                                {!record.scorecard && <span>{value}</span>}
                                </div>
                        );
                    }}/>
            <Column title={"Min."} dataIndex={"qualification"} width={60} render={(value) => {
                switch (value) {
                    case 1:
                        return "I. o.";
                    case 2:
                        return "II. o.";
                    case 3:
                        return "III. o.";
                    default:
                        return "";
                }
            }
            }/>
        </Table>
    </div>
}
