import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Col,
  PageHeader,
  Row,
  Typography,
  Avatar,
  Card,
  Empty,
  Switch,
  Radio,
} from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  MinusOutlined,
  TrophyTwoTone,
} from "@ant-design/icons";
import "./Athlete.less";
import { ScoreAverageChart } from "./ScoreAverageChart";
import { Results } from "./Results";
import { Qualifications } from "./Qualifications";
import { useQuery } from "react-query";
import {getMiszResults, getResults} from "../api/Result";
import { Trophies } from "./Trophies";
import { useHistory } from "react-router";
import moment from "moment";
import { ScoreDistributionChart } from "./ScoreDistributionChart";
import { getRankingByPermitNumber } from "../api/Ranking";
import _ from "lodash";
import DOMPurify from "dompurify";
import {getLabel} from "../utils/CompetitionTypeUtils";

export const Athlete = () => {
  let { permitNumber } = useParams();
  const history = useHistory();

  const [resultsMode, setResultsMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { data: athlete } = useQuery(
    ["results", parseInt(permitNumber)],
    getResults,
    {
      onSuccess: (result) => {
        if (result.results.length > 0) {
          setSelectedCategory(
          result.results[result.results.length - 1].category + " - " + getLabel(result.results[result.results.length - 1].competitionType)
          );
        }
      },
    }
  );

  const { data: miszResultsString } = useQuery(
    ["miszresults", parseInt(permitNumber)],
    getMiszResults,
    {
      onSuccess: (result) => {
        console.log("result")
      },
    }
  );

  const { data: ranking } = useQuery(
    ["ranking", parseInt(permitNumber)],
    getRankingByPermitNumber
  );

  const athleteData = athlete &&
    athlete.results &&
    athlete.results.length > 0 && {
      name: athlete.results[athlete.results.length - 1].name,
      club: athlete.results[athlete.results.length - 1].club,
      permitNumber: athlete.results[athlete.results.length - 1].permitNumber,
      entries: _.filter(
        athlete.results.sort((a, b) =>
          moment(a.startDate).diff(moment(b.startDate))
        ),
          (item) => {
            return item.category + " - " + getLabel(item.competitionType) === selectedCategory;
          }
      ),
    };

  let sorted = _.filter(
    ranking || [],
    (r) => r.category.name + " - " + getLabel(r.competitionType) === selectedCategory
  ).sort((a, b) => moment(b.issued).diff(moment(a.issued)));
  let diff = sorted[1] ? sorted[1].rank - sorted[0].rank : 0;

  let rank = { ...sorted[0], diff };

  console.log("sorted", sorted);
  console.log("diff", diff);
  console.log("rank", rank);

  let categories =
    athlete &&
    athlete.results &&
    athlete.results.length > 0 &&
    _.uniqBy(athlete.results, (result) => result.category + result.competitionType);

  const getTrophyColor = (placement) => {
    switch (placement) {
      case 1:
        return "#ffce52";
      case 2:
        return "#949494";
      case 3:
        return "#925408";
      default:
        return "#000000";
    }
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={"Versenyző"}
      />
      {athleteData && ranking ? (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} xl={18} className={"name-box"}>
            <div className={"rank-box"}>
              <div className={"rank"}>
                <Typography.Title level={2}>{rank.rank}.</Typography.Title>
              </div>
              <div
                className={
                  "diff " +
                  (rank.diff > 0 ? "positive " : "") +
                  (rank.diff < 0 ? "negative " : "")
                }
              >
                <Typography.Title level={5}>
                  {rank.diff > 0 && (
                    <div>
                      <CaretUpOutlined style={{ color: "green" }} />
                      {rank.diff}
                    </div>
                  )}
                  {rank.diff < 0 && (
                    <div>
                      <CaretDownOutlined style={{ color: "red" }} />
                      {rank.diff * -1}
                    </div>
                  )}
                  {rank.diff === 0 && (
                    <div>
                      <MinusOutlined />
                    </div>
                  )}
                </Typography.Title>
              </div>
            </div>
            <div>
              <Typography.Title level={3}>{athleteData.name}</Typography.Title>
              <Typography.Title level={5}>{athleteData.club}</Typography.Title>
              {athlete.achievement && (
                <div className={"achievement"}>
                  <div className={"trophy"}>
                    <TrophyTwoTone
                      twoToneColor={getTrophyColor(
                        athlete.achievement.placement
                      )}
                    />
                  </div>
                  <div>
                    <div className={"title"}>{athlete.achievement.title}</div>
                    <div className={"subtitle"}>
                      {athlete.achievement.subtitle}
                    </div>
                  </div>
                </div>
              )}

              {categories && categories.length > 1 && (
                <Radio.Group
                  defaultValue={selectedCategory}
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  buttonStyle="solid"
                >
                  {categories.map((category) => (
                    <Radio.Button
                      key={category.category + " - " + getLabel(category.competitionType)}
                      value={category.category + " - " + getLabel(category.competitionType)}
                    >
                      {category.category} - {getLabel(category.competitionType)}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              )}
            </div>
          </Col>
          <Col xs={24} md={0}>
            <Card title={"Helyezések"}>
              <Trophies entries={athleteData.entries} />
            </Card>
          </Col>
          <Col xs={0} md={12} xl={6}>
            <Trophies entries={athleteData.entries} />
          </Col>
          <Col xs={{ order: 1, span: 24 }} md={{ order: 3, span: 12 }} xxl={0}>
            <Card title={"Találat eloszlás"}>
              <ScoreDistributionChart data={athleteData.entries} />
            </Card>
          </Col>
          <Col
            xs={{ order: 2, span: 24 }}
            md={{ order: 1, span: 24 }}
            className={"score-average-col"}
          >
            <Card title={"Vesszőátlag"}>
              <ScoreAverageChart data={athleteData.entries} />
            </Card>
          </Col>
          <Col
            xs={{ order: 3, span: 24 }}
            md={{ order: 2, span: 24 }}
            xxl={{ order: 2, span: 18 }}
          >
            <Card
              title={"Eredmények"}
              extra={[
                <Switch
                  key={"toggle"}
                  checkedChildren={"Százalék"}
                  unCheckedChildren={"Darab"}
                  onChange={setResultsMode}
                />,
              ]}
            >
              <Results entries={athleteData.entries} mode={resultsMode} />
            </Card>
            <Card
              title={"MISZ Eredmények"}
              style={{overflowX: "auto"}}
              >
              <div dangerouslySetInnerHTML={{__html:
                    DOMPurify.sanitize(miszResultsString
                        ?.replace("<h2> eddigi eredményei:</h2>", "")
                        .replace("<div><a onclick=\"gobacktoscores()\"> Vissza </a><br><br><br></div>", ""))
              }}></div>
            </Card>
          </Col>
          <Col xs={{ order: 4, span: 24 }} md={{ order: 4, span: 12 }} xxl={0}>
            <Card title={"Megszerzett minősítések"}>
              <Qualifications qualifications={athlete.qualifications} />
            </Card>
          </Col>
          <Col xs={0} xxl={{ order: 3, span: 6 }}>
            <Card title={"Találat eloszlás"}>
              <ScoreDistributionChart data={athleteData.entries} />
            </Card>
            <br />
            <Card title={"Megszerzett minősítések"}>
              <Qualifications qualifications={athlete.qualifications} />
            </Card>
          </Col>
        </Row>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"Nincs adat a versenyzőről."}
        />
      )}
    </>
  );
};
