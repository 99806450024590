export const resolveCompetitionType = (type) => {
  switch (type) {
    case "THREED":
      return "3D";
    case "TARGET":
      return "Pálya";
    case "FIELD":
      return "Terep";
    case "HISTORICAL":
      return "Történelmi";
    case "FUN":
      return "Örömíjász";
    case "OTHER":
      return "Egyéb";
    default:
      return type;
  }
};
