import React from "react";
import { Tag } from "antd";

export const CompetitionTypeTag = ({ type }) => {
  return (
    <>
      {{
        THREED: <Tag color={"green"}>3D</Tag>,
        FIELD: <Tag color={"gold"}>Terep</Tag>,
        TARGET: <Tag color={"blue"}>Pálya</Tag>,
        HISTORICAL: <Tag color={"red"}>Tört</Tag>,
        FUN: <Tag color={"orange"}>Öröm</Tag>,
        OTHER: <Tag color={"purple"}>Egyéb</Tag>,
      }[type] || <Tag color={"blue"}>{type}</Tag>}
    </>
  );
};
