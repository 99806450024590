import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from "react-query";
import {
  getCompetition,
  getEntries,
  getEntryByModificationToken,
  insertEntry,
  modifyEntry,
  unregister,
} from "../api/Competition";
import {
  PageHeader,
  Form,
  Skeleton,
  Row,
  Col,
  BackTop,
  Space,
  Divider,
  Card,
  Button,
  Modal, Alert,
} from "antd";
import { Link } from "react-router-dom";
import { SettingOutlined, TrophyOutlined, ExclamationCircleOutlined, DownloadOutlined } from "@ant-design/icons";

import "./Competition.less";
import { getClubs } from "../api/Club";
import { useHistory, useLocation } from "react-router";
import Swal from "sweetalert2";
import { resolveCompetitionType } from "../utils/CompetitionType";
import { ReviewModal } from "./ReviewModal";
import { CompetitionXXL } from "./CompetitionXXL";
import { CompetitionXS } from "./CompetitionXS";
import { CompetitionMD } from "./CompetitionMD";
import { getSummarizedReview } from "../api/Review";
import { useBreakpoint } from "../utils/Breakpoint";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import XLSX from "xlsx";

export const Competition = ({
  userData,
  ownEntries,
  calendarButton,
  entryEditor,
  getProfileInput,
  miszApprovedFilter,
}) => {
  const { t } = useTranslation();
  const { xl } = useBreakpoint();
  let { competitionId } = useParams();
  let history = useHistory();
  const location = useLocation();
  let competitionIdNumber = parseInt(competitionId);

  const cache = useQueryCache();

  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const [highlightedEntry, setHighlightedEntry] = useState(null);
  const [reviewUUID, setReviewUUID] = useState(null);
  const [modificationToken, setModificationToken] = useState(null);

  const { data: competition, isFetching: isFetchingCompetition } = useQuery(
    ["competition", competitionIdNumber],
    getCompetition
  );

  const { data: reviews } = useQuery(
    ["reviews", competitionIdNumber],
    getSummarizedReview
  );

  const { data: entryToModify } = useQuery(
    ["entryToModify", modificationToken],
    getEntryByModificationToken,
    { enabled: modificationToken }
  );

  const { data: entries, isFetching: isFetchingEntries } = useQuery(
    ["entries", competitionIdNumber],
    getEntries
  );
  const { data: clubs } = useQuery("clubs", getClubs);
  const [unregisterMutate] = useMutation(unregister, {
    onSuccess: () => {
      Swal.fire({
        title: "A nevezésed sikeresen visszavonva!",
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
      }).then(() => cache.invalidateQueries(["entries", competitionIdNumber]));
    },
    onError: (error) => {
      Swal.fire({
        title:
          error.response.data.message === "UNREGISTER_IS_CLOSED"
            ? "A nevezések visszavonásának határideje lejárt!"
            : "Hiba történt!",
        icon: "error",
        timer: 4000,
        showConfirmButton: false,
      });
    },
  });
  const [mutate, { isLoading: isLoadingInsertEntry }] = useMutation(
    insertEntry,
    {
      onSuccess: () => {
        cache.invalidateQueries(["entries", competitionIdNumber]);
        cache.invalidateQueries(["ownentries", competitionIdNumber]);
        form.resetFields();
      },
      onError: (err) => {
        if (err.response.status === 400) {
          Modal.confirm({
            title: "A korábbiaktól eltérő nevezés!",
            content: (
              <div>
                Egy másik fordulóban a mostanitól eltérő kategóriájú,
                korosztályú vagy nemű nevezést adtál le. Biztos hogy ebben a
                fordulóban mást választassz? <br />
                <br />A korábbi nevezésed: <br />
                <strong>{err.response.data.message}</strong>
              </div>
            ),
            onOk: () => {
              onSubmitEntry(form.getFieldsValue(true), true);
            },
            okText: "Igen",
            cancelText: "Mégsem",
          });
        }

        if(err.response.status === 500) {
          let errorMsg = "Hiba történt a nevezés leadásakor. Próbáld újra!";

          console.log("errrr", err.response);

          if(err.response.data.message.startsWith("Permit is expired")) {
            errorMsg = "A verseny napján nem lesz érvényes a megadott versenyengedély."
          } else if(err.response.data.message.startsWith("Permit expiration is not set")) {
            errorMsg = "A megadott versenyengedélyszámhoz nem tartozik lejárati dátum. Nem érvényes MISZ engedélyszám."
          } else if(err.response.data.message.startsWith("Permit not found")) {
            errorMsg = "Nincs ilyen versenyengedélyszám a rendszerben."
          }

          Swal.fire({
            title: t("competition.applicationError"),
            text: errorMsg,
            icon: "error",
            showConfirmButton: true,
          });
        }
      },
    }
  );

  const [mutateModify, { isLoading: isLoadingModifyEntry }] = useMutation(
    modifyEntry,
    {
      onSuccess: () => {
        cache.invalidateQueries(["entries", competitionIdNumber]);
        cache.setQueryData(["entryToModify", modificationToken], null);
        setModificationToken(null);
        form.resetFields();
      },
    }
  );

  const [form] = Form.useForm();

  const onSubmitEntry = async (entryData, forced) => {
    console.log("onsubmitentry", entryData, forced);
    let entry = { ...entryData };
    entry.category = competition.categories.find(
      (category) => category.id === entryData.category
    );
    entry.ageGroup = competition.ageGroups.find(
      (ageGroup) => ageGroup.id === entryData.ageGroup
    );
    entry.club = clubs.find((club) => club.id === entryData.club) || {id: 999, name: "Egyéb"};

    if (entryData.profile) {
      entry.profile = userData.profiles.find(
        (profile) => profile.id === entryData.profile
      );
      entry.name = entry.profile.name;
      entry.firstName = entry.profile.firstName;
      entry.lastName = entry.profile.lastName;
      entry.email = userData.email;
    } else {
      entry.profile = null;
    }

    if (!entry.name) {
      entry.name =
        entryData.lastName.trim().replace(/\s+/g, " ") +
        " " +
        entryData.firstName.trim().replace(/\s+/g, " ");
    }

    try {
      let insertedEntry = null;

      if (modificationToken) {
        insertedEntry = await mutateModify({
          modificationToken,
          entry,
        });
      } else {
        insertedEntry = await mutate({
          competitionId: competitionIdNumber,
          entry,
          forced,
        });
      }

      if (insertedEntry) {
        Swal.fire({
          title: t("competition.success"),
          text:
            !insertedEntry.profile &&
            t("competition.successMsg"),
          icon: "success",
          showConfirmButton: true,
        }).then(() => setHighlightedEntry(insertedEntry));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("review")) {
      setReviewUUID(searchParams.get("review"));
      setIsReviewModalVisible(true);
      searchParams.delete("review");
      history.replace({
        search: searchParams.toString(),
      });
    }

    if (searchParams.get("unregister")) {
      let unregisterToken = searchParams.get("unregister");
      console.log("unregisterToken", unregisterToken);
      Modal.confirm({
        title: 'Biztos hogy törlöd a nevezésedet?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          unregisterMutate(unregisterToken);
        },
        okText: "Igen",
        cancelText: "Mégsem"
      });

      searchParams.delete("unregister");
      history.replace({
        search: searchParams.toString(),
      });
    }

    if (searchParams.get("modify")) {
      setModificationToken(searchParams.get("modify"));
      searchParams.delete("modify");
      history.replace({
        search: searchParams.toString(),
      });
    }
  }, [location]);

  const multiRound = competition?.rounds?.length > 1;
  const multiRoundFinished = multiRound && _.some(entries, (entry) => {
    let lastRound = _.find(entry.roundEntries, {round: competition?.rounds?.length - 1});
    return lastRound && lastRound.score;
  });

  return (
    <>
      {isFetchingCompetition && <Skeleton active paragraph={{ rows: 10 }} />}
      {!isFetchingCompetition && (
        <div className={"competition-page"}>
          {!competition.published && <Alert
              message="Ez a verseny nem nyilvános! Nem jelenik meg a versenylistán, de a megosztott link megnyitásával bárki számára elérhető."
              type="warning"
              showIcon
          />}
          <PageHeader
            className="site-page-header"
            onBack={() => history.goBack()}
            title={competition.name}
            subTitle={
              <Col xs={0} md={24}>
                <Space split={<Divider type="vertical" />}>
                  <span>{resolveCompetitionType(competition.type)}</span>
                  <span>
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href={`https://www.google.com/maps/search/?api=1&query=${competition.lat},${competition.lng}`}
                    >
                      {competition.location}
                    </a>
                  </span>
                  <span>{competition.startDate}</span>
                  <span>{competition.organizerClub.name}</span>
                </Space>
              </Col>
            }
            extra={[
              competition.tournamentId && (
                <Link to={`/tournament/${competition.tournamentId}/t/summary`}>
                  <Button key={"tournament"} type={"primary"}>
                    {xl ? "Összesítés" : <TrophyOutlined />}
                  </Button>
                </Link>
              ),
              calendarButton,
              userData && userData.admin && (
                <Link to={`/competition/edit/${competition.id}`}>
                  <Button>
                    <SettingOutlined />
                  </Button>
                </Link>
              ),
              competition.stage === "SCORED" && <Button onClick={() => generateExcel(entries, competition, t)}><DownloadOutlined /></Button>
            ]}
          />
          <BackTop />
          {competition.stage === "APPLICATION" &&
            ownEntries &&
            ownEntries.length > 0 && (
              <Row gutter={[16, 16]}>
                <Col xs={0} md={24}>
                  <Card title={"Leadott nevezéseid"}>
                    {entries && entryEditor}
                  </Card>
                </Col>
              </Row>
            )}
          <Row gutter={[16, 16]}>
            {/* XS */}
            <Col xs={24} md={0}>
              <CompetitionXS
                competition={competition}
                entries={entries}
                form={form}
                onSubmitEntry={onSubmitEntry}
                userData={userData}
                clubs={clubs}
                isLoading={isLoadingInsertEntry || isLoadingModifyEntry}
                isFetchingEntries={isFetchingEntries}
                highlightedEntry={highlightedEntry}
                ownEntries={ownEntries}
                reviews={reviews}
                entryToModify={entryToModify}
                entryEditor={entryEditor}
                getProfileInput={getProfileInput}
                permitRequired={competition.permitRequired}
                miszApprovedFilter={miszApprovedFilter}
                multiRound={multiRound}
                multiRoundFinished={multiRoundFinished}
              />
            </Col>

            {/* MD */}

            <Col xs={0} md={24} xxl={0}>
              <CompetitionMD
                competition={competition}
                entries={entries}
                form={form}
                onSubmitEntry={onSubmitEntry}
                userData={userData}
                clubs={clubs}
                isLoading={isLoadingInsertEntry || isLoadingModifyEntry}
                isFetchingEntries={isFetchingEntries}
                highlightedEntry={highlightedEntry}
                reviews={reviews}
                entryToModify={entryToModify}
                getProfileInput={getProfileInput}
                permitRequired={competition.permitRequired}
                miszApprovedFilter={miszApprovedFilter}
                multiRound={multiRound}
                multiRoundFinished={multiRoundFinished}
              />
            </Col>

            {/* XXL */}

            <Col xs={0} xxl={24}>
              <CompetitionXXL
                competition={competition}
                entries={entries}
                form={form}
                onSubmitEntry={onSubmitEntry}
                userData={userData}
                clubs={clubs}
                isLoading={isLoadingInsertEntry || isLoadingModifyEntry}
                isFetchingEntries={isFetchingEntries}
                highlightedEntry={highlightedEntry}
                reviews={reviews}
                entryToModify={entryToModify}
                getProfileInput={getProfileInput}
                permitRequired={competition.permitRequired}
                miszApprovedFilter={miszApprovedFilter}
                multiRound={multiRound}
                multiRoundFinished={multiRoundFinished}
              />
            </Col>
          </Row>
        </div>
      )}
      {competition && competition.stage === "SCORED" && (
        <ReviewModal
          isVisible={isReviewModalVisible}
          competition={competition}
          uuid={reviewUUID}
          onOk={() => setIsReviewModalVisible(false)}
          onCancel={() => setIsReviewModalVisible(false)}
        />
      )}
    </>
  );
};

const generateExcel = (localEntries, localCompetitionData, t) => {
  let flatEntries = [...localEntries].map((entry) => {
    let newEntry = {
      name: entry.name,
      permitNumber: entry.permitNumber,
      club: entry.club.name,
      team: entry.team,
      category: entry.category.name,
      ageGroup: entry.ageGroup.ageGroup.name,
      gender: entry.gender === "MALE" ? t("genderTag.MALE") : t("genderTag.FEMALE"),
      score: entry.score,
      tiebreaker: entry.tiebreaker,
      placement: entry.placement,
      s12: entry.s12,
      qualification: entry.qualification
    };
    localCompetitionData.scoring.forEach(
        (scoringValue) =>
            (newEntry["s" + scoringValue] = entry["s" + scoringValue])
    );

    if(localCompetitionData.rounds.length > 0) {
      localCompetitionData.rounds.forEach(round => {
        [...round.scoring].reverse().forEach(
            (scoringValue) =>
                (newEntry["r" + round.round + "_s" + scoringValue] = entry.roundEntries[round.round]["s" + scoringValue])
        );
        newEntry["r" + round.round + "_score"] = entry.roundEntries[round.round]["score"];
        newEntry["r" + round.round + "_qualification"] = entry.roundEntries[round.round]["qualification"];
      })
    }

    return newEntry;
  });
  let workBook = XLSX.utils.book_new();
  let headers = [
    "name",
    "permitNumber",
    localCompetitionData.international ? "country" : "club",
    "team",
    "category",
    "ageGroup",
    "gender"
  ];

  if(localCompetitionData.rounds.length > 0) {
    localCompetitionData.rounds.forEach(round => {
      [...round.scoring].reverse().forEach(
          (scoringValue) => {
            headers = headers.concat(
                ["r" + round.round + "_s" + scoringValue]
            );
          }
      );
      headers = headers.concat(
          ["r" + round.round + "_score"],
          ["r" + round.round + "_qualification"]
      );
    })
  }

  headers = headers.concat(
      [...localCompetitionData.scoring, 12]
          .reverse()
          .map((scoringValue) => "s" + scoringValue)
  );

  headers = headers.concat([
    "score",
    "tiebreaker",
    "placement",
    "qualification"
  ]);

  let workSheet = XLSX.utils.json_to_sheet(flatEntries, {
    header: headers,
  });
  XLSX.utils.book_append_sheet(workBook, workSheet, "Adatok");
  XLSX.writeFile(workBook, localCompetitionData.name + ".xls");
};