import React, { useEffect, useState } from "react";
import { InputNumber } from "antd";

import "./ValueKeepingInputNumber.less";

export const ValueKeepingInputNumber = ({ value, onChange, ...restProps }) => {
  const [componentValue, setComponentValue] = useState(value);

  useEffect(() => {
    setComponentValue(value);
  }, [value]);

  return (
    <div
      className={
        "value-keeping-input-number " +
        (value !== componentValue ? "pending" : "")
      }
    >
      <InputNumber
        value={componentValue}
        {...restProps}
        onChange={(value) => {
          setComponentValue(value);
          onChange(value);
        }}
      />
    </div>
  );
};
