import React from "react";
import { CompetitionTypeTag } from "artemis-shared/tag/CompetitionTypeTag";
import { Button, Table } from "antd";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import { getCompetitionsThisWeek } from "../api/Competition";
import { RightOutlined } from "@ant-design/icons";
import { useBreakpoint } from "artemis-shared/utils/Breakpoint";
import { CompetitionList } from "artemis-shared/competitions/CompetitionList";

const { Column } = Table;

export const UpcomingCompetitions = () => {
  const { md, lg, xl } = useBreakpoint();
  const { data, isFetching, isLoading } = useQuery(
    "competitions-this-week",
    getCompetitionsThisWeek
  );

  return (
    <>
      {((md && !lg) || xl) && (
        <Table
          dataSource={data}
          loading={isFetching || isLoading}
          onSelect
          pagination={false}
          locale={{ emptyText: "Nincs verseny a héten." }}
        >
          <Column
            title="Típus"
            dataIndex="type"
            key="type"
            render={(type) => <CompetitionTypeTag type={type} />}
          />
          <Column title="Név" dataIndex="name" key="name" />
          <Column
            title="Helyszín"
            dataIndex="location"
            key="location"
            responsive={["sm"]}
          />
          <Column
            title="Dátum"
            dataIndex="startDate"
            key="startDate"
            width={120}
            responsive={["sm"]}
          />
          <Column
            title=""
            key="action"
            align={"right"}
            render={(text, record) => (
              <NavLink to={`/competition/${record.id}/${record.slug}`}>
                <Button>
                  <RightOutlined />
                </Button>
              </NavLink>
            )}
          />
        </Table>
      )}
      {!((md && !lg) || xl) && (
        <CompetitionList competitions={data} isFetching={isFetching} />
      )}
    </>
  );
};
