import React from "react";
import { Divider, Space } from "antd";
import { FacebookFilled, YoutubeFilled } from "@ant-design/icons";
import "./SocialButtons.less";

export const SocialButtons = () => {
  return (
    <div className={"social-buttons-container"}>
      <div className={"inner-container"}>
        <a
          href="https://www.facebook.com/ijasznet"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookFilled /> Facebook
        </a>
        <a
          href="https://www.youtube.com/channel/UClx4iecMZmOxPDk9mQFFj6w"
          target="_blank"
          rel="noreferrer"
        >
          <YoutubeFilled /> YouTube
        </a>
      </div>
    </div>
  );
};
