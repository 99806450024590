import React from "react";
import "./FeeSummaryModal.less";
import { Button } from "antd";
import {useTranslation} from "react-i18next";

export const FeeSummaryModal = ({ entries, onClose }) => {
    const { t } = useTranslation();
  return (
    <div className={"fee-summary-modal"}>
        <div className={"table-container"}>
            <table border={1} style={{ width: "100%" }}>
                <tr className={"header-row"}>
                    <th>{t("manager.name")}</th>
                    <th>{t("manager.entryFeeShort")}</th>
                    <th>{t("manager.lunch")}</th>
                </tr>
                {entries.map((entry) => (
                    <tr key={entry.id}>
                        <td>{entry.name}</td>
                        <td>{(entry.entryFee || 0).toLocaleString()}</td>
                        <td>{(entry.lunchFee || 0).toLocaleString()}</td>
                    </tr>
                ))}
                <tr className={"sum-row"}>
                    <td>{t("manager.total")}</td>
                    <td colSpan={2}>
                        {entries
                            .reduce((acc, entry) => {
                                return acc + entry.entryFee + entry.lunchFee;
                            }, 0)
                            .toLocaleString()}
                    </td>
                </tr>
            </table>
        </div>

      <Button block type={"primary"} onClick={() => onClose()}>
          {t("manager.clear")}
      </Button>
    </div>
  );
};
