import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { Button, Space, Dropdown, Pagination, List, Skeleton, Tag } from "antd";
import { CompetitionTypeTag } from "../tag/CompetitionTypeTag";
import moment from "moment";
import { MenuOutlined, CalendarOutlined } from "@ant-design/icons";
import { useBreakpoint } from "../utils/Breakpoint";
import { UrlContext } from "../utils/UrlContext";
import { useHistory } from "react-router";

export const ListView = ({
  filteredCompetitions,
  isLoading,
  weekFilter,
  filterExpression,
  page,
  setPage,
  calendar,
  menu,
  selectedYear,
  miszApprovedFilter,
}) => {
  const { xxl } = useBreakpoint();
  let history = useHistory();
  const urls = useContext(UrlContext);

  return (
    <>
      <List
        dataSource={filteredCompetitions}
        loading={isLoading}
        pagination={
          weekFilter !== "all" || filterExpression
            ? false
            : {
                showLessItems: true,
                pageSize: 6,
                current: page,
                showSizeChanger: false,
                onChange: (page) => {
                  setPage(page);
                  history.replace(`${urls.competitions}/${page}`);
                },
              }
        }
        renderItem={(competition) => (
          <List.Item
            key={competition.id}
            actions={[
              <Dropdown
                trigger={"click"}
                key={"menu-" + competition.id}
                overlay={menu(competition)}
                placement="bottomRight"
              >
                <Button type={competition.detailLevel === 2 ? "primary" : ""}>
                  <MenuOutlined />
                </Button>
              </Dropdown>,
            ]}
          >
            <Skeleton title={false} loading={isLoading} active>
              <List.Item.Meta
                title={
                  <Space>
                    {_.find(calendar, { id: competition.id }) && (
                      <CalendarOutlined />
                    )}
                    <NavLink
                      to={`${urls.competition}/${competition.id}/${competition.slug}`}
                    >
                      <span style={{ color: "#000000" }}>
                        {competition.name}
                      </span>
                    </NavLink>
                  </Space>
                }
                description={
                  <div>
                    <CompetitionTypeTag type={competition.type} />
                    {competition.miszApproved && !miszApprovedFilter && (
                      <Tag color={"red"}>MISZ</Tag>
                    )}
                    {competition.location} -{" "}
                    {moment(competition.startDate).isoWeekday() === 7 ? (
                      <span style={{ color: "#e80000" }}>
                        {competition.startDate}
                      </span>
                    ) : (
                      <span>{competition.startDate}</span>
                    )}
                  </div>
                }
              />
            </Skeleton>
          </List.Item>
        )}
      />
      {weekFilter !== "all" && !filterExpression && (
        <div className={"week-paginator"}>
          <Pagination
            showLessItems={true}
            current={page}
            pageSize={1}
            itemRender={(page, type, originalElement) => {
              if (type === "page" && weekFilter === "month") {
                return (
                  <span>
                    {moment()
                      .month(page - 1)
                      .format("MMM")}
                  </span>
                );
              } else {
                return originalElement;
              }
            }}
            total={
              weekFilter === "week"
                ? moment(new Date(selectedYear + "-01-20")).isoWeeksInYear()
                : 12
            }
            showSizeChanger={false}
            onChange={(page) => {
              setPage(page);
              history.replace(`${urls.competitions}/${page}/${weekFilter}`);
            }}
          />
        </div>
      )}
    </>
  );
};
