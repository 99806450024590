import DOMPurify from "dompurify";
import React from "react";

export const Description = ({ competition }) => {
  return (
    <div
      className={"description-container"}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(competition.description),
      }}
    />
  );
};
