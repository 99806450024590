import React from "react";
import { Tag } from "antd";
import {useTranslation} from "react-i18next";

export const AgeGroupTag = ({ ageGroup }) => {
  const {t} = useTranslation();

  return (
    <>
      {{
        Gyerek: <Tag color={"green"}>{t("Gyerek")}</Tag>,
        "Young adult": <Tag color={"green"}>{t("Young adult")}</Tag>,
        Felnőtt: <Tag color={"geekblue"}>{t("Felnőtt")}</Tag>,
        Veterán: <Tag color={"red"}>{t("Veterán")}</Tag>,
        Senior: <Tag color={"red"}>{t("Senior")}</Tag>,
        Ifi: <Tag color={"cyan"}>{t("Ifi")}</Tag>,
        Ifjúsági: <Tag color={"cyan"}>{t("Ifjúsági")}</Tag>,
        Junior: <Tag color={"cyan"}>{t("Junior")}</Tag>,
        Mini: <Tag color={"magenta"}>{t("Mini")}</Tag>,
        Kadet: <Tag color={"purple"}>{t("Kadet")}</Tag>,
        Kadett: <Tag color={"purple"}>{t("Kadett")}</Tag>,
        "65+": <Tag color={"gold"}>{t("65+")}</Tag>,
      }[ageGroup] || <Tag color={"blue"}>{t(ageGroup)}</Tag>}
    </>
  );
};
