import React from "react";

import "./DistributionBar.less";

export const DistributionBar = ({ entry, mode }) => {
  const arrowCount = entry.targetCount * entry.arrowCount;

  const s11p = (entry.s11 / arrowCount) * 100;
  const s10p = (entry.s10 / arrowCount) * 100;
  const s8p = (entry.s8 / arrowCount) * 100;
  const s5p = (entry.s5 / arrowCount) * 100;
  const s0p = (entry.s0 / arrowCount) * 100;

  console.log(s11p);

  const correctResult =
    entry.s11 + entry.s10 + entry.s8 + entry.s5 + entry.s0 === arrowCount;

  return (
    <div className={"distribution-bar"}>
      {correctResult && (
        <>
          <div style={{ width: s11p + "%", backgroundColor: "#7666f9" }}>
            {mode ? s11p.toFixed() + "%" : entry.s11}
          </div>
          <div style={{ width: s10p + "%", backgroundColor: "#f6c022" }}>
            {mode ? s10p.toFixed() + "%" : entry.s10}
          </div>
          <div style={{ width: s8p + "%", backgroundColor: "#657797" }}>
            {mode ? s8p.toFixed() + "%" : entry.s8}
          </div>
          <div style={{ width: s5p + "%", backgroundColor: "#62daab" }}>
            {mode ? s5p.toFixed() + "%" : entry.s5}
          </div>
          <div style={{ width: s0p + "%", backgroundColor: "#6395f9" }}>
            {mode ? s0p.toFixed() + "%" : entry.s0}
          </div>
        </>
      )}
      {!correctResult && (
        <div style={{ width: "100%", backgroundColor: "#fafafa" }}></div>
      )}
    </div>
  );
};
