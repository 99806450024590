import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  InputNumber,
  PageHeader,
  Popconfirm,
  Slider,
  Space,
} from "antd";
import { useParams } from "react-router-dom";
import { db } from "../utils/TrainingDB";
import { useHistory } from "react-router";
import "./Training.less";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ScoreAverageChart } from "artemis-shared/scorecard/ScoreAverageChart";
import _ from "lodash";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { GenderTag } from "artemis-shared/tag/GenderTag";

export const Training = (props) => {
  const history = useHistory();
  let { trainingId } = useParams();
  const [training, setTraining] = useState(null);
  const [distance, setDistance] = useState(5);
  const [currentTarget, setCurrentTarget] = useState(0);
  const [currentAthlete, setCurrentAthlete] = useState(0);
  const [currentArrow, setCurrentArrow] = useState(0);

  const getLocalTraining = async () => {
    let finalTrainingId = trainingId || props.trainingId;
    console.log("trainingId", finalTrainingId);
    let training = await db.trainings
      .where("id")
      .equals(finalTrainingId)
      .first();

    console.log("got", training);

    if (training) {
      setTraining(training);
    }

    return training;
  };

  const updateTraining = async (training) => {
    await db.trainings.where("id").equals(training.id).modify(training);
    await getLocalTraining();
  };

  useEffect(() => {
    getLocalTraining();
  }, []);

  const generateMarks = () => {
    let obj = {};
    for (let i = 5; i <= training.maxDistance; i += 5) {
      obj[i] = i.toString();
    }

    return obj;
  };

  const updateDistanceEstimate = (value) => {
    let newObj = { ...training };
    newObj.scores[currentTarget].distance = value;

    updateTraining(newObj);
  };

  const updateDistance = (targetNumber, value) => {
    let newObj = { ...training };
    newObj.distances[targetNumber] = value;

    updateTraining(newObj);
  };

  const setScore = (value) => {
    let newObj = { ...training };

    if (training.scorecardTraining) {
      newObj.teamScores[currentAthlete].scores[currentTarget].scores[
        currentArrow
      ] = value;
    } else {
      newObj.scores[currentTarget].scores[currentArrow] = value;
    }

    updateTraining(newObj);
    let newArrowIndex = currentArrow + 1;

    if (newArrowIndex <= getArrowsPerTarget() - 1) {
      setCurrentArrow(newArrowIndex);
    }
  };

  const getScores = () => {
    if (training.scorecardTraining) {
      return training.teamScores[currentAthlete].scores;
    } else {
      return training.scores;
    }
  };

  const getArrowsPerTarget = () => {
    if (training.scorecardTraining) {
      return training.teamScores[currentAthlete].arrowsPerTarget;
    } else {
      return training.arrowsPerTarget;
    }
  };

  const getColor = (value) => {
    switch (value) {
      case "x":
      case "20":
      case "19":
      case "18":
      case "17":
      case "16":
      case "15":
      case "14":
      case "13":
      case "12":
      case "11":
        return {
          backgroundColor: "#ff3f2e",
          color: "#ffffff",
        };
      case "10":
      case "9":
        return {
          backgroundColor: "#5dd2ff",
          color: "#ffffff",
        };
      case "8":
      case "7":
      case "6":
        return {
          backgroundColor: "#b4b4b4",
          color: "#000000",
        };
      case "5":
      case "4":
      case "3":
      case "2":
      case "1":
        return {
          backgroundColor: "#963e00",
          color: "#000000",
        };
      case "0":
        return {
          backgroundColor: "#000000",
          color: "#ffffff",
        };
    }
  };

  const calculateChartData = () => {
    const maxScoringValue = _.max(
      training.scoring
        .split(",")
        .map((value) => (value === "x" ? -1 : parseInt(value)))
    );
    let data = getScores().map((scoreItem, index) => {
      let scoreArray = getScores()
        .slice(0, index + 1)
        .reduce((acc, sc) => _.concat(acc, sc.scores), []);
      let scoreSum = _.sum(
        scoreArray.map((v) => (v === "x" ? maxScoringValue : parseInt(v)))
      );
      let avg = scoreSum / scoreArray.length;

      return {
        target: scoreItem.target,
        scoreAverage: Math.round((avg || 0) * 100) / 100,
        scoreSum,
      };
    });

    console.log(data);
    return data;
  };

  const countScoreValues = () => {
    let values = getScores().reduce((acc, sc) => _.concat(acc, sc.scores), []);

    let obj = {};
    training.scoring
      .split(",")
      .map(
        (scoreValue) =>
          (obj[scoreValue] = values.filter(
            (value) => value === scoreValue
          ).length)
      );

    console.log(obj);

    return obj;
  };

  const countScore = () => {
    const maxScoringValue = _.max(
      training.scoring
        .split(",")
        .map((value) => (value === "x" ? -1 : parseInt(value)))
    );
    let values = getScores().reduce((acc, sc) => _.concat(acc, sc.scores), []);

    values = values.filter((value) => value);

    return {
      score: _.sum(
        values.map((v) => (v === "x" ? maxScoringValue : parseInt(v)))
      ),
      diff:
        _.sum(values.map((v) => (v === "x" ? maxScoringValue : parseInt(v)))) -
        values.length * Math.min(maxScoringValue, 10),
    };
  };

  const prefixedNumber = (number) => {
    if (number === 0) {
      return "-";
    } else {
      return number > 0 ? "+" + number : number;
    }
  };

  const getMissColor = (value) => {
    if (Math.abs(value) <= 2) {
      return "#00b700";
    } else if (Math.abs(value) <= 4) {
      return "#ffbf41";
    } else {
      return "#ff0000";
    }
  };

  const chartData = training && calculateChartData();
  const scoreValues = training && countScoreValues();
  const score = training && countScore();

  const isComplete = () => {
    let complete = true;

    training.teamScores.forEach((athlete) => {
      let count = athlete.scores.reduce(
        (acc, target) => acc + target.scores.length,
        0
      );
      complete =
        complete && count === athlete.arrowsPerTarget * training.targetCount;
    });

    return complete;
  };

  const targetComplete = (target) => {
    let complete = true;

    training.teamScores.forEach((athlete) => {
      let count = athlete.scores[target].scores.length;
      complete = complete && count === athlete.arrowsPerTarget;
    });

    return complete;
  };

  const athleteComplete = (athlete, target) => {
    let count = training.teamScores[athlete].scores[target].scores.length;
    return count === training.teamScores[athlete].arrowsPerTarget;
  };

  return (
    <div className={"training"}>
      {training && (
        <>
          {!training.scorecardTraining && (
            <PageHeader
              className="site-page-header"
              onBack={() => history.push("/training")}
              title={training.name}
            />
          )}
          {training.scorecardTraining && isComplete() && (
            <Popconfirm
              title="Ellenőriztétek az eredményeket? Rögzítés után nincs lehetőség a módosításra."
              onConfirm={() => props.onComplete(training.teamScores)}
              okText={"Igen, rögzítem"}
              cancelText={"Nem"}
              placement={"bottom"}
            >
              <Button block type={"primary"}>
                Eredmények rögzítése
              </Button>
            </Popconfirm>
          )}
          {training.scorecardTraining && (
            <div className={"paging-marks-container"}>
              {[...Array(training.targetCount)].map((e, i) => (
                <div
                  key={i}
                  className={
                    "paging-mark " +
                    (targetComplete(i) ? "complete " : "") +
                    (i === currentTarget ? "active " : "")
                  }
                  onClick={() => setCurrentTarget(i)}
                />
              ))}
            </div>
          )}
          <div className={"target-selector-container"}>
            <div className={"paging"}>
              <LeftOutlined
                onClick={() => {
                  let newTargetNumber =
                    currentTarget > 0
                      ? currentTarget - 1
                      : training.targetCount - 1;
                  setCurrentTarget(newTargetNumber);
                  setCurrentArrow(0);
                  setCurrentAthlete(0);
                  setDistance(getScores()[newTargetNumber].distance);
                }}
              />
            </div>
            <div>
              {currentTarget + 1} / {training.targetCount}
            </div>
            <div className={"paging"}>
              <RightOutlined
                onClick={() => {
                  let newTargetNumber =
                    currentTarget < training.targetCount - 1
                      ? currentTarget + 1
                      : 0;
                  setCurrentTarget(newTargetNumber);
                  setCurrentArrow(0);
                  setCurrentAthlete(0);
                  setDistance(getScores()[newTargetNumber].distance);
                }}
              />
            </div>
          </div>
          {training.scorecardTraining && (
            <>
              <div className={"paging-marks-container"}>
                {[...Array(training.teamScores.length)].map((e, i) => (
                  <div
                    key={i}
                    className={
                      "paging-mark " +
                      (athleteComplete(i, currentTarget) ? "complete " : "") +
                      (i === currentAthlete ? "active" : "")
                    }
                    onClick={() => setCurrentAthlete(i)}
                  />
                ))}
              </div>
              <div className={"athlete-selector-container"}>
                <div className={"paging"}>
                  {currentAthlete > 0 && (
                    <LeftOutlined
                      onClick={() => {
                        setCurrentAthlete(currentAthlete - 1);
                        setCurrentArrow(0);
                      }}
                    />
                  )}
                </div>
                <div className={"name-container"}>
                  <div className={"name"}>
                    {training.teamScores[currentAthlete].name}
                  </div>
                  <div className={"tags"}>
                    <CategoryTag
                      category={training.teamScores[currentAthlete].category}
                    />
                    <AgeGroupTag
                      ageGroup={training.teamScores[currentAthlete].ageGroup}
                    />
                    <GenderTag
                      gender={training.teamScores[currentAthlete].gender}
                    />
                  </div>
                </div>
                <div className={"paging"}>
                  {currentAthlete < training.teamScores.length - 1 && (
                    <RightOutlined
                      onClick={() => {
                        setCurrentAthlete(currentAthlete + 1);
                        setCurrentArrow(0);
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
          <div className={"target-score-container"}>
            {[...Array(getArrowsPerTarget())].map((e, i) => (
              <div
                className={
                  "score-value " +
                  (!getScores()[currentTarget].scores[i] ? "empty " : "") +
                  (currentArrow === i ? "active" : "")
                }
                key={i}
                onClick={(e) => {
                  setCurrentArrow(i);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {getScores()[currentTarget].scores[i] ? (
                  getScores()[currentTarget].scores[i].toUpperCase()
                ) : (
                  <span>#{i + 1}</span>
                )}
              </div>
            ))}
          </div>
          <div className={"scoring-buttons-container"}>
            {training.scoring.split(",").map((scoreValue) => (
              <div
                className={"score-button"}
                key={scoreValue}
                onClick={(e) => {
                  setScore(scoreValue);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {scoreValue.toUpperCase()}
              </div>
            ))}
          </div>
          {!training.scorecardTraining && (
            <>
              <h3>Becsült távolság: {distance} méter</h3>
              <Slider
                value={distance}
                onChange={setDistance}
                onAfterChange={updateDistanceEstimate}
                min={5}
                max={training.maxDistance}
                tooltipVisible={false}
                marks={generateMarks()}
              />
              <Divider />
            </>
          )}
          <div className={"statistics"}>
            <div>
              Pontszám: {score.score} ({" "}
              {score.diff > 0 ? "+" + score.diff : score.diff} )
            </div>
            <div>
              Vesszőátlag: {chartData[chartData.length - 1].scoreAverage}
            </div>
          </div>
          {!training.scorecardTraining && (
            <ScoreAverageChart data={chartData} />
          )}
          <div>
            <table
              border={1}
              style={{ width: "100%", marginTop: "24px", tableLayout: "fixed" }}
            >
              <tr>
                {training.scoring.split(",").map((sv) => (
                  <th key={sv} style={{ ...getColor(sv) }}>
                    {sv.toUpperCase()}
                  </th>
                ))}
              </tr>
              <tr>
                {training.scoring.split(",").map((sv) => (
                  <td key={sv} style={{ textAlign: "center" }}>
                    {scoreValues[sv]}
                  </td>
                ))}
              </tr>
            </table>
          </div>
          <div>
            <table
              border={1}
              style={{ width: "100%", marginTop: "24px", tableLayout: "fixed" }}
            >
              <tr>
                <th>#</th>
                {[...Array(getArrowsPerTarget())].map((e, index) => (
                  <th key={index}>{index + 1}</th>
                ))}
                <th>Pont</th>
                {!training.scorecardTraining && <th>Becs.</th>}
                {!training.scorecardTraining && <th>Táv</th>}
              </tr>
              {[...Array(training.targetCount)].map((e, targetNumber) => {
                let adjustedTargetNumber = training.firstTargetNumber + targetNumber > training.targetCount
                    ? training.firstTargetNumber + targetNumber - training.targetCount
                    : training.firstTargetNumber + targetNumber;

                return (
                    <tr
                        key={targetNumber}
                        className={
                          targetNumber === currentTarget ? "selected-row" : ""
                        }
                        onClick={() => setCurrentTarget(targetNumber)}
                    >
                      <td style={{ textAlign: "center" }}>{adjustedTargetNumber}</td>
                      {[...Array(getArrowsPerTarget())].map((e, arrowNumber) => (
                          <td
                              key={targetNumber + "-" + arrowNumber}
                              style={{
                                ...getColor(
                                    getScores()[targetNumber].scores[arrowNumber]
                                ),
                                textAlign: "center",
                              }}
                          >
                            {getScores()[targetNumber].scores[arrowNumber]
                                ? getScores()[targetNumber].scores[
                                    arrowNumber
                                    ].toUpperCase()
                                : "-"}
                          </td>
                      ))}
                      <td style={{ textAlign: "center" }}>
                        {chartData[targetNumber].scoreSum}
                      </td>
                      {!training.scorecardTraining && (
                          <td style={{ textAlign: "center" }}>
                            {getScores()[targetNumber].distance}{" "}
                            {_.isNumber(training.distances[targetNumber]) ? (
                                <span
                                    style={{
                                      color: getMissColor(
                                          getScores()[targetNumber].distance -
                                          training.distances[targetNumber]
                                      ),
                                    }}
                                >
                          (
                                  {prefixedNumber(
                                      getScores()[targetNumber].distance -
                                      training.distances[targetNumber]
                                  )}
                                  )
                        </span>
                            ) : (
                                ""
                            )}
                          </td>
                      )}
                      {!training.scorecardTraining && (
                          <td style={{ textAlign: "center" }}>
                            <InputNumber
                                size={"small"}
                                style={{ width: "100%" }}
                                value={training.distances[targetNumber]}
                                onChange={(value) =>
                                    updateDistance(targetNumber, value)
                                }
                            />
                          </td>
                      )}
                    </tr>
                )
              })}
            </table>
          </div>
        </>
      )}
    </div>
  );
};
