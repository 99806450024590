import React from "react";
import { Col, Typography } from "antd";

export const CookiePolicy = () => {
  let html = "asdasd";

  return (
    <Col xs={24} lg={{ span: 18, offset: 3 }} xl={{ span: 12, offset: 6 }}>
      <Typography.Title level={2}>Sütik (cookie) kezelése</Typography.Title>
      <p>
        A(z) Íjász.NET weboldala sütiket használ a weboldal működtetése,
        használatának megkönnyítése érdekében.
      </p>
      <p>
        Kérjük, hogy a dokumentumot figyelmesen olvassa el és csak akkor vegye
        igénybe a weboldal szolgáltatásait, amennyiben minden pontjával egyetért
        és azokat Önre (a továbbiakban: Felhasználó) nézve kötelező érvényűnek
        elfogadja. Felhívjuk figyelmét, hogy jelen szabályzat csak az adott
        weboldalon történő cookie-kezelésre vonatkozik.
      </p>
      <Typography.Title level={5}>Mi az a Cookie?</Typography.Title>
      <p>
        A cookie (magyarul süti) egy olyan kisméretű adatcsomag, amit az
        internetes szolgáltatások a böngészőben tárolnak el. A hatékony és
        modern felhasználói élményt nyújtó online szolgáltatás működéséhez
        elengedhetetlen technológia, amelyet manapság minden böngésző támogat.
      </p>

      <Typography.Title level={5}>Hogyan keletkezik a Cookie?</Typography.Title>
      <p>
        Először a kliens gép küld egy kérést a kiszolgáló irányába. Ekkor a
        kiszolgáló létrehoz egy egyedi azonosítót és ezt eltárolja a saját
        adatbázisában, majd az így létrehozott cookie-t az összes információval
        visszaküldi a kliensnek. Az így visszakapott információs cookie
        eltárolódik a kliens gépen.
      </p>

      <Typography.Title level={5}>Hogyan hasznosul a Cookie?</Typography.Title>
      <p>
        Amikor a kliens gép újra kapcsolatba lép a kiszolgálóval már párosítja
        az előzőleg már létrehozott és eltárolt cookie-t. A kiszolgáló
        összehasonlítja a kapott és az általa tárolt cookie tartalmát. Ez által
        könnyedén azonosítja pl. a bejelentkezett regisztrált felhasználót.
        Enélkül például nem lehetne bejelentkezni egy weboldalra.
      </p>

      <Typography.Title level={5}>
        Milyen sütiket és mire használ a weboldal?
      </Typography.Title>
      <p>
        <ul>
          <li>weboldalunk fejlesztése,</li>
          <li>
            az Ön navigációjának megkönnyítése weboldalunkon és az oldal
            funkcióinak használata során, így biztosítva a zökkenőmentes
            felhasználói élményt,
          </li>
          <li>böngésző felhasználók megkülönböztetése, azonosítása</li>
          <li>a süti szabályzat elfogadás tényének rögzítésére</li>
        </ul>
      </p>

      <Typography.Title level={4}>A sütik típusai</Typography.Title>
      <Typography.Title level={5}>
        Alapműködést biztosító sütik
      </Typography.Title>
      <p>
        Ezen sütik biztosítják a weboldal megfelelő működését, megkönnyítik
        annak használatát, és látogatóink azonosítása nélkül gyűjtenek
        információt a használatáról.
      </p>
      <p>
        Ide tartozik például a sütikezelés elfogadásának státusza,
        bejelentkezési módok és adatok megjegyzése, és a weboldal értesítési
        üzenetek státusza.
      </p>
      <p>
        Ne feledje, ezen sütik alkalmazása nélkül nem tudjuk garantálni Önnek
        weboldalunk kényelmes használatát.
      </p>

      <Typography.Title level={5}>
        Hogyan ellenőrizheti, és hogyan tudja kikapcsolnia a sütiket?
      </Typography.Title>
      <p>
        Minden modern böngésző engedélyezi a sütik beállításának a
        változtatását. A legtöbb böngésző alapértelmezettként automatikusan
        elfogadja a sütiket, ezek a beállítások azonban általában
        megváltoztathatók, így a böngésző meg tudja akadályozni az automatikus
        elfogadást, és minden alkalommal fel tudja ajánlani a választás
        lehetőségét, hogy engedélyezi-e a sütiket.
      </p>
      <p>
        Felhívjuk figyelmét, hogy mivel a sütik célja weboldalunk
        használhatóságának és folyamatainak megkönnyítése vagy lehetővé tétele,
        a cookie-k alkalmazásának megakadályozása vagy törlése miatt
        előfordulhat, hogy nem tudja weboldalunk funkcióit teljes körűen
        használni, illetve hogy a weboldal a tervezettől eltérően fog működni
        böngészőjében.
      </p>

      <Typography.Title level={5}>
        A legnépszerűbb böngészők süti beállításairól az alábbi linkeken
        tájékozódhat:
      </Typography.Title>
      <ul>
        <li>
          <a href={"https://support.google.com/accounts/answer/61416?hl=hu"}>
            Google Chrome
          </a>
        </li>
        <li>
          <a
            href={
              "https://support.mozilla.org/hu/kb/sutik-informacio-amelyet-weboldalak-tarolnak-szami"
            }
          >
            Firefox
          </a>
        </li>
        <li>
          <a
            href={
              "https://support.microsoft.com/hu-hu/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
            }
          >
            Microsoft Internet Explorer
          </a>
        </li>
        <li>
          <a
            href={
              "https://privacy.microsoft.com/hu-hu/windows-10-microsoft-edge-and-privacy"
            }
          >
            Microsoft Edge
          </a>
        </li>
        <li>
          <a
            href={
              "https://support.apple.com/hu-hu/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
            }
          >
            Safari
          </a>
        </li>
      </ul>
    </Col>
  );
};
