import {importDB} from "dexie-export-import";
import Swal from "sweetalert2";
import React, {useRef, useState } from "react";
import {Button, Modal, Typography} from "antd";
import {useTranslation} from "react-i18next";

export const BackupModal = () => {
    const {t} = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const fileInputRef = useRef();

    const handleOk =
        async () => {
        if(fileInputRef.current.files[0]) {
            await importDB(fileInputRef.current.files[0], {clearTablesBeforeImport: true});
                await Swal.fire({
                    title: t("manager.backupLoadSuccess"),
                    text: t("manager.backupLoadSuccessText"),
                    icon: "success",
                    timer: 2000,
                    showConfirmButton: false,
                });
                setIsModalVisible(false);
                fileInputRef.current.value = "";
                window.location.reload();
            }
        };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return <>
        <Button onClick={() => setIsModalVisible(true)}>{t("manager.backupLoadButton")}</Button>
        <Modal title={t("manager.backupTitle")} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText={t("manager.load")} cancelText={t("manager.cancel")}>
            <Typography.Paragraph>
                {t("manager.backupLoadDescription")}
            </Typography.Paragraph>
            <input type={"file"} ref={fileInputRef} />
        </Modal>
    </>;
}