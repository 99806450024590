import React from "react";
import { Area } from "@ant-design/charts";
import moment from "moment";
import _ from "lodash";

export const ScoreAverageChart = ({ data }) => {
  const chartData = data.map((result) => ({
    date: result.startDate,
    score:
      result.scoreWoFinals /
      (result.targetCount * result.arrowCount),
  }));

  const config = {
    height: 200,
    smooth: false,
    data: chartData,
    xField: "date",
    yField: "score",
    yAxis: {
      min:
        chartData && chartData.length > 0
          ? Math.max(_.minBy(chartData, "score").score - 0.1, 0)
          : 0,
      max:
        chartData && chartData.length > 0
          ? _.maxBy(chartData, "score").score + 0.1
          : 10,
      label: {
        formatter: function formatter(value) {
          return parseFloat(value).toFixed(2);
        },
      },
    },
    xAxis: {
      tickCount: 5,
      label: {
        formatter: function formatter(value) {
          return moment(value).format("YYYY.MM.DD");
        },
      },
    },
    areaStyle: function areaStyle() {
      return { fill: "#0a7400" };
    },
    line: {
      color: "#0a7400",
    },
    tooltip: {
      formatter: (data) => {
        return {
          name: "Vesszőátlag",
          value: data.score.toFixed(2),
          title: moment(data.date).format("YYYY.MM.DD"),
        };
      },
    },
  };

  return <Area {...config} />;
};
