import React, {useState} from "react";
import {Card, Switch, Tooltip} from "antd";
import "./Targets.less"
import {decodeScorecard} from "../scorecard/ScorecardUtils";
import _ from "lodash";
import {QuestionCircleOutlined} from "@ant-design/icons";

export const Targets = ({ competition, entries }) => {

    const [stakeSelected, setStakeSelected] = useState(false);

    let hitsMap = {
        red: {},
        blue: {}
    };

    const maxScoringValue = _.max(competition.scoring.map(value => value === "x" ? -1 : parseInt(value)));

    entries.filter(entry => entry.ageGroup.ageGroup.name === "Felnőtt" || entry.ageGroup.ageGroup.name === "Veterán").forEach(entry => {
       if(!entry.scorecard) {
           return;
       }


       let stake = entry.category.sight ? "red" : "blue";

       let decoded = decodeScorecard(entry.scorecard);

        if(decoded.decodedScorecards[entry.id]) {
           decoded.decodedScorecards[entry.id].scores.forEach((scoreObj, index) => {

               let targetNumber = index + 1;
               let adjustedIndex = entry.team - 1 + targetNumber > competition.targetCount
                   ? entry.team - 1 + targetNumber - competition.targetCount
                   : entry.team - 1 + targetNumber;

               scoreObj.scores.map(scoreStr => {
                   if(!hitsMap[stake][adjustedIndex]) {
                       hitsMap[stake][adjustedIndex] = [];
                   }

                   hitsMap[stake][adjustedIndex].push(scoreStr === "x" ? maxScoringValue : parseInt(scoreStr))
               })
           })
        }
    });

    let blueAvg = Object.keys(hitsMap.blue).map(target =>
        _.sum(hitsMap.blue[target]) / hitsMap.blue[target].length
    );

    let redAvg = Object.keys(hitsMap.red).map(target =>
        _.sum(hitsMap.red[target]) / hitsMap.red[target].length
    );

    let blueMax = _.max(blueAvg);
    let blueAdjustedMin = _.min(blueAvg) * 0.98;

    let redMax = _.max(redAvg);
    let redAdjustedMin = _.min(redAvg) * 0.98;


    let showAvg = hitsMap[stakeSelected ? "red" : "blue"][1] && competition.teamsPublished;

  return (
      <div className={"targets"}>
          <Card title={<span>Távok</span>} style={{position: "relative"}} extra={hitsMap[stakeSelected ? "red" : "blue"][1] ? [<Tooltip placement={"bottomRight"} title={"A mobilos teljes beírólapon rögzített és leadott találatok átlagát mutatja az adott célon."}><span style={{marginRight: "15px"}}><strong>{stakeSelected ? hitsMap.red[1].length : hitsMap.blue[1].length}</strong> találat alapján &nbsp; <QuestionCircleOutlined /></span></Tooltip>, <Switch checked={stakeSelected} className={"stake-switch"} onChange={setStakeSelected} checkedChildren={"Piros"} unCheckedChildren={"Kék"} />] : []}>

              {!competition.multiRound && <table className={"targets-table"}>
                  <tr className={"table-row"}>
                      <th>#</th>
                      <th style={{textAlign: "left"}}>Cél</th>
                      <th>Kék</th>
                      <th>Piros</th>
                      {showAvg && <th>V.átlag</th>}
                  </tr>
                  {competition.targets.map(target => <tr className={"table-row"}>
                      <td className={"centered"}>{target.targetNumber}</td>
                      <td>{target.targetName}</td>
                      <td className={"centered"}>{target.blueStake}</td>
                      <td className={"centered"}>{target.redStake}</td>
                      {showAvg && <td className={"centered"}>{stakeSelected ? redAvg[target.targetNumber - 1].toFixed(2) : blueAvg[target.targetNumber - 1].toFixed(2)}</td>}
                  </tr>)}
              </table>}

              {competition.multiRound &&
                  <table className={"targets-table"}>
                      <tr className={"table-row"}>
                          <th>#</th>
                          <th style={{textAlign: "left"}}>Cél</th>
                          <th>Kék</th>
                          <th>Piros</th>
                          {showAvg && <th>V.átlag</th>}
                      </tr>
                      {competition.rounds.filter(round => !round.finals).map((round) => (
                          <>
                              <tr style={{height: "40px"}}>
                                  <td colSpan={4}>{round.name}</td>
                              </tr>
                              {competition.targets.slice(round.round * round.arrowCount, (round.round * round.arrowCount) + round.arrowCount).map((target, index) => <tr className={"table-row"}>
                                  <td className={"centered"}>{index + 1}</td>
                                  <td>{target.targetName}</td>
                                  <td className={"centered"}>{target.blueStake}</td>
                                  <td className={"centered"}>{target.redStake}</td>
                                  {showAvg && <td className={"centered"}>{stakeSelected ? redAvg[target.targetNumber - 1].toFixed(2) : blueAvg[target.targetNumber - 1].toFixed(2)}</td>}
                              </tr>)}
                          </>)
                      )}
                  </table>
              }

              {showAvg && <div className={"stats-background"}>
                  {competition.targets.map(target => <div className={"stats-row"}>
                      {stakeSelected && <div className={"red-stake-row"} style={{width: (((redAvg[target.targetNumber - 1] - redAdjustedMin) / (redMax - redAdjustedMin)) * 100) + "%"}}></div>}
                      {!stakeSelected && <div className={"blue-stake-row"} style={{width: (((blueAvg[target.targetNumber - 1] - blueAdjustedMin) / (blueMax - blueAdjustedMin)) * 100) + "%"}}></div>}

                  </div>)}
              </div>}
          </Card>
      </div>

  );
};
