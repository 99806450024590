import React, { useEffect, useMemo, useState } from "react";
import { Button, Select, Typography } from "antd";
import { BrowserQRCodeReader } from "@zxing/library";
import {useTranslation} from "react-i18next";

const { Option } = Select;

export const QRReader = ({ onClose, onRead, showDescription, mirrored }) => {
    const { t } = useTranslation();
  const codeReader = useMemo(() => new BrowserQRCodeReader(), []);
  const [inputDevices, setInputDevices] = useState([]);
  const [selectedInputDevice, setSelectedInputDevice] = useState(null);

  const decode = async (codeReader, selectedDeviceId) => {
    console.log("decode from device ", selectedDeviceId);

    await codeReader.decodeFromInputVideoDeviceContinuously(
      selectedDeviceId,
      "video",
      (result, err) => {
        if (result) {
          console.log("Found QR code!", result);
          onRead(result);
        }
      }
    );
  };

  useEffect(() => {
    console.log("Code reader initialized");

    codeReader.getVideoInputDevices().then((videoInputDevices) => {
      codeReader.reset();

      setInputDevices(videoInputDevices);

      console.log(videoInputDevices);
    });

    return () => {
      console.log("cleanup qr reader");
      codeReader.reset();
    };
  }, []);

  useEffect(async () => {
    codeReader.reset();
    await decode(codeReader, selectedInputDevice);
  }, [selectedInputDevice]);

  return (
    <>
      {inputDevices.length > 1 && (
        <>
          {showDescription && (
            <>
              <Typography.Title level={5} style={{ textAlign: "center" }}>
                A készüléked több kamerával rendelkezik. Ha nem a hátlapi kamera
                képét látod, válts kamerát a lenyíló segítségével.
              </Typography.Title>
              <Typography.Paragraph style={{ textAlign: "center" }}>
                A széles látószögű kamera torzítja a képet, megnehezíti a
                beolvasást!
              </Typography.Paragraph>
              <Typography.Paragraph style={{ textAlign: "center" }}>
                Irányítsd a kamerát a QR matricára! Miután az összes QR kódot
                sikerült beolvasni, nyomj a bezárás gombra.
              </Typography.Paragraph>
            </>
          )}
          <Select
            onChange={setSelectedInputDevice}
            value={selectedInputDevice}
            placeholder={"Kamera kiválasztása"}
            style={{ width: "100%" }}
          >
            {inputDevices.map((device) => (
              <Option key={device.deviceId} value={device.deviceId}>
                {device.label} - {device.deviceId.substr(0, 10)}
              </Option>
            ))}
          </Select>
        </>
      )}
      <div>
        <video
          id="video"
          width={"100%"}
          style={{ transform: mirrored ? "rotateY(180deg)" : "none" }}
        />
      </div>
      <Button
        block
        type={"primary"}
        onClick={() => {
          console.log("cleanup qr reader");
          codeReader.reset();
          onClose();
        }}
      >
          {t("manager.closeQR")}
      </Button>
    </>
  );
};
