import React from "react";
import { Pie } from "@ant-design/charts";

export const ScoreDistributionChart = ({ data }) => {
  let count = {
    11: 0,
    10: 0,
    8: 0,
    5: 0,
    0: 0,
  };

  data.forEach((entry) => {
    count["11"] += entry.s11;
    count["10"] += entry.s10;
    count["8"] += entry.s8;
    count["5"] += entry.s5;
    count["0"] += entry.s0;
  });

  let chartdata = Object.entries(count).map(([type, count]) => ({
    type,
    count,
  }));

  let config = {
    height: 300,
    appendPadding: 10,
    data: chartdata,
    angleField: "count",
    colorField: "type",
    radius: 0.9,
    innerRadius: 0.64,
    legend: false,
    label: {
      type: "inner",
      content: "{name}",
      autoRotate: false,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      { type: "element-selected" },
      { type: "element-active" },
      { type: "pie-statistic-active" },
    ],
    statistic: {
      title: {
        formatter: (a) => {
          return !a ? "Összesen" : a.type;
        },
      },
    },
  };
  return <Pie {...config} />;
};
