import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Card,
    Col,
    Button,
    PageHeader,
    Space,
    Table,
    Input,
    Row,
    Form,
    InputNumber,
    Radio,
    Popconfirm,
    Modal, Select
} from "antd";
import { Link } from "react-router-dom";
import {
    DeleteTwoTone,
    QuestionCircleOutlined,
    TableOutlined,
} from "@ant-design/icons";
import {useHistory, useLocation} from "react-router";
import { db } from "../utils/LocalDB";
import { useNetworkStatus } from "../networkStatus/NetworkStatus";
import XLSX from "xlsx";
import moment from "moment";
import _ from "lodash";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import sla3d from "./sla3d.png";
import {BackupModal} from "../manager/BackupModal";
import {ArrowCountExceptionInput} from "../createCompetition/ArrowCountExceptionInput";

export const ManagerDashboardSK = () => {
    const { i18n, t } = useTranslation();
  const { Column } = Table;

  useEffect(() => {
      i18n.changeLanguage("sk");
  }, []);

    const { search } = useLocation();

    let query = React.useMemo(() => new URLSearchParams(search), [search]);

    let urlParams = {
        format: query.get("format"),
        finals: query.get("finals"),
        name: query.get("name"),
        teams: query.get("teams"),
        targets: query.get("targets"),
        arrows: query.get("arrows"),
        tie: query.get("tie"),
        lunch: query.get("lunch"),
        deti: query.get("deti"),
        kadet: query.get("kadet"),
        senior: query.get("senior"),
        veteran: query.get("veteran"),
        url: query.get("url"),
    }

    window.history.replaceState({}, false, "/manager-dashboard-sk");

    const getAgeGroupsWithFees = (detiDoFee, kadetFee, seniorFee, veteranFee) => {
        return [
            {
                "id": 1,
                "skName": "Deti do 12,99",
                "skToAge": 12,
                "ageGroup": {
                    "id": 4,
                    "name": "Deti do",
                    "type": "THREED"
                },
                "fromAge": 0,
                "toAge": 12,
                "entryFee": detiDoFee || 0
            },
            {
                "id": 2,
                "skName": "Kadet 13+",
                "skToAge": 17,
                "ageGroup": {
                    "id": 6,
                    "name": "Kadet",
                    "type": "THREED"
                },
                "fromAge": 13,
                "toAge": 17,
                "entryFee": kadetFee || 0
            },
            {
                "id": 3,
                "skName": "Senior 18+",
                "skToAge": 50,
                "ageGroup": {
                    "id": 1,
                    "name": "Senior",
                    "type": "THREED"
                },
                "fromAge": 18,
                "toAge": 54,
                "entryFee": seniorFee || 0
            },
            {
                "id": 4,
                "skToAge": 999,
                "skName": "Veterán 55+",
                "ageGroup": {
                    "id": 2,
                    "name": "Veterán",
                    "type": "THREED"
                },
                "fromAge": 55,
                "toAge": 99,
                "entryFee": veteranFee || 0
            }
        ];
    }

    const createFromUrl = async (urlParams) => {
        console.log("urlParams", urlParams);

        Swal.fire({
            title: "Loading! Please wait!",
            text: urlParams.url,
            icon: "info",
            showCloseButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
        });

        const values = {
            format: parseInt(urlParams.format) || 1,
            finals: parseInt(urlParams.finals) || 0,
            name: urlParams.name || null,
            teamCount: parseInt(urlParams.teams) || null,
            targetCount: parseInt(urlParams.targets) || null,
            arrowCount: parseInt(urlParams.arrows) || null,
            tiebreaking: {HDHIAA: "0,11,10,8,5,T", WA: "11,10,8,5,0,T"}[urlParams.tie] || null,
            lunchFee: parseFloat(urlParams.lunch) || null,
            detiDoFee: parseFloat(urlParams.deti) || null,
            kadetFee: parseFloat(urlParams.kadet) || null,
            seniorFee: parseFloat(urlParams.senior) || null,
            veteranFee: parseFloat(urlParams.veteran) || null,
            exceptions: []
        };

        let ageGroups = getAgeGroupsWithFees(values.detiDoFee, values.kadetFee, values.seniorFee, values.veteranFee);
        let arrowCountExceptions = [];

        const result = await fetch("https://archery3d.sk/export/ijasz/" + urlParams.url);
        const resultBlob = await result.blob();

        let reader = new FileReader();

        reader.onload = async function (e) {
            let workbook = XLSX.read(e.target.result, {
                type: "binary",
            });

            let excelRows = XLSX.utils.sheet_to_json(
                workbook.Sheets[workbook.SheetNames[0]],
                {
                    header: "A",
                }
            );

            const compId = await createCallback(excelRows, values, ageGroups, arrowCountExceptions);
            window.location = "/manager-sk/" + compId;
        };

        reader.readAsBinaryString(resultBlob);

        form.resetFields();
        excelInputRef.current.value = "";
    }


    //AUTO CREATE DISABLED
    //useEffect(() => {
    //    if(urlParams.url) {
    //        console.log("HAS URL ", urlParams);
    //
    //        createFromUrl(urlParams);
    //    }
    //}, []);

    console.log("query", urlParams);

  let history = useHistory();
  let networkStatus = useNetworkStatus();

  let [isTeamUpdateModalVisible, setIsTeamUpdateModalVisible] = useState(false);
  let [selectedCompetitionId, setSelectedCompetitionId] = useState(null);

  const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 },
    };

  let categories = useMemo(() => [
      {
          "id": 6,
          "name": "BB",
          "sight": false,
          "type": "THREED",
          "rulebook": "HDH-IAA"
      },
      {
          "id": 4,
          "name": "CB",
          "sight": false,
          "type": "THREED",
          "rulebook": "HDH-IAA"
      },
      {
          "id": 1,
          "name": "CRB",
          "sight": true,
          "type": "THREED",
          "rulebook": "HDH-IAA"
      },
      {
          "id": 2,
          "name": "CU",
          "sight": true,
          "type": "THREED",
          "rulebook": "HDH-IAA"
      },
      {
          "id": 3,
          "name": "HU",
          "sight": true,
          "type": "THREED",
          "rulebook": "HDH-IAA"
      },
      {
          "id": 5,
          "name": "OL",
          "sight": true,
          "type": "THREED",
          "rulebook": "HDH-IAA"
      },
      {
          "id": 9,
          "name": "PBHB",
          "sight": false,
          "type": "THREED",
          "rulebook": "HDH-IAA"
      },
      {
          "id": 8,
          "name": "TRLB",
          "sight": false,
          "type": "THREED",
          "rulebook": "HDH-IAA"
      },
      {
          "id": 7,
          "name": "TRRB",
          "sight": false,
          "type": "THREED",
          "rulebook": "HDH-IAA"
      }
  ], []);

  const excelInputRef = useRef();
  const teamInputRef = useRef();

  const [localCompetitions, setLocalCompetitions] = useState([]);

  useEffect(() => {
    db.competitions.toArray().then((lc) => setLocalCompetitions(lc));
  }, []);

  console.log("excelInputRef.current.files[0]",excelInputRef.current?.files[0]);

  const createLocalCompetition = async ({id, name, tiebreaking, teamCount, targetCount, arrowCount, lunchFee, rounds, additionalData, entries, detiDoFee, kadetFee, seniorFee, veteranFee, arrowCountExceptions}) => {

      await db.competitions.put({
          id: id,
          name: name,
          type: "THREED",
          scoring: ["0","5","8","10","11"],
          tiebreaking: tiebreaking,
          categories: categories,
          ageGroups: getAgeGroupsWithFees(detiDoFee, kadetFee, seniorFee, veteranFee),
          location: "Location",
          startDate: moment(new Date()).format("YYYY-MM-DD"),
          syncDate: new Date(),
          teamsPublished: true,
          teamCount: teamCount,
          lunch: true,
          lunchFee: lunchFee,
          targetCount: targetCount,
          arrowCount: arrowCount,
          rounds: rounds,
          description: "",
          additionalData: additionalData,
          teamLabels: "",
          slug: "",
          chooseRound: false,
          arrowCountExceptions: arrowCountExceptions
      });

      await db.entries.bulkPut(entries);

      db.competitions.toArray().then((lc) => setLocalCompetitions(lc));
  }

  const createCallback = async (excelRows, values, ageGroups, arrowCountExceptions) => {
      let competitionId = Math.floor(Math.random() * 1000000);

      let baseRound = {
          active: true,
          finals: null,
          round: 0,
          s0: null,
          s1: null,
          s2: null,
          s3: null,
          s4: null,
          s5: null,
          s6: null,
          s7: null,
          s8: null,
          s9: null,
          s10: null,
          s11: null,
          s12: null,
          s13: null,
          s14: null,
          sx: null,
          score: null,
          scorecard: null
      };

      let roundEntries = [];

      for(let i = 0; i < values.format; i++) {
          roundEntries.push({...baseRound, round: i});
      }

      if(values.finals) {
          roundEntries.push({...baseRound, round: roundEntries.length});
      }

      let entries = excelRows.slice(2).filter(row => row.AJ).map(row => ({
          id: row.AJ,
          name: row.D + " " + row.E,
          firstName: row.E || "",
          lastName: row.D || "",
          permitNumber: row.F || "",
          gender: row.G === "m" ? "MALE" : "FEMALE",
          category: _.find(categories, {name: row.I}) || categories[0],
          ageGroup: _.find(ageGroups, {skName: row.H}) || ageGroups[0],
          club: {id: 1, name: row.J || "-"},
          lunch: row.AE || 0,
          agree: false,
          profile: "",
          email: "",
          team: row.A || 0,
          teamIndex: row.C || Math.floor(Math.random() * 1000),
          roundEntries: values.format === 1 && !values.finals ? [] : roundEntries,
          rounds: 0,
          additionalData0: row.AD || "",
          additionalData1: null,
          additionalData2: null,
          additionalData3: null,
          additionalData4: null,
          s0: null,
          s1: null,
          s2: null,
          s3: null,
          s4: null,
          s5: null,
          s6: null,
          s7: null,
          s8: null,
          s9: null,
          s10: null,
          s11: null,
          s12: null,
          s13: null,
          s14: null,
          sx: null,
          score: null,
          tiebreaker: null,
          placement: null,
          competition: competitionId
      }));

      console.log("entries", entries);

      let existingEntry = await db.entries.get(entries[0]?.id || -1);

      if(existingEntry) {
          Swal.fire({
              title: t("manager.existingEntry"),
              icon: "error",
              timer: 2000,
              showConfirmButton: false,
          });
          console.log("existing");
          return;
      }

      let gpRounds = [];

      for(let j = 0; j < values.format; j++) {
          gpRounds.push({
              arrowCount: values.targetCount,
              finals: false,
              id: j + 1,
              name: t("manager.round" + j),
              round: j,
              scoring: ["0", "5", "8", "10", "11"],
              topCount: 999
          })
      }

      if(values.finals) {
          gpRounds.push({
              arrowCount: 6,
              finals: true,
              id: gpRounds.length,
              name: t("manager.finals"),
              round: gpRounds.length,
              scoring: ["0", "5", "8", "10", "12"],
              topCount: 6
          });
      }

      let competitionConfig = {
          id: competitionId,
          name: values.name,
          tiebreaking: values.tiebreaking.split(","),
          teamCount: values.teamCount,
          targetCount: values.targetCount,
          arrowCount: values.arrowCount,
          lunchFee: values.lunchFee,
          rounds: values.format === 1 && !values.finals ? [] : gpRounds,
          entries: entries,
          arrowCountExceptions: arrowCountExceptions,
          additionalData: [{
              id: 1,
              options: null,
              label: "Doprovod",
              pos: 0,
              required: false,
              type: "STRING"
          }]
      }

      await createLocalCompetition(competitionConfig);

      return competitionId;
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/")}
        title={<div>{t("manager.dashboardTitle")}<img style={{paddingLeft: "30px", width: "170px"}} src={sla3d} /></div>}
      />
        <Row gutter={16}>
            <Col span={14}>
                <Card title={t("manager.newCompetition")}>
                    <Form
                        colon={false}
                        form={form}
                        {...layout}
                        name="basic"
                        initialValues={{
                            format: parseInt(urlParams.format) || 1,
                            finals: parseInt(urlParams.finals) || 0,
                            name: urlParams.name || null,
                            teamCount: parseInt(urlParams.teams) || null,
                            targetCount: parseInt(urlParams.targets) || null,
                            arrowCount: parseInt(urlParams.arrows) || null,
                            tiebreaking: {HDHIAA: "0,11,10,8,5,T", WA: "11,10,8,5,0,T"}[urlParams.tie] || null,
                            lunchFee: parseFloat(urlParams.lunch) || null,
                            detiDoFee: parseFloat(urlParams.deti) || null,
                            kadetFee: parseFloat(urlParams.kadet) || null,
                            seniorFee: parseFloat(urlParams.senior) || null,
                            veteranFee: parseFloat(urlParams.veteran) || null,
                            exceptions: []
                        }}
                        onFinish={async (values) => {
                            console.log("values", values);
                            let ageGroups = getAgeGroupsWithFees(values.detiDoFee, values.kadetFee, values.seniorFee, values.veteranFee);
                            let reader = new FileReader();
                            let arrowCountExceptions = values.exceptions.filter(ex => ex.arrowCount).map(ex => ({...ex, arrowCount: parseInt(ex.arrowCount)}));
                            reader.onload = async function (e) {
                                let workbook = XLSX.read(e.target.result, {
                                    type: "binary",
                                });

                                let excelRows = XLSX.utils.sheet_to_json(
                                    workbook.Sheets[workbook.SheetNames[0]],
                                    {
                                        header: "A",
                                    }
                                );

                                console.log(excelRows);

                                createCallback(excelRows, values, ageGroups, arrowCountExceptions);
                            };

                            if(urlParams.url) {

                                Swal.fire({
                                    title: "Loading! Please wait!",
                                    text: urlParams.url,
                                    icon: "info",
                                    showCloseButton: false,
                                    showConfirmButton: false,
                                    allowOutsideClick: false,
                                });

                                const result = await fetch("https://archery3d.sk/export/ijasz/" + urlParams.url);
                                const resultBlob = await result.blob();
                                reader.readAsBinaryString(resultBlob);

                                Swal.fire({
                                    title: "Loaded!",
                                    text: urlParams.url,
                                    icon: "success"
                                });
                            } else {
                                if(excelInputRef.current.files[0]) {
                                    reader.readAsBinaryString(excelInputRef.current.files[0]);
                                } else {
                                    createCallback([], values, ageGroups, arrowCountExceptions);
                                }

                                form.resetFields();
                                excelInputRef.current.value = "";
                            }
                        }}
                        onFinishFailed={() => {}}
                        requiredMark={false}
                        scrollToFirstError={true}
                    >
                        <Form.Item name="format" label={t("manager.type")}>
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value={1}>{t("manager.normal")}</Radio.Button>
                                <Radio.Button value={2}>{t("manager.gp")}</Radio.Button>
                                <Radio.Button value={3}>{t("manager.threeRound")}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="finals" label={t("manager.finals")}>
                            <Radio.Group buttonStyle="solid">
                                <Radio.Button value={0}>{t("manager.noFinals")}</Radio.Button>
                                <Radio.Button value={1}>{t("manager.hasFinals")}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label={t("manager.compName")}
                            name="name"
                            rules={[
                                { required: true, message: t("manager.required") },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("manager.teamCount")}
                            name="teamCount"
                            rules={[
                                { required: true, message: t("manager.required") },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            label={t("manager.targetCount")}
                            name="targetCount"
                            rules={[
                                { required: true, message: t("manager.required") },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            label={t("manager.arrowCount")}
                            name="arrowCount"
                            rules={[
                                { required: true, message: t("manager.required") },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            label={" "}
                            name={"exceptions"}

                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.arrowCount !== currentValues.arrowCount
                            }
                        >
                            <ArrowCountExceptionInput categories={categories} ageGroups={getAgeGroupsWithFees().map(ag => ag.ageGroup)} />
                        </Form.Item>
                        <Form.Item
                        label={t("manager.tiebreakingrule")}
                        name={"tiebreaking"}
                        rules={[
                            {
                                required: true,
                                message: "Válassz",
                            },
                        ]}
                    >
                        <Select
                            data-cy="type-name-select"
                        >
                            <Select.Option key={"HDH"} value={"0,11,10,8,5,T"}>
                                HDH-IAA (0, 11, 10 ..)
                            </Select.Option>
                            <Select.Option key={"WA"} value={"11,10,8,5,0,T"}>
                                WA (11, 10, 8...)
                            </Select.Option>
                        </Select>
                    </Form.Item>
                        <Form.Item
                            label={t("manager.lunchFeeRule")}
                            name="lunchFee"
                            rules={[
                                { required: true, message: t("manager.required") },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>

                        <Form.Item
                            label="Deti štartovné | Deti do fee"
                            name="detiDoFee"
                            rules={[
                                { required: true, message: t("manager.required") },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            label="Kadeti štartovné | Kadet fee"
                            name="kadetFee"
                            rules={[
                                { required: true, message: t("manager.required") },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            label="Dospelí štartovné | Senior fee"
                            name="seniorFee"
                            rules={[
                                { required: true, message: t("manager.required") },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            label="Veteráni štartovné | Veteran fee"
                            name="veteranFee"
                            rules={[
                                { required: true, message: t("manager.required") },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label="XLS file">
                            {!urlParams.url && <input type={"file"} ref={excelInputRef} />}
                            {urlParams.url && <span>{urlParams.url}</span>}
                        </Form.Item>
                        <Button block type={"primary"} htmlType="submit">
                            {t("manager.import")}
                        </Button>
                    </Form>
                </Card>
            </Col>
            <Col span={10}>
                <Card title={t("manager.competitions")} bodyStyle={{ padding: "1px 0 0 0" }} extra={[<BackupModal key={"back"}/>]}>
                    <Table
                        dataSource={localCompetitions}
                        showHeader={false}
                    >
                        <Column
                            width={110}
                            title={t("manager.date")}
                            dataIndex="startDate"
                            key="startDate"
                        />
                        <Column
                            title={t("manager.name")}
                            dataIndex={"name"}
                            render={(text) => (
                                <Space>
                                    <span>{text}</span>
                                </Space>
                            )}
                        />
                        <Column
                            align={"right"}
                            key="action"
                            render={(text, record) => (
                                <Space size="middle">
                                    <Link to={`/manager-sk/${record.id}`}>
                                        <Button
                                            disabled={
                                                !networkStatus &&
                                                !localCompetitions.find(
                                                    (comp) => comp.id === record.id
                                                )
                                            }
                                        >
                                            <Col xs={24} sm={0}>
                                                <TableOutlined />
                                            </Col>
                                            <Col xs={0} sm={24}>
                                                {t("manager.office")}
                                            </Col>
                                        </Button>
                                    </Link>
                                    {record.rounds.length > 1 && <Button onClick={() => {
                                        setSelectedCompetitionId(record.id);
                                        setIsTeamUpdateModalVisible(true);
                                    }}>
                                        {t("manager.teams")}
                                    </Button>}
                                    <Popconfirm
                                        title={t("manager.deleteConfirm")}
                                        okText={t("manager.yes")}
                                        cancelText={t("manager.cancel")}
                                        onConfirm={async () => {
                                            await db.competitions.where("id")
                                                .equals(parseInt(record.id))
                                                .delete();
                                            await db.entries.where("competition")
                                                .equals(parseInt(record.id))
                                                .delete();

                                            db.competitions.toArray().then((lc) => setLocalCompetitions(lc));
                                        }}
                                        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                                    >
                                        <DeleteTwoTone twoToneColor="#ff0000" />
                                    </Popconfirm>
                                </Space>
                            )}
                        />
                    </Table>
                </Card>
            </Col>
        </Row>
        <Modal
            title={t("manager.updatingTeams")}
            visible={isTeamUpdateModalVisible}
            onOk={() => {
                setSelectedCompetitionId(null);
                setIsTeamUpdateModalVisible(false);

                let reader = new FileReader();
                reader.onload = async function (e) {
                    let workbook = XLSX.read(e.target.result, {
                        type: "binary",
                    });

                    let excelRows = XLSX.utils.sheet_to_json(
                        workbook.Sheets[workbook.SheetNames[0]],
                        {
                            header: "A",
                        }
                    );

                    let entries = excelRows.slice(2).filter(row => row.AJ).map(row => {
                        db.entries
                            .where("id")
                            .equals(parseInt(row.AJ))
                            .modify({
                                team: row.A,
                                teamIndex: row.C
                            });
                    });

                    console.log(entries);

                    Swal.fire({
                        title: t("manager.updateSuccess"),
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false,
                    });
                }
                reader.readAsBinaryString(teamInputRef.current.files[0]);
                teamInputRef.current.value = "";
            }}
            onCancel={() => {
                setSelectedCompetitionId(null);
                setIsTeamUpdateModalVisible(false);
            }}
            destroyOnClose={true}
            okText={t("manager.update")}
            cancelText={t("manager.cancel")}
        >
            {selectedCompetitionId && <div>{selectedCompetitionId} - {_.find(localCompetitions, {id: selectedCompetitionId}).name}</div>}
            <input type={"file"} ref={teamInputRef} />
        </Modal>
    </>
  );
};
