import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getCompetitions = async () => {
  const { data } = await axios(`${API_URL}/competition`);
  return data;
};

export const getCompetition = async (key, id) => {
  const { data } = await axios(`${API_URL}/competition/${id}`);
  return data;
};

export const getEntries = async (key, id) => {
  const { data } = await axios(`${API_URL}/competition/${id}/entry`);
  return data;
};

export const getEntryByModificationToken = async (key, modificationToken) => {
  const { data } = await axios(
    `${API_URL}/competition-entry/token/${modificationToken}`
  );
  return data;
};

export const insertEntry = async ({ competitionId, entry, forced = false }) => {
  const { data } = await axios.post(
    `${API_URL}/competition/${competitionId}/entry`,
    entry,
    forced
      ? {
          params: {
            forced: true,
          },
        }
      : {}
  );
  return data;
};

export const modifyEntry = async ({ modificationToken, entry }) => {
  const { data } = await axios.put(
    `${API_URL}/competition-entry/token/${modificationToken}`,
    entry
  );
  return data;
};

export const unregister = async (unregisterToken) => {
  return axios.delete(
    `${API_URL}/competition-entry/unregister/${unregisterToken}`
  );
};

export const getLatestScores = async () => {
  const { data } = await axios(`${API_URL}/latest-scores`);
  return data;
};

export const getUpcomingCompetitions = async () => {
  const { data } = await axios(`${API_URL}/upcoming-competitions`);
  return data;
};
