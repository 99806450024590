import React, {useState} from "react";
import {Card, Radio} from "antd";
import "./Kinetic.less";
import {create, all} from "mathjs";

export const Kinetic = () => {

    const math = create(all,  {});

    const [mass, setMass] = useState(0);
    const [massUnit, setMassUnit] = useState("grain");
    const [velocity, setVelocity] = useState(0);
    const [velocityUnit, setVelocityUnit] = useState("ft/s");

    const v2 = math.unit(velocity, velocityUnit)
    const m = math.unit(mass, massUnit)
    const k = math.multiply(0.5, math.multiply(math.pow(v2, 2), m))

    return <Card title={"Vesszőenergia kalkulátor"} className={"kinetic"}>
        <div>
            <div className={"value"}>
                <h3>Vessző tömege</h3>
                <div>
                    <input type={"number"} onChange={(e) => setMass(e.target.value)}/>
                    <Radio.Group
                        options={["grain", "gram"]}
                        onChange={(e) => setMassUnit(e.target.value)}
                        value={massUnit}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </div>
            </div>
            <div className={"value"}>
                <h3>Sebesség</h3>
                <div>
                    <input type={"number"} onChange={(e) => setVelocity(e.target.value)} />
                    <Radio.Group
                        options={["ft/s", "km/h", "m/s"]}
                        onChange={(e) => setVelocityUnit(e.target.value)}
                        value={velocityUnit}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </div>
            </div>

        </div>
        <div className={"result-box"}>
            <div className={"result-value " + (k.value > 165 ? "fail" : "pass")}>{math.format(k, 4)}</div>
            { k.value > 160 &&<div>Túllépi a HDH-IAA szerinti 165 joule limitet!</div>}
        </div>
    </Card>

}