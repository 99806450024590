import React from "react";
import { Competition } from "artemis-shared/competition/Competition";

import { useAuth } from "../auth/Auth";
import {
  addToCalendar,
  getCalendar,
  getUserData,
  removeFromCalendar,
} from "../api/User";

import { EntryEditor } from "../entryEditor/EntryEditor";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { getOwnEntries } from "../api/Competition";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "antd";
import { CalendarOutlined, CarryOutOutlined } from "@ant-design/icons";
import { useBreakpoint } from "artemis-shared/utils/Breakpoint";
import { getCompetition } from "artemis-shared/api/Competition";
import { ProfileInput } from "./ProfileInput";

import { Helmet } from "react-helmet";

export const CompetitionWithAuth = () => {
  const auth = useAuth();
  const { xl } = useBreakpoint();
  let { competitionId } = useParams();
  let competitionIdNumber = parseInt(competitionId);

  const cache = useQueryCache();

  const { data: competition, isFetching: isFetchingCompetition } = useQuery(
    ["competition", competitionIdNumber],
    getCompetition
  );

  const { data: userData } = useQuery(["userData"], getUserData, {
    enabled: auth.user,
  });

  const { data: ownEntries } = useQuery(
    ["ownentries", competitionIdNumber],
    getOwnEntries,
    { enabled: auth.user }
  );

  const { data: calendar } = useQuery("calendar", getCalendar, {
    enabled: auth.user,
  });

  const [addToCalendarMutate] = useMutation(addToCalendar, {
    onSuccess: () => {
      cache.invalidateQueries(["calendar"]);
      Swal.fire({
        title: "Hozzáadva a naptáradhoz!",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    },
  });

  const [removeFromCalendarMutate] = useMutation(removeFromCalendar, {
    onSuccess: () => {
      cache.invalidateQueries(["calendar"]);
      Swal.fire({
        title: "Eltávolítva a naptáradból!",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    },
  });

  const getCalendarButton = () => {
    if (auth.user && calendar && competition) {
      if (
        !calendar.find(
          (calendarCompetition) => calendarCompetition.id === competition.id
        )
      ) {
        return (
          <Button
            key={"add"}
            onClick={() => addToCalendarMutate(competition.id)}
          >
            {xl ? "Felvétel a versenynaptáradba" : <CarryOutOutlined />}
          </Button>
        );
      } else {
        return (
          <Button
            key={"remove"}
            danger
            onClick={() => removeFromCalendarMutate(competition.id)}
          >
            {xl ? "Eltávolítás a versenynaptáradból" : <CalendarOutlined />}
          </Button>
        );
      }
    }
  };

  const getEntryEditor = () => {
    return <EntryEditor entries={ownEntries} competition={competition} />;
  };

  const getProfileInput = (form) => {
    return (
      <ProfileInput
        profiles={userData.profiles}
        onSelect={(profile) => {
          form.setFieldsValue({
            profile: profile.id,
            gender: profile.gender,
            club: profile.club.id,
            permitNumber:
              form.getFieldValue("permitNumber") === -1 || form.getFieldValue("permitNumber") === -2
                  ? form.getFieldValue("permitNumber")
                  : profile.permitNumber > 0 ? profile.permitNumber : null,
            email: userData.email,
          });
        }}
      />
    );
  };

  return (
    <>
      {competition && (
        <Helmet>
          <title>{competition.name} - Íjász.NET</title>
          <meta
            property="og:title"
            content={competition.name + " - Íjász.NET"}
          />
          {competition.stage === "SCORED" && (
            <meta
              property="og:image"
              content="https://ijasz.net/fb_banner_result.png"
            />
          )}
        </Helmet>
      )}
      <Competition
        userData={userData}
        ownEntries={ownEntries}
        calendarButton={getCalendarButton()}
        entryEditor={getEntryEditor()}
        getProfileInput={getProfileInput}
      />
    </>
  );
};
