import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  Form,
  InputNumber,
  Modal,
  Select,
  Space,
  Table,
} from "antd";
import { generatePDF } from "../utils/StickerGenerator";
import _ from "lodash";
import {useTranslation} from "react-i18next";

export const StickerModal = ({
  competition,
  entries,
  isModalVisible,
  setIsModalVisible,
}) => {
  const { t } = useTranslation();
  const [pdfData, setPdfData] = useState("");
  const [qrPerEntry, setQrPerEntry] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState(
    entries.map((entry) => entry.id)
  );

  const [form] = Form.useForm();

  const templates = [
    {
      id: 0,
      name: "Egyedi",
      config: {
        stickerWidth: null,
        stickerHeight: null,
        qrWidth: null,
        qrRightMargin: null,
        stickerTopPadding: null,
        stickerLeftPadding: null,
        rowsPerPage: null,
        colsPerPage: null,
        titleFontSize: null,
        subtitleFontSize: null,
        titleLineHeight: null,
        subtitleLineHeight: null,
        bordered: false,
        showTeam: true,
        skip: 0
      },
    },
    {
      id: 1,
      name: "70 x 37 mm - 24 / oldal - margó nélkül",
      config: {
        stickerWidth: 70,
        stickerHeight: 37.1,
        qrWidth: 20,
        qrRightMargin: 3,
        stickerTopPadding: 6.5,
        stickerLeftPadding: 4,
        rowsPerPage: 8,
        colsPerPage: 3,
        titleFontSize: 12,
        subtitleFontSize: 10,
        titleLineHeight: 16,
        subtitleLineHeight: 4,
        bordered: true,
        showTeam: true,
        skip: 0
      },
    },
    {
      id: 4,
      name: "70 x 37 mm - 24 / oldal - margó nélkül, nagyobb QR",
      config: {
        stickerWidth: 70,
        stickerHeight: 37.1,
        qrWidth: 27,
        qrRightMargin: 0,
        stickerTopPadding: 6.5,
        stickerLeftPadding: 4,
        rowsPerPage: 8,
        colsPerPage: 3,
        titleFontSize: 12,
        subtitleFontSize: 10,
        titleLineHeight: 16,
        subtitleLineHeight: 4,
        bordered: true,
        showTeam: true,
        skip: 0
      },
    },
    {
      id: 2,
      name: "48.5 x 25.4 mm - 44 / oldal - margóval",
      config: {
        stickerWidth: 48.5,
        stickerHeight: 25.3,
        qrWidth: 17,
        qrRightMargin: 0,
        stickerTopPadding: 2,
        stickerLeftPadding: 3,
        rowsPerPage: 11,
        colsPerPage: 4,
        titleFontSize: 10,
        subtitleFontSize: 7,
        titleLineHeight: 16,
        subtitleLineHeight: 3,
        bordered: true,
        showTeam: true,
        skip: 0
      },
    },
    {
      id: 3,
      name: "48.5 x 25.4 mm - 40 / oldal - margóval",
      config: {
        stickerWidth: 48.5,
        stickerHeight: 25.2,
        qrWidth: 17,
        qrRightMargin: 0,
        stickerTopPadding: 2,
        stickerLeftPadding: 3,
        rowsPerPage: 10,
        colsPerPage: 4,
        titleFontSize: 10,
        subtitleFontSize: 7,
        titleLineHeight: 16,
        subtitleLineHeight: 3,
        bordered: true,
        showTeam: true,
        skip: 0
      },
    },
    {
      id: 5,
      name: "38.1 x 21.2 mm - 65 / oldal - margóval",
      config: {
        stickerWidth: 38.1,
        stickerHeight: 21.2,
        qrWidth: 15,
        qrRightMargin: 1,
        stickerTopPadding: 2,
        stickerLeftPadding: 2,
        rowsPerPage: 13,
        colsPerPage: 5,
        titleFontSize: 9,
        subtitleFontSize: 5.5,
        titleLineHeight: 12,
        subtitleLineHeight: 3,
        bordered: true,
        showTeam: true,
        skip: 0
      },
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  return (
    <Modal
      title="QR Matricák generálása"
      visible={isModalVisible}
      onOk={() => setIsModalVisible(false)}
      onCancel={() => setIsModalVisible(false)}
      width={1200}
      footer={false}
      destroyOnClose={true}
    >
      <div style={{ display: "flex" }}>
        <div style={{ width: "400px" }}>
          <Select
            placeholder={t("manager.chooseTemplate")}
            style={{ width: "100%", marginBottom: "16px" }}
            onChange={(templateId) =>
              form.setFieldsValue(_.find(templates, { id: templateId }).config)
            }
          >
            {templates.map((template) => (
              <Select.Option key={template.id} value={template.id}>
                {template.name}
              </Select.Option>
            ))}
          </Select>
          <Select
            value={qrPerEntry}
            placeholder={t("manager.stickerPerCompetitor")}
            style={{ width: "100%", marginBottom: "16px" }}
            onChange={setQrPerEntry}
          >
            <Select.Option key={1} value={1}>
              1
            </Select.Option>
            <Select.Option key={2} value={2}>
              2
            </Select.Option>
            <Select.Option key={3} value={3}>
              3
            </Select.Option>
            <Select.Option key={4} value={4}>
              4
            </Select.Option>
            <Select.Option key={5} value={5}>
              5
            </Select.Option>
            <Select.Option key={6} value={6}>
              6
            </Select.Option>
            <Select.Option key={7} value={7}>
              7
            </Select.Option>
            <Select.Option key={8} value={8}>
              8
            </Select.Option>
            <Select.Option key={9} value={9}>
              9
            </Select.Option>
            <Select.Option key={10} value={10}>
              10
            </Select.Option>
          </Select>
          <Select
            value={sortBy}
            placeholder={t("manager.sortBy")}
            style={{ width: "100%", marginBottom: "16px" }}
            onChange={setSortBy}
          >
            <Select.Option key={1} value={"name"}>
              {t("manager.abc")}
            </Select.Option>
            <Select.Option key={2} value={"team"}>
              {t("manager.byTeam")}
            </Select.Option>
          </Select>
          <Form
            form={form}
            name="basic"
            size={"small"}
            requiredMark={false}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            initialValues={{
              stickerWidth: null,
              stickerHeight: null,
              qrWidth: null,
              qrRightMargin: null,
              stickerTopPadding: null,
              stickerLeftPadding: null,
              rowsPerPage: null,
              colsPerPage: null,
              titleFontSize: null,
              subtitleFontSize: null,
              titleLineHeight: null,
              subtitleLineHeight: null,
              bordered: false,
            }}
            onFinish={(values) => {}}
          >
            <Form.Item
              label="Sorok száma"
              name="rowsPerPage"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Oszlopok száma"
              name="colsPerPage"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Matrica szélesség"
              name="stickerWidth"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Matrica magasság"
              name="stickerHeight"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Matrica felső margó"
              name="stickerTopPadding"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Matrica oldalsó margó"
              name="stickerLeftPadding"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="QR kód szélesség"
              name="qrWidth"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="QR jobb margó"
              name="qrRightMargin"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Név betűméret"
              name="titleFontSize"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Név sormagasság"
              name="titleLineHeight"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Kategória betűméret"
              name="subtitleFontSize"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Kategória sormagasság"
              name="subtitleLineHeight"
              rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
                label="Üresen hagyott matricák"
                name="skip"
                rules={[{ required: true, message: "" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              name="bordered"
              valuePropName="checked"
              wrapperCol={{ offset: 12, span: 12 }}
            >
              <Checkbox>{t("manager.border")}</Checkbox>
            </Form.Item>

            <Form.Item
                name="showTeam"
                valuePropName="checked"
                wrapperCol={{ offset: 12, span: 12 }}
            >
              <Checkbox>{t("manager.teamNumber")}</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Space>
                <Button
                  block
                  disabled={qrPerEntry === null || sortBy === null}
                  htmlType={"submit"}
                  onClick={() =>
                    form.validateFields().then((values) => {
                      generatePDF(
                        entries.filter(
                          (entry) => selectedRowKeys.indexOf(entry.id) > -1
                        ),
                        competition,
                        values,
                        false,
                        qrPerEntry,
                        sortBy,
                          t
                      ).then((data) => setPdfData(data));
                    })
                  }
                >
                  {t("manager.preview")}
                </Button>
                <Button
                  block
                  disabled={qrPerEntry === null || sortBy === null}
                  htmlType={"submit"}
                  type={"primary"}
                  onClick={() =>
                    form.validateFields().then((values) => {
                      generatePDF(
                        entries.filter(
                          (entry) => selectedRowKeys.indexOf(entry.id) > -1
                        ),
                        competition,
                        values,
                        true,
                        qrPerEntry,
                        sortBy,
                          t
                      ).then((data) => setPdfData(data));
                    })
                  }
                >
                  {t("manager.download")}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
        <div>
          <iframe
            src={pdfData}
            width="800px"
            height="600px"
            type="application/pdf"
          />

          <Collapse>
            <Collapse.Panel header={t("manager.selectEntries")} key="1">
              <Table
                rowKey={(row) => row.id}
                pagination={false}
                dataSource={_.sortBy(
                  entries,
                  sortBy === "team" ? ["team", "teamIndex", "id"] : ["name"]
                )}
                size={"small"}
                rowSelection={{
                  selectedRowKeys,
                  onChange: onSelectChange,
                }}
              >
                <Table.Column dataIndex={"team"} title={"Cs."} />
                <Table.Column dataIndex={"name"} title={"Név"} />
                <Table.Column
                  dataIndex={["club", "name"]}
                  title={"Egyesület"}
                />
                <Table.Column
                  dataIndex={["category", "name"]}
                  title={"Kategória"}
                />
                <Table.Column
                  dataIndex={["ageGroup", "ageGroup", "name"]}
                  title={"Korosztály"}
                />
                <Table.Column dataIndex={["gender"]} title={"Nem"} />
              </Table>
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
    </Modal>
  );
};
