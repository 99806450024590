import React, { useState } from "react";
import { Button, PageHeader, Space, Switch } from "antd";
import { useMutation } from "react-query";
import { updateTeams } from "../api/Competition";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "../fonts/Roboto-Regular-normal";
import "../fonts/Roboto-Bold-bold";
import { downloadFile } from "../utils/DownloadFile";
import { useInterval } from "../utils/useInterval";
import {useTranslation} from "react-i18next";

export const TeamsPageHeader = ({
  competition,
  entries,
  published,
  setPublished,
    teamLabels
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  let { competitionId } = useParams();

  const [autoSave, setAutoSave] = useState(false);

  useInterval(() => {
    console.log("trigger autosave");
    if (autoSave) {
      save();
    }
  }, 60000);

  const [updateEntriesMutation, { isLoading: isLoadingUpdate }] = useMutation(
    updateTeams,
    {
      onSuccess: () => {
        Swal.fire({
          title: "Sikeres mentés!",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      },
    }
  );

  const save = () => {
    updateEntriesMutation({
      competitionId: parseInt(competitionId),
      entries: Object.values(entries)
        .reduce(
          (acc, arr) => [
            ...acc,
            ...arr.reduce((acc, group) => [...acc, ...group.entries], []),
          ],
          []
        )
        .map(({ id, team, teamIndex, groupId, groupIndex }) => ({
          id,
          team,
          teamIndex,
          groupId,
          groupIndex,
        })),
      published: published,
      teamLabels: teamLabels.join(";")
    });
  };

  const generatePdf = () => {
    var doc = new jsPDF();
    //doc.text(competition.name + " - Csapatbeosztás", 20, 20);

    Object.entries(entries).forEach(([team, teamEntries]) => {
      if (team == 0) return;

      doc.autoTable({
        pageBreak: "avoid",
        bodyStyles: { font: "Roboto-Regular" },
        headStyles: { font: "Roboto-Bold" },
        columnStyles: {
          0: { cellWidth: 60 },
          1: { cellWidth: competition.type === "HISTORICAL" ? 43 : 20 },
          2: { cellWidth: 20 },
          3: { cellWidth: 20 },
        },
        head: [
          [
            {
              content: (competition.teamLabels?.split(";")[team - 1] || team) + ". csapat",
              colSpan: 5,
              styles: { halign: "center", fontSize: 14 },
            },
          ],
          //["Név", "Kategória", "Korosztály", "Nem", "Egyesület"],
        ],
        body: teamEntries
          .reduce((acc, ent) => acc.concat(ent.entries), [])
          .map((entry) => [
            entry.name,
            t(entry.category.name, competition.international ? {lng: "en"} : {}),
            t(entry.ageGroup.ageGroup.name, competition.international ? {lng: "en"} : {}),
            t(entry.gender, competition.international ? {lng: "en"} : {}),
            competition.international ? (entry.country && t("country." + entry.country)) || "-" : entry.club.name,
          ]),
        //startY: parseInt(team) == 1 ? 30 : undefined,
      });
    });
    downloadFile(doc.output("blob"), `${competition.slug}_csapatbeosztas.pdf`);
  };

  return (
    <PageHeader
      className="site-page-header"
      onBack={() => history.push("/manager-dashboard")}
      title={`${competition.name} - Csapatbeosztás`}
      extra={[
        <Space key={"auto"}>
          <span key={"autos"}>Automatikus mentés percenként</span>
          <Switch
            key={"autosavecheck"}
            checkedChildren="Bekapcsolva"
            unCheckedChildren="Kikapcsolva"
            checked={autoSave}
            onChange={(checked) => setAutoSave(checked)}
          />
          <span> | </span>
          <Switch
            key={"published"}
            checkedChildren="Nyilvános"
            unCheckedChildren="Privát"
            checked={published}
            onChange={(checked) => setPublished(checked)}
          />
        </Space>,
        <Button
          id="save-teams-button"
          key="2"
          type="primary"
          loading={isLoadingUpdate}
          onClick={() => {
            save();
          }}
        >
          Mentés
        </Button>,
        <Button key={"pdf"} onClick={generatePdf}>
          PDF Letöltése
        </Button>,
      ]}
    />
  );
};
