import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const checkPermit = async (key, permitNumber) => {
  const { data } = await axios.get(`${API_URL}/permit/${permitNumber}`);
  return data;
};

export const checkPermitAndName = async ({ permitNumber, name }) => {
  const { data } = await axios.post(`${API_URL}/permit/checkPermitAndName`, {
    permitNumber,
    name,
  });
  return data;
};

export const createPermit = async ({ name }) => {
  const { data } = await axios.post(`${API_URL}/permit/createPermit`, {
    name,
  });
  return data;
};
