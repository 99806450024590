import React, { useEffect, useState } from "react";
import { BellFilled } from "@ant-design/icons";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { getSubscription, sendSubscription } from "../api/Notification";
import { Modal, Space, Switch, Typography } from "antd";

export const NotificationMenu = ({ serviceWorker }) => {
  const cache = useQueryCache();
  const [mutateSubscription] = useMutation(sendSubscription, {
    onSuccess: () =>
      cache.invalidateQueries(["notification-subscription", key]),
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [permission, setPermission] = useState(Notification.permission);
  const [key, setKey] = useState(null);

  useEffect(() => {
    serviceWorker &&
      serviceWorker.pushManager.getSubscription().then((subscription) => {
        const subObj = JSON.parse(JSON.stringify(subscription));
        if (subscription) {
          setKey(subObj.keys.p256dh);
        }
      });
  }, []);

  const { data: notificationSubscription } = useQuery(
    ["notification-subscription", key],
    getSubscription,
    { enabled: key }
  );

  const urlBase64ToUint8Array = (base64String) => {
    var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

    var rawData = atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  const sendSubscriptionToBackEnd = (subscription) => {
    const subObj = JSON.parse(subscription);
    console.log("send subscription", subObj);
    mutateSubscription({
      auth: subObj.keys.auth,
      endpoint: subObj.endpoint,
      p256dh: subObj.keys.p256dh,
    })
      .then((result) => {
        console.log("succ");
        setKey(subObj.keys.p256dh);
      })
      .catch((err) => console.log("failed", err));
  };

  const askNotificationPermission = () => {
    return new Promise(function (resolve, reject) {
      const permissionResult = Notification.requestPermission(function (
        result
      ) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function (permissionResult) {
      setPermission(permissionResult);
      if (permissionResult !== "granted") {
        throw new Error("We weren't granted permission.");
      }
    });
  };

  const notificationOnClick = () => {
    askNotificationPermission()
      .then(() => {
        console.log("Notification granted");

        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            "BMFtXdoBTQ9IBQ2gQPM7VU7HGwPuFjaUrupubsStOObUIzC3_b2MrXEHLEKa4rb0GqOi18Ncl5EFn3A7_BfdP78"
          ),
        };

        serviceWorker.pushManager
          .subscribe(subscribeOptions)
          .then((pushSubscription) => {
            console.log(
              "Received PushSubscription: ",
              JSON.stringify(pushSubscription)
            );

            sendSubscriptionToBackEnd(JSON.stringify(pushSubscription));

            return pushSubscription;
          });
      })
      .catch((e) => console.log(e));
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onToggleChange = (param, value) => {
    mutateSubscription({
      ...notificationSubscription,
      [param]: value,
    });
  };

  const notifications = [
    {
      id: "newCompetition",
      label: "Új verseny felvételéről",
    },
    {
      id: "appliedCompetitionUpdate",
      label: "Módosul a verseny amire neveztél",
    },
    {
      id: "teamsUpdate",
      label: "Csapatbeosztás feltöltésekor, változásakor",
    },
    {
      id: "resultUpload",
      label: "Versenyeredmény feltöltésekor",
    },
    {
      id: "targetsUpload",
      label: "Távok feltöltésekor",
    },
    {
      id: "applicationDeadline",
      label: "Naptáradban lévő verseny nevezési határideje előtt",
    },
    {
      id: "rankingChange",
      label: "Ranglista pozíció megváltozásakor",
    },
  ];

  console.log("notif", notificationSubscription);

  return (
    <>
      <div onClick={() => setIsModalVisible(true)}>
        <BellFilled /> Értesítések
      </div>
      <Modal
        title="Értesítések"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Typography.Title level={4}>Értesítések engedélyezése</Typography.Title>
        <div>(Minden eszközön külön kell engedélyezni az értesítéseket)</div>
        <br />
        <div>
          <Space>
            <Switch
              checked={
                notificationSubscription && notificationSubscription.active
              }
              onChange={(checked) => {
                if (checked) {
                  if (!notificationSubscription) {
                    notificationOnClick();
                  } else {
                    mutateSubscription({
                      ...notificationSubscription,
                      active: true,
                    });
                  }
                } else {
                  mutateSubscription({
                    ...notificationSubscription,
                    active: false,
                  });
                }
              }}
            />
            Engedélyezés
          </Space>
        </div>
        <br />
        <Typography.Title level={4}>
          Mikről szeretnél értesítést kapni?
        </Typography.Title>
        <div>
          <Space>
            <Switch
              disabled={permission !== "granted"}
              checked={
                notificationSubscription && notificationSubscription.allEnabled
              }
              onChange={(checked) => {
                if (checked) {
                  mutateSubscription({
                    ...notificationSubscription,
                    allEnabled: true,
                    newCompetition: true,
                    appliedCompetitionUpdate: true,
                    teamsUpdate: true,
                    resultUpload: true,
                    targetsUpload: true,
                    applicationDeadline: true,
                    rankingChange: true,
                  });
                } else {
                  mutateSubscription({
                    ...notificationSubscription,
                    allEnabled: false,
                  });
                }
              }}
            />
            Mindenről
          </Space>
        </div>
        <br />
        <Typography.Title level={4}>Csak az alábbiakról</Typography.Title>
        {notifications.map((notification) => (
          <div key={notification.id}>
            <Space>
              <Switch
                disabled={
                  permission !== "granted" ||
                  (notificationSubscription &&
                    notificationSubscription.allEnabled)
                }
                checked={
                  notificationSubscription &&
                  notificationSubscription[notification.id]
                }
                onChange={(checked) => onToggleChange(notification.id, checked)}
              />
              {notification.label}
            </Space>
          </div>
        ))}
      </Modal>
    </>
  );
};
