import React from "react";
import { Area } from "@ant-design/charts";
import moment from "moment";
import _ from "lodash";

// eslint-disable-next-line react/display-name
export const ScoreAverageChart = React.memo(
  ({ data }) => {
    const chartData = data.map((target) => ({
      target: target.target,
      scoreAverage: target.scoreAverage,
    }));

    const config = {
      height: 200,
      smooth: true,
      data: chartData,
      xField: "target",
      yField: "scoreAverage",
      xAxis: {
        label: {
          formatter: function formatter(value) {
            return parseFloat(value) + 1;
          },
        },
      },
      yAxis: {
        min: chartData
          ? Math.max(_.minBy(chartData, "scoreAverage").scoreAverage - 0.3, 0)
          : 0,
        max: chartData
          ? _.maxBy(chartData, "scoreAverage").scoreAverage + 0.3
          : 10,
        label: {
          formatter: function formatter(value) {
            return parseFloat(value).toFixed(2);
          },
        },
      },
      areaStyle: function areaStyle() {
        return { fill: "#0a7400" };
      },
      line: {
        color: "#0a7400",
      },
      tooltip: {
        formatter: (data) => {
          return {
            name: "Vesszőátlag",
            value: data.scoreAverage.toFixed(2),
            title: data.target + 1 + ". cél",
          };
        },
      },
    };

    return <Area {...config} />;
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
);
