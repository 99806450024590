import { Select } from "antd";
import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const { Option } = Select;

export const CategoryInput = ({ categories, ...restProps }) => {
  const {t} = useTranslation();
  return (
    <Select {...restProps} listHeight={385} dropdownMatchSelectWidth={270}>
      {categories
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((category) => (
          <Option key={category.id} value={category.id}>
            {t(category.name)}{category.description ? ` (${category.description})` : ""}
          </Option>
        ))}
    </Select>
  );
};

CategoryInput.propTypes = {
  categories: PropTypes.array,
};
