import React from "react";
import { Tag } from "antd";
import {useTranslation} from "react-i18next";

export const GenderTag = ({ gender }) => {
  const { t } = useTranslation();
  return (
    <>
      {{
        MALE: <Tag color={"blue"}>{t("genderTag.MALE")}</Tag>,
        Male: <Tag color={"blue"}>Male</Tag>,
        FEMALE: <Tag color={"magenta"}>{t("genderTag.FEMALE")}</Tag>,
        Female: <Tag color={"magenta"}>Female</Tag>,
        Férfi: <Tag color={"blue"}>{t("genderTag.MALE")}</Tag>,
        Nő: <Tag color={"magenta"}>{t("genderTag.FEMALE")}</Tag>,
      }[gender] || <Tag color={"blue"}>{gender}</Tag>}
    </>
  );
};
