import React from "react";
import { Table } from "antd";

const { Column } = Table;

export const Qualifications = ({ qualifications }) => {
  return (
    <Table
      dataSource={qualifications}
      pagination={false}
      locale={{ emptyText: "Nincs megszerzett minősítés" }}
    >
      <Column title="Év" dataIndex="year" key="year" />
      <Column title="Szakág" dataIndex="discipline" key="discipline" />
      <Column
        title="Minősítés"
        dataIndex="qualification"
        key="qualification"
        render={(value) => value + ". osztály"}
      />
    </Table>
  );
};
