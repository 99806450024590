import React, {useEffect, useState} from "react";
import { useQuery } from "react-query";
import {
  getManagedCompetitions,
  getManagedCompetitionsMisz,
} from "../api/Competition";
import { Card, Col, Button, PageHeader, Space, Table } from "antd";
import { Link } from "react-router-dom";
import {
  SettingOutlined,
  UserOutlined,
  TableOutlined,
  SaveOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router";
import { db } from "../utils/LocalDB";
import { useNetworkStatus } from "../networkStatus/NetworkStatus";
import { CompetitionTypeTag } from "artemis-shared/tag/CompetitionTypeTag";
import { useAuth } from "../auth/Auth";
import { getUserData } from "../api/User";
import {useTranslation} from "react-i18next";
import {BackupModal} from "../manager/BackupModal";

export const ManagerDashboard = () => {
  const {t} = useTranslation();
  const { Column } = Table;

  let history = useHistory();
  let networkStatus = useNetworkStatus();

  const auth = useAuth();

  console.log(auth);

  const [localCompetitions, setLocalCompetitions] = useState([]);

  useEffect(() => {
    db.competitions.toArray().then((lc) => setLocalCompetitions(lc));
  }, []);

  const { data, isLoading } = useQuery(
    "managedcompetitions",
    getManagedCompetitions,
    { enabled: networkStatus }
  );

  const { data: userData } = useQuery(["userData"], getUserData, {
    enabled: auth.user && networkStatus,
  });

  const { data: miszData, isLoading: miszLoading } = useQuery(
    "managedcompetitionsmisz",
    getManagedCompetitionsMisz,
    { enabled: userData && userData.misz }
  );

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/")}
        title={"Vezérlőpult"}
        extra={[
          <Link key={"create"} to={"/competition/create"}>
            <Button id="create-competition-button" key="2" type="primary">
              Új verseny
            </Button>
          </Link>,
            <BackupModal key={"backup"}/>
        ]}
      />
      <Card title={"Versenyek"} bodyStyle={{ padding: "1px 0 0 0" }}>
        <Table
          dataSource={
            networkStatus
              ? userData?.misz
                ? miszData
                : data
              : localCompetitions
          }
          loading={isLoading || miszLoading}
          showHeader={false}
        >
          <Column
            width={110}
            title="Dátum"
            dataIndex="startDate"
            key="startDate"
          />
          <Column
            width={90}
            title="Típus"
            dataIndex="type"
            key="type"
            render={(type) => <CompetitionTypeTag type={type} />}
          />
          <Column
            width={90}
            title="MISZ"
            dataIndex="miszApproved"
            key="miszApproved"
            render={(type) => (type ? <span>MISZ</span> : <span>-</span>)}
          />
          <Column
            title={"Név"}
            dataIndex={"name"}
            render={(text, record) => (
              <Space>
                {localCompetitions.find((comp) => comp.id === record.id) && (
                  <SaveOutlined />
                )}
                <span>{text}</span>
              </Space>
            )}
          />
          <Column
            align={"right"}
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <Link to={`/competition/edit/${record.id}`}>
                  <Button disabled={!networkStatus} className={"edit-button"}>
                    <Col xs={24} sm={0}>
                      <SettingOutlined />
                    </Col>
                    <Col xs={0} sm={24}>
                      Szerkesztés
                    </Col>
                  </Button>
                </Link>
                {record.stage != "INFO" && record.stage != "EXTERNAL_SCORED" && (
                  <>
                    <Link
                      to={`/manage-competition/${record.id}/${record.slug}`}
                    >
                      <Button disabled={!networkStatus}>
                        <Col xs={24} sm={0}>
                          <UserOutlined />
                        </Col>
                        <Col xs={0} sm={24}>
                          Nevezők
                        </Col>
                      </Button>
                    </Link>
                    <Link to={`/manage-teams/${record.id}/${record.slug}`}>
                      <Button disabled={!networkStatus}>
                        <Col xs={24} sm={0}>
                          <UsergroupAddOutlined />
                        </Col>
                        <Col xs={0} sm={24}>
                          Csapatbeosztás
                        </Col>
                      </Button>
                    </Link>
                    <Link to={`/manager/${record.id}/${record.slug}`}>
                      <Button
                        disabled={
                          !networkStatus &&
                          !localCompetitions.find(
                            (comp) => comp.id === record.id
                          )
                        }
                      >
                        <Col xs={24} sm={0}>
                          <TableOutlined />
                        </Col>
                        <Col xs={0} sm={24}>
                          Versenyiroda
                        </Col>
                      </Button>
                    </Link>
                    <Link to={`/targets/${record.id}/${record.slug}`}>
                      <Button disabled={!networkStatus}>
                        <Col xs={24} sm={0}>
                          <UsergroupAddOutlined />
                        </Col>
                        <Col xs={0} sm={24}>
                          Célok
                        </Col>
                      </Button>
                    </Link>
                  </>
                )}
              </Space>
            )}
          />
        </Table>
      </Card>
    </>
  );
};
