import React from "react";
import { Col, Typography } from "antd";
import { Link } from "react-router-dom";

export const Privacy = () => {
  return (
    <Col xs={24} lg={{ span: 18, offset: 3 }} xl={{ span: 12, offset: 6 }}>
      <Typography.Title level={2}>Adatvédelmi irányelvek</Typography.Title>
      <p>Az Ön által megadott személyes adatokat kezeli:</p>
      <p>
        <strong>Íjász.NET – Király Dávid</strong>
      </p>
      <p>
        a továbbiakban: <strong>Adatkezelő.</strong>
      </p>
      <p>
        Kérjük, figyelmesen olvassa el jelen adatkezelési tájékoztatónkat (a
        továbbiakban: <strong>Tájékoztató</strong>), melyben személyes adatai
        kezelését érintő gyakorlatunkat ismertetjük az Általános Adatvédelmi
        Rendelet (a GDPR, azaz az Európai Parlament és Tanács 2016/679.
        rendelete) szerint. Ez a Tájékoztató az Adatkezelő szolgáltatásait
        fogyasztóként igénybe vevő személyekre (Ön) vonatkozik. Bemutatja, hogy
        az Adatkezelő hogyan gyűjti, használja fel és osztja meg bizonyos
        esetekben harmadik felekkel az Ön személyes adatait, továbbá
        információval szolgál az Ön adatkezeléssel összefüggő „érintetti”
        jogairól.
      </p>
      <p>
        Az Adatkezelő elkötelezett e honlapot felkereső egyének személyes
        adatainak és magánéletének védelme iránt. Jelen tájékoztató és
        nyilatkozat mindenekelőtt az ügyfelek személyes adatainak interneten
        keresztül történő bizalmas kezeléséről és védelméről szól, mindazonáltal
        ide tartoznak az oldal látogatói is. Összefoglaljuk, hogy a tulajdonos
        milyen Önre vonatkozó adatokat hogyan gyűjthet és használhat fel,
        illetve milyen módon használhatják fel. Az adatok illetéktelen személyek
        részére nem kerülnek átadásra és ezen tájékoztatóban meghatározott
        formában kerülnek felhasználásra.
      </p>
      <p>
        A tájékoztató fontos információt nyújt Önnek személyes adatai védelméről
        és ehhez fűződő jogairól. Ha a felhasználó nem fogadja el ezen
        feltételeket, jogában áll a honlap használatát felfüggeszteni és
        személyes adat megadása nélkül a böngészést befejezni.
      </p>
      <Typography.Title level={5}>
        Adatkezeléssel kapcsolatos információk
      </Typography.Title>
      <p>
        Jelen Tájékoztatóban ismertetett adatkezelések tekintetében Király Dávid
        az adatkezelő (a továbbiakban: <strong>Adatkezelő</strong>).
      </p>
      <p>
        Adatvédelmi eljárásainkat érintő észrevétel, kérdés vagy panasz esetén
        az alábbi elérhetőségeken vagyunk elérhetőek:{" "}
        <strong>Király Dávid, 3525 Miskolc, Közdomb utca 17.</strong> vagy írjon
        az <strong>info@ijasz.net</strong> e-mail címre.
      </p>
      <p>
        <strong>Adatkezelő:</strong> Király Dávid 3525 Miskolc, Közdomb utca 17.
        <strong> Felelősségi körök:</strong> Összes tevékenység
      </p>
      <p>
        Választásának megfelelően Ön személyes adatai megadása nélkül is
        jogosult hozzáférni weboldalainkhoz, viszont a böngészéshez
        elengedhetetlen a süti - cookie szabályzat elfogadása, regisztráció,
        illetve versenyre nevezés esetén pedig az adatvédelmi tájékoztató és
        nyilatkozat jóváhagyása. Személyes adat az összes információ, amely
        közvetett vagy közvetlen módon, azonosító szám szerint vagy az ehhez
        tartozó adatok alapján azonosítható természetes személyre vonatkozik.
        Abban az esetben, hogyha az oldal Tulajdonosa az Ön személyes adatait
        gyűjti, az adatgyűjtés átlátható és bizalmas adatkezeléssel történik.
      </p>
      <p>
        Az Ön személyes adatai olyan információk, melyek segítségével az Ön
        személye beazonosítható, például: vezetéknév, utónév, állampolgárság,
        telefonszám, postacím, e-mail cím. Az Ön személyes adatait önkéntesen
        megadott módon, a tulajdonos az oldalon történő regisztrációkor,
        valamint versenyre nevezéskor gyűjti, megjelölve minden esetben a cél
        eléréséhez szükségszerűen megadandó adatokat. Ha nem kívánja megadni
        ezen adatokat, akkor nem férhet hozzá a honlap egyes funkcióihoz,
        szolgáltatásaihoz.
      </p>
      <p>
        A Tulajdonos minden intézkedést megtesz, hogy az általa kezelt személyes
        adatok pontosak, naprakészek legyenek.
      </p>
      <Typography.Title level={5}>
        Az alábbi személyes adatok kezelése történik:
      </Typography.Title>
      <p>
        <ul>
          <li>Vezetéknév, keresztnév</li>
          <li>E-mail cím</li>
          <li>A Magyar Íjász Szövetség által kiadott versenyengedély száma</li>
          <li>Egyesületi tagságra vonatkozó adatok</li>
          <li>Az oldalon meghirdetett versenyeken elért eredmények</li>
          <li>Rendszer információk (IP-cím, rendszer verzió, felbontás)</li>
        </ul>
      </p>
      <Typography.Title level={5}>Adatkezelés időtartama:</Typography.Title>
      <p>
        <ul>
          <li>A felhasználó hozzájárulásának visszavonásáig</li>
          <li>
            Profiladatok tekintetében az utolsó bejelentkezéstől számított 4 év
          </li>
        </ul>
      </p>
      <Typography.Title level={5}>Adattovábbítás feltételei:</Typography.Title>
      <p>
        Az Adatkezelő az általa kezelt adatokat - a szükséges mértékben -
        továbbíthatja a következő területeken tevékenykedő, általa kijelölt
        személyek, valamint társaságok részére:
      </p>
      <p>
        <ul>
          <li>Jogi képviselet</li>
          <li>Jogviták kezelésére jogszabály alapján jogosult szervek</li>
        </ul>
      </p>
      <Typography.Title level={5}>Adatfeldolgozók:</Typography.Title>
      <p>
        Az oldalon meghirdetett versenyekre történő nevezéskor, az Adatkezelő a
        Felhasználó nevét, e-mail címét, a Magyar Íjász Szövetség által kiadott
        versenyengedélyének számát, a sportegyesületének nevét, valamint a
        nevezéskor megadott további adatokat (választott kategória, korosztály,
        nem, megjegyzés), továbbítja a verseny szervezőjének, a versenyen
        történő azonosítás, és a verseny lebonyolítása céljából.
      </p>
      <p>
        A leadott verseny nevezések adatai továbbá megjelenítésre kerülnek a
        verseny nyilvános adatlapján (a Felhasználó neve, választott
        kategóriája, korosztálya, neme, a Magyar Íjász Szövetség által kiadott
        versenyengedélyének száma, egyesületének neve és a versenyen elért
        eredménye). Ezek az adatok összesítve is megjelennek az oldalon szereplő
        nyilvános ranglistákon.
      </p>
      <Typography.Title level={5}>
        Adattulajdonosi jogok és jogérvényesítés:
      </Typography.Title>
      <p>
        Amennyiben nem szeretné személyes adatai felhasználását, joga van
        mindezt indoklás nélkül megtiltani. Törlési kérelmét írásban szükséges
        megküldenie az Adatkezelő részére, e-mailben vagy postai levél
        formájában, a jelen Adatvédelmi Irányelvekben megjelölt elérhetőségekre.
      </p>
      <Typography.Title level={5}>
        Kiskorúak és korlátozottan cselekvőképes személyes adatainak védelme:
      </Typography.Title>
      <p>
        Íjász.NET internetes weboldala, valamint megjelenéseinek tartalma
        többnyire nem 13 év alatti kiskorúak részére szól. 13 év alatti
        Felhasználó nem regisztrálhat az oldalra. Nem kezelünk és nem gyűjtünk
        13 év alatti gyermekekről személyes adatokat igazolható szülői vagy
        gondviselői hozzájárulás nélkül. A Szülő vagy gondviselő általi nevezés
        hozzájárulásnak minősül. Szülei vagy gondviselői kérhetik a gyermekre
        vonatkozó adatok kiadását, törlését. Ha az Adatkezelő számára az
        adatkezelés, adatfeldolgozás folyamán válik nyilvánvalóvá az életkor,
        abban az esetben a szülői hozzájárulás beszerzése céljából az adatok
        felhasználhatók.
      </p>
      <p>
        Törvényes képviselő hozzájárulása szükséges cselekvőképtelen és
        korlátozottan cselekvőképes kiskorú személy nyilatkozatához, kivéve a
        mindennapi életben tömegesen előforduló regisztrációt célzó és
        különösebb megfontolást nem igénylő szolgáltatásrészeket.
      </p>
      <Typography.Title level={5}>Cookie-k (sütik) kezelése:</Typography.Title>
      <p>
        A <Link to={"/cookie-policy"}>Cookie nyilatkozatban</Link> olvashat
        bővebben a sütik (cookie-k) kezeléséről.
      </p>
      <Typography.Title level={5}>Fogalmak</Typography.Title>
      <p>
        <ul>
          <li>
            <strong>Adatfeldolgozó:</strong> természetes vagy jogi személy,
            közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely az
            adatkezelő nevében személyes adatokat kezel
          </li>
          <li>
            <strong>Adatkezelés:</strong> a személyes adatokon vagy
            adatállományokon automatizált vagy nem automatizált módon végzett
            bármely művelet vagy műveletek összessége, például: gyűjtés,
            rögzítés, rendszerezés, tagolás, tárolás, átalakítás,
            megváltoztatás, lekérdezés, betekintés, felhasználás, közlés,
            továbbítás, terjesztés, egyéb módon történő hozzáférhetővé tétel,
            összehangolás, összekapcsolás, korlátozás, törlés, megsemmisítés
          </li>
          <li>
            <strong>Adattovábbítás:</strong> az Adatkezelő által kezelt
            személyes adatok harmadik személyek számára történő hozzáférhetővé
            tétele
          </li>
          <li>
            <strong>Adatvédelmi incidens:</strong> a biztonság sérülése, amely a
            továbbított, tárolt vagy más módon kezelt személyes adatok véletlen
            vagy jogellenes megsemmisítését, elvesztését, megváltoztatását,
            jogosulatlan közlését vagy az azokhoz való jogosulatlan hozzáférést
            eredményezi
          </li>
          <li>
            <strong>Azonosítható természetes személy:</strong> természetes
            személy, aki közvetlen vagy közvetett módon, valamely azonosító,
            például: név, azonosító szám, helymeghatározó adat, online
            azonosító, fizikai, fiziológiai, genetikai, szellemi, gazdasági,
            kulturális vagy szociális azonosságára vonatkozó egy vagy több
            tényező alapján azonosítható
          </li>
          <li>
            <strong>Címzett:</strong> természetes vagy jogi személy, közhatalmi
            szerv, ügynökség vagy bármely egyéb szerv, amellyel a személyes
            adatot közlik, függetlenül attól, hogy harmadik fél-e
          </li>
          <li>
            <strong>Érintett hozzájárulása:</strong> az érintett akaratának
            önkéntes, konkrét, megfelelő tájékoztatáson alapuló, egyértelmű
            kinyilvánítása, amellyel az érintett nyilatkozat vagy a megerősítést
            félreérthetetlenül kifejező cselekedet útján jelzi, hogy
            beleegyezését adja az őt érintő személyes adatok kezeléséhez
          </li>
          <li>
            <strong>Érintett:</strong> bármely információ alapján azonosított
            vagy azonosítható természetes személy
          </li>
          <li>
            <strong>Felhasználók:</strong> az Adatkezelő által üzemeltetett,
            https://ijasz.net weboldalon és az ott meghatározott egyéb címeken
            elérhető weboldalak látogatói
          </li>
          <li>
            <strong>Harmadik fél:</strong> természetes vagy jogi személy,
            közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely nem
            azonos az érintettel, az adatkezelővel, az adatfeldolgozóval vagy
            azokkal a személyekkel, akik az adatkezelő vagy adatfeldolgozó
            közvetlen irányítása alatt a személyes adatok kezelésére
            felhatalmazást kaptak
          </li>
          <li>
            <strong>Hatóság:</strong> Nemzeti Adatvédelmi és Információszabadság
            Hatóság (székhely: 1125 Budapest, Szilágyi Erzsébet fasor 22/c.,
            telefon: 06-1 391-1400, fax: 06-1 391-1410, e-mail:
            ugyfelszolgalat@naih.hu, weboldal: naih.hu)
          </li>
          <li>
            <strong>Partner/Ügyfél:</strong> természetes személy, aki az
            Adatkezelővel nem weboldalon köt szerződést termék értékesítésére
            vagy szolgáltatás nyújtására
          </li>
          <li>
            <strong>Regisztráció:</strong> a weboldal felhasználó személyes
            adatainak rögzítése, mellyel felhasználói fiókot hoz létre
          </li>
          <li>
            <strong>Személyes adat:</strong> az érintettre vonatkozó bármely
            információ
          </li>
          <li>
            <strong>Szolgáltatás:</strong> az Adatkezelő által Ügyfelei,
            Partnerei és Felhasználói részére a weboldalon vagy egyéb módon
            nyújtott szolgáltatások
          </li>
          <li>
            <strong>Weboldal:</strong> az Adatkezelő által üzemeltetett,
            https://ijasz.net és az ott meghatározott egyéb címeken elérhető
            weboldalai
          </li>
        </ul>
      </p>
      <p>
        Jelen Adatvédelmi Nyilatkozat hatályba lépésének időpontja: 2020.10.05.
        Az adatkezelő a változtatás jogát fenntartja.
      </p>
    </Col>
  );
};
