import {Table, Tooltip} from "antd";
import React, { useContext, useState } from "react";
import { GenderTag } from "../tag/GenderTag";
import { CategoryTag } from "../tag/CategoryTag";
import { AgeGroupTag } from "../tag/AgeGroupTag";
import _ from "lodash";
import "./EntriesTable.less";
import { useBreakpoint } from "../utils/Breakpoint";
import { UrlContext } from "../utils/UrlContext";
import { ExternalAwareLink } from "../utils/ExternalAwareLink";
import {useTranslation} from "react-i18next";

const { Column } = Table;

export const EntriesTable = ({
  entries,
  isFetchingEntries,
  competition,
  highlightedEntry,
    hideClub
}) => {
    const { t } = useTranslation();
  const { xxl } = useBreakpoint();
  const urls = useContext(UrlContext);

  const [filter, setFilter] = useState({});

  const [filterActive, setFilterActive] = useState(false);

  const hasTeams = competition.teamsPublished;

  const getSortedEntries = () => {
    let sortedEntries = [...entries];
    if (hasTeams) {
      sortedEntries.sort((a, b) => {
        if (a.team < b.team) return -1;
        if (a.team > b.team) return 1;
        if (a.teamIndex < b.teamIndex) return -1;
        if (a.teamIndex > b.teamIndex) return 1;
        return 0;
      });
    } else {
      sortedEntries.sort((a, b) => a.id - b.id);
    }

    return sortedEntries;
  };

  const getLowestTeamIndexOfTeam = (team) => {
    let entryWithLowestTeamIndex = _.minBy(
      _.filter(entries, { team: team }),
      "teamIndex"
    );

    return entryWithLowestTeamIndex ? entryWithLowestTeamIndex.teamIndex : 0;
  };

  const count = _.countBy(entries, (value) => value.team || 0);

  const teams = _.uniq(_.map(entries, "team")).sort((a, b) => a - b);

  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={entries && getSortedEntries()}
      pagination={false}
      loading={isFetchingEntries}
      size={"small"}
      onChange={(pagination, filters) => {
        setFilterActive(
          filters.ageGroup || filters.gender || filters.club || filters.category
        );
        setFilter(filters);
      }}
      rowClassName={(record) => {
        let className = "";

        if (hasTeams && !filterActive) {
          className +=
            teams.indexOf(record.team) % 2 === 0
              ? "table-even-row-background-color "
              : "table-odd-row-background-color ";
        }

        if (highlightedEntry && record.id === highlightedEntry.id) {
          className += "highlighted";
        }

        return className;
      }}
    >
      {hasTeams && (
        <Column
          title={t("entries.team")}
          dataIndex={["team"]}
          key="team"
          width={
            42
          }
          render={(value, row) => {
            const obj = {
              children: competition.teamLabels?.split(";")[value - 1] || value,
              props: {},
            };

            if (!filterActive) {
              if (row.teamIndex === getLowestTeamIndexOfTeam(row.team)) {
                obj.props.rowSpan = count[row.team || 0];
              } else {
                obj.props.rowSpan = 0;
              }
            }

            return obj;
          }}
        />
      )}
      <Column
        title={t("entries.name")}
        dataIndex={["name"]}
        key="name"
        sorter={hasTeams ? null : (a, b) => a.name.localeCompare(b.name)}
        render={(text, record) =>
          record.permitNumber > 0 ? (
            <ExternalAwareLink to={`${urls.athlete}/${record.permitNumber}`}>
              {text}
            </ExternalAwareLink>
          ) : (
            text
          )
        }
      />
      <Column
        title={xxl ? t("entries.category") : t("entries.categoryShort")}
        dataIndex={["category", "name"]}
        key="category"
        filteredValue={filter.category}
        filters={competition.categories.map((category) => {
          return { text: category.name, value: category.id };
        })}
        onFilter={(value, record) => record.category.id === value}
        render={(text, record) => (
            <Tooltip placement="right" title={record.category.description}>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFilterActive(true);
                  setFilter({ ...filter, category: [record.category.id] });
                }}
              >
                  <CategoryTag category={text} />
              </span>
            </Tooltip>
        )}
        width={
          xxl
            ? competition.type === "HISTORICAL"
              ? 130
              : 110
            : competition.type === "HISTORICAL"
            ? 130
            : 80
        }
      />
      <Column
        title={xxl ? t("entries.ageGroup") : t("entries.ageGroupShort")}
        dataIndex={["ageGroup", "ageGroup", "name"]}
        key="ageGroup"
        filteredValue={filter.ageGroup}
        filters={competition.ageGroups.map((ageGroup) => {
          return { text: ageGroup.ageGroup.name, value: ageGroup.id };
        })}
        onFilter={(value, record) => record.ageGroup.id === value}
        render={(text, record) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setFilterActive(true);
              setFilter({ ...filter, ageGroup: [record.ageGroup.id] });
            }}
          >
            <AgeGroupTag ageGroup={text} />
          </span>
        )}
        width={xxl ? 110 : 90}
      />
      <Column
        title={t("entries.gender")}
        dataIndex={["gender"]}
        key="gender"
        filteredValue={filter.gender}
        filters={[
          {
            text: t("genderInput.male"),
            value: "MALE",
          },
          { text: t("genderInput.female"), value: "FEMALE" },
        ]}
        onFilter={(value, record) => record.gender === value}
        render={(text, record) => (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setFilterActive(true);
              setFilter({ ...filter, gender: [record.gender] });
            }}
          >
            <GenderTag gender={text} />
          </span>
        )}
        width={xxl ? 80 : 70}
      />
        {!hideClub && !competition.international && <Column
        title={t("entries.club")}
        className={"club-cell"}
        dataIndex={["club", "name"]}
        key="club"
        ellipsis={true}
        filteredValue={filter.club}
        filters={
          entries &&
          entries
            .map((entry) => entry.club)
            .filter(
              (elem, pos, arr) =>
                arr.findIndex((value) => value.id === elem.id) === pos
            )
            .map((club) => {
              return { text: club.name, value: club.id };
            })
            .sort((a, b) => a.text.localeCompare(b.text))
        }
        onFilter={(value, record) => record.club.id === value}
      />}
        {!hideClub && competition.international && <Column
            title={t("entries.country")}
            className={"club-cell"}
            dataIndex={["country"]}
            key="country"
            ellipsis={true}
            filteredValue={filter.country}
            render={text => text && t("country." + text) || "-"}
            filters={
                entries &&
                entries
                    .map((entry) => entry.country || "-")
                    .filter(
                        (elem, pos, arr) =>
                            arr.findIndex((value) => value === elem) === pos
                    )
                    .map((country) => {
                        return { text: country !== "-" && t("country." + country) || "-", value: country };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text))
            }
            onFilter={(value, record) => (record.country || "-") === value}
        />}
    </Table>
  );
};
