import { Select } from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

const { Option } = Select;

export const ClubInput = ({ clubs, ...restProps }) => {
  const { t } = useTranslation();
  return (
    <Select
      {...restProps}
      data-cy="club-select"
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Option value={999}>{t("clubInput.other")}</Option>
      {clubs &&
        clubs
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((club) => (
            <Option key={club.id} value={club.id}>
              {club.name}
            </Option>
          ))}
    </Select>
  );
};
