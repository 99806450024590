import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getUserData = async () => {
  const { data } = await axios(`${API_URL}/user`);
  return data;
};

export const getCalendar = async () => {
  const { data } = await axios(`${API_URL}/user/calendar`);
  return data;
};

export const addToCalendar = async (competitionId) => {
  return axios.post(`${API_URL}/user/calendar/${competitionId}`);
};

export const removeFromCalendar = async (competitionId) => {
  return axios.delete(`${API_URL}/user/calendar/${competitionId}`);
};
