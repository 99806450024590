import React, { useState } from "react";
import "./RankingItem.scss";
import { Avatar, Typography } from "antd";
import { Link } from "react-router-dom";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DownOutlined,
  MinusOutlined,
  UpOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { RankedEntries } from "./RankedEntries";

export const RankingItem = ({
  ranking,
  competitionType,
  ageGroup,
  category,
  gender,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div key={ranking.id} className={"ranking-item"}>
      <div className={"ranking-row"}>
        <div className={"rank-box"}>
          <div className={"rank"}>
            <Typography.Title level={2}>{ranking.rank}.</Typography.Title>
          </div>
          <div
            className={
              "diff " +
              (ranking.diff > 0 && "positive ") +
              (ranking.diff < 0 && "negative ")
            }
          >
            <Typography.Title level={5}>
              {ranking.diff > 0 && (
                <div>
                  <CaretUpOutlined style={{ color: "green" }} />
                  {ranking.diff}
                </div>
              )}
              {ranking.diff < 0 && (
                <div>
                  <CaretDownOutlined style={{ color: "red" }} />
                  {ranking.diff * -1}
                </div>
              )}
              {ranking.diff === 0 && (
                <div>
                  <MinusOutlined />
                </div>
              )}
            </Typography.Title>
          </div>
        </div>

        <div className={"avatar"}>
          <Avatar size={70} icon={<UserOutlined />} />
        </div>
        <div className={"name"}>
          <Typography.Title level={4}>
            <Link to={`/athlete/${ranking.permitNumber}`}>{ranking.name}</Link>
          </Typography.Title>
        </div>
        <div className={"club"}>
          <Typography.Title level={5}>{ranking.club.name}</Typography.Title>
        </div>
        <div className={"score"} onClick={() => setIsOpen(!isOpen)}>
          <Typography.Title level={3}>
            {ranking.score.toFixed(2)}
          </Typography.Title>
        </div>

        <div className={"toggle-arrow"} onClick={() => setIsOpen(!isOpen)}>
          {isOpen && <UpOutlined />}
          {!isOpen && <DownOutlined />}
        </div>
      </div>

      {isOpen && (
        <RankedEntries
          permitNumber={ranking.permitNumber}
          competitionType={competitionType}
          category={category}
          ageGroup={ageGroup}
          gender={gender}
        />
      )}
    </div>
  );
};
