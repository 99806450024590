import { Form, Input, InputNumber, Select } from "antd";
import { GenderInput } from "artemis-shared/entryForm/GenderInput";
import { CategoryInput } from "artemis-shared/entryForm/CategoryInput";
import { AgeGroupInput } from "artemis-shared/entryForm/AgeGroupInput";
import { ClubInput } from "artemis-shared/entryForm/ClubInput";
import { LunchInput } from "artemis-shared/entryForm/LunchInput";
import React from "react";
import _ from "lodash";
import {CountrySelect} from "artemis-shared/competition/CountrySelect";

export const EditableCell = ({
  editing,
  dataIndex,
  children,
  ...restProps
}) => {
  let inputNode;

  let dataIndexString = Array.isArray(dataIndex) ? dataIndex[0] : dataIndex;

  switch (dataIndexString) {
    case "name":
      inputNode = (
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Add meg a nevet!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      );
      break;
    case "gender":
      inputNode = (
        <Form.Item
          name="gender"
          rules={[
            {
              required: true,
              message: "Válassz nemet!",
            },
          ]}
        >
          <GenderInput />
        </Form.Item>
      );
      break;
    case "permitNumber":
      inputNode = (
        <Form.Item
          name="permitNumber"
          rules={[
            {
              required: true,
              message: "Add meg a versenyengedélyt!",
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      );
      break;
    case "category":
      inputNode = (
        <Form.Item
          name="category"
          rules={[
            {
              required: true,
              message: "Válassz kategóriát!",
            },
          ]}
        >
          <CategoryInput categories={restProps.competition.categories} />
        </Form.Item>
      );
      break;
    case "ageGroup":
      inputNode = (
        <Form.Item
          name="ageGroup"
          rules={[
            {
              required: true,
              message: "Válassz korosztályt!",
            },
          ]}
        >
          <AgeGroupInput ageGroups={restProps.competition.ageGroups} />
        </Form.Item>
      );
      break;
    case "club":
      inputNode = (
        <Form.Item
          name="club"
          rules={[
            {
              required: true,
              message: "Válaszd ki az egyesületed!",
            },
          ]}
        >
          <ClubInput clubs={restProps.clubs} />
        </Form.Item>
      );
      break;
    case "lunch":
      inputNode = (
        <Form.Item
          name="lunch"
          rules={[
            {
              required: true,
              message: "Add meg hány ebédet kérsz!",
            },
          ]}
        >
          <LunchInput />
        </Form.Item>
      );
      break;
      case "paymentStatus":
      inputNode = (
        <Form.Item
          name="paymentStatus"
        >
            <Select {...restProps}>
                <Select.Option key={"PAID"} value={"PAID"}>
                    Igen
                </Select.Option>
                <Select.Option key={"NOT_PAID"} value={"NOT_PAID"}>
                    Nem
                </Select.Option>
            </Select>
        </Form.Item>
      );
      break;
    case "country":
      inputNode = (
          <Form.Item
              name={"country"}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]
              }
          >
            <CountrySelect />
          </Form.Item>
      );
      break;
    case "additionalData0":
      inputNode = getAdditionalDataCell(0, restProps.competition);
      break;
    case "additionalData1":
      inputNode = getAdditionalDataCell(1, restProps.competition);
      break;
    case "additionalData2":
      inputNode = getAdditionalDataCell(2, restProps.competition);
      break;
    case "additionalData3":
      inputNode = getAdditionalDataCell(3, restProps.competition);
      break;
    case "additionalData4":
      inputNode = getAdditionalDataCell(4, restProps.competition);
      break;
    default:
      inputNode = (
        <Form.Item name={dataIndexString}>
          <Input />
        </Form.Item>
      );
  }

  return <td {...restProps}>{editing ? inputNode : children}</td>;
};

const getAdditionalDataCell = (pos, competition) => {
  const additionalData = _.find(competition?.additionalData, { pos: pos });
  if (additionalData) {
    return (
      <>
        {additionalData.type === "STRING" && (
          <Form.Item
            name={["additionalData" + additionalData.pos]}
            rules={
              additionalData.required && [
                {
                  required: true,
                  message: "Kötelező mező!",
                },
              ]
            }
          >
            <Input />
          </Form.Item>
        )}
        {additionalData.type === "NUMBER" && (
          <Form.Item
            name={["additionalData" + additionalData.pos]}
            rules={
              additionalData.required && [
                {
                  required: true,
                  message: "Kötelező mező!",
                },
              ]
            }
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        )}
        {additionalData.type === "SELECT" && (
            <Form.Item
                name={"additionalData" + additionalData.pos}
                rules={
                    additionalData.required && [
                      {
                        required: true,
                        message: "Kötelező mező!",
                      },
                    ]
                }
            >
              <Select>
                {additionalData.options.split(";").map(option => <Select.Option key={option} value={option}>{option}</Select.Option>)}
              </Select>
            </Form.Item>
        )}
      </>
    );
  } else {
    return "asda";
  }
};
