import React from "react";
import { useQuery } from "react-query";
import { getTournaments } from "../api/Tournament";

export const Tournaments = () => {
  const { data } = useQuery("tournaments", getTournaments);

  return (
    <>
      <div>Tournaments</div>
      {data &&
        data.map((tournament) => (
          <div key={tournament.id}>{tournament.name}</div>
        ))}
    </>
  );
};
