import React from "react";
import { TrophyTwoTone } from "@ant-design/icons";

export const Trophies = ({ entries }) => {
  return (
    <div className={"trophies"}>
      <div className={"trophy gold"}>
        <TrophyTwoTone twoToneColor={"#ffce52"} className={"trophy-icon"} />
        <div>{entries.filter((result) => result.placement === 1).length}</div>
      </div>
      <div className={"trophy silver"}>
        <TrophyTwoTone twoToneColor={"#949494"} className={"trophy-icon"} />
        <div>{entries.filter((result) => result.placement === 2).length}</div>
      </div>
      <div className={"trophy bronze"}>
        <TrophyTwoTone twoToneColor={"#925408"} className={"trophy-icon"} />
        <div>{entries.filter((result) => result.placement === 3).length}</div>
      </div>
    </div>
  );
};
