import React, { useEffect, useState } from "react";
import {
  Card,
  Collapse,
  Empty,
  List,
  PageHeader,
  Popconfirm,
  Typography,
} from "antd";
import "./Training.less";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { db } from "../utils/TrainingDB";
import moment from "moment";
import { useHistory } from "react-router";
import _ from "lodash";

export const TrainingList = () => {
  const history = useHistory();
  const [localTrainings, setLocalTrainings] = useState([]);

  const getLocalTrainings = async () => {
    let localTrainings = await db.trainings.toArray();

    console.log("got", localTrainings);

    if (localTrainings) {
      setLocalTrainings(
        _.filter(localTrainings, (training) => !training.scorecardTraining)
      );
    }
  };

  useEffect(() => {
    getLocalTrainings();
  }, []);

  const countScore = (training) => {
    let values = training.scores.reduce(
      (acc, sc) => _.concat(acc, sc.scores),
      []
    );

    values = values.filter((value) => _.isNumber(value));

    return {
      sum: _.sum(values),
      count: values.length,
    };
  };

  const groupedTrainings = _.groupBy(
    _.orderBy(localTrainings, "created", "desc"),
    (training) => moment(training.created).format("YYYY. MMMM")
  );

  console.log(groupedTrainings);

  const getScoring = (training) => {
    return training.scoring
      .split(",")
      .map(parseInt)
      .sort((a, b) => b - a);
  };

  const getHighestScoringValue = (scoring) => {
    return _.find(scoring, _.isFinite);
  };

  const deleteTraining = async (id) => {
    console.log("id", id);
    await db.trainings.where("id").equals(id).delete();
    getLocalTrainings();
  };

  return (
    <div className={"training"}>
      <Card bodyStyle={{ padding: "16px 8px 40px 8px" }}>
        <PageHeader
          className="site-page-header"
          onBack={() => history.goBack()}
          title={"Edzések"}
        />
        {localTrainings.length === 0 && (
          <Empty
            description={
              <>
                <span>Nincs rögzített edzésed.</span>
                <br />
                <span>Vegyél fel egyet a plusz ikonra kattintva!</span>
              </>
            }
          />
        )}
        {localTrainings.length > 0 && (
          <div>
            <Collapse defaultActiveKey={["0"]}>
              {Object.entries(groupedTrainings).map(
                ([month, trainings], index) => (
                  <Collapse.Panel header={month} key={index}>
                    <List
                      dataSource={_.orderBy(trainings, "created", "desc")}
                      renderItem={(item) => (
                        <List.Item
                          onClick={() => history.push("/training/" + item.id)}
                        >
                          <List.Item.Meta
                            title={
                              <div className={"training-title"}>
                                <span>{item.name}</span>
                                <Popconfirm
                                  title="Biztos hogy törlöd az edzést?"
                                  onClick={(e) => e.stopPropagation()}
                                  onConfirm={(e) => {
                                    e.stopPropagation();
                                    deleteTraining(item.id);
                                  }}
                                  onCancel={(e) => {
                                    e.stopPropagation();
                                  }}
                                  okText="Igen"
                                  cancelText="Nem"
                                >
                                  <DeleteOutlined />
                                </Popconfirm>
                              </div>
                            }
                            description={
                              <div className={"training-description"}>
                                <div>
                                  {moment(item.created).format(
                                    "YYYY.MM.DD - HH:mm"
                                  )}
                                </div>
                                <div>
                                  {countScore(item).sum} /{" "}
                                  {item.targetCount *
                                    item.arrowsPerTarget *
                                    Math.min(
                                      10,
                                      getHighestScoringValue(getScoring(item))
                                    )}{" "}
                                  ({" "}
                                  {(
                                    countScore(item).sum /
                                    (countScore(item).count || 1)
                                  ).toFixed(2)}{" "}
                                  )
                                </div>
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Collapse.Panel>
                )
              )}
            </Collapse>
          </div>
        )}
      </Card>
      <Link to={"/training/create"}>
        <div className={"create-button"}>
          <PlusOutlined />
        </div>
      </Link>
    </div>
  );
};
