import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { GenderInput } from "../entryForm/GenderInput";
import { CategoryInput } from "../entryForm/CategoryInput";
import { AgeGroupInput } from "../entryForm/AgeGroupInput";
import { ClubInput } from "../entryForm/ClubInput";
import { LunchInput } from "../entryForm/LunchInput";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import "./ApplicationForm.less";
import { ExternalAwareLink } from "../utils/ExternalAwareLink";
import { UrlContext } from "../utils/UrlContext";
import { useMutation, useQuery } from "react-query";
import { checkPermit, checkPermitAndName, createPermit } from "../api/Permit";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {CountrySelect} from "./CountrySelect";

const { Title } = Typography;

export const ApplicationForm = ({
  form,
  onSubmitEntry,
  competition,
  clubs,
  isLoading,
  entriesNumber,
  entryToModify,
  userData,
  getProfileInput,
    permitRequired,
    misz
}) => {
  useEffect(() => {
    if (entryToModify) {
      form.setFieldsValue({
        name: entryToModify.name,
        firstName: entryToModify.firstName,
        lastName: entryToModify.lastName,
        gender: entryToModify.gender,
        category: entryToModify.category.id,
        ageGroup: entryToModify.ageGroup.id,
        club: entryToModify.club.id,
        country: entryToModify.country,
        lunch: entryToModify.lunch,
        email: entryToModify.email,
        comment: entryToModify.comment,
        permitNumber: entryToModify.permitNumber,
        team: entryToModify.team,
        agree: false,
        profile: "",
        additionalData0: null,
        additionalData1: null,
        additionalData2: null,
        additionalData3: null,
        additionalData4: null,
        anonymized: entryToModify.anonymized
      });
    }
  }, [entryToModify]);


  const { t, i18n } = useTranslation();

  const [permitNumber, setPermitNumber] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [invalidPermitNumber, setInvalidPermitNumber] = useState(false);
  const [nameHelper, setNameHelper] = useState(null);
  const [permitInputType, setPermitInputType] = useState(null);

  const { data: permitData } = useQuery(["permit", permitNumber], checkPermit, {
    enabled: permitNumber,
    retry: false,
    onSuccess: (data) => {
      form.setFieldsValue({
        name: data.fullName,
      });
      form.validateFields(["permitNumber"]);
      setInvalidPermitNumber(false);
    },
    onError: (err) => {
      if (err.response.status === 404) {
        form.setFieldsValue({
          name: null,
        });
        form.validateFields(["permitNumber"]);
        setInvalidPermitNumber(true);
      }
    },
  });

  const [mutateCheckPermitAndName] = useMutation(checkPermitAndName, {
    retry: false,
    onSuccess: (data) => {
      form.validateFields(["firstName", "lastName"]);
      setNameHelper(null);
    },
    onError: (err) => {
      if (err.response.status === 400) {
        form.validateFields(["firstName", "lastName"]);
        setNameHelper(err.response.data.message);
      }
    },
  });

  const [mutateCreatePermit] = useMutation(createPermit, {
    enabled: permitNumber && firstName && lastName,
    retry: false,
    onSuccess: (data) => {},
    onError: (err) => {
      if (err.response.status === 400) {
        alert("Hiba történt!");
      }
    },
  });

  console.log("permitdata", permitData);

  const urls = useContext(UrlContext);

  const submitApplication = (values) => {
    if (values.name) {
      onSubmitEntry({
        ...values,
        name: values.name.trim().replace(/\s+/g, " "),
        permitNumber: values.permitNumber || -1,
      });
    } else {
      onSubmitEntry({
        ...values,
        permitNumber: values.permitNumber || -1,
      });
    }

    setPermitNumber(null);
    setFirstName(null);
    setLastName(null);
    setInvalidPermitNumber(false);
    setNameHelper(null);
  };

  const checkPermitLevel = competition.permitRequired ? competition.checkPermitLevel : 0;

  return (
    <>
      {moment(competition.applicationDeadline).isBefore(moment()) && (
        <div className={"application-closed-overlay"}>
          <Title level={3}>{t("applicationForm.applicationIsClosed")}</Title>
        </div>
      )}
      {competition.maxParticipants <= entriesNumber && (
        <div className={"application-closed-overlay"}>
          <Title level={3}>{t("applicationForm.full")}</Title>
        </div>
      )}
      {moment(competition.applicationDeadline).isAfter(moment()) &&
        competition.maxParticipants > entriesNumber && (
          <Form
            name="entry"
            form={form}
            layout={"vertical"}
            requiredMark={false}
            initialValues={{
              firstName: "",
              lastName: "",
              gender: "",
              category: "",
              ageGroup: "",
              club: "",
              lunch: "",
              agree: false,
              profile: "",
              additionalData0: null,
              additionalData1: null,
              additionalData2: null,
              additionalData3: null,
              additionalData4: null,
              anonymized: false
            }}
            onFinish={(values) => {
              console.log("onfinish", values);

              let firstName = values.firstName?.trim().replace(/\s+/g, ' ');
              let lastName = values.lastName?.trim().replace(/\s+/g, ' ');

              if ((!values.permitNumber || values.permitNumber < 1000) && !(misz || i18n.language === "en" || permitInputType === 1 || permitInputType === 2)) {
                Modal.confirm({
                  title: "Szeretnél létrehozni egy azonosítót?",
                  content: (
                    <div>
                      <Typography.Paragraph>
                        Nem adtál meg versenyengedély számot. Nevezhetsz nélküle
                        is, de ha szeretnéd hogy az eredményed megjelenjen a
                        versenyzői profilodon és a ranglistán, szükséged van egy
                        azonosítóra.
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        Ellenőrizd a megadott nevet, ügyelve az ékezetekre,
                        kis-nagybetűre.
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <Typography.Title level={5}>
                          {`${lastName} ${firstName}`}
                        </Typography.Title>
                      </Typography.Paragraph>
                    </div>
                  ),
                  okText: "Igen, kérek egyet",
                  cancelText: "Nem",
                  onOk() {
                    mutateCreatePermit({ name: `${lastName} ${firstName}` }).then((data) => {
                      Swal.fire({
                        title: "Elkészült az azonosítód!",
                        html: `
                          <div>
                              Mostantól használhatod a következő
                              név-versenyengedélyszám párost nevezéskor:<br /><br />

                              ${values.lastName} ${values.firstName} - ${data.permitNumber}
                            <br /><br />
                              Jegyezd fel az adatokat hogy a következő
                              nevezéskor is meglegyen!
                          </div>`,
                        icon: "success",
                        showConfirmButton: true,
                      }).then(() => {
                        submitApplication({
                          ...values,
                          permitNumber: data.permitNumber,
                          name: `${lastName} ${firstName}`,
                          firstName: firstName,
                          lastName: lastName
                        });
                      });
                    });
                  },
                  onCancel() {
                    submitApplication({
                      ...values,
                      name: `${lastName} ${firstName}`,
                      firstName: firstName,
                      lastName: lastName
                    });
                  },
                });
              } else {
                const valuesToSubmit = {
                  ...values,
                  name: `${lastName} ${firstName}`,
                  firstName: firstName,
                  lastName: lastName
                }

                if(permitInputType === 1) {
                  valuesToSubmit.permitNumber = -2;
                }

                if(permitInputType === 2) {
                  valuesToSubmit.permitNumber = -1;
                }

                submitApplication(valuesToSubmit);
              }
            }}
            onFinishFailed={(error) => console.log("onfinishfailed", error)}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={3}>
                {checkPermitLevel > 0 && permitInputType !== 0 && <Form.Item
                    label={
                      <div>
                        <Space>
                          <span>{"Indulás"}</span>
                        </Space>
                      </div>
                    }>
                    <Select onSelect={
                      (value) => {
                        setPermitInputType(value);
                      }
                    } placeholder={"Válassz!"}>
                      <Option key={"0"} value={0}>
                        V.engedéllyel
                      </Option>
                      {checkPermitLevel >= 1 && <Option key={"1"} value={1}>
                        Rajtengedéllyel
                      </Option>}
                      {checkPermitLevel >= 2 && <Option key={"2"} value={2}>
                        Versenyen kívül
                      </Option>}
                    </Select>
                </Form.Item>
                }

                {(checkPermitLevel === 0 || permitInputType === 0) && <Form.Item
                  label={
                    <div>
                      <Space>
                        <span>{t("applicationForm.permitNumber")}</span>
                      </Space>
                    </div>
                  }
                  name="permitNumber"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if((permitRequired && i18n.language !== "en") && !value) {
                          return Promise.reject(
                              new Error(t("applicationForm.required"))
                          );
                        }

                        if (
                          !value ||
                          (value && !invalidPermitNumber)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t("applicationForm.invalidPermitNumber"))
                        );
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    onBlur={(e) => {
                      setPermitNumber(e.target.value ? e.target.value : null);
                      setFirstName(null);
                      setLastName(null);
                      setInvalidPermitNumber(false);
                      setNameHelper(null);
                      form.setFieldsValue({
                        firstName: null,
                        lastName: null
                      });
                    }}
                    disabled={userData || entryToModify}
                    placeholder={!permitRequired ? "Nem kötelező" : ""}
                  />
                </Form.Item>}
              </Col>
              {!userData && (<Col xs={24} sm={4}>

                  <Form.Item
                    label={t("applicationForm.lastName")}
                    name="lastName"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (nameHelper) {
                            return Promise.reject(
                              new Error(
                                  t("applicationForm.invalidLastName")
                              )
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                      {
                        required: true,
                        message: t("applicationForm.nameRequired"),
                      },
                    ]}
                  >
                    <Input
                        disabled={entryToModify}
                      onBlur={(e) => {
                        if(form.getFieldValue("firstName") && form.getFieldValue("lastName")) {
                          setLastName(e.target.value ? e.target.value : null);
                          setNameHelper(null);
                          if (permitNumber) {
                            mutateCheckPermitAndName({
                              permitNumber,
                              name: `${e.target.value?.trim().replace(/\s+/g, " ")} ${form.getFieldValue("firstName").trim().replace(/\s+/g, " ")}`,
                            });
                          }
                        }
                      }}
                    />
                  </Form.Item>
              </Col>
              )}
              {userData &&
                <Col xs={24} sm={8}>
                  {userData && getProfileInput(form)}
                </Col>
              }
              {!userData && (<Col xs={24} sm={4}>

                    <Form.Item
                        label={t("applicationForm.firstName")}
                        name="firstName"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (nameHelper) {
                                return Promise.reject(
                                    new Error(
                                        t("applicationForm.invalidFirstName") +
                                        nameHelper
                                    )
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                          {
                            required: true,
                            message: t("applicationForm.nameRequired"),
                          },
                        ]}
                    >
                      <Input
                          disabled={entryToModify}
                          onBlur={(e) => {
                            if(form.getFieldValue("firstName") && form.getFieldValue("lastName")) {
                              setFirstName(e.target.value ? e.target.value : null);
                              setNameHelper(null);
                              if (permitNumber) {
                                mutateCheckPermitAndName({
                                  permitNumber,
                                  name: `${form.getFieldValue("lastName").trim().replace(/\s+/g, " ")} ${e.target.value?.trim().replace(/\s+/g, " ")}`,
                                });
                              }
                            }
                          }}
                      />
                    </Form.Item>

              </Col> )}
              <Col xs={24} sm={5}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("applicationForm.emailRequired"),
                    },
                  ]}
                >
                  <Input type={"email"} />
                </Form.Item>
              </Col>
              {competition.international && <Col key={"country"} xs={24} sm={5}>
                <Form.Item
                    label={t("applicationForm.country")}
                    name={"country"}
                    rules={[
                      {
                        required: true,
                        message: t("applicationForm.requiredCountry"),
                      },
                    ]
                    }
                >
                  <CountrySelect />
                </Form.Item>
              </Col>}
              {!competition.international &&<Col xs={24} sm={5}>
                <Form.Item
                  label={t("applicationForm.club")}
                  name="club"
                  rules={[
                    {
                      required: true,
                      message: t("applicationForm.clubRequired"),
                    },
                  ]}
                >
                  <ClubInput clubs={clubs} />
                </Form.Item>
              </Col>}
              {competition.lunch && (
                <Col xs={24} sm={3}>
                  <Form.Item
                    label={competition.guestLunch ? t("applicationForm.guestLunch") : t("applicationForm.lunch")}
                    name="lunch"
                    rules={[
                      {
                        required: true,
                        message: t("applicationForm.lunchRequired"),
                      },
                    ]}
                  >
                    <LunchInput />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} sm={3}>
                <Form.Item
                  label={t("applicationForm.gender")}
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: t("applicationForm.genderRequired"),
                    },
                  ]}
                >
                  <GenderInput />
                </Form.Item>
              </Col>
              <Col xs={24} sm={4}>
                <Form.Item
                  label={t("applicationForm.category")}
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: t("applicationForm.categoryRequired"),
                    },
                  ]}
                >
                  <CategoryInput categories={competition.categories} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={5}>
                <Form.Item
                  label={
                    <div>
                      <Space>
                        <span>{t("applicationForm.ageGroup")}</span>
                      </Space>
                    </div>
                  }
                  name="ageGroup"
                  rules={[
                    {
                      required: true,
                      message: t("applicationForm.ageGroupRequired"),
                    },
                  ]}
                >
                  <AgeGroupInput ageGroups={competition.ageGroups} />
                </Form.Item>
              </Col>
              {competition.chooseRound && (
                <Col xs={24} sm={4}>
                  <Form.Item
                    label={t("applicationForm.rounds")}
                    name="rounds"
                    rules={[
                      {
                        required: true,
                        message: t("applicationForm.roundsRequired"),
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option value="0">{t("applicationForm.rounds0")}</Select.Option>
                      <Select.Option value="1">{t("applicationForm.rounds1")}</Select.Option>
                      <Select.Option value="2">{t("applicationForm.rounds2")}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col
                xs={24}
                sm={
                  competition.chooseRound
                    ? 8
                    : competition.teamsPublished
                    ? 9
                    : 12
                }
              >
                <Form.Item label={t("applicationForm.comment")} name="comment">
                  <Input />
                </Form.Item>
              </Col>
              {competition.additionalData.map((additionalData) => (
                <Col key={additionalData.id} xs={24} sm={6}>
                  {additionalData.type === "STRING" && (
                    <Form.Item
                      label={additionalData.label}
                      name={"additionalData" + additionalData.pos}
                      rules={
                        additionalData.required && [
                          {
                            required: true,
                            message: t("applicationForm.requiredAdditionalData"),
                          },
                        ]
                      }
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {additionalData.type === "NUMBER" && (
                    <Form.Item
                      label={additionalData.label}
                      name={"additionalData" + additionalData.pos}
                      rules={
                        additionalData.required && [
                          {
                            required: true,
                            message: t("applicationForm.requiredAdditionalData"),
                          },
                        ]
                      }
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  )}
                  {additionalData.type === "SELECT" && (
                      <Form.Item
                          label={additionalData.label}
                          name={"additionalData" + additionalData.pos}
                          rules={
                              additionalData.required && [
                                {
                                  required: true,
                                  message: t("applicationForm.requiredAdditionalData"),
                                },
                              ]
                          }
                      >
                        <Select>
                          {additionalData.options.split(";").map(option => <Select.Option key={option} value={option}>{option}</Select.Option>)}
                        </Select>
                      </Form.Item>
                  )}
                </Col>
              ))}
              {competition.applicationInfoMessage && (
                <Col xs={24}>
                  <Alert
                    message={t("applicationForm.warning")}
                    description={competition.applicationInfoMessage}
                    type="warning"
                    showIcon
                  />
                </Col>
              )}
              <Col flex={"auto"}>
                {competition.anonymized && <Form.Item
                    name="anonymized"
                    valuePropName="checked"
                    style={{marginBottom: "6px"}}
                >
                  <Checkbox className={"square-checkbox"}>
                    A nevezésem, eredményem NE legyen nyílvános! Csak a szervező egyesület és a MISZ kapja meg.
                  </Checkbox>
                </Form.Item>}
                <Form.Item
                  name="agree"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (value) {
                          callback();
                        } else {
                          callback([new Error(t("applicationForm.privacyRequired"))]);
                        }
                      },
                    },
                  ]}
                >
                  <Checkbox className={"square-checkbox"}>
                    {t("applicationForm.privacy1")}{" "}
                    <ExternalAwareLink to={urls.privacy} target={"_blank"}>
                      {t("applicationForm.privacy2")}
                    </ExternalAwareLink>{" "}
                    {t("applicationForm.privacy3")}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col flex="100px">
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    {entryToModify ? t("applicationForm.modify") : t("applicationForm.apply")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
    </>
  );
};
