import React from "react";
import "./RankedEntries.less";
import { useQuery } from "react-query";
import { getRankedEntries } from "../api/Ranking";
import { Link } from "react-router-dom";
import moment from "moment";

export const RankedEntries = ({
  permitNumber,
  competitionType,
  category,
  ageGroup,
  gender,
}) => {
  const { data: rankedEntries } = useQuery(
    ["ranked-entries", permitNumber],
    getRankedEntries
  );

  const getFilteredEntries = () => {
    return rankedEntries.filter((entry) => {
      return (
        entry.competition.type === competitionType &&
        entry.category.id === category &&
        entry.ageGroup.ageGroup.id === ageGroup &&
        entry.gender === gender
      );
    });
  };

  return (
    <div className={"ranked-entries"}>
      <div className={"included"}>
        {rankedEntries &&
          getFilteredEntries()
            .sort((a, b) =>
              moment(b.competition.startDate).diff(
                moment(a.competition.startDate)
              )
            )
            .slice(0, 6)
            .map((entry) => (
              <div className={"ranked-entry"} key={entry.id}>
                <div className={"date"}>{entry.competition.startDate}</div>

                <div className={"name"}>
                  <Link
                    key={entry.id}
                    to={`/competition/${entry.competition.id}/${entry.competition.slug}`}
                  >
                    <span>{entry.competition.name}</span>
                    <span className={"location"}>
                      {" "}
                      - {entry.competition.location}
                    </span>
                  </Link>
                </div>
                <div className={"placement"}>{entry.placement}. hely</div>
                <div className={"score"}>{entry.rankingScore.toFixed(2)}</div>
              </div>
            ))}
      </div>
      <div className={"not-included"}>
        {rankedEntries &&
          getFilteredEntries()
            .sort((a, b) =>
              moment(b.competition.startDate).diff(
                moment(a.competition.startDate)
              )
            )
            .slice(6)
            .map((entry) => (
              <div className={"ranked-entry"} key={entry.id}>
                <div className={"date"}>{entry.competition.startDate}</div>

                <div className={"name"}>
                  <Link
                    key={entry.id}
                    to={`/competition/${entry.competition.id}/${entry.competition.slug}`}
                  >
                    <span>{entry.competition.name}</span>
                    <span className={"location"}>
                      {" "}
                      - {entry.competition.location}
                    </span>
                  </Link>
                </div>
                <div className={"placement"}>{entry.placement}. hely</div>
                <div className={"score"}>{entry.rankingScore.toFixed(2)}</div>
              </div>
            ))}
      </div>
    </div>
  );
};
