import {importInto} from "dexie-export-import";
import Swal from "sweetalert2";
import React, {useRef, useState} from "react";
import {Button, Modal, Table, Tooltip, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {db, tempDb} from "../utils/LocalDB";
import _ from "lodash";
import {CategoryTag} from "artemis-shared/tag/CategoryTag";
import {AgeGroupTag} from "artemis-shared/tag/AgeGroupTag";
import {GenderTag} from "artemis-shared/tag/GenderTag";

export const ParallelModal = ({competitionId, calculateAndSetPlacement, getLocalEntries}) => {
    const {t} = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const fileInputRef = useRef();

    const [updatedEntries, setUpdatedEntries] = useState([]);

    const handleOk =
        async () => {
            if (fileInputRef.current.files[0]) {
                await importInto(tempDb, fileInputRef.current.files[0], {
                    clearTablesBeforeImport: true,
                    acceptNameDiff: true,
                    noTransaction: true
                });

                let remoteEntries = await tempDb.entries
                    .where("competition")
                    .equals(parseInt(competitionId))
                    .toArray();

                let localEntries = await db.entries
                    .where("competition")
                    .equals(parseInt(competitionId))
                    .toArray();

                console.log("gotremote", remoteEntries);
                console.log("got", localEntries);

                let newUpdatedEntries = [];

                remoteEntries.forEach(remoteEntry => {
                    let entryFound = _.find(localEntries, {id: remoteEntry.id});

                    if(entryFound) {
                        if(remoteEntry.lastUpdated > (entryFound.lastUpdated || 0)) {
                            newUpdatedEntries.push(remoteEntry);
                        }
                    } else {
                        newUpdatedEntries.push(remoteEntry);
                    }
                });

                console.log("updatedentries", newUpdatedEntries);

                if(newUpdatedEntries.length === 0) {
                    await Swal.fire({
                        title: "Nincs eltérés!",
                        text: "A betöltött mentésben nincs frissebb adat mint ami ezen a gépen van!",
                        icon: "error",
                        showConfirmButton: true,
                    });
                }

                setUpdatedEntries(newUpdatedEntries);
            }
        }

    const handleCancel = () => {
        setIsModalVisible(false);
        if(fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setUpdatedEntries([]);
    };

    const saveUpdatedEntries = async () => {
        await db.entries.bulkPut(updatedEntries);

        let local = await getLocalEntries();
        await calculateAndSetPlacement(local);
        const bc = new BroadcastChannel("manager_display_channel");
        bc.postMessage({ selectChanged: true });

        await Swal.fire({
            title: t("manager.backupLoadSuccess"),
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
        });
        setIsModalVisible(false);
        if(fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setUpdatedEntries([]);
    }

    return <>
        <Button onClick={() => setIsModalVisible(true)}>{t("manager.parallelLoadButton")}</Button>
        {updatedEntries.length === 0 && <Modal title={t("manager.parallelTitle")} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
               okText={t("manager.load")} cancelText={t("manager.cancel")}>
            <Typography.Paragraph>
                {t("manager.parallelLoadDescription")}
            </Typography.Paragraph>
            <input type={"file"} ref={fileInputRef}/>
        </Modal>}
        {updatedEntries.length !== 0 && <Modal width={1000} title={t("manager.parallelTitle")} visible={isModalVisible} onOk={() => saveUpdatedEntries()} onCancel={handleCancel}
                                               okText={t("manager.load")} cancelText={t("manager.cancel")}>
            <Typography.Paragraph>
                Módosított sorok:
            </Typography.Paragraph>
            <Table dataSource={updatedEntries}>
                <Table.Column title={"#"} dataIndex={"id"} />
                <Table.Column title={t("manager.teamShort")} dataIndex={"team"} />
                <Table.Column title={t("manager.entryName")} dataIndex={"name"} />
                <Table.Column
                    title={t("manager.categoryShort")}
                    dataIndex={["category", "name"]}
                    render={(colValue, record) => (
                        <Tooltip placement="right" title={record.category?.description}>
                            <div>
                                <CategoryTag category={colValue} />
                            </div>
                        </Tooltip>
                    )}
                />
                <Table.Column
                    title={t("manager.ageGroupShort")}
                    dataIndex={["ageGroup", "ageGroup", "name"]}
                    render={(colValue) => <AgeGroupTag ageGroup={colValue} />}
                />
                <Table.Column
                    title={t("manager.gender")}
                    dataIndex={"gender"}
                    render={(colValue) => <GenderTag gender={colValue} />}
                />
            </Table>
        </Modal>}
    </>;
}