import React, { createContext, useContext, useState } from "react";
import { login } from "../api/Auth";
import axios from "axios";

const authContext = createContext();

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

export const useProvideAuth = () => {
  const [user, setUser] = useState(null);

  const signin = (usernamePassword) => {
    return login(usernamePassword).then((data) => {
      console.log("signin");
      axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
      localStorage.setItem("auth_token", data.token);
      setUser("user");
    });
  };

  const tokenSignin = (token) => {
    console.log("tokensignin");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("auth_token", token);
    setUser("user");
  };

  const signout = () => {
    console.log("signout");
    setUser(null);
    localStorage.setItem("auth_token", null);
    delete axios.defaults.headers.common["Authorization"];
    window.location.reload();
  };

  return {
    user,
    signin,
    tokenSignin,
    signout,
  };
};
