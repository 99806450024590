import React, { useEffect, useState } from "react";
import "./App.less";
import { Switch, Route, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { Home } from "../home/Home";
import { Login } from "../login/Login";
import { useHistory } from "react-router";
import { useNetworkStatus } from "../networkStatus/NetworkStatus";

import { useQuery } from "react-query";
import { getUserData } from "../api/User";
import { Tournaments } from "../tournaments/Tournaments";
import { Tournament } from "../tournament/Tournament";

import { Layout, Skeleton, Space, Alert, Typography } from "antd";
import { TournamentScoreSummary } from "../tournamentScoreSummary/TournamentScoreSummary";
import { CreateCompetition } from "../createCompetition/CreateCompetition";
import { ManageCompetition } from "../manageCompetition/ManageCompetition";
import { Manager } from "../manager/Manager";
import { TournamentManager } from "../tournamentManager/TournamentManager";
import { Register } from "../register/Register";
import { Navbar } from "../menu/NavBar";
import { useAuth } from "../auth/Auth";
import { PrivateRoute } from "../utils/PrivateRoute";
import { UserCalendar } from "../userCalendar/UserCalendar";
import { Privacy } from "../privacy/Privacy";
import { UserDataDeletion } from "../privacy/UserDataDeletion";
import { ErrorBoundary } from "../utils/ErrorBoundary";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { ManagerDashboard } from "../managerDashboard/ManagerDashboard";
import { Teams } from "../teams/Teams";
import { Athlete } from "../athlete/Athlete";
import { CookiePolicy } from "../privacy/CookiePolicy";
import { CookieConsent } from "../privacy/CookieConsent";
import { AppPromotion } from "../privacy/AppPromotion";
import { Ranking } from "../ranking/Ranking";
import { Introduction } from "../introduction/Introduction";
import { Helmet } from "react-helmet";
import { Scorecard } from "../scorecard/Scorecard";
import { CompetitionsWithCalendar } from "../competitions/CompetitionsWithCalendar";
import { CompetitionWithAuth } from "../competition/CompetitionWithAuth";
import { ClubProfile } from "../clubs/ClubProfile";
import { ClubList } from "../clubs/ClubList";
import { Targets } from "../targets/Targets";
import { useBreakpoint } from "artemis-shared/utils/Breakpoint";
import { TrainingList } from "../training/TrainingList";
import { CreateTraining } from "../training/CreateTraining";
import { Display } from "../manager/display/Display";
import { TrainingPage } from "../training/TrainingPage";
import ScrollToTop from "artemis-shared/utils/ScrollToTop";
import { Qualification } from "../qualification/Qualification";
import { PasswordReset } from "../auth/PasswordReset";
import { Tools } from "../tools/Tools";
import {ManagerDashboardSK} from "../managerDashboardSK/ManagerDashboardSK";
import {AccountRemoval} from "../accountRemoval/AccountRemoval";

const { Header, Content, Footer } = Layout;

function App() {
  const { xs, sm } = useBreakpoint();
  let location = useLocation();
  let auth = useAuth();
  let history = useHistory();
  let networkStatus = useNetworkStatus();
  let [waitingWorker, setWaitingWorker] = useState(null);
  let [worker, setWorker] = useState(null);
  let [newVersionAvailable, setNewVersionAvailable] = useState(false);

  const updateServiceWorker = (worker) => {
    console.log("updateServiceWorker");

    if (worker) {
      worker.postMessage({ type: "SKIP_WAITING" });
    } else {
      waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    }

    setNewVersionAvailable(false);
    window.location.reload();
  };

  const onServiceWorkerRegistered = (registration) => {
    console.log("onServiceWorkerRegistered", registration);
    setWorker(registration);

    if (registration && registration.waiting) {
      updateServiceWorker(registration && registration.waiting);
    }
  };

  const onServiceWorkerUpdate = (registration) => {
    console.log("onserviceworkerupdate");
    setWaitingWorker(registration && registration.waiting);
    setWorker(registration);
    // FORCE SKIP
    updateServiceWorker(registration && registration.waiting);
    //setNewVersionAvailable(true);
  };

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onServiceWorkerUpdate,
      onRegistered: onServiceWorkerRegistered,
    });
  }, []);

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        401 === error.response.status &&
        !error.request.responseURL.endsWith("/auth/login")
      ) {
        console.log("401 received");
        auth.signout();
        history.push("/login?redirect=" + location.pathname);
      } else {
        return Promise.reject(error);
      }
    }
  );

  let searchParams = new URLSearchParams(location.search);
  let authTokenUrlParam = searchParams.get("auth_token");

  if (authTokenUrlParam) {
    auth.tokenSignin(authTokenUrlParam);
    history.replace("/");
  }

  let waitForTokenSignin = false;
  let authTokenLocalStorage = localStorage.getItem("auth_token");
  if (authTokenLocalStorage && authTokenLocalStorage !== "null" && !auth.user) {
    waitForTokenSignin = true;
    auth.tokenSignin(authTokenLocalStorage);
  }

  const { isLoading: isLoadingUserData } = useQuery(["userData"], getUserData, {
    enabled: auth.user,
  });

  if (waitForTokenSignin && !auth.user) {
    return <div>Waiting for sign-in....</div>;
  }

  return (
    <>
      <Helmet>
        <title>Íjász.NET - Versenyek, nevezés, eredmények</title>
        <meta
          property="og:title"
          content="Íjász.NET - Versenyek, nevezés, eredmények"
        />
        <meta
          property="og:image"
          content="https://ijasz.net/facebook_banner.png"
        />
      </Helmet>
      {!networkStatus && <div className={"offline-message"}>Offline</div>}
      {newVersionAvailable && (
        <Alert
          onClick={() => updateServiceWorker()}
          showIcon={false}
          message={
            <span className={"worker-update-message"}>
              Az oldal új verziója érhető el. Kattints ide a frissítéshez!
            </span>
          }
          banner
          type="success"
        />
      )}
      <Layout className="layout">
        {location &&
          location.pathname &&
          location.pathname.indexOf("manager-display") === -1 && (
            <Header>
              <Navbar serviceWorker={worker} />
            </Header>
          )}
        <Content style={{ padding: "54px 50px 0 50px" }}>
          <div className="site-layout-content">
            {isLoadingUserData && networkStatus && (
              <Skeleton active paragraph={{ rows: 10 }} />
            )}
            {(!isLoadingUserData || !networkStatus) && (
              <ErrorBoundary>
                <ScrollToTop />
                <Switch>
                  <Route path="/tools">
                    <Tools />
                  </Route>
                  <Route path="/passwordreset">
                    <PasswordReset />
                  </Route>
                  <Route path="/qualification">
                    <Qualification />
                  </Route>
                  <Route path="/manager-display/:competitionId/:defaultDisplay">
                    <Display />
                  </Route>
                  <Route path="/manager-display/:competitionId">
                    <Display />
                  </Route>
                  <Route path="/training/create">
                    <CreateTraining />
                  </Route>
                  <Route path="/training/:trainingId">
                    <TrainingPage />
                  </Route>
                  <Route path="/training">
                    <TrainingList />
                  </Route>
                  <PrivateRoute path="/targets/:competitionId/:slug">
                    <Targets />
                  </PrivateRoute>
                  <Route path="/clubs">
                    <ClubList />
                  </Route>
                  <Route path="/club/:clubId">
                    <ClubProfile />
                  </Route>
                  <Route path="/scorecard">
                    <Scorecard />
                  </Route>
                  <Route path="/miert">
                    <Introduction />
                  </Route>
                  <Route path="/account-removal">
                    <AccountRemoval />
                  </Route>
                  <Route path="/ranking/:category/:ageGroup/:gender">
                    <Ranking />
                  </Route>
                  <Route path="/ranking">
                    <Ranking />
                  </Route>
                  <Route path="/athlete/:permitNumber">
                    <Athlete />
                  </Route>
                  <Route path="/manager-sk/:competitionId">
                    <Manager sk={true}/>
                  </Route>
                  <Route path="/manager/:competitionId/:slug">
                    <Manager />
                  </Route>
                  <Route path="/tournament-manager/:tournamentId/:slug">
                    <TournamentManager />
                  </Route>
                  <Route path="/competitions/:page/:pagingType">
                    <CompetitionsWithCalendar />
                  </Route>
                  <Route path="/competitions/:page">
                    <CompetitionsWithCalendar />
                  </Route>
                  <Route path="/competitions">
                    <CompetitionsWithCalendar />
                  </Route>
                  <Route path="/manager-dashboard-sk">
                    <ManagerDashboardSK />
                  </Route>
                  <PrivateRoute
                    path="/manager-dashboard"
                    availableOffline={true}
                  >
                    <ManagerDashboard />
                  </PrivateRoute>
                  <PrivateRoute path="/competition/create">
                    <CreateCompetition />
                  </PrivateRoute>
                  <PrivateRoute path="/competition/edit/:competitionId">
                    <CreateCompetition />
                  </PrivateRoute>
                  <Route path="/competition/:competitionId/:slug">
                    <CompetitionWithAuth />
                  </Route>
                  <Route path="/competition/:competitionId">
                    <CompetitionWithAuth />
                  </Route>
                  <PrivateRoute path="/manage-competition/:competitionId/:slug">
                    <ManageCompetition />
                  </PrivateRoute>
                  <PrivateRoute path="/manage-teams/:competitionId/:slug">
                    <Teams />
                  </PrivateRoute>
                  <PrivateRoute path="/calendar">
                    <UserCalendar />
                  </PrivateRoute>
                  <Route path="/tournaments">
                    <Tournaments />
                  </Route>
                  <Route path="/tournament/:tournamentId/:slug/summary">
                    <TournamentScoreSummary />
                  </Route>
                  <Route path="/tournament/:tournamentId/:slug">
                    <Tournament />
                  </Route>
                  <Route path="/register">
                    <Register />
                  </Route>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/privacy">
                    <Privacy />
                  </Route>
                  <Route path="/cookie-policy">
                    <CookiePolicy />
                  </Route>
                  <Route path="/user-data-deletion">
                    <UserDataDeletion />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </ErrorBoundary>
            )}
          </div>
        </Content>
        {location &&
          location.pathname &&
          location.pathname.indexOf("manager-display") === -1 && (
            <Footer style={{ textAlign: "center" }}>
              <Space split={"|"}>
                <span>info@ijasz.net</span>
                <Link to={"/privacy"}>Adatvédelmi irányelvek</Link>
                <Link to={"/cookie-policy"}>Süti szabályzat</Link>
                {sm && <span>Build: {process.env.REACT_APP_BUILD_NUMBER}</span>}
              </Space>
              {xs && !sm && (
                <div style={{ marginTop: "10px" }}>
                  <Typography.Text type="secondary">
                    Build: {process.env.REACT_APP_BUILD_NUMBER}
                  </Typography.Text>
                </div>
              )}
            </Footer>
          )}
      </Layout>
      <CookieConsent />
      <AppPromotion />
    </>
  );
}

export default App;
