import {ArrowCountExceptionModal} from "./ArrowCountExceptionModal";
import {Collapse} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

const {Panel} = Collapse;

export const ArrowCountExceptionInput = ({value, onChange, categories, ageGroups}) => {
    const {t} = useTranslation();
    return <Collapse style={{marginBottom: "20px"}}>
        <Panel header={t("manager.arrowCountExceptions")} key="1">
            <h4>{t("manager.arrowCountExceptionsDesc")}</h4>
            <div style={{marginTop: "50px"}}>
                <ArrowCountExceptionModal exceptions={value}
                                          setExceptions={(exceptions) => onChange(exceptions)}
                                          categories={categories}
                                          ageGroups={ageGroups}/>
            </div>
        </Panel>
    </Collapse>;
}