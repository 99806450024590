import _ from "lodash";

export const getShotCount = (entry) =>
  Object.keys(entry.scores).reduce((acc, scoreValue) => {
    let value = parseInt(entry.scores[scoreValue]);

    return acc + (isNaN(value) ? 0 : value);
  }, 0);

export const getScore = (entry, scoring) => {
  const maxScoringValue = _.max(scoring.map(value => value === "x" ? -1 : parseInt(value)));
  return Object.keys(entry.scores).reduce((acc, scoreValue) => {
    let value = parseInt(entry.scores[scoreValue]);

    return acc + (isNaN(value) ? 0 : value) * parseInt((scoreValue === "x" ? maxScoringValue : scoreValue));
  }, 0);
}

export const encodeScorecard = (targetCount, scoring, entries, firstTargetNumber = 1) => {
  console.log("scoring", scoring);
  console.log("entries", entries);

  const version = "02";
  const scorecardType = "1";

  const targetCountString = targetCount.toString().padStart(3, "0");
  console.log("targetCountString", targetCountString);

  const scoringValueCount = scoring.length - 1;
  console.log("scoringValueCount", scoringValueCount);

  let scoringString = _.orderBy(scoring, "desc")
    .map((scoringValue) => scoringValue.padStart(2, "0"))
    .join("");
  console.log("scoringString", scoringString);

  const firstTargetNumberString = firstTargetNumber.toString().padStart(3, "0");
  console.log("firstTargetNumberString", firstTargetNumberString);

  let encodedEntries = entries
    .map((entry) => {
      let id = entry.id.padStart(6, "0");
      console.log("id", id);
      let arrowsPerTarget = entry.arrowsPerTarget.toString().padStart(2, "0");
      console.log("arrowsPerTarget", arrowsPerTarget);
      let scoreString = entry.flatScores
        .map((score) => (scoring.indexOf(score.toString())).toString(16))
        .join("");
      console.log("scoreString", scoreString);

      let concatenated = `${id}${arrowsPerTarget}${scoreString}`;
      console.log("concat", concatenated);

      return concatenated;
    })
    .join("");
  console.log("encodedentries", encodedEntries);

  let encodedScorecard = `${version}${scorecardType}${targetCountString}${scoringValueCount}${scoringString}${firstTargetNumberString}${encodedEntries}`;
  console.log("encoded", encodedScorecard);

  return encodedScorecard;
};

export const decodeScorecard = (encodedString, withToken) => {
  console.log(" DECODE ");
  let encoded = encodedString.split("");
  const version = encoded.splice(0, 2).join("");
  console.log("version", version);

  const withFirstTargetNumber = parseInt(version) >= 2;
  console.log("withFirstTargetNumber", withFirstTargetNumber);

  const longId = parseInt(version) >= 2;
  console.log("longId", longId);

  const scorecardTypeString = encoded.splice(0, 1).join("");
  const scorecardType = parseInt(scorecardTypeString);
  console.log("scorecardType", scorecardType);

  const targetCountString = encoded.splice(0, 3).join("");
  const targetCount = parseInt(targetCountString);
  console.log("targetCount", targetCount);

  const scoringValueCountString = encoded.splice(0, 1).join("");
  const scoringValueCount = parseInt(scoringValueCountString, 16) + 1;
  console.log("scoringValueCount", scoringValueCount);

  const scoringString = encoded.splice(0, scoringValueCount * 2).join("");
  console.log("scoringString", scoringString);

  const scoringValues = scoringString
    .match(/.{1,2}/g).map(value => value === "0x" ? "x" : parseInt(value).toString());
  console.log("scoringValues", scoringValues);

  const baseScoresObj = {};
  scoringValues.forEach((scoringValue) => (baseScoresObj[scoringValue] = 0));

  let firstTargetNumber = 1;
  let firstTargetNumberString = "001";
  if(withFirstTargetNumber) {
    firstTargetNumberString = encoded.splice(0, 3).join("");
    firstTargetNumber = parseInt(firstTargetNumberString);
  }
  console.log("firstTargetNumber", firstTargetNumber);

  const entries = [];
  const scoreCards = {};
  const decodedScorecards = {};

  while (encoded.length > 0) {
    let decodedObj = { scores: [] };
    decodedObj.targetCount = targetCount;
    decodedObj.scoringValues = scoringValues;
    decodedObj.firstTargetNumber = firstTargetNumber;

    let idString = encoded.splice(0, longId ? 6 : 5).join("");
    let id = parseInt(idString);
    decodedObj.id = id;
    console.log("ENTRY - id ", id);

    decodedObj.token = withToken ? encoded.splice(0, 36).join("") : null;

    let arrowsPerTargetString = encoded.splice(0, 2).join("");
    let arrowsPerTarget = parseInt(arrowsPerTargetString);
    decodedObj.arrowsPerTarget = arrowsPerTarget;
    console.log("arrowsPerTarget", arrowsPerTarget);


    /////// BEGIN FULLSCORECARD ///////////
    if(scorecardType === 1) {
      let flatScoresString = encoded.splice(0, targetCount * arrowsPerTarget);
      console.log("flatScoresString", flatScoresString);
      let scores = flatScoresString.map((s) => scoringValues[parseInt(s, 16)]);
      console.log("scores", scores);

      scores.forEach((score, index) => {
        let target = Math.floor(index / arrowsPerTarget);

        if (!decodedObj.scores[target]) {
          decodedObj.scores[target] = { target: target, scores: [] };
        }

        decodedObj.scores[target].scores.push(score);
      });

      let count = _.countBy(scores);
      console.log("count", count);

      let mergedScores = { ...baseScoresObj, ...count };
      console.log("mergedScores", mergedScores);

      let scoreString = Object.entries(mergedScores)
          .sort((a, b) => (b[0] == "x" ? 15 : b[0]) - (a[0] == "x" ? 15 : a[0]))
          .map(([scoringValue, score]) => score || 0)
          .join(",");

      console.log("scoreString", scoreString);

      let entryString = `${id},${scoreString}`;
      console.log("entryString", entryString);
      entries.push(entryString);
      scoreCards[
          id
          ] = `${version}${scorecardTypeString}${targetCountString}${scoringValueCountString}${scoringString}${withFirstTargetNumber ? firstTargetNumberString : ""}${idString}${arrowsPerTargetString}${flatScoresString.join(
          ""
      )}`;

      decodedScorecards[id] = decodedObj;
    }
    /////// END FULLSCORECARD ///////////

    /////// BEGIN SUMMARYSCORECARD ///////////
    if(scorecardType === 2) {
      let flatScoresString = encoded.splice(0, scoringValues.length * 2);
      console.log("flatScoresString", flatScoresString);

      let scoreString = flatScoresString.join("").match(/.{1,2}/g).join(",");
      console.log("scoreString", scoreString);

      let entryString = `${id},${scoreString}`;
      console.log("entryString", entryString);
      entries.push(entryString);
      scoreCards[id] = null;

      decodedScorecards[id] = decodedObj;
    }
    /////// END SUMMARYSCORECARD ///////////
  }

  let result = {
    decodedScorecards: decodedScorecards,
    scorecards: scoreCards,
    entriesString: entries.join(";"),
  };
  console.log("result", result);

  return result;
};
