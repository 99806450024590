import React, { useMemo, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import {
    Affix,
    Button,
    Card,
    Input,
    Pagination,
    Popconfirm, Select,
    Space,
    Switch,
    Tooltip,
} from "antd";
import { Group } from "./Group";
import {
  SearchOutlined,
  PushpinFilled,
  PushpinOutlined,
} from "@ant-design/icons";
import { CategoryInput } from "artemis-shared/entryForm/CategoryInput";
import { AgeGroupInput } from "artemis-shared/entryForm/AgeGroupInput";
import { GenderInput } from "artemis-shared/entryForm/GenderInput";
import { InnerTeam } from "./InnerTeam";
import { ClubInput } from "artemis-shared/entryForm/ClubInput";
import _ from "lodash";

export const EntriesCol = ({
  entries,
  unlockGroup,
  competition,
  rawEntries,
  resetTeams,
  orderBy,
  setOrderBy,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(7);
  const [searchText, setSearchText] = useState("");
  const [searchCategory, setSearchCategory] = useState(null);
  const [searchAgeGroup, setSearchAgeGroup] = useState(null);
  const [searchGender, setSearchGender] = useState(null);
  const [searchClub, setSearchClub] = useState(null);
  const [searchRound, setSearchRound] = useState(null);
  const [affixEnabled, setAffixEnabled] = useState(false);

  let fromEntry = (currentPage - 1) * currentPageSize;
  let toEntry = currentPage * currentPageSize;

  let filteredEntries = useMemo(
    () =>
      entries[0]
        ? entries[0]
            .filter((group) => {
              return searchText === ""
                ? true
                : group.entries.find((entry) => {
                    return (
                      entry.name
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) > -1
                    );
                  });
            })
            .filter((group) => {
              return !searchCategory
                ? true
                : group.entries.find((entry) => {
                    return entry.category.id === searchCategory.id;
                  });
            })
            .filter((group) => {
              return !searchAgeGroup
                ? true
                : group.entries.find((entry) => {
                    return (
                      entry.ageGroup.ageGroup.id === searchAgeGroup.ageGroup.id
                    );
                  });
            })
            .filter((group) => {
              return !searchGender
                ? true
                : group.entries.find((entry) => {
                    return entry.gender === searchGender;
                  });
            })
            .filter((group) => {
              return !searchClub
                ? true
                : group.entries.find((entry) => {
                    return entry.club.id === searchClub;
                  });
            })
              .filter((group) => {
                  return searchRound === null
                      ? true
                      : group.entries.find((entry) => {
                          return entry.roundEntries[searchRound].active;
                      });
              })
        : [],
    [
      entries,
      searchText,
      searchCategory,
      searchAgeGroup,
      searchGender,
      searchClub,
      searchRound,
      orderBy,
    ]
  );
  const slicedFilteredEntries = useMemo(
    () => filteredEntries.slice(fromEntry, toEntry),
    [filteredEntries, fromEntry, toEntry]
  );

  const innerContent = (provided) => {
    return (
      <Card
        title={"Nevezők"}
        key={"entries"}
        extra={
          <Space>
            <Switch
              key={"orderBy"}
              checkedChildren="Erősorrend"
              unCheckedChildren="ABC sorrend"
              checked={orderBy}
              onChange={(checked) => setOrderBy(checked)}
            />
            <Popconfirm
              title="Az igen gombra kattintva kiüríted az eddig elkészített csapatbeosztást, és minden versenyző visszakerül ebbe a listába. Biztosan ezt szeretnéd?"
              onConfirm={() => {
                resetTeams();
              }}
              onCancel={() => {}}
              okText="Igen"
              cancelText="Nem"
            >
              <Button type={"danger"}>Visszaállítás</Button>
            </Popconfirm>
            {affixEnabled ? (
              <Tooltip placement="left" title={"A lista rögzítése a képernyőn"}>
                <PushpinFilled
                  style={{ fontSize: "20px" }}
                  onClick={() => setAffixEnabled(false)}
                />
              </Tooltip>
            ) : (
              <Tooltip placement="left" title={"A lista rögzítése a képernyőn"}>
                <PushpinOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => setAffixEnabled(true)}
                />
              </Tooltip>
            )}
          </Space>
        }
      >
        <div className={"search-input"}>
          <CategoryInput
            allowClear={true}
            placeholder={"Kategória"}
            style={{ width: "110px" }}
            categories={competition.categories}
            value={searchCategory ? searchCategory.id : null}
            onChange={(value) => {
              setCurrentPage(1);
              setSearchCategory(
                competition.categories.find((category) => category.id === value)
              );
            }}
          />
          <AgeGroupInput
            allowClear={true}
            placeholder={"Korosztály"}
            style={{ width: "110px" }}
            ageGroups={competition.ageGroups}
            value={searchAgeGroup ? searchAgeGroup.id : null}
            onChange={(value) => {
              setCurrentPage(1);
              setSearchAgeGroup(
                competition.ageGroups.find((ageGroup) => ageGroup.id === value)
              );
            }}
          />

          <GenderInput
            allowClear={true}
            placeholder={"Nem"}
            style={{ width: "110px" }}
            value={searchGender ? searchGender : null}
            onChange={(value) => {
              setCurrentPage(1);
              setSearchGender(value);
            }}
          />
        </div>
        <div className={"search-input"}>
          <ClubInput
            clubs={
              rawEntries
                ? _.uniqBy(
                    rawEntries.map((entry) => entry.club),
                    "id"
                  ).filter((c) => c.id !== 999)
                : []
            }
            allowClear={true}
            placeholder={"Egyesület"}
            style={{ width: "210px" }}
            value={searchClub ? searchClub : null}
            onChange={(value) => {
              setCurrentPage(1);
              setSearchClub(value);
            }}
          />
          <Input
            placeholder="Keresés..."
            prefix={<SearchOutlined />}
            onChange={(event) => {
              setCurrentPage(1);
              setSearchText(event.target.value);
            }}
          />
        </div>
        {competition.rounds.length > 1 && <div className={"search-input"}>
              <Select style={{ width: "210px" }}
                  value={searchRound}
                  onChange={(value) => {
                      setCurrentPage(1);
                      setSearchRound(value);
                  }}
              >
                  <Select.Option key={"both"} value={null}>
                      Mindkét nap
                  </Select.Option>
                  <Select.Option key={"0"} value={0}>
                      1. nap
                  </Select.Option>
                  <Select.Option key={"1"} value={1}>
                      2. nap
                  </Select.Option>
              </Select>
        </div>
        }
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <InnerTeam
            entries={slicedFilteredEntries}
            unlockGroup={unlockGroup}
          />
          {provided.placeholder}
        </div>
        {entries[0] && (
          <Pagination
            size={"small"}
            current={currentPage}
            showSizeChanger
            pageSizeOptions={[7, 10, 11, 12, 15, 20, 30, 50, 100, 200]}
            onShowSizeChange={(current, pageSize) =>
              setCurrentPageSize(pageSize)
            }
            pageSize={currentPageSize}
            total={filteredEntries.length}
            onChange={setCurrentPage}
          />
        )}
      </Card>
    );
  };

  return (
    <Droppable droppableId={`team-0`} key={"team-0"} isCombineEnabled>
      {(provided) => {
        return (
          <>
            {affixEnabled && (
              <Affix offsetTop={0}>{innerContent(provided)}</Affix>
            )}
            {!affixEnabled && innerContent(provided)}
          </>
        );
      }}
    </Droppable>
  );
};
