import React, { useEffect, useState } from "react";
import { Menu, Grid, Button } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../auth/Auth";
import { useQuery } from "react-query";
import { getUserData } from "../api/User";
import { useNetworkStatus } from "../networkStatus/NetworkStatus";
import { db } from "../utils/LocalDB";
import { ExportOutlined, UserOutlined, BellOutlined } from "@ant-design/icons";
import { CreateProfileModal } from "../createProfileModal/CreateProfileModal";
import { useBreakpoint } from "artemis-shared/utils/Breakpoint";
import { NotificationMenu } from "../notification/NotificationMenu";

export const RightMenu = ({ onClose, serviceWorker }) => {
  const networkStatus = useNetworkStatus();
  const auth = useAuth();
  const [localCompetitions, setLocalCompetitions] = useState(null);
  const [createProfileModalVisible, setCreateProfileModalVisible] =
    useState(false);
  const { data: userData } = useQuery("userData", getUserData, {
    enabled: auth.user,
  });
  const { lg, menu } = useBreakpoint();
  let location = useLocation();

  useEffect(() => {
    db.competitions.toArray().then((lc) => setLocalCompetitions(lc));
  }, []);

  return (
    <>
      <Menu
        selectable={false}
        theme={"dark"}
        mode={!menu ? "horizontal" : "inline"}
      >
        {auth.user && (
          <>
            {!networkStatus && localCompetitions && (
              <Menu.Item className={"logout-button"}>
                <Link to={"/manager-dashboard"} onClick={onClose}>
                  Vezérlőpult
                </Link>
              </Menu.Item>
            )}
            {userData && (
              <Menu.Item id={"dashboard-button"}>
                <Link to={"/manager-dashboard"} onClick={onClose}>
                  Vezérlőpult
                </Link>
              </Menu.Item>
            )}
            {userData && (
              <Menu.SubMenu
                className={"profile-mobile"}
                key="3"
                title={
                  <>
                    <UserOutlined />
                    <span>Én</span>
                  </>
                }
              >
                {"serviceWorker" in navigator && "PushManager" in window && (
                  <Menu.Item key={"notifications"}>
                    <NotificationMenu serviceWorker={serviceWorker} />
                  </Menu.Item>
                )}
                <Menu.Item key="5">
                  <Link to="/calendar" onClick={onClose}>
                    Naptáram
                  </Link>
                </Menu.Item>
                {userData.profiles &&
                  userData.profiles.map((profile) => (
                    <Menu.Item key={profile.id}>
                      {" "}
                      <Link to={"/athlete/" + profile.permitNumber}>
                        {profile.name}
                      </Link>
                    </Menu.Item>
                  ))}
                <Menu.Item key={"newProfile"}>
                  <Button
                    ghost
                    block
                    onClick={() => setCreateProfileModalVisible(true)}
                  >
                    Új profil
                  </Button>
                </Menu.Item>
              </Menu.SubMenu>
            )}
            <Menu.Item className={"logout-button"}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  auth.signout();
                }}
              >
                <div className={"logout-desktop"}>
                  <Button ghost>
                    <ExportOutlined style={{ fontSize: "20px" }} />
                  </Button>
                </div>
                <div className={"logout-mobile"}>Kilépés</div>
              </div>
            </Menu.Item>
          </>
        )}
        {!auth.user && (
          <Menu.Item className={"login-button"}>
            <Link to={"/login?redirect=" + location.pathname} onClick={onClose}>
              Bejelentkezés
            </Link>
          </Menu.Item>
        )}
      </Menu>
      <CreateProfileModal
        visible={createProfileModalVisible}
        onCreate={() => {
          setCreateProfileModalVisible(false);
        }}
        onCancel={() => {
          setCreateProfileModalVisible(false);
        }}
      />
    </>
  );
};
