import React, { useEffect, useState } from "react";
import { Numpad } from "./Numpad";
import { Button, Divider, Space, Typography } from "antd";

import "./EnterScores.less";
import {
  getScore,
  getShotCount,
} from "artemis-shared/scorecard/ScorecardUtils";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { GenderTag } from "artemis-shared/tag/GenderTag";

const { Title } = Typography;

export const EnterScores = ({
  entries,
  setEntryValue,
  setActivePage,
  currentEntryIndex,
  setCurrentEntryIndex,
    scoring,
}) => {
  const [selectedScoringValue, setSelectedScoringValue] = useState("");

  const currentEntry = entries[currentEntryIndex];

  console.log(currentEntry);

  useEffect(
    () =>
      setSelectedScoringValue(
        Object.keys(currentEntry.scores).sort(
          (a, b) => parseInt(b) - parseInt(a)
        )[0]
      ),
    [currentEntry]
  );

  return (
    <div className={"enter-scores"}>
      <div className={"entry-title"}>
        <span>
          {currentEntry.name} {currentEntry.manual && currentEntry.id}
        </span>
        <br />
        <CategoryTag category={currentEntry.category} />
        <AgeGroupTag ageGroup={currentEntry.ageGroup} />
        <GenderTag gender={currentEntry.gender} />
      </div>

      <div
        className={
          "score " +
          (getShotCount(currentEntry) === currentEntry.arrowCount
            ? "valid"
            : "invalid")
        }
      >
        <Space split={<Divider type="vertical" />}>
          <div>
            <div>Pontszám:</div>
            <div className={"score-sum"}>{getScore(currentEntry, scoring)}</div>
          </div>
          <div>
            <div>Találatok:</div>
            <div className={"score-sum"}>
              {getShotCount(currentEntry)}/{currentEntry.arrowCount}
            </div>
          </div>
        </Space>
      </div>

      <div className={"input-container"}>
        {Object.keys(currentEntry.scores)
          .sort((a, b) => parseInt(b) - parseInt(a))
          .map((scoringValue) => {
            if (scoringValue !== "score") {
              return (
                <div
                  className={"input-box"}
                  onClick={() => {
                    setSelectedScoringValue(scoringValue);
                    currentEntry.scores[scoringValue] &&
                      setEntryValue(currentEntry.id, scoringValue, "");
                  }}
                >
                  <div className={"input-title"}>{scoringValue}</div>
                  <div
                    className={
                      "custom-input " +
                      (scoringValue === selectedScoringValue ? "selected" : "")
                    }
                  >
                    {currentEntry.scores[scoringValue]}
                  </div>
                </div>
              );
            }
          })}
      </div>

      {getShotCount(currentEntry) === currentEntry.arrowCount && (
        <Button
          block
          type={"primary"}
          size={"large"}
          className={"sign-button"}
          onClick={() => {
            if (currentEntryIndex === entries.length - 1) {
              setActivePage("teamQr");
            } else {
              setCurrentEntryIndex(currentEntryIndex + 1);
            }
          }}
        >
          AZ EREDMÉNY RÖGZÍTÉSE
        </Button>
      )}

      <Numpad
        onType={(digit) => {
          let newDigit =
            currentEntry.scores[selectedScoringValue].toString() + digit;

          if (newDigit.length <= 2) {
            setEntryValue(currentEntry.id, selectedScoringValue, newDigit);
          }

          if (newDigit.length >= 2 && selectedScoringValue !== "0") {
            let sortedScoringValues = Object.keys(currentEntry.scores).sort(
              (a, b) => parseInt(b) - parseInt(a)
            );

            setSelectedScoringValue(
              sortedScoringValues[
                sortedScoringValues.indexOf(selectedScoringValue) + 1
              ]
            );
          }
        }}
        onDelete={() => {
          if (currentEntry.scores[selectedScoringValue].toString() === "") {
            let sortedScoringValues = Object.keys(currentEntry.scores).sort(
              (a, b) => parseInt(b) - parseInt(a)
            );

            if (selectedScoringValue !== sortedScoringValues[0]) {
              setSelectedScoringValue(
                sortedScoringValues[
                  sortedScoringValues.indexOf(selectedScoringValue) - 1
                ]
              );
            }
          } else {
            setEntryValue(
              currentEntry.id,
              selectedScoringValue,
              currentEntry.scores[selectedScoringValue].toString().slice(0, -1)
            );
          }
        }}
        onSubmit={() => {
          if (selectedScoringValue !== "0") {
            let sortedScoringValues = Object.keys(currentEntry.scores).sort(
              (a, b) => parseInt(b) - parseInt(a)
            );

            setSelectedScoringValue(
              sortedScoringValues[
                sortedScoringValues.indexOf(selectedScoringValue) + 1
              ]
            );
          }
        }}
      />
    </div>
  );
};
