import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const detectionOptions = {
    // order and from where user language should be detected
    order: ['querystring'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng'
};

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: detectionOptions,
        fallbackLng: 'hu',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        resources: {
            hu: {
                translation: {
                    MALE: "Férfi",
                    FEMALE: "Nő",
                    competition: {
                        applicationCardTitle: "Nevezés",
                        maxParticipants: "Max jelentkezők",
                        applicationDeadline: "Határidő",
                        participants: "Nevezők listája",
                        participantsShort: "Nevezők",
                        participant: "nevező",
                        description: "Versenykiírás",
                        results: "Eredmények",
                        teams: "Csapatbeosztás",
                        success: "Sikeres nevezés!",
                        successMsg: "A nevezést módosíthatod, visszavonhatod a visszaigazoló emailben szereplő linkkel.",
                        applicationError: "Sikertelen nevezés!",
                    },
                    applicationForm: {
                        applicationIsClosed: "A nevezés lezárult!",
                        full: "A létszám betelt!",
                        permitNumber: "Versenyeng.",
                        required: "Kötelező megadni!",
                        invalidPermitNumber: "Nem létező engedélyszám!",
                        lastName: "Vezetéknév",
                        invalidLastName: "Hibás név!",
                        nameRequired: "Add meg a neved!",
                        firstName: "Keresztnév",
                        invalidFirstName: "Ehhez a veng. számhoz ez a név tartozik: ",
                        emailRequired: "Add meg az email címed!",
                        club: "Egyesület",
                        clubRequired: "Válaszd ki az egyesületed!",
                        guestLunch: "Vendégebéd",
                        lunch: "Ebéd",
                        lunchRequired: "Add meg hány ebédet kérsz!",
                        gender: "Nem",
                        genderRequired: "Válassz nemet!",
                        category: "Kategória",
                        categoryRequired: "Válassz kategóriát!",
                        ageGroup: "Korosztály",
                        ageGroupRequired: "Válassz korosztályt!",
                        rounds: "Melyik nap",
                        roundsRequired: "Válassz napot!",
                        rounds0: "Mindkét nap",
                        rounds1: "Csak az első nap",
                        rounds2: "Csak a második nap",
                        team: "Csapat",
                        teamRequired: "Válassz egy csapatot!",
                        comment: "Megjegyzés, csapatbeosztás",
                        requiredAdditionalData: "Kötelező mező",
                        country: "Ország",
                        warning: "Figyelem!",
                        privacy1: "A versenyszabályzatot és az",
                        privacy2: "Adatvédelmi irányelveket",
                        privacy3: "megismertem és elfogadom!",
                        privacyRequired: "El kell fogadni!",
                        modify: "Nevezés módosítása",
                        apply: "Nevezés leadása",
                        requiredCountry: "Válassz országot"
                    },
                    genderInput: {
                        male: "Férfi",
                        female: "Nő"
                    },
                    clubInput: {
                        other: "Egyéb"
                    },
                    entries: {
                        team: "Cs",
                        name: "Név",
                        category: "Kategória",
                        categoryShort: "Kateg.",
                        ageGroup: "Korosztály",
                        ageGroupShort: "Koroszt.",
                        gender: "Nem",
                        club: "Egyesület",
                        country: "Ország"
                    },
                    manager: {
                        controlQR: "Második beíró",
                        recordSecond: "Második beíró rögzítése",
                        dashboardTitle: "Vezérlőpult SK",
                        newCompetition:"Új verseny",
                        existingEntry: "Létező nevezés!",
                        finals: "Döntő",
                        finalsrule: "Döntő",
                        round0: "1. forduló",
                        round1: "2. forduló",
                        round2: "3. forduló",
                        type: "Típus",
                        normal: "Normál",
                        gp: "GP",
                        threeRound: "GP",
                        hasFinals: "Van",
                        noFinals: "Nincs",
                        compName: "Verseny neve",
                        required: "Kötelező",
                        teamCount: "Csapatok száma",
                        targetCount: "Célok száma",
                        arrowCount: "Vesszők száma",
                        lunchFee: "Ebéd díj",
                        import: "Beolvasás",
                        competitions: "Versenyek",
                        date: "Date",
                        name: "Név",
                        office: "Versenyiroda",
                        teams: "Csapatok",
                        deleteConfirm: "Biztos hogy törlőd?",
                        yes: "Igen",
                        cancel: "Mégsem",
                        updatingTeams: "Csapatok frissítése",
                        updateSuccess: "Sikeres frissítés",
                        update: "Frissítés",
                        uploadSuccess: "Sikeres feltöltés",
                        uploadFailed: "Sikertelen feltöltés",
                        scorecardsLoaded: "Eredmények rögzítve!",
                        registerSuccess: "Sikeres nevezés",
                        updateWarning: "Figyelem! Az adatok frissítésére készülsz!",
                        downloadAgain: "Igen, újra letöltöm az adatokat!",
                        scanQrError: "Hiba történt a beolvasás közben! A táblázatból hiányzó eredményeket (piros) kézzel kell felvenni! A többi eredmény rögzül a gombra kattintva. Kérlek hogy jelezd nekem a hibát!",
                        teamShort: "Cs",
                        entryName: "Név",
                        categoryShort: "Kat",
                        ageGroupShort: "Kor",
                        gender: "Nem",
                        score: "Pont",
                        offlineWarning: "A pontszámok kezelése offline, internetkapcsolat nélkül működik. A módosítások nem kerülnek automatikusan feltöltésre, azok csak ezen a készüléken lesznek elérhetőek amíg fel nem töltöd!",
                        competitionDescription: "Versenykiírás",
                        close: "Bezárás",
                        closeQR: "Bezárás",
                        visibleColumns: "Megjelenítendő oszlopok",
                        secondDisplay: "Második kijelző",
                        entriesDisplay: "Nevezés",
                        progressDisplay: "Folyamat",
                        resultsDisplay: "Eredmény",
                        exportExcel: "Excel mentése",
                        scanQr: "QR beolvasás",
                        downloadData: "Adatok letöltése",
                        downloadDataToDevice: "Adatok letöltése az eszközre",
                        qrStickers: "QR Matricák",
                        skResults: "SK Results",
                        noData: "Nincsenek letöltött adatok",
                        downloadedData: "Adatok letöltve",
                        scoring: "Pontozás",
                        tiebreaker: "Holtverseny",
                        lunch: "Ebéd",
                        arrived: "Megérkezett",
                        processed: "Feldolgozva",
                        invalidResults: "Hibás eredmény",
                        entries: "Nevezők",
                        scores: "Pontszámok",
                        results: "Helyezések",
                        scannedValues: "Beolvasott értékek",
                        round: "forduló",
                        record: "Rögzítés",
                        back: "Vissza",
                        chooseTemplate: "Válassz sablont",
                        stickerPerCompetitor: "Matrica per versenyző",
                        sortBy: "Sorbarendezés mi szerint",
                        abc: "ABC szerint",
                        byTeam: "Csapat szerint",
                        preview: "Előnézet",
                        download: "Letöltés",
                        selectEntries: "Versenyzők kiválasztása",
                        search: "Keresés (név vagy azonosító) ...",
                        checkedIn: "Bejelentkezett",
                        notCheckedIn: "Nem jelentkezett be",
                        idShort: "Azon.",
                        permitShort: "Veng.",
                        man: "Férfi",
                        woman: "Nő",
                        entryFee: "Nev. díj",
                        comment: "Megjegyzés",
                        deleteEntryConfirm: "Biztos hogy törlöd a nevezést?",
                        newEntry: "Új versenyző felvétele erre az eszközre",
                        entryFeeShort: "Nevezés",
                        total: "Összesen",
                        clear: "Nullázás / Bezárás",
                        automaticScoring: "Automatikus helyezés kalkuláció",
                        automaticScoreFill: "Automatikus pont kitöltés",
                        club: "Egyesület",
                        shots: "lövés",
                        calculated: "Kalkulált",
                        tiebreakerScore: "Szétlövés",
                        placement: "Helyezés",
                        totalShort: "Össz",
                        deleteFullEntryWarning: "Biztos hogy törlöd a TELJES nevezést, minden fordulóval?",
                        sum: "Össz.",
                        goldMedal: "Arany érem",
                        silverMedal: "Ezüst érem",
                        bronzeMedal: "Bronz érem",
                        bestWithSight: "Legjobb irányzékos felnőtt",
                        bestWoSight: "Legjobb irányzék nélküli felnőtt",
                        print: "Nyomtatás",
                        printPodium: "Dobogós PDF",
                        generatePodiumExcel: "Dobogós XLS",
                        categoryList: "Kategória lista",
                        teamMembers: "Csapattagok",
                        teamFilter: "Csapat szűrés",
                        teamCreateConfirm: "Biztos hogy felveszel egy új csapatot?",
                        border: "Keret",
                        teamNumber: "Csapatszám",
                        additionalData: "Egyéb adat",
                        firstName: "Keresztnév",
                        lastName: "Vezetéknév",
                        guestLunch: "Vendégebéd",
                        rounds: "Melyik nap",
                        both: "Mindkét nap",
                        firstRoundOnly: "Csak az első nap",
                        secondRoundOnly: "Csak a második nap",
                        createEntry: "Nevezés leadása",
                        team: "Csapat",
                        stickerman: "Férfi",
                        stickerwoman: "Nő",
                        permitNumber: "Versenyeng.",
                        country: "Ország",
                        backupLoadSuccess: "Sikeres betöltés",
                        backupLoadSuccessText: "Frissítsd a megnyitott böngészőablakokat",
                        backupTitle: "Biztonsági mentés betöltése",
                        load: "Betöltés",
                        backupLoadButton: "Biztonsági mentés betöltése",
                        backupLoadDescription: "A számítógépen található ÖSSZES letöltött versenyiroda visszaáll a mentésben szereplő adatokra. Biztos hogy FELÜLÍROD a számítógépen szereplő ÖSSZES adatot?",
                        backupButton: "Biztonsági mentés",
                        arrowCountExceptions: "Alaptól eltérő lövésszámok",
                        arrowCountExceptionsDesc: "Ha valamelyik kategória + korosztály többet vagy kevesebbet lő célonként mint az alapbeállítás, itt add meg. Elég az alaptól eltérő értékeket megadni, nem kell az összeset!",
                        finalsDisplay: "Döntő",
                        parallelLoadButton: "Párhuzamos betöltés",
                        parallelTitle: "Párhuzamos betöltés",
                        parallelLoadDescription: "Ha egyszerre több laptopon történik a feldolgozás, itt lehet betölteni a tobbi laptop biztonsági mentését. A filet kiválasztva, a LEGUTOLJÁRA MÓDOSÍTOTT sorok maradnak meg. Teljes sorok kerülnek betöltésre, nem csak a változtatott érték. Betöltés után megjelennek a módosított sorok.",
                        scorecards: "Beírólapok",
                        placementShort: "Hely"

                    },
                    genderTag: {
                        MALE: "Férfi",
                        FEMALE: "Nő"
                    },
                    country: {
                        AFG:"Afghanistan",
                        ALB:"Albania",
                        ALG:"Algeria",
                        AND:"Andorra",
                        ANG:"Angola",
                        ANT:"Antigua and Barbuda",
                        ARG:"Argentina",
                        ARM:"Armenia",
                        ARU:"Aruba",
                        AUS:"Australia",
                        ANZ:"Australasia",
                        AUT:"Austria",
                        AZE:"Azerbaijan",
                        BAH:"The Bahamas",
                        BRN:"Bahrain",
                        BAN:"Bangladesh",
                        BAR:"Barbados",
                        BLR:"Belarus",
                        BEL:"Belgium",
                        BIZ:"Belize",
                        BER:"Bermuda",
                        BEN:"Benin",
                        BHU:"Bhutan",
                        BOH:"Bohemia",
                        BOL:"Bolivia",
                        BIH:"Bosnia and Herzegovina",
                        BOT:"Botswana",
                        BRA:"Brazil",
                        IVB:"British Virgin Islands",
                        BWI:"British West Indies",
                        BRU:"Brunei",
                        BUL:"Bulgaria",
                        BUR:"Burkina Faso",
                        BDI:"Burundi",
                        CAM:"Cambodia",
                        CMR:"Cameroon",
                        CAN:"Canada",
                        CPV:"Cape Verde",
                        CAY:"Cayman Islands",
                        CAF:"Central African Republic",
                        CHA:"Chad",
                        CHI:"Chile",
                        CHN:"China",
                        COL:"Colombia",
                        COM:"Comoros",
                        CGO:"Congo, Republic of the",
                        COD:"Congo, Democratic",
                        COK:"Republic of the Cook Islands",
                        CRC:"Costa Rica",
                        CIV:"Cote d'Ivoire",
                        CRO:"Croatia",
                        CUB:"Cuba",
                        CYP:"Cyprus",
                        CZE:"Czechia",
                        TCH:"Czechoslovakia",
                        DEN:"Denmark",
                        DJI:"Djibouti",
                        DMA:"Dominica",
                        DOM:"Dominican Republic",
                        GDR:"East Germany",
                        TLS:"East Timor (Timor-Leste)",
                        ECU:"Ecuador",
                        EGY:"Egypt",
                        ESA:"El Salvador",
                        GEQ:"Equatorial Guinea",
                        ERI:"Eritrea",
                        EST:"Estonia",
                        SWZ:"Eswatini",
                        ETH:"Ethiopia",
                        FIJ:"Fiji",
                        FIN:"Finland",
                        FRA:"France",
                        GAB:"Gabon",
                        GAM:"The Gambia",
                        GEO:"Georgia",
                        GER:"Germany",
                        GHA:"Ghana",
                        GRE:"Greece",
                        GRN:"Grenada",
                        GUM:"Guam",
                        GUA:"Guatemala",
                        GUI:"Guinea",
                        GBS:"Guinea-Bissau",
                        GUY:"Guyana",
                        HAI:"Haiti",
                        HON:"Honduras",
                        HKG:"Hong Kong",
                        HUN:"Hungary",
                        ISL:"Iceland",
                        IND:"India",
                        INA:"Indonesia",
                        IRI:"Iran",
                        IRQ:"Iraq",
                        IRL:"Ireland",
                        ISR:"Israel",
                        ITA:"Italy",
                        JAM:"Jamaica",
                        JPN:"Japan",
                        JOR:"Jordan",
                        KAZ:"Kazakhstan",
                        KEN:"Kenya",
                        KIR:"Kiribati",
                        PRK:"Korea, North (PDR of Korea)",
                        KOR:"Korea, South",
                        KOS:"Kosovo",
                        KUW:"Kuwait",
                        KGZ:"Kyrgyzstan",
                        LAO:"Laos",
                        LAT:"Latvia",
                        LIB:"Lebanon",
                        LES:"Lesotho",
                        LBR:"Liberia",
                        LBA:"Libya",
                        LIE:"Liechtenstein",
                        LTU:"Lithuania",
                        LUX:"Luxembourg",
                        MAD:"Madagascar",
                        MAW:"Malawi",
                        MAL:"Malaya",
                        MAS:"Malaysia",
                        MDV:"Maldives",
                        MLI:"Mali",
                        MLT:"Malta",
                        MHL:"Marshall Islands",
                        MTN:"Mauritania",
                        MRI:"Mauritius",
                        MEX:"Mexico",
                        FSM:"Micronesia (Federated States of)",
                        MDA:"Moldova",
                        MON:"Monaco",
                        MGL:"Mongolia",
                        MNE:"Montenegro",
                        MAR:"Morocco",
                        MOZ:"Mozambique",
                        MYA:"Myanmar (Burma)",
                        NAM:"Namibia",
                        NRU:"Nauru",
                        NEP:"Nepal",
                        NED:"Netherlands",
                        AHO:"Netherlands Antilles",
                        NZL:"New Zealand",
                        NCA:"Nicaragua",
                        NIG:"Niger",
                        NGR:"Nigeria",
                        NBO:"North Borneo",
                        MKD:"North Macedonia",
                        YAR:"North Yemen",
                        NOR:"Norway",
                        OMA:"Oman",
                        PAK:"Pakistan",
                        PLW:"Palau",
                        PLE:"Palestine",
                        PAN:"Panama",
                        PNG:"Papua New Guinea",
                        PAR:"Paraguay",
                        PER:"Peru",
                        PHI:"Philippines",
                        POL:"Poland",
                        POR:"Portugal",
                        PUR:"Puerto Rico",
                        QAT:"Qatar",
                        ROC:"Republic of China",
                        ROU:"Romania",
                        RUS:"Russia",
                        RU1:"Russian Empire",
                        RWA:"Rwanda",
                        SAA:"Saar",
                        SKN:"Saint Kitts and Nevis",
                        LCA:"Saint Lucia",
                        VIN:"Saint Vincent and the Grenadines",
                        SAM:"Samoa",
                        SMR:"San Marino",
                        STP:"Sao Tome and Principe",
                        KSA:"Saudi Arabia",
                        SEN:"Senegal",
                        SRB:"Serbia",
                        SCG:"Serbia and Montenegro",
                        SEY:"Seychelles",
                        SLE:"Sierra Leone",
                        SIN:"Singapore",
                        SVK:"Slovakia",
                        SLO:"Slovenia",
                        SOL:"Solomon Islands",
                        SOM:"Somalia",
                        RSA:"South Africa",
                        SSD:"South Sudan",
                        YMD:"South Yemen",
                        URS:"Soviet Union",
                        ESP:"Spain",
                        SRI:"Sri Lanka",
                        SUD:"Sudan",
                        SUR:"Suriname",
                        SWE:"Sweden",
                        SUI:"Switzerland",
                        SYR:"Syria",
                        TPE:"Taiwan (Chinese Taipei)",
                        TJK:"Tajikistan",
                        TAN:"Tanzania",
                        THA:"Thailand",
                        TOG:"Togo",
                        TGA:"Tonga",
                        TRI:"Trinidad and Tobago",
                        TUN:"Tunisia",
                        TUR:"Türkiye",
                        TKM:"Turkmenistan",
                        TUV:"Tuvalu",
                        UGA:"Uganda",
                        UKR:"Ukraine",
                        EUN:"Unified Team",
                        EUA:"Unified Team of Germany",
                        UAE:"United Arab Emirates",
                        RAU:"United Arab Republic",
                        GBR:"United Kingdom (Great Britain)",
                        USA:"United States",
                        URU:"Uruguay",
                        UZB:"Uzbekistan",
                        VAN:"Vanuatu",
                        VEN:"Venezuela",
                        VIE:"Vietnam",
                        ISV:"Virgin Islands",
                        FRG:"West Germany",
                        YEM:"Yemen",
                        YUG:"Yugoslavia",
                        ZAM:"Zambia",
                        ZIM:"Zimbabwe"
                    }
                }
            },
            en: {
                translation: {
                    MALE: "Male",
                    FEMALE: "Female",
                    "50# és alatt": "50# and below",
                    "51# és felett": "51# and above",
                    "Mini": "Mini",
                    "Kadet": "Cadet",
                    "Fiatal felnőtt": "Young adult",
                    "Junior": "Junior",
                    "Ifjúsági": "Junior",
                    "Veterán": "Veteran",
                    "Felnőtt": "Adult",
                    "Történelmi (modern)": "Historical (modern)",
                    "Történelmi (természetes)": "Historical (natural)",
                    "Történelmi hosszúíjak és egyéb botíjak": "Historical longbow",
                    "Történelmi számszeríj": "Historical crossbows",
                    "Vadászíj": "Hunting bows",
                    "Vadászíj fa vesszővel": "Hunting bows",
                    competition: {
                        applicationCardTitle: "Registration",
                        maxParticipants: "Max participants",
                        applicationDeadline: "Deadline",
                        participants: "Participants",
                        participantsShort: "Participants",
                        participant: "participant",
                        description: "Information",
                        results: "Results",
                        teams: "Teams",
                        success: "Success!",
                        successMsg: "You can revoke or modify your registration using the link in the confirmation email.",
                        applicationError: "Error!",
                    },
                    applicationForm: {
                        applicationIsClosed: "Registration is closed!",
                        full: "The competition is full!",
                        permitNumber: "License No.",
                        required: "Required!",
                        invalidPermitNumber: "Invalid license number!",
                        lastName: "Last name",
                        invalidLastName: "Invalid!",
                        nameRequired: "Required!",
                        firstName: "First name",
                        invalidFirstName: "The name associated with this license no: ",
                        emailRequired: "Required!",
                        club: "Club",
                        clubRequired: "Required!",
                        guestLunch: "Guest lunch",
                        lunch: "Lunch",
                        lunchRequired: "Required!",
                        gender: "Gender",
                        genderRequired: "Required!",
                        category: "Style",
                        categoryRequired: "Required!",
                        ageGroup: "Class",
                        ageGroupRequired: "Required!",
                        rounds: "Which round",
                        roundsRequired: "Required!",
                        rounds0: "Both rounds",
                        rounds1: "First round only",
                        rounds2: "Second round only",
                        team: "Team",
                        teamRequired: "Required!",
                        comment: "Comment for the organizer",
                        requiredAdditionalData: "Required!",
                        country: "Country",
                        warning: "Attention!",
                        privacy1: "I read the competition information and the",
                        privacy2: "Privacy policy",
                        privacy3: "and I accept them!",
                        privacyRequired: "Required!",
                        modify: "Modify",
                        apply: "Apply",
                        requiredCountry: "Required!"
                    },
                    genderInput: {
                        male: "Male",
                        female: "Female"
                    },
                    clubInput: {
                        other: "Other"
                    },
                    entries: {
                        team: "T",
                        name: "Name",
                        category: "Style",
                        categoryShort: "Style",
                        ageGroup: "Class",
                        ageGroupShort: "Class",
                        gender: "Gender",
                        club: "Club",
                        country: "Country"
                    },
                    manager: {
                        controlQR: "Second scorecard",
                        recordSecond: "Record second scorecard",
                        dashboardTitle: "Dashboard SK",
                        newCompetition:"Nová súťaž | New competition",
                        existingEntry: "Competition already imported!",
                        finalsrule: "Finále | Finals",
                        finals: "Finals",
                        round0: "1. round",
                        round1: "2. round",
                        round2: "3. round",
                        type: "Formát | Format",
                        normal: "Klasické | Normal",
                        gp: "2 kolá | GP",
                        threeRound: "3 kolá | GP",
                        hasFinals: "Áno | Yes",
                        noFinals: "Nie | No",
                        compName: "Názov súťaže | Competition name",
                        required: "Required",
                        teamCount: "Počet skupín | Number of teams",
                        targetCount: "Počet terčov | Number of targets",
                        arrowCount: "Počet výstrelov na terč | Arrows per target",
                        tiebreakingrule: "Rozhodovanie pri rovnosti bodov | Tiebreaking",
                        lunchFeeRule: "Cena za obed | Lunch fee",
                        lunchFee: "Lunch fee",
                        import: "Import",
                        competitions: "Competitions",
                        date: "Date",
                        name: "Name",
                        office: "Office",
                        teams: "Teams",
                        deleteConfirm: "Are you sure you want to delete?",
                        yes: "Yes",
                        cancel: "Cancel",
                        updatingTeams: "Update teams",
                        updateSuccess: "Update success!",
                        update: "Update",
                        uploadSuccess: "Upload success!",
                        uploadFailed: "Upload failed!",
                        scorecardsLoaded: "Scorecards loaded!",
                        registerSuccess: "Register success!",
                        updateWarning: "Warning! You're about to override the data on this PC !",
                        downloadAgain: "Confirm, I want to override them!",
                        scanQrError: "Failed to load every result! You have to input the missing (red) scores manually! Everything else will be recorded!",
                        teamShort: "Team",
                        entryName: "Name",
                        categoryShort: "Cat.",
                        ageGroupShort: "Age",
                        gender: "Gender",
                        score: "Score",
                        offlineWarning: "",
                        competitionDescription: "Description",
                        close: "Close",
                        closeQR: "Zatvor / Close",
                        visibleColumns: "Columns visible",
                        secondDisplay: "Second display",
                        entriesDisplay: "Entries",
                        progressDisplay: "Progress",
                        resultsDisplay: "Results",
                        exportExcel: "Export excel",
                        scanQr: "QR scan",
                        downloadData: "Download data",
                        downloadDataToDevice: "Download data",
                        qrStickers: "QR stickers",
                        skResults: "SK Results",
                        noData: "No data downloaded",
                        downloadedData: "Data downloaded",
                        scoring: "Scoring",
                        tiebreaker: "Tiebreaker",
                        lunch: "Lunch",
                        arrived: "Arrived",
                        processed: "Processed",
                        invalidResults: "Invalid result",
                        entries: "Entries",
                        scores: "Scores",
                        results: "Results",
                        scannedValues: "Scanned values",
                        round: "round",
                        record: "Record",
                        back: "Back",
                        chooseTemplate: "Choose template",
                        stickerPerCompetitor: "Sticker per entry",
                        sortBy: "Order by",
                        abc: "Name",
                        byTeam: "Team",
                        preview: "Preview",
                        download: "Download",
                        selectEntries: "Select competitiors",
                        search: "Search (name or ID) ...",
                        checkedIn: "Checked in",
                        notCheckedIn: "Not checked in",
                        idShort: "ID",
                        permitShort: "License",
                        man: "Male",
                        woman: "Female",
                        entryFee: "Entry fee",
                        comment: "Comment",
                        deleteEntryConfirm: "Are you sure you want to delete?",
                        newEntry: "Create new entry",
                        entryFeeShort: "Entry fee",
                        total: "Total",
                        clear: "Clear / Close",
                        automaticScoring: "Automatic placement calculation",
                        automaticScoreFill: "Automatic score calculation",
                        club: "Club",
                        shots: "shot",
                        calculated: "Calculated",
                        tiebreakerScore: "Tiebreaker",
                        placement: "Placement",
                        totalShort: "Sum",
                        placementShort: "Fin.",
                        deleteFullEntryWarning: "Are you sure you want to delete the entry with EVERY round?",
                        sum: "Sum",
                        goldMedal: "Gold medal",
                        silverMedal: "Silver medal",
                        bronzeMedal: "Bronze medal",
                        bestWithSight: "Best score with sight",
                        bestWoSight: "Best score without sight",
                        print: "Print",
                        printPodium: "Podium PDF",
                        generatePodiumExcel: "Podium XLS",
                        categoryList: "Category list",
                        teamMembers: "Team members",
                        teamFilter: "Team filter",
                        teamCreateConfirm: "Are you sure you want to create a new team?",
                        border: "Border",
                        teamNumber: "Team number",
                        additionalData: "Additional data",
                        firstName: "First name",
                        lastName: "Last name",
                        guestLunch: "Guest lunch",
                        rounds: "Which round",
                        both: "Both",
                        firstRoundOnly: "First round only",
                        secondRoundOnly: "Second round only",
                        createEntry: "Create entry",
                        team: "Team",
                        stickerman: "Male",
                        stickerwoman: "Female",
                        permitNumber: "License No.",
                        country: "Country",
                        backupLoadSuccess: "Load Successfull",
                        backupLoadSuccessText: "Reload all the browser pages",
                        backupTitle: "Load backup",
                        load: "Load",
                        backupLoadButton: "Load backup",
                        backupLoadDescription: "This will overwrite EVERY competition data on this computer with the data from the backup file. Are you sure?",
                        backupButton: "Backup",
                        arrowCountExceptions: "Arrow count exceptions",
                        arrowCountExceptionsDesc: "If archers in a category + age group shoots different count of arrows per target than the default, set them here. You only have to set the exceptions, don't have to set the ones matching the default!",
                        finalsDisplay: "Finals",
                        parallelLoadButton: "Párhuzamos betöltés",
                        parallelTitle: "Párhuzamos betöltés",
                        parallelLoadDescription: "Ha egyszerre több laptopon történik a feldolgozás, itt lehet betölteni a tobbi laptop biztonsági mentését. A filet kiválasztva, a LEGUTOLJÁRA MÓDOSÍTOTT sorok maradnak meg. Teljes sorok kerülnek betöltésre, nem csak a változtatott érték. Betöltés után megjelennek a módosított sorok.",
                        scorecards: "Scorecards"

                    },
                    genderTag: {
                        MALE: "Male",
                        FEMALE: "Female"
                    },
                    country: {
                        AFG:"Afghanistan",
                        ALB:"Albania",
                        ALG:"Algeria",
                        AND:"Andorra",
                        ANG:"Angola",
                        ANT:"Antigua and Barbuda",
                        ARG:"Argentina",
                        ARM:"Armenia",
                        ARU:"Aruba",
                        AUS:"Australia",
                        ANZ:"Australasia",
                        AUT:"Austria",
                        AZE:"Azerbaijan",
                        BAH:"The Bahamas",
                        BRN:"Bahrain",
                        BAN:"Bangladesh",
                        BAR:"Barbados",
                        BLR:"Belarus",
                        BEL:"Belgium",
                        BIZ:"Belize",
                        BER:"Bermuda",
                        BEN:"Benin",
                        BHU:"Bhutan",
                        BOH:"Bohemia",
                        BOL:"Bolivia",
                        BIH:"Bosnia and Herzegovina",
                        BOT:"Botswana",
                        BRA:"Brazil",
                        IVB:"British Virgin Islands",
                        BWI:"British West Indies",
                        BRU:"Brunei",
                        BUL:"Bulgaria",
                        BUR:"Burkina Faso",
                        BDI:"Burundi",
                        CAM:"Cambodia",
                        CMR:"Cameroon",
                        CAN:"Canada",
                        CPV:"Cape Verde",
                        CAY:"Cayman Islands",
                        CAF:"Central African Republic",
                        CHA:"Chad",
                        CHI:"Chile",
                        CHN:"China",
                        COL:"Colombia",
                        COM:"Comoros",
                        CGO:"Congo, Republic of the",
                        COD:"Congo, Democratic",
                        COK:"Republic of the Cook Islands",
                        CRC:"Costa Rica",
                        CIV:"Cote d'Ivoire",
                        CRO:"Croatia",
                        CUB:"Cuba",
                        CYP:"Cyprus",
                        CZE:"Czechia",
                        TCH:"Czechoslovakia",
                        DEN:"Denmark",
                        DJI:"Djibouti",
                        DMA:"Dominica",
                        DOM:"Dominican Republic",
                        GDR:"East Germany",
                        TLS:"East Timor (Timor-Leste)",
                        ECU:"Ecuador",
                        EGY:"Egypt",
                        ESA:"El Salvador",
                        GEQ:"Equatorial Guinea",
                        ERI:"Eritrea",
                        EST:"Estonia",
                        SWZ:"Eswatini",
                        ETH:"Ethiopia",
                        FIJ:"Fiji",
                        FIN:"Finland",
                        FRA:"France",
                        GAB:"Gabon",
                        GAM:"The Gambia",
                        GEO:"Georgia",
                        GER:"Germany",
                        GHA:"Ghana",
                        GRE:"Greece",
                        GRN:"Grenada",
                        GUM:"Guam",
                        GUA:"Guatemala",
                        GUI:"Guinea",
                        GBS:"Guinea-Bissau",
                        GUY:"Guyana",
                        HAI:"Haiti",
                        HON:"Honduras",
                        HKG:"Hong Kong",
                        HUN:"Hungary",
                        ISL:"Iceland",
                        IND:"India",
                        INA:"Indonesia",
                        IRI:"Iran",
                        IRQ:"Iraq",
                        IRL:"Ireland",
                        ISR:"Israel",
                        ITA:"Italy",
                        JAM:"Jamaica",
                        JPN:"Japan",
                        JOR:"Jordan",
                        KAZ:"Kazakhstan",
                        KEN:"Kenya",
                        KIR:"Kiribati",
                        PRK:"Korea, North (PDR of Korea)",
                        KOR:"Korea, South",
                        KOS:"Kosovo",
                        KUW:"Kuwait",
                        KGZ:"Kyrgyzstan",
                        LAO:"Laos",
                        LAT:"Latvia",
                        LIB:"Lebanon",
                        LES:"Lesotho",
                        LBR:"Liberia",
                        LBA:"Libya",
                        LIE:"Liechtenstein",
                        LTU:"Lithuania",
                        LUX:"Luxembourg",
                        MAD:"Madagascar",
                        MAW:"Malawi",
                        MAL:"Malaya",
                        MAS:"Malaysia",
                        MDV:"Maldives",
                        MLI:"Mali",
                        MLT:"Malta",
                        MHL:"Marshall Islands",
                        MTN:"Mauritania",
                        MRI:"Mauritius",
                        MEX:"Mexico",
                        FSM:"Micronesia (Federated States of)",
                        MDA:"Moldova",
                        MON:"Monaco",
                        MGL:"Mongolia",
                        MNE:"Montenegro",
                        MAR:"Morocco",
                        MOZ:"Mozambique",
                        MYA:"Myanmar (Burma)",
                        NAM:"Namibia",
                        NRU:"Nauru",
                        NEP:"Nepal",
                        NED:"Netherlands",
                        AHO:"Netherlands Antilles",
                        NZL:"New Zealand",
                        NCA:"Nicaragua",
                        NIG:"Niger",
                        NGR:"Nigeria",
                        NBO:"North Borneo",
                        MKD:"North Macedonia",
                        YAR:"North Yemen",
                        NOR:"Norway",
                        OMA:"Oman",
                        PAK:"Pakistan",
                        PLW:"Palau",
                        PLE:"Palestine",
                        PAN:"Panama",
                        PNG:"Papua New Guinea",
                        PAR:"Paraguay",
                        PER:"Peru",
                        PHI:"Philippines",
                        POL:"Poland",
                        POR:"Portugal",
                        PUR:"Puerto Rico",
                        QAT:"Qatar",
                        ROC:"Republic of China",
                        ROU:"Romania",
                        RUS:"Russia",
                        RU1:"Russian Empire",
                        RWA:"Rwanda",
                        SAA:"Saar",
                        SKN:"Saint Kitts and Nevis",
                        LCA:"Saint Lucia",
                        VIN:"Saint Vincent and the Grenadines",
                        SAM:"Samoa",
                        SMR:"San Marino",
                        STP:"Sao Tome and Principe",
                        KSA:"Saudi Arabia",
                        SEN:"Senegal",
                        SRB:"Serbia",
                        SCG:"Serbia and Montenegro",
                        SEY:"Seychelles",
                        SLE:"Sierra Leone",
                        SIN:"Singapore",
                        SVK:"Slovakia",
                        SLO:"Slovenia",
                        SOL:"Solomon Islands",
                        SOM:"Somalia",
                        RSA:"South Africa",
                        SSD:"South Sudan",
                        YMD:"South Yemen",
                        URS:"Soviet Union",
                        ESP:"Spain",
                        SRI:"Sri Lanka",
                        SUD:"Sudan",
                        SUR:"Suriname",
                        SWE:"Sweden",
                        SUI:"Switzerland",
                        SYR:"Syria",
                        TPE:"Taiwan (Chinese Taipei)",
                        TJK:"Tajikistan",
                        TAN:"Tanzania",
                        THA:"Thailand",
                        TOG:"Togo",
                        TGA:"Tonga",
                        TRI:"Trinidad and Tobago",
                        TUN:"Tunisia",
                        TUR:"Türkiye",
                        TKM:"Turkmenistan",
                        TUV:"Tuvalu",
                        UGA:"Uganda",
                        UKR:"Ukraine",
                        EUN:"Unified Team",
                        EUA:"Unified Team of Germany",
                        UAE:"United Arab Emirates",
                        RAU:"United Arab Republic",
                        GBR:"United Kingdom (Great Britain)",
                        USA:"United States",
                        URU:"Uruguay",
                        UZB:"Uzbekistan",
                        VAN:"Vanuatu",
                        VEN:"Venezuela",
                        VIE:"Vietnam",
                        ISV:"Virgin Islands",
                        FRG:"West Germany",
                        YEM:"Yemen",
                        YUG:"Yugoslavia",
                        ZAM:"Zambia",
                        ZIM:"Zimbabwe"
                    }
                }
            },
            sk: {
                translation: {
                    MALE: "Muž",
                    FEMALE: "Žena",
                    "50# és alatt": "50# and below",
                    "51# és felett": "51# and above",
                    "Mini": "Mini",
                    "Kadet": "Cadet",
                    "Fiatal felnőtt": "Young adult",
                    "Junior": "Junior",
                    "Veterán": "Veteran",
                    "Felnőtt": "Adult",
                    "Történelmi (modern)": "Historical (modern)",
                    "Történelmi (természetes)": "Historical (natural)",
                    "Történelmi hosszúíjak és egyéb botíjak": "Historical longbow",
                    "Történelmi számszeríj": "Historical crossbows",
                    "Vadászíj": "Hunting bows",
                    competition: {
                        applicationCardTitle: "Registration",
                        maxParticipants: "Max participants",
                        applicationDeadline: "Deadline",
                        participants: "Participants",
                        participantsShort: "Participants",
                        participant: "participant",
                        description: "Information",
                        results: "Results",
                        teams: "Teams",
                        success: "Success!",
                        successMsg: "You can revoke or modify your registration using the link in the confirmation email.",
                        applicationError: "Error!",
                    },
                    applicationForm: {
                        applicationIsClosed: "Registration is closed!",
                        full: "The competition is full!",
                        permitNumber: "číslo licencie",
                        required: "Required!",
                        invalidPermitNumber: "Invalid license number!",
                        lastName: "Priezvisko",
                        invalidLastName: "Invalid!",
                        nameRequired: "Required!",
                        firstName: "Krstné meno",
                        invalidFirstName: "The name associated with this license no: ",
                        emailRequired: "Required!",
                        club: "Club",
                        clubRequired: "Required!",
                        guestLunch: "Guest lunch",
                        lunch: "Obed",
                        lunchRequired: "Required!",
                        gender: "Pohlavie",
                        genderRequired: "Required!",
                        category: "Style",
                        categoryRequired: "Required!",
                        ageGroup: "Class",
                        ageGroupRequired: "Required!",
                        rounds: "Which round",
                        roundsRequired: "Required!",
                        rounds0: "Both rounds",
                        rounds1: "First round only",
                        rounds2: "Second round only",
                        team: "Team",
                        teamRequired: "Required!",
                        comment: "Comment for the organizer",
                        requiredAdditionalData: "Required!",
                        country: "Country",
                        warning: "Attention!",
                        privacy1: "I read the competition information and the",
                        privacy2: "Privacy policy",
                        privacy3: "and I accept them!",
                        privacyRequired: "Required!",
                        modify: "Modify",
                        apply: "Apply",
                        requiredCountry: "Required!"
                    },
                    genderInput: {
                        male: "Man",
                        female: "Woman"
                    },
                    clubInput: {
                        other: "Other"
                    },
                    entries: {
                        team: "T",
                        name: "Name",
                        category: "Style",
                        categoryShort: "Style",
                        ageGroup: "Class",
                        ageGroupShort: "Class",
                        gender: "Pohlavie",
                        club: "Club",
                        country: "Country"
                    },
                    manager: {
                        controlQR: "Second scorecard",
                        recordSecond: "Record second scorecard",
                        dashboardTitle: "Dashboard SK",
                        newCompetition:"Nová súťaž | New competition",
                        existingEntry: "Competition already imported!",
                        finalsrule: "Finále | Finals",
                        finals: "Finals",
                        round0: "1. round",
                        round1: "2. round",
                        round2: "3. round",
                        type: "Formát | Format",
                        normal: "Klasické | Normal",
                        gp: "2 kolá | GP",
                        threeRound: "3 kolá | GP",
                        hasFinals: "Áno | Yes",
                        noFinals: "Nie | No",
                        compName: "Názov súťaže | Competition name",
                        required: "Required",
                        teamCount: "Počet skupín | Number of teams",
                        targetCount: "Počet terčov | Number of targets",
                        arrowCount: "Počet výstrelov na terč | Arrows per target",
                        tiebreakingrule: "Rozhodovanie pri rovnosti bodov | Tiebreaking",
                        lunchFeeRule: "Cena za obed | Lunch fee",
                        lunchFee: "Cena obedu",
                        import: "Import",
                        competitions: "Competitions",
                        date: "Date",
                        name: "Meno",
                        office: "Office",
                        teams: "Teams",
                        deleteConfirm: "Are you sure you want to delete?",
                        yes: "Yes",
                        cancel: "Cancel",
                        updatingTeams: "Update teams",
                        updateSuccess: "Update success!",
                        update: "Update",
                        uploadSuccess: "Upload success!",
                        uploadFailed: "Upload failed!",
                        scorecardsLoaded: "Scorecards loaded!",
                        registerSuccess: "Register success!",
                        updateWarning: "Warning! You're about to override the data on this PC !",
                        downloadAgain: "Confirm, I want to override them!",
                        scanQrError: "Failed to load every result! You have to input the missing (red) scores manually! Everything else will be recorded!",
                        teamShort: "Team",
                        entryName: "Meno",
                        categoryShort: "Cat.",
                        ageGroupShort: "Vek",
                        gender: "Pohlavie",
                        score: "Score",
                        offlineWarning: "",
                        competitionDescription: "Description",
                        close: "Close",
                        closeQR: "Zatvor / Close",
                        visibleColumns: "Columns visible",
                        secondDisplay: "Second display",
                        entriesDisplay: "Entries",
                        progressDisplay: "Progress",
                        resultsDisplay: "Results",
                        exportExcel: "Export excel",
                        scanQr: "QR scan",
                        downloadData: "Download data",
                        downloadDataToDevice: "Download data",
                        qrStickers: "QR nálepky",
                        skResults: "Export výsledkov pre stránku SLA 3D",
                        noData: "No data downloaded",
                        downloadedData: "Dáta stiahnuté",
                        scoring: "Scoring",
                        tiebreaker: "Tiebreaker",
                        lunch: "Obed",
                        arrived: "Pricestovalo",
                        processed: "Spracované",
                        invalidResults: "Chybný výsledok",
                        entries: "Štartová listina",
                        scores: "Bodovanie",
                        results: "Výsledky",
                        scannedValues: "Scanned values",
                        round: "round",
                        record: "Record",
                        back: "Back",
                        chooseTemplate: "Choose template",
                        stickerPerCompetitor: "Sticker per entry",
                        sortBy: "Order by",
                        abc: "Meno",
                        byTeam: "Team",
                        preview: "Preview",
                        download: "Download",
                        selectEntries: "Select competitiors",
                        search: "Search (name or ID) ...",
                        checkedIn: "Checked in",
                        notCheckedIn: "Not checked in",
                        idShort: "ID",
                        permitShort: "License",
                        man: "Man",
                        woman: "Woman",
                        entryFee: "Štartovné",
                        comment: "Poznámky",
                        deleteEntryConfirm: "Are you sure you want to delete?",
                        newEntry: "Vytvor nového súťažiacieho",
                        entryFeeShort: "Štartovné",
                        total: "Total",
                        clear: "Clear / Close",
                        automaticScoring: "Automatic placement calculation",
                        automaticScoreFill: "Automatic score calculation",
                        club: "Club",
                        shots: "shot",
                        calculated: "Calculated",
                        tiebreakerScore: "Tiebreaker",
                        placement: "Placement",
                        totalShort: "Sum",
                        deleteFullEntryWarning: "Are you sure you want to delete the entry with EVERY round?",
                        sum: "Sum",
                        goldMedal: "Gold medal",
                        silverMedal: "Silver medal",
                        bronzeMedal: "Bronze medal",
                        bestWithSight: "Best score with sight",
                        bestWoSight: "Best score without sight",
                        print: "Tlačiť",
                        printPodium: "Tlačiť top 3 PDF",
                        generatePodiumExcel: "Tlačiť top 3 XLS",
                        categoryList: "Category list",
                        teamMembers: "Team members",
                        teamFilter: "Team filter",
                        teamCreateConfirm: "Are you sure you want to create a new team?",
                        border: "Border",
                        teamNumber: "Team number",
                        additionalData: "Additional data",
                        firstName: "Krstné meno",
                        lastName: "Priezvisko",
                        guestLunch: "Guest lunch",
                        rounds: "Which round",
                        both: "Both",
                        firstRoundOnly: "First round only",
                        secondRoundOnly: "Second round only",
                        createEntry: "Vytvor súťažiaceho",
                        team: "Team",
                        stickerman: "Muž",
                        stickerwoman: "Žena",
                        permitNumber: "číslo licencie",
                        country: "Country",
                        backupLoadSuccess: "Load Successfull",
                        backupLoadSuccessText: "Reload all the browser pages",
                        backupTitle: "Load backup",
                        load: "Load",
                        backupLoadButton: "Load backup",
                        backupLoadDescription: "This will overwrite EVERY competition data on this computer with the data from the backup file. Are you sure?",
                        backupButton: "Backup",
                        arrowCountExceptions: "Arrow count exceptions",
                        arrowCountExceptionsDesc: "If archers in a category + age group shoots different count of arrows per target than the default, set them here. You only have to set the exceptions, don't have to set the ones matching the default!",
                        finalsDisplay: "Finals",
                        parallelLoadButton: "Párhuzamos betöltés",
                        parallelTitle: "Párhuzamos betöltés",
                        parallelLoadDescription: "Ha egyszerre több laptopon történik a feldolgozás, itt lehet betölteni a tobbi laptop biztonsági mentését. A filet kiválasztva, a LEGUTOLJÁRA MÓDOSÍTOTT sorok maradnak meg. Teljes sorok kerülnek betöltésre, nem csak a változtatott érték. Betöltés után megjelennek a módosított sorok.",
                        scorecards: "Scorecards"

                    },
                    genderTag: {
                        MALE: "Man",
                        FEMALE: "Woman"
                    },
                    country: {
                        AFG:"Afghanistan",
                        ALB:"Albania",
                        ALG:"Algeria",
                        AND:"Andorra",
                        ANG:"Angola",
                        ANT:"Antigua and Barbuda",
                        ARG:"Argentina",
                        ARM:"Armenia",
                        ARU:"Aruba",
                        AUS:"Australia",
                        ANZ:"Australasia",
                        AUT:"Austria",
                        AZE:"Azerbaijan",
                        BAH:"The Bahamas",
                        BRN:"Bahrain",
                        BAN:"Bangladesh",
                        BAR:"Barbados",
                        BLR:"Belarus",
                        BEL:"Belgium",
                        BIZ:"Belize",
                        BER:"Bermuda",
                        BEN:"Benin",
                        BHU:"Bhutan",
                        BOH:"Bohemia",
                        BOL:"Bolivia",
                        BIH:"Bosnia and Herzegovina",
                        BOT:"Botswana",
                        BRA:"Brazil",
                        IVB:"British Virgin Islands",
                        BWI:"British West Indies",
                        BRU:"Brunei",
                        BUL:"Bulgaria",
                        BUR:"Burkina Faso",
                        BDI:"Burundi",
                        CAM:"Cambodia",
                        CMR:"Cameroon",
                        CAN:"Canada",
                        CPV:"Cape Verde",
                        CAY:"Cayman Islands",
                        CAF:"Central African Republic",
                        CHA:"Chad",
                        CHI:"Chile",
                        CHN:"China",
                        COL:"Colombia",
                        COM:"Comoros",
                        CGO:"Congo, Republic of the",
                        COD:"Congo, Democratic",
                        COK:"Republic of the Cook Islands",
                        CRC:"Costa Rica",
                        CIV:"Cote d'Ivoire",
                        CRO:"Croatia",
                        CUB:"Cuba",
                        CYP:"Cyprus",
                        CZE:"Czechia",
                        TCH:"Czechoslovakia",
                        DEN:"Denmark",
                        DJI:"Djibouti",
                        DMA:"Dominica",
                        DOM:"Dominican Republic",
                        GDR:"East Germany",
                        TLS:"East Timor (Timor-Leste)",
                        ECU:"Ecuador",
                        EGY:"Egypt",
                        ESA:"El Salvador",
                        GEQ:"Equatorial Guinea",
                        ERI:"Eritrea",
                        EST:"Estonia",
                        SWZ:"Eswatini",
                        ETH:"Ethiopia",
                        FIJ:"Fiji",
                        FIN:"Finland",
                        FRA:"France",
                        GAB:"Gabon",
                        GAM:"The Gambia",
                        GEO:"Georgia",
                        GER:"Germany",
                        GHA:"Ghana",
                        GRE:"Greece",
                        GRN:"Grenada",
                        GUM:"Guam",
                        GUA:"Guatemala",
                        GUI:"Guinea",
                        GBS:"Guinea-Bissau",
                        GUY:"Guyana",
                        HAI:"Haiti",
                        HON:"Honduras",
                        HKG:"Hong Kong",
                        HUN:"Hungary",
                        ISL:"Iceland",
                        IND:"India",
                        INA:"Indonesia",
                        IRI:"Iran",
                        IRQ:"Iraq",
                        IRL:"Ireland",
                        ISR:"Israel",
                        ITA:"Italy",
                        JAM:"Jamaica",
                        JPN:"Japan",
                        JOR:"Jordan",
                        KAZ:"Kazakhstan",
                        KEN:"Kenya",
                        KIR:"Kiribati",
                        PRK:"Korea, North (PDR of Korea)",
                        KOR:"Korea, South",
                        KOS:"Kosovo",
                        KUW:"Kuwait",
                        KGZ:"Kyrgyzstan",
                        LAO:"Laos",
                        LAT:"Latvia",
                        LIB:"Lebanon",
                        LES:"Lesotho",
                        LBR:"Liberia",
                        LBA:"Libya",
                        LIE:"Liechtenstein",
                        LTU:"Lithuania",
                        LUX:"Luxembourg",
                        MAD:"Madagascar",
                        MAW:"Malawi",
                        MAL:"Malaya",
                        MAS:"Malaysia",
                        MDV:"Maldives",
                        MLI:"Mali",
                        MLT:"Malta",
                        MHL:"Marshall Islands",
                        MTN:"Mauritania",
                        MRI:"Mauritius",
                        MEX:"Mexico",
                        FSM:"Micronesia (Federated States of)",
                        MDA:"Moldova",
                        MON:"Monaco",
                        MGL:"Mongolia",
                        MNE:"Montenegro",
                        MAR:"Morocco",
                        MOZ:"Mozambique",
                        MYA:"Myanmar (Burma)",
                        NAM:"Namibia",
                        NRU:"Nauru",
                        NEP:"Nepal",
                        NED:"Netherlands",
                        AHO:"Netherlands Antilles",
                        NZL:"New Zealand",
                        NCA:"Nicaragua",
                        NIG:"Niger",
                        NGR:"Nigeria",
                        NBO:"North Borneo",
                        MKD:"North Macedonia",
                        YAR:"North Yemen",
                        NOR:"Norway",
                        OMA:"Oman",
                        PAK:"Pakistan",
                        PLW:"Palau",
                        PLE:"Palestine",
                        PAN:"Panama",
                        PNG:"Papua New Guinea",
                        PAR:"Paraguay",
                        PER:"Peru",
                        PHI:"Philippines",
                        POL:"Poland",
                        POR:"Portugal",
                        PUR:"Puerto Rico",
                        QAT:"Qatar",
                        ROC:"Republic of China",
                        ROU:"Romania",
                        RUS:"Russia",
                        RU1:"Russian Empire",
                        RWA:"Rwanda",
                        SAA:"Saar",
                        SKN:"Saint Kitts and Nevis",
                        LCA:"Saint Lucia",
                        VIN:"Saint Vincent and the Grenadines",
                        SAM:"Samoa",
                        SMR:"San Marino",
                        STP:"Sao Tome and Principe",
                        KSA:"Saudi Arabia",
                        SEN:"Senegal",
                        SRB:"Serbia",
                        SCG:"Serbia and Montenegro",
                        SEY:"Seychelles",
                        SLE:"Sierra Leone",
                        SIN:"Singapore",
                        SVK:"Slovakia",
                        SLO:"Slovenia",
                        SOL:"Solomon Islands",
                        SOM:"Somalia",
                        RSA:"South Africa",
                        SSD:"South Sudan",
                        YMD:"South Yemen",
                        URS:"Soviet Union",
                        ESP:"Spain",
                        SRI:"Sri Lanka",
                        SUD:"Sudan",
                        SUR:"Suriname",
                        SWE:"Sweden",
                        SUI:"Switzerland",
                        SYR:"Syria",
                        TPE:"Taiwan (Chinese Taipei)",
                        TJK:"Tajikistan",
                        TAN:"Tanzania",
                        THA:"Thailand",
                        TOG:"Togo",
                        TGA:"Tonga",
                        TRI:"Trinidad and Tobago",
                        TUN:"Tunisia",
                        TUR:"Türkiye",
                        TKM:"Turkmenistan",
                        TUV:"Tuvalu",
                        UGA:"Uganda",
                        UKR:"Ukraine",
                        EUN:"Unified Team",
                        EUA:"Unified Team of Germany",
                        UAE:"United Arab Emirates",
                        RAU:"United Arab Republic",
                        GBR:"United Kingdom (Great Britain)",
                        USA:"United States",
                        URU:"Uruguay",
                        UZB:"Uzbekistan",
                        VAN:"Vanuatu",
                        VEN:"Venezuela",
                        VIE:"Vietnam",
                        ISV:"Virgin Islands",
                        FRG:"West Germany",
                        YEM:"Yemen",
                        YUG:"Yugoslavia",
                        ZAM:"Zambia",
                        ZIM:"Zimbabwe"
                    }
                }
            }
        }
    });


export default i18n;
