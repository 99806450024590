import _ from "lodash";
import jsPDF from "jspdf";
import {decodeScorecard} from "artemis-shared/scorecard/ScorecardUtils";
import {downloadFile} from "../utils/DownloadFile";

export const calculateScore = (scoring, entry, round) => {
  let sum = 0;

  scoring.map((scoreProperty) => {
    sum +=
      (scoreProperty === "x"
        ? _.max(scoring.filter((v) => v !== "x").map((v) => parseInt(v)))
        : scoreProperty) *
      (_.isNumber(round)
        ? entry.roundEntries[round]["s" + scoreProperty] || 0
        : entry["s" + scoreProperty] || 0);
  });

  console.log("score", round, sum);

  return sum;
};

export const calculateArrowCount = (scoring, entry, round) => {
  let sum = 0;

  scoring.map((scoreProperty) => {
    sum += _.isNumber(round)
      ? entry.roundEntries[round]["s" + scoreProperty] || 0
      : entry["s" + scoreProperty] || 0;
  });

  console.log("count", round, sum);

  return sum;
};

export const hasTeams = (entries) => {
  return _.some(entries, (entry) => entry.team);
};

export const sumRounds = (entry, subtype, finalsType) => {
  const possibleScoreValues = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    "x",
  ];

  let roundToIterate = subtype && subtype === "LDTS" ? entry.roundEntries.length - 1 : entry.roundEntries.length;

  for (let i = 0; i < possibleScoreValues.length; i++) {
    let scoreValue = possibleScoreValues[i];

    let sum = 0;

    for (let r = 0; r < roundToIterate; r++) {
      if (entry.roundEntries[r] && !(finalsType === "SCORE" && r === entry.roundEntries.length - 1)) {
        sum += entry.roundEntries[r]["s" + scoreValue];
      }
    }

    entry["s" + scoreValue] = sum;
  }

  let scoreSum = 0;

  for (let r = 0; r < roundToIterate; r++) {
    if (entry.roundEntries[r]) {
      scoreSum += entry.roundEntries[r]["score"];
    }
  }

  entry.score = scoreSum;
};

const countScoreValues = (scorecard) => {
  let values = scorecard.scores.reduce(
      (acc, sc) => _.concat(acc, sc.scores),
      []
  );

  let arr = [];
  scorecard.scoringValues.map(
      (scoreValue) =>{
        arr.push({value: scoreValue, count: values.filter(
              (value) => value === scoreValue
          ).length})
      }
  );

  return arr;
};

export const generateScorecardPdf = (localEntries, localCompetitionData, t) => {
  let doc = new jsPDF("landscape");

  let newEntries = [...localEntries].sort((a, b) => a.name.localeCompare(b.name));

  let scorecards = newEntries.filter(entry=> entry.scorecard).map(entry => {
    return {
      name: entry.name,
      category: entry.category.name,
      ageGroup: entry.ageGroup.ageGroup.name,
      gender: entry.gender,
      scorecard: decodeScorecard(entry.scorecard)
    }
  });

  for(let i = 0; i < scorecards.length; i++) {
    let margins = null;


    switch(i % 3) {
      case 0:
        margins = { right: 202 };
        break;
      case 1:
        margins = { right: 108, left: 108 };
        break;
      case 2:
        margins= { left: 202 };
        break;
      default:
        break;
    }

    if(i % 3 === 0) {
      doc.addPage();
      doc.setPage(Math.floor(i / 3) + 1);
    }

    const scorecard = scorecards[i];
    doc.setFont("Roboto-Regular");
    doc.setFontSize(12);
    doc.text(scorecard.name, (i % 3 * 94)  + 15, 8);
    doc.setFontSize(10);
    doc.text(`${scorecard.category} ${scorecard.ageGroup} ${scorecard.gender === "MALE" ? t("manager.man") : t("manager.woman")}`, (i % 3 * 94)  + 15, 13);
    doc.setFontSize(12);

    const scoreValues = countScoreValues(scorecards[i].scorecard.decodedScorecards[Object.keys(scorecards[i].scorecard.decodedScorecards)[0]]);

    doc.autoTable({
      bodyStyles: { font: "Roboto-Regular", fontSize: 8, halign: "center" },
      headStyles: { font: "Roboto-Bold", halign: "center" },
      columns: scoreValues.map(sv => sv.value),
      body: [scoreValues.map(sv => sv.count)],
      margin: margins,
      startY: 16,
      styles: { cellPadding: {top: 0.7, bottom: 0.7, left: 2, right: 2} },
    });


    const scores = scorecards[i].scorecard.decodedScorecards[Object.keys(scorecards[i].scorecard.decodedScorecards)[0]].scores;
    const firstTargetNumber = scorecards[i].scorecard.decodedScorecards[Object.keys(scorecards[i].scorecard.decodedScorecards)[0]].firstTargetNumber;
    const targetCount = scorecards[i].scorecard.decodedScorecards[Object.keys(scorecards[i].scorecard.decodedScorecards)[0]].targetCount;

    let columns = ["#"];

    for(let j = 0; j < scorecards[i].scorecard.decodedScorecards[Object.keys(scorecards[i].scorecard.decodedScorecards)[0]].arrowsPerTarget; j++) {
      columns.push(j + 1 + "");
    }

    columns.push("Target", "Sum");

    doc.autoTable({
      bodyStyles: { font: "Roboto-Regular", fontSize: 8, halign: "center" },
      headStyles: { font: "Roboto-Bold", halign: "center" },
      columns: columns,
      body: scores.map((target, index) => {
        let adjustedTargetNumber = firstTargetNumber + target.target > targetCount
            ? firstTargetNumber + target.target - targetCount
            : firstTargetNumber + target.target;

        return [adjustedTargetNumber + ".", ...target.scores, _.sum(target.scores.map((score) => parseInt(score))), scores.slice(0, index + 1).reduce((acc, current) => acc + _.sum(current.scores.map((score) => parseInt(score))), 0)]
      }),
      margin: margins,
      startY: 27,
      styles: { cellPadding: {top: 0.7, bottom: 0.7, left: 2, right: 2} },
    });
  }

  downloadFile(
      doc.output("blob"),
      `${localCompetitionData.slug}_scorecards.pdf`
  );
}
