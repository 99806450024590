import React from "react";
import { Card } from "antd";
import { Training } from "./Training";

export const TrainingPage = () => {
  return (
    <Card bodyStyle={{ padding: "16px 8px 40px 8px" }}>
      <Training />
    </Card>
  );
};
