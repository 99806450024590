import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { useMutation } from "react-query";
import { passwordReset, requestPasswordReset } from "../api/Auth";

export const PasswordReset = () => {
  const [token, setToken] = useState(null);
  const [success, setSuccess] = useState(false);
  const [successReset, setSuccessReset] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("token")) {
      setToken(searchParams.get("token"));
    }
  }, [location]);

  const [requestPasswordResetMutation, { isLoading: isLoadingRequest }] =
    useMutation(requestPasswordReset, {
      onSuccess: (data) => {
        setSuccess(true);
      },
      onError: (error) => {
        alert("Hiba történt, próbáld újra");
      },
    });

  const [passwordResetMutation, { isLoading: isLoading }] = useMutation(
    passwordReset,
    {
      onSuccess: (data) => {
        setSuccessReset(true);
      },
      onError: (error) => {
        alert("Hiba történt, próbáld újra");
      },
    }
  );

  return (
    <div>
      {!token && !success && (
        <>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8, offset: 8 }}
            xxl={{ span: 6, offset: 9 }}
          >
            <Card>
              <h2>Jelszóvisszaállítás</h2>
              <Form
                requiredMark={"optional"}
                layout={"vertical"}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={(values) =>
                  requestPasswordResetMutation(values.email)
                }
              >
                <Form.Item
                  label="Regisztrációnál használt email címed"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Add meg a regisztrációkor használt emailcímed!",
                    },
                  ]}
                >
                  <Input id="email" />
                </Form.Item>

                <Form.Item>
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <Button
                        id="login-button"
                        block
                        type="primary"
                        htmlType="submit"
                      >
                        Jelszó visszaállítása
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </>
      )}
      {!token && success && (
        <Col
          xs={{ span: 24 }}
          md={{ span: 8, offset: 8 }}
          xxl={{ span: 6, offset: 9 }}
        >
          <Card>
            <h2>Jelszóvisszaállítás</h2>
            Ellenőrizd az email fiókodat. A visszaállításhoz szükséges linket
            elküldtük emailben.
          </Card>
        </Col>
      )}
      {token && !successReset && (
        <>
          <Col
            xs={{ span: 24 }}
            md={{ span: 8, offset: 8 }}
            xxl={{ span: 6, offset: 9 }}
          >
            <Card>
              <h2>Jelszóvisszaállítás</h2>
              <Form
                requiredMark={"optional"}
                layout={"vertical"}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={(values) =>
                  passwordResetMutation({ token, newPassword: values.password })
                }
              >
                <Form.Item
                  label="Új jelszó"
                  name="password"
                  rules={[
                    { required: true, message: "Add meg az új jelszavad!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  label="Jelszó megerősítése"
                  name="confirm"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Erősítsd meg a jelszavad!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "A két megadott jelszó nem egyezik!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <Button
                        id="login-button"
                        block
                        type="primary"
                        htmlType="submit"
                      >
                        Új jelszó mentése
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </>
      )}
      {token && successReset && (
        <Col
          xs={{ span: 24 }}
          md={{ span: 8, offset: 8 }}
          xxl={{ span: 6, offset: 9 }}
        >
          <Card>
            <h2>Jelszóvisszaállítás</h2>
            Sikeresen megváltoztattad a jelszavad! Jelentkezz be az új
            jelszóval!
          </Card>
        </Col>
      )}
    </div>
  );
};
