import React, { useState } from "react";

import { Col, Form, Input, Button, Alert, Checkbox, Card } from "antd";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { register } from "../api/Auth";
import { useHistory } from "react-router";
import { useAuth } from "../auth/Auth";

export const Register = () => {
  const auth = useAuth();
  const history = useHistory();

  const [error, setError] = useState(null);

  const [registerMutation, { isLoading: isLoadingRegister }] = useMutation(
    register,
    {
      onSuccess: (data) => {
        auth.tokenSignin(data.token);
        history.replace("/");
      },
      onError: (error) => {
        setError(
          error.response.data.message === "USER_ALREADY_EXISTS"
            ? "Foglalt email cím!"
            : "Hiba történt, próbáld újra!"
        );
      },
    }
  );

  const onFinish = (values) => {
    setError(null);
    registerMutation(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Col
        xs={{ span: 24 }}
        md={{ span: 8, offset: 8 }}
        xxl={{ span: 6, offset: 9 }}
      >
        <Card>
          <h2>Regisztráció</h2>
          <Form
            requiredMark={"optional"}
            layout={"vertical"}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Név"
              name="name"
              rules={[{ required: true, message: "Add meg a teljes neved!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Nem helyes email cím!",
                },
                { required: true, message: "Add meg a felhasználóneved!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Jelszó"
              name="password"
              rules={[{ required: true, message: "Add meg a jelszavad!" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Jelszó megerősítése"
              name="confirm"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Erősítsd meg a jelszavad!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("A két megadott jelszó nem egyezik!");
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="privacy"
              valuePropName="checked"
              rules={[
                {
                  validator: (rule, value, callback) => {
                    if (value) {
                      callback();
                    } else {
                      callback([
                        new Error("A regisztrációhoz el kell fogadni!"),
                      ]);
                    }
                  },
                },
              ]}
            >
              <Checkbox>
                Az{" "}
                <Link to={"/privacy"} target={"_blank"}>
                  Adatvédelmi irányelveket
                </Link>{" "}
                megismertem és elfogadom!
              </Checkbox>
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              Már van felhasználód? <Link to={"/login"}>Jelentkezz be</Link>
            </Form.Item>

            {error && (
              <Form.Item>
                <Alert message={error} type="error" />
              </Form.Item>
            )}

            <Form.Item>
              <Button
                id={"register-button"}
                type="primary"
                htmlType="submit"
                loading={isLoadingRegister}
              >
                Regisztráció
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </>
  );
};
