import React from "react";
import { Link } from "react-router-dom";

export const ExternalAwareLink = ({ to, children }) => {
  return (to && to.indexOf("http://") > -1) || to.indexOf("https://") > -1 ? (
    <a href={to} target={"_blank"} rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link to={to}>{children}</Link>
  );
};
