import React from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { GenderInput } from "./GenderInput";
import { CategoryInput } from "./CategoryInput";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { AgeGroupInput } from "./AgeGroupInput";
import { ClubInput } from "./ClubInput";
import {useTranslation} from "react-i18next";
import {CountrySelect} from "../competition/CountrySelect";

export const EntryForm = ({ competition, clubs, onSubmit, withTeam, sk }) => {
  const [form] = Form.useForm();
    const { t } = useTranslation();

  return (
    <Form
      name="entry"
      form={form}
      layout={"vertical"}
      requiredMark={"optional"}
      initialValues={{
        name: "",
          firstName: "",
          lastName: "",
        permitNumber: "",
        gender: "",
        category: "",
        ageGroup: "",
        club: "",
        lunch: 0,
        agree: false,
        profile: "",
        email: "",
        team: 0,
        rounds: null,
        additionalData0: null,
        additionalData1: null,
        additionalData2: null,
        additionalData3: null,
        additionalData4: null,
        paymentStatus: null
      }}
      onFinish={(values) => {
        let entry = { ...values };

        if (entry.rounds) {
          entry.rounds = parseInt(values.rounds);
        } else if(competition.rounds.length > 0 && !competition.chooseRound) {
            entry.rounds = 0;
        }

        entry.category = competition.categories.find(
          (category) => category.id === values.category
        );
        entry.ageGroup = competition.ageGroups.find(
          (ageGroup) => ageGroup.id === values.ageGroup
        );
        entry.club = sk ? {id: Math.floor(Math.random() * 10000) + 10000, name: values.club} : clubs.find((club) => club.id === values.club) || {id: 999, name: "Egyéb"};

        entry.id = Math.floor(Math.random() * 1000000) + 1000000000;

        entry.name = values.lastName.trim().replace(/\s+/g, " ") + " " + values.firstName.trim().replace(/\s+/g, " ")
        onSubmit(entry);
        console.log("onfinish", entry);
      }}
      onFinishFailed={(error) => console.log("onfinishfailed", error)}
    >
      <Row gutter={16}>
        <Col span={3}>
          <Form.Item
            label={t("manager.lastName")}
            name="lastName"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
          <Col span={3}>
              <Form.Item
                  label={t("manager.firstName")}
                  name="firstName"
                  rules={[
                      {
                          required: true,
                          message: "",
                      },
                  ]}
              >
                  <Input />
              </Form.Item>
          </Col>
          {!sk && <Col span={2}>
          <Form.Item
            label={t("manager.permitNumber")}
            name="permitNumber"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>}
          {sk && <Col span={2}>
              <Form.Item
                  label={t("applicationForm.permitNumber")}
                  name="permitNumber"
                  rules={[
                      {
                          required: true,
                          message: "",
                      },
                  ]}
              >
                  <Input style={{ width: "100%" }} />
              </Form.Item>
          </Col>}

        <Col span={3}>
          <Form.Item
            label={t("applicationForm.category")}
            name="category"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <CategoryInput categories={competition.categories} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={
              <div>
                <Space>
                  <span>{t("applicationForm.ageGroup")}</span>
                  <Tooltip
                    placement="rightTop"
                    title={"A január 1-én betöltött kor számít."}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              </div>
            }
            name="ageGroup"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <AgeGroupInput ageGroups={competition.ageGroups} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label={t("manager.gender")}
            name="gender"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <GenderInput />
          </Form.Item>
        </Col>
          {competition.international && <Col span={3}>
              <Form.Item
                  label={t("applicationForm.country")}
                  name={"country"}
                  rules={[
                      {
                          required: true,
                          message: t("applicationForm.requiredCountry"),
                      },
                  ]
                  }
              >
                  <CountrySelect />
              </Form.Item>
          </Col>}
          {!sk && !competition.international && <Col span={3}>
          <Form.Item
            label={t("manager.club")}
            name="club"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <ClubInput clubs={clubs} />
          </Form.Item>
        </Col>}
          {sk && <Col span={3}>
          <Form.Item
            label={t("manager.club")}
            name="club"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>}
        <Col span={3}>
          <Form.Item
            label={competition.guestLunch ? t("manager.guestLunch") : t("manager.lunch")}
            name="lunch"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {withTeam && (
          <Col span={3}>
            <Form.Item
              label={t("manager.team")}
              name="team"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} min={0} max={withTeam} />
            </Form.Item>
          </Col>
        )}
        <Col span={5}>
          <Form.Item label="Email" name="email">
            <Input />
          </Form.Item>
        </Col>{" "}
        {competition.chooseRound && (
          <Col span={5}>
            <Form.Item
              label={t("manager.rounds")}
              name="rounds"
              rules={[
                {
                  required: true,
                  message: "Válassz napot!",
                },
              ]}
            >
              <Select>
                <Select.Option value="0">{t("manager.both")}</Select.Option>
                <Select.Option value="1">{t("manager.firstRoundOnly")}</Select.Option>
                <Select.Option value="2">{t("manager.secondRoundOnly")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        )}
          <Col span={2}>
              <Form.Item label={"Fizetett"} name="paymentStatus">
                  <Select>
                      <Select.Option value="PAID">Igen</Select.Option>
                      <Select.Option value="NOT_PAID">Nem</Select.Option>
                  </Select>
              </Form.Item>
          </Col>
        <Col span={5}>
          <Form.Item label={t("manager.comment")} name="comment">
            <Input />
          </Form.Item>
        </Col>
        {competition.additionalData?.map((additionalData) => (
          <Col key={additionalData.id} xs={24} sm={4}>
            {additionalData.type === "STRING" && (
              <Form.Item
                label={additionalData.label}
                name={["additionalData" + additionalData.pos]}
                rules={
                  additionalData.required && [
                    {
                      required: true,
                      message: "Kötelező mező!",
                    },
                  ]
                }
              >
                <Input />
              </Form.Item>
            )}
            {additionalData.type === "NUMBER" && (
              <Form.Item
                label={additionalData.label}
                name={["additionalData" + additionalData.pos]}
                rules={
                  additionalData.required && [
                    {
                      required: true,
                      message: "Kötelező mező!",
                    },
                  ]
                }
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            )}
          </Col>
        ))}
        <Col span={1} style={{ paddingTop: "30px" }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
                {t("manager.createEntry")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
