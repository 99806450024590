import React from "react";
import _ from "lodash";
import { decodeScorecard } from "./ScorecardUtils";
import { ScoreAverageChart } from "./ScoreAverageChart";

export const ScorecardDisplay = ({ scorecardString, entryId }) => {
  console.log("entryid", entryId);
  console.log("scs", scorecardString);
  const scorecard = decodeScorecard(scorecardString).decodedScorecards[entryId];

  const getColor = (value) => {
    switch (value) {
      case "x":
      case "20":
      case "19":
      case "18":
      case "17":
      case "16":
      case "15":
      case "14":
      case "13":
      case "12":
      case "11":
        return {
          backgroundColor: "#ff3f2e",
          color: "#ffffff",
        };
      case "10":
      case "9":
        return {
          backgroundColor: "#5dd2ff",
          color: "#ffffff",
        };
      case "8":
      case "7":
      case "6":
        return {
          backgroundColor: "#b4b4b4",
          color: "#000000",
        };
      case "5":
      case "4":
      case "3":
      case "2":
      case "1":
        return {
          backgroundColor: "#963e00",
          color: "#000000",
        };
      case "0":
        return {
          backgroundColor: "#000000",
          color: "#ffffff",
        };
    }
  };

  const maxScoringValue = _.max(scorecard.scoringValues.map(value => value === "x" ? -1 : parseInt(value)));

  const calculateChartData = () => {
    let data = scorecard.scores.map((scoreItem, index) => {
      let scoreArray = scorecard.scores
        .slice(0, index + 1)
        .reduce((acc, sc) => _.concat(acc, sc.scores), []);
      let scoreSum = _.sum(
          scoreArray.map(value =>
              value === "x" ? maxScoringValue : parseInt(value)));
      let avg = scoreSum / scoreArray.length;

      return {
        target: scoreItem.target,
        scoreAverage: Math.round((avg || 0) * 100) / 100,
        scoreSum,
      };
    });

    console.log(data);
    return data;
  };

  const countScoreValues = () => {
    let values = scorecard.scores.reduce(
      (acc, sc) => _.concat(acc, sc.scores),
      []
    );

    let obj = {};
    scorecard.scoringValues.map(
      (scoreValue) =>
        (obj[scoreValue] = values.filter(
          (value) => value === scoreValue
        ).length)
    );

    console.log(obj);

    return obj;
  };

  const chartData = calculateChartData();
  const scoreValues = countScoreValues();

  return (
    <div>
      <table
        border={1}
        style={{ width: "100%", marginTop: "24px", tableLayout: "fixed" }}
      >
        <tr>
          <th>#</th>
          {[...Array(scorecard.arrowsPerTarget)].map((e, index) => (
            <th key={index}>{index + 1}</th>
          ))}
          <th>Pont</th>
        </tr>
        {[...Array(scorecard.targetCount)].map((e, targetNumber) =>
            {
              let adjustedTargetNumber = scorecard.firstTargetNumber + targetNumber > scorecard.targetCount
                  ? scorecard.firstTargetNumber + targetNumber - scorecard.targetCount
                  : scorecard.firstTargetNumber + targetNumber;

              return <tr key={targetNumber}>
                <td style={{ textAlign: "center" }}>{adjustedTargetNumber}</td>
                {[...Array(scorecard.arrowsPerTarget)].map((e, arrowNumber) => (
                    <td
                        key={adjustedTargetNumber + "-" + arrowNumber}
                        style={{
                          ...getColor(
                              scorecard.scores[targetNumber].scores[arrowNumber]
                          ),
                          textAlign: "center",
                        }}
                    >
                      {scorecard.scores[targetNumber].scores[arrowNumber] !== ""
                          ? scorecard.scores[targetNumber].scores[arrowNumber].toUpperCase()
                          : "-"}
                    </td>
                ))}
                <td style={{ textAlign: "center" }}>
                  {chartData[targetNumber].scoreSum}
                </td>
              </tr>
            }
        )}
      </table>
      <div>
        <table
          border={1}
          style={{ width: "100%", marginTop: "24px", tableLayout: "fixed" }}
        >
          <tr>
            {scorecard.scoringValues.map((sv) => (
              <th key={sv} style={{ ...getColor(sv) }}>
                {sv.toUpperCase()}
              </th>
            ))}
          </tr>
          <tr>
            {scorecard.scoringValues.map((sv) => (
              <td key={sv} style={{ textAlign: "center" }}>
                {scoreValues[sv]}
              </td>
            ))}
          </tr>
        </table>
      </div>
      <ScoreAverageChart data={chartData} />
    </div>
  );
};
