import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../utils/LocalDB";
import "./Display.less";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { GenderTag } from "artemis-shared/tag/GenderTag";
import { Card } from "antd";
import _ from "lodash";
import { Progress } from "antd";
import {useTranslation} from "react-i18next";

export const Display = () => {
  const {t} = useTranslation();
  let { competitionId, defaultDisplay } = useParams();

  const [localCompetitionData, setlocalCompetitionData] = useState(null);
  const [localEntries, setLocalEntries] = useState([]);
  const [localClubs, setLocalClubs] = useState([]);
  const [selectedDisplay, setSelectedDisplay] = useState(defaultDisplay || "entries");
  const [categories, setCategories] = useState([]);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [finalsSelected, setFinalsSelected] = useState(null);

  const currentCategory = categories[currentCategoryIndex] || {category: "-", ageGroup: "-", gender: "-"};

  const international = !!(localCompetitionData?.international);

  const tOptions = international ? {lng: "en"} : {};

  console.log("currentCategory", currentCategory);

  const [broadcastChannel, setBroadcastChannel] = useState(null);

  useEffect(() => {
    if(selectedDisplay === "score") {
      setTimeout(() => {
        scroll(0);
      }, 5000);
    }
  }, [selectedDisplay, currentCategoryIndex]);

  const scroll = (index) => {
    let el = document.getElementById("row_" + index);
    if(el) {
      if(isElementInViewport(el)) {
        scroll(index + 1)
      } else {
        console.log("scroll ", index);
        document.getElementById("row_" + index).scrollIntoView({behavior: "smooth", block: "end"});
        setTimeout(() => scroll(index + 1), 4000);
      }
    } else {
      let newIndex = currentCategoryIndex + 1;
      console.log("newindex", newIndex);
      if(newIndex >= categories.length) {
        if(currentCategoryIndex === 0) {
          setCurrentCategoryIndex(null);
        }
        setCurrentCategoryIndex(0);
      } else {
        setCurrentCategoryIndex(newIndex);
      }
    }
  }

  const isElementInViewport = (el) => {
    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  const getLocalCompetition = async () => {
    let localCompetition = await db.competitions
      .where("id")
      .equals(parseInt(competitionId))
      .toArray();

    console.log("got", localCompetition);

    if (localCompetition[0]) {
      setlocalCompetitionData({
        ...localCompetition[0],
        // a már letöltött de még roundsal nem rendelkező competitionok miatt
        rounds: localCompetition[0].rounds || [],
      });
    }
  };

  const getLocalEntries = async () => {
    let localEntries = await db.entries
      .where("competition")
      .equals(parseInt(competitionId))
      .toArray();

    console.log("got", localEntries);

    if (localEntries) {
      setLocalEntries(localEntries);
    }

    return localEntries;
  };

  const getLocalClubs = async () => {
    let localClubs = await db.clubs.toArray();

    console.log("got", localClubs);

    if (localClubs) {
      setLocalClubs(localClubs);
    }

    return localClubs;
  };

  useEffect(() => {
    const bc = new BroadcastChannel("manager_display_channel");

    bc.onmessage = (ev) => {
      console.log(ev);
      if (ev.data.selectDisplay) {
        setSelectedDisplay(ev.data.selectDisplay);
      }

      if (ev.data.selectFinals) {
        setFinalsSelected(ev.data.selectFinals);
      }

      if (ev.data.selectCategories) {
        setCurrentCategoryIndex(0);
        setCategories(ev.data.selectCategories.split(";").map(categoryString => {
          let exploded = categoryString.split(",");
          return {
            category: exploded[0],
            ageGroup: exploded[1],
            gender: exploded[2]
          }
        }));
      }

      getLocalEntries();
      getLocalCompetition();
      getLocalClubs();
    };

    setBroadcastChannel(bc);

    getLocalEntries();
    getLocalCompetition();
    getLocalClubs();

    return () => bc.close();
  }, []);

  const finalsEntries = finalsSelected ? localEntries.filter(entry => entry.category.id === finalsSelected.category && entry.ageGroup.id === finalsSelected.ageGroup && entry.gender === finalsSelected.gender).sort((a,b) => a.placement - b.placement).slice(0, finalsSelected.topCount) : [];

  return (
    <>
      {localCompetitionData && selectedDisplay === "entries" && (
        <Card>
          <div className={"display"}>
            <table border={1} style={{ width: "100%" }}>
              <thead>
                <tr className={"header-row"}>
                  <th>{t("manager.teamShort", tOptions)}.</th>
                  <th>{t("manager.name", tOptions)}</th>
                  {!international && <th>{t("manager.permitShort", tOptions)}</th>}
                  <th>{t("manager.categoryShort", tOptions)}</th>
                  <th>{t("manager.ageGroupShort", tOptions)}</th>
                  <th>{t("manager.gender", tOptions)}</th>
                  {!international && <th>{t("manager.entryFeeShort", tOptions)}</th>}
                  {!international && <th>{t("manager.lunchFee", tOptions)}</th>}
                </tr>
              </thead>
              <tbody>
              {localEntries
                  .filter((entry) => entry.selected)
                  .map((entry) => (
                      <tr key={entry.id}>
                        <td className={"centered"}>{(localCompetitionData?.teamLabels?.split(";")[entry.team - 1] || entry.team)}</td>
                        <td>
                          <div className={"name-col"}>
                            <div>{entry.name}</div>
                            <div className={"club-name"}>{international ? t("country." +entry.country, tOptions) : entry.club.name}</div>
                          </div>
                        </td>
                        {!international &&<td className={"nowrap"}>{entry.permitNumber}</td>}
                        <td className={"centered"} >
                          <CategoryTag category={t(entry.category.name, tOptions)}/>
                        </td>
                        <td className={"centered"}>
                          <AgeGroupTag ageGroup={t(entry.ageGroup.ageGroup.name, tOptions)} />
                        </td>
                        <td className={"centered"}>
                          <GenderTag gender={t(entry.gender, tOptions)} />
                        </td>
                        {!international &&<td className={"centered"}>
                          {(entry.entryFee || 0).toLocaleString()}
                        </td>}
                        {!international &&<td className={"centered"}>
                          {(entry.lunchFee || 0).toLocaleString()}
                        </td>}
                      </tr>
                  ))}
              {!international && <tr className={"sum-row"}>
                  <td colSpan={international ? 4 : 7}>{t("manager.total", tOptions)}</td>
                  <td className={"centered"}>
                    {localEntries
                        .filter((entry) => entry.selected)
                        .reduce((acc, entry) => {
                          return acc + entry.entryFee + entry.lunchFee;
                        }, 0)
                        .toLocaleString()}
                  </td>
                </tr>}
              </tbody>
            </table>
          </div>
        </Card>
      )}
      {localCompetitionData && selectedDisplay === "teams" && (
        <Card>
          <div className={"display"}>
            <div className={"processed"}>
              <Progress
                type="circle"
                percent={(
                  (_.filter(localEntries, "score").length /
                    localEntries.length) *
                  100
                ).toFixed(2)}
                width={200}
              />
              {_.filter(localEntries, "score").length}
              {" / " + localEntries.length}
            </div>
            <div className={"teams-row"}>
              {[...Array(localCompetitionData.teamCount).keys()].map(
                (teamNum) => (
                  <div
                    key={teamNum}
                    className={
                      "team-indicator " +
                      (_.filter(localEntries, { team: teamNum + 1 }).some(
                        (entry) => entry.score
                      )
                        ? "in-progress "
                        : "") +
                      (_.filter(localEntries, { team: teamNum + 1 }).every(
                        (entry) => entry.score
                      )
                        ? "complete "
                        : "")
                    }
                  >
                    {teamNum + 1}
                  </div>
                )
              )}
            </div>
          </div>
        </Card>
      )}
      {localCompetitionData && selectedDisplay === "score" && localCompetitionData.subtype !== "FS" && (
          <Card>
            <div className={"display " + selectedDisplay}>
              <div className={"category-row"}>
                <span>{t(currentCategory.category, tOptions)}</span>
                <span>{t(currentCategory.ageGroup, tOptions).toUpperCase()}</span>
                  <span>{currentCategory.gender === "MALE" ? t("MALE", tOptions)?.toUpperCase() : t("FEMALE", tOptions)?.toUpperCase()}</span>
              </div>
              <table border={1} style={{ width: "100%" }}>
                <thead>
                  <tr className={"header-row"}>
                    <th>#</th>
                    <th>{t("manager.name", tOptions)}</th>
                    {international && <th></th>}
                    {localCompetitionData &&
                        [...localCompetitionData.scoring]
                            .reverse()
                            .map((scoreProperty) => (
                                <th key={scoreProperty}>{scoreProperty}</th>))}
                    <th>{t("manager.score", tOptions)}</th>
                  </tr>
                </thead>
                <tbody>
                {localEntries
                    .filter(entry => entry.category.name === currentCategory.category && entry.ageGroup.ageGroup.name === currentCategory.ageGroup && entry.gender === currentCategory.gender)
                    .sort((a, b) => a.placement - b.placement)
                    .map((entry, index) => (
                        <tr key={entry.id} id={"row_" + index}>
                          <td>{entry.placement}</td>
                          <td>{entry.name}</td>
                          {international && <td>{entry.country}</td>}
                          {localCompetitionData &&
                              [...localCompetitionData.scoring]
                                  .reverse()
                                  .map((scoreProperty) => (
                                      <td key={scoreProperty}>{entry["s" + scoreProperty] || "-"}</td>
                                  ))}
                          <td>{entry.score}</td>
                        </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div id={"bottomMarker"}></div>
          </Card>
      )}
      {localCompetitionData && selectedDisplay === "score" && localCompetitionData.subtype === "FS" && (
          <Card>
            <div className={"display " + selectedDisplay}>
              <div className={"category-row"}>
                <span>{currentCategory.category}</span>
                <span>{currentCategory.ageGroup.toUpperCase()}</span>
                <span>{currentCategory.gender === "MALE" ? "FÉRFI" : "NŐ"}</span>
              </div>
              <table border={1} style={{ width: "100%" }}>
                <thead>
                <tr className={"header-row"}>
                  <th>#</th>
                  <th>Név</th>
                  <th>Orsz</th>
                  <th>Táv</th>
                </tr>
                </thead>
                <tbody>
                {localEntries
                    .filter(entry => entry.category.name === currentCategory.category && entry.ageGroup.ageGroup.name === currentCategory.ageGroup && entry.gender === currentCategory.gender)
                    .sort((a, b) => a.placement - b.placement)
                    .map((entry, index) => (
                        <tr key={entry.id} id={"row_" + index}>
                          <td>{entry.placement}</td>
                          <td>{entry.name}</td>
                          <td>{entry.country}</td>
                          <td>{entry.score}</td>
                        </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div id={"bottomMarker"}></div>
          </Card>
      )}
      {localCompetitionData && selectedDisplay === "finals" && !finalsSelected && <h1 style={{fontSize: "50px", textAlign: "center"}}>Válassz ki egy döntőt a lapozóval!</h1>}
      {localCompetitionData && selectedDisplay === "finals" && localCompetitionData.subtype === "LDTS" && finalsSelected && finalsEntries.length > 0 && (
          <Card>
            <div className={"display ldts " + selectedDisplay}>
              <h3>{t(finalsEntries[0].category.name, tOptions)} {t(finalsEntries[0].ageGroup.ageGroup.name, tOptions)} {t(finalsEntries[0].gender, tOptions)}</h3>
              <table border={1} className={"ldts-table"} style={{ width: "100%", height: "80vh" }}>
                <thead>
                  <tr className={"header-row"}>
                    <th colSpan={2}></th>
                    <th></th>
                    <th>{t("manager.totalShort", tOptions)}</th>
                    <th style={{width: "115px"}}>40m</th>
                    <th style={{width: "115px"}}>60m</th>
                    <th style={{width: "115px"}}>75m</th>
                    <th style={{width: "115px"}}>90m</th>
                    <th style={{width: "115px"}}>140m</th>
                    <th>{t("manager.placementShort", tOptions)}</th>
                  </tr>
                </thead>
                <tbody>
                  {finalsEntries.sort(
                      (a,b) => _.sumBy(b.roundEntries.slice(0, b.roundEntries.length - 1), "score") - _.sumBy(a.roundEntries.slice(0, a.roundEntries.length - 1), "score")
                  ).map((entry, index) => (<tr key={entry.id}>
                    <td className={"ldts-col"}>{index + 1}.</td>
                    <td className={"ldts-name-col"}>{entry.name}</td>
                    <td className={"ldts-col"}>{entry.country || "HUN"}</td>
                    <td className={"ldts-col"}>{_.sumBy(entry.roundEntries.slice(0, entry.roundEntries.length - 1), "score")}</td>
                    <td className={"ldts-col ldts-hit-" + (entry.roundEntries[entry.roundEntries.length - 1].finals || "").split(";")[0]}>{(entry.roundEntries[entry.roundEntries.length - 1].finals || "").split(";")[0]}</td>
                    <td className={"ldts-col ldts-hit-" + (entry.roundEntries[entry.roundEntries.length - 1].finals || "").split(";")[1]}>{(entry.roundEntries[entry.roundEntries.length - 1].finals || "").split(";")[1]}</td>
                    <td className={"ldts-col ldts-hit-" + (entry.roundEntries[entry.roundEntries.length - 1].finals || "").split(";")[2]}>{(entry.roundEntries[entry.roundEntries.length - 1].finals || "").split(";")[2]}</td>
                    <td className={"ldts-col ldts-hit-" + (entry.roundEntries[entry.roundEntries.length - 1].finals || "").split(";")[3]}>{(entry.roundEntries[entry.roundEntries.length - 1].finals || "").split(";")[3]}</td>
                    <td className={"ldts-col ldts-hit-" + (entry.roundEntries[entry.roundEntries.length - 1].finals || "").split(";")[4]}>{(entry.roundEntries[entry.roundEntries.length - 1].finals || "").split(";")[4]}</td>
                    <td className={"ldts-col ldts-placement-" + entry.placement + " " + (entry.tie ? "tie-col" : "")}>{entry.placement}.</td>
                  </tr>))}
                </tbody>
              </table>
            </div>
          </Card>
          )}

      {localCompetitionData && selectedDisplay === "finals" && (localCompetitionData.type === "THREED" || (localCompetitionData.type === "HISTORICAL" && localCompetitionData.subtype !== "LDTS") ) && finalsSelected && finalsEntries.length > 0 && (
          <Card>
            <div className={"display " + selectedDisplay}>
              <h3>{t(finalsEntries[0].category.name, tOptions)} {t(finalsEntries[0].ageGroup.ageGroup.name, tOptions)} {t(finalsEntries[0].gender, tOptions)}</h3>
              <table border={1} style={{ width: "100%" }}>
                <thead>
                  <tr className={"header-row"}>
                    <th>#</th>
                    <th>{t("manager.name", tOptions)}</th>
                    {international && <th></th>}
                    <th>{t("manager.totalShort", tOptions)}</th>
                    <th style={{width: "115px"}}>1.</th>
                    <th style={{width: "115px"}}>2.</th>
                    <th style={{width: "115px"}}>3.</th>
                    <th style={{width: "115px"}}>4.</th>
                    <th style={{width: "115px"}}>5.</th>
                    <th style={{width: "115px"}}>6.</th>
                    <th>{t("manager.totalShort", tOptions)}</th>
                    <th>{t("manager.placementShort", tOptions)}</th>
                  </tr>
                </thead>
                <tbody>
                  {finalsEntries.sort(
                      (a,b) => _.sumBy(b.roundEntries.slice(0, b.roundEntries.length - 1), "score") - _.sumBy(a.roundEntries.slice(0, a.roundEntries.length - 1), "score")
                  ).map((entry, index) => {
                    let finalsValue = entry.roundEntries[entry.roundEntries.length - 1].finals || "";
                      return <tr key={entry.id}>
                    <td className={"threed-col"}>{index + 1}.</td>
                    <td>{entry.name}</td>
                        {international && <td>{entry.country}</td>}
                    <td className={"threed-col"}>{_.sumBy(entry.roundEntries.slice(0, entry.roundEntries.length - 1), "score")}</td>
                    <td className={"has-divided"}><div className={"divided"}></div><div className={"hit-value"}>{finalsValue.split(";")[0]}</div><div className={"score-value"}>{finalsValue.split(";")[0] && _.sumBy(entry.roundEntries.slice(0, entry.roundEntries.length - 1), "score") + _.sum(finalsValue.split(";").slice(0, 1).map(v => parseInt(v)))}</div></td>
                    <td className={"has-divided"}><div className={"divided"}></div><div className={"hit-value"}>{finalsValue.split(";")[1]}</div><div className={"score-value"}>{finalsValue.split(";")[1] && _.sumBy(entry.roundEntries.slice(0, entry.roundEntries.length - 1), "score") + _.sum(finalsValue.split(";").slice(0, 2).map(v => parseInt(v)))}</div></td>
                    <td className={"has-divided"}><div className={"divided"}></div><div className={"hit-value"}>{finalsValue.split(";")[2]}</div><div className={"score-value"}>{finalsValue.split(";")[2] && _.sumBy(entry.roundEntries.slice(0, entry.roundEntries.length - 1), "score") + _.sum(finalsValue.split(";").slice(0, 3).map(v => parseInt(v)))}</div></td>
                    <td className={"has-divided"}><div className={"divided"}></div><div className={"hit-value"}>{finalsValue.split(";")[3]}</div><div className={"score-value"}>{finalsValue.split(";")[3] && _.sumBy(entry.roundEntries.slice(0, entry.roundEntries.length - 1), "score") + _.sum(finalsValue.split(";").slice(0, 4).map(v => parseInt(v)))}</div></td>
                    <td className={"has-divided"}><div className={"divided"}></div><div className={"hit-value"}>{finalsValue.split(";")[4]}</div><div className={"score-value"}>{finalsValue.split(";")[4] && _.sumBy(entry.roundEntries.slice(0, entry.roundEntries.length - 1), "score") + _.sum(finalsValue.split(";").slice(0, 5).map(v => parseInt(v)))}</div></td>
                    <td className={"has-divided"}><div className={"divided"}></div><div className={"hit-value"}>{finalsValue.split(";")[5]}</div><div className={"score-value"}>{finalsValue.split(";")[5] && _.sumBy(entry.roundEntries.slice(0, entry.roundEntries.length - 1), "score") + _.sum(finalsValue.split(";").slice(0, 6).map(v => parseInt(v)))}</div></td>
                    <td className={"threed-col"}>{entry.score}</td>
                    <td className={"threed-col threed-placement-" + entry.placement + " " + (entry.tie ? "tie-col" : "")}>{entry.placement}.</td>
                  </tr>})}
                </tbody>
              </table>
            </div>
          </Card>
      )}
    </>
  );
};
