import React, {useEffect, useState} from "react";
import {Button, Modal, Space} from "antd";
import {groupEntries} from "artemis-shared/utils/CompetitionEntryUtils";
import _ from "lodash";
import {CheckCircleOutlined, CheckCircleTwoTone} from "@ant-design/icons";

export const CategorySelectorModal = ({entries}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [broadcastChannel, setBroadcastChannel] = useState(null);

    useEffect(() => {
        const bc = new BroadcastChannel("manager_display_channel");
        setBroadcastChannel(bc);
    }, []);

    useEffect(() => {
        broadcastChannel && broadcastChannel.postMessage({ selectCategories: selectedCategories.join(";") });
    }, [selectedCategories])

    let groupedEntries = groupEntries(entries);
    let sortedGroupEntries = Object.entries(groupedEntries).sort((a, b) => {
        if (a[1].category.name.localeCompare(b[1].category.name) == 1) return 1;
        if (a[1].category.name.localeCompare(b[1].category.name) == -1) return -1;

        return a[1].ageGroup.fromAge - b[1].ageGroup.fromAge;
    });

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Kateg.
            </Button>
            <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                {sortedGroupEntries.map(([key, group]) =>
                    <div
                        key={key}
                        className={"category"}
                        onClick={() => {
                            let index = selectedCategories.indexOf(`${group.category.name},${group.ageGroup.ageGroup.name},${group.gender}`)
                            if(index === -1) {
                                setSelectedCategories([...selectedCategories, `${group.category.name},${group.ageGroup.ageGroup.name},${group.gender}`]);

                            } else {
                                let newArray = [...selectedCategories];
                                newArray.splice(index, 1);
                                setSelectedCategories(newArray);
                            }
                        }}
                    >
                        <Space>
                            {selectedCategories.indexOf(`${group.category.name},${group.ageGroup.ageGroup.name},${group.gender}`) === -1 ? (
                                    <CheckCircleTwoTone twoToneColor={"#ff0000"} />
                                ) : (
                                    <CheckCircleTwoTone twoToneColor={"#52c41a"} />
                                )}
                            <span>
          {group.category.name} {group.ageGroup.ageGroup.name}{" "}
                                {group.gender === "MALE" ? "Férfi" : "Nő"}
        </span>
                        </Space>
                    </div>)}
            </Modal>
        </>
    );
}