import React, { useState } from "react";
import "./CookieConsent.less";
import { Button, Space } from "antd";
import { Link } from "react-router-dom";

export const CookieConsent = () => {
  const [accepted, setAccepted] = useState(
    localStorage.getItem("cookie-consent")
  );
  if (!accepted) {
    return (
      <div className={"cookie-consent"}>
        <span className={"consent-text"}>
          Ez az oldal cookie-kat használ. Ha az oldalon böngészik, akkor
          elfogadja ezek használatát. A cookie szabályzat megtekinthető
        </span>
        <Link to={"/cookie-policy"}>ide kattintva</Link>
        <Button
          onClick={() => {
            setAccepted(true);
            localStorage.setItem("cookie-consent", "accepted");
          }}
        >
          Elfogadom
        </Button>
      </div>
    );
  } else {
    return null;
  }
};
