import React, { useContext } from "react";
import { Divider, Space, Tooltip } from "antd";
import {
  AimOutlined,
  InfoCircleOutlined,
  NumberOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import { GenderTag } from "artemis-shared/tag/GenderTag";
import { TournamentEntriesContext } from "./TournamentEntriesContext";
import _ from "lodash";
import {CompetitionContext} from "./CompetitionContext";
import {useTranslation} from "react-i18next";

const GroupElementComponent = ({ entry }) => {
  const { t } = useTranslation();
  const tournamenEntries = useContext(TournamentEntriesContext);
  const competition = useContext(CompetitionContext);

  const tournamentEntry = _.find(
    tournamenEntries,
    (te) =>
      te.name === entry.name &&
      te.ageGroup.id === entry.ageGroup.ageGroup.id &&
      te.category.id === entry.category.id &&
      te.gender === entry.gender
  );

  return (
    <Tooltip
      key={entry.id}
      placement="topLeft"
      title={entry.comment ? entry.comment : ""}
      mouseEnterDelay={0.3}
      mouseLeaveDelay={0}
    >
      <div className={"entry"} key={entry.id}>
        <div className={"entry-left"}>
          <div className={"entry-name"}>
            <div>
              <Space>
                {entry.comment && (
                  <InfoCircleOutlined
                    style={{
                      color: "#258f17",
                    }}
                  />
                )}
                <span>{entry.name}</span>
              </Space>
            </div>
            <div className={"entry-club"}>{competition?.international ? (entry.country && t("country." + entry.country)) || "-" : entry.club.name}</div>
          </div>
        </div>
        <div className={"entry-right"}>
          <div className={"category-info"}>
            <Tooltip placement="right" title={entry.category.description}>
              <div>
                <CategoryTag category={entry.category.name} />
              </div>
            </Tooltip>
            <div>
              <AgeGroupTag ageGroup={entry.ageGroup.ageGroup.name} />
            </div>
            <div>
              <GenderTag gender={entry.gender} />
            </div>
          </div>
          {tournamenEntries && entry.roundEntries?.length === 0 && (
            <div className={"tournament-info"}>
              {tournamentEntry && (
                <Space split={<Divider type="vertical" />}>
                  <Tooltip key="placement" title={"Kupa helyezés"}>
                    <NumberOutlined /> {tournamentEntry.placement}.
                  </Tooltip>
                  <Tooltip key="tournamentScore" title={"Kupa pontszám"}>
                    <TrophyOutlined /> {tournamentEntry.tournamentScore}
                  </Tooltip>
                  <Tooltip key="score" title={"Pontszám"}>
                    <AimOutlined /> {tournamentEntry.sumScore}
                  </Tooltip>
                  <Tooltip key="rounds" title={"Fordulók"}>
                    {tournamentEntry.tournamentScores?.length} F.
                  </Tooltip>
                </Space>
              )}
              {!tournamentEntry && <span>--</span>}
            </div>
          )}

          {entry.roundEntries.length > 0 && (
            <div className={"tournament-info"}>
              <Space split={<Divider type="vertical" />}>
                <Tooltip key="placement" title={"Helyezés"}>
                  <NumberOutlined /> {entry.placement}.
                </Tooltip>
                <Tooltip key="score" title={"Pontszám"}>
                  <AimOutlined /> {entry.score}
                </Tooltip>
              </Space>
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export const GroupElement = React.memo(GroupElementComponent);
