import React from "react";
import { Card, Form, Input, Slider, Button, Select, PageHeader } from "antd";
import { useHistory } from "react-router";
import { createTraining } from "./TrainingUtils";

export const CreateTraining = () => {
  const history = useHistory();

  return (
    <div className={"training"}>
      <Card bodyStyle={{ padding: "16px 8px 40px 8px" }}>
        <PageHeader
          className="site-page-header"
          onBack={() => history.push("/training")}
          title={"Új edzés létrehozása"}
        />
        <Form
          requiredMark={false}
          scrollToFirstError={true}
          size={"large"}
          onFinish={(training) => {
            createTraining(training).then((trainingId) =>
              history.replace("/training/" + trainingId)
            );
          }}
          initialValues={{
            name: "",
            targetCount: 20,
            arrowsPerTarget: 2,
            maxDistance: 45,
            scoring: "11,10,8,5,0",
          }}
        >
          <Form.Item
            label={<h3>Edzés neve</h3>}
            name={"name"}
            rules={[{ required: true, message: "Adj nevet az edzésnek" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={<h3>Célok száma</h3>} name={"targetCount"}>
            <Slider
              min={1}
              max={50}
              marks={{
                1: "1",
                12: "12",
                20: "20",
                24: "24",
                28: "28",
                40: "40",
                50: "50",
              }}
            />
          </Form.Item>
          <Form.Item
            label={<h3>Lövések célonként</h3>}
            name={"arrowsPerTarget"}
          >
            <Slider
              min={1}
              max={10}
              marks={{
                1: "1",
                2: "2",
                3: "3",
                4: "4",
                5: "5",
                6: "6",
                7: "7",
                8: "8",
                9: "9",
                10: "10",
              }}
            />
          </Form.Item>
          <Form.Item label={<h3>Max táv méterben</h3>} name={"maxDistance"}>
            <Slider
              min={10}
              max={70}
              marks={{
                10: "10",
                20: "20",
                27: "27",
                45: "45",
                50: "50",
                60: "60",
                70: "70",
              }}
            />
          </Form.Item>
          <Form.Item
            label={<h3>Lehetséges találati értékek</h3>}
            name={"scoring"}
          >
            <Select>
              <Select.Option value="11,10,8,5,0">11, 10, 8, 5, 0</Select.Option>
              <Select.Option value="x,10,8,5,0">X, 10, 8, 5, 0</Select.Option>
              <Select.Option value="10,8,5,0">10, 8, 5, 0</Select.Option>
              <Select.Option value="12,10,8,5,0">12, 11, 8, 5, 0</Select.Option>
              <Select.Option value="10,9,8,7,6,0">
                10, 9, 8, 7, 6, 0
              </Select.Option>
              <Select.Option value="10,9,8,7,6,5,4,3,2,1,0">
                10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0
              </Select.Option>
              <Select.Option value="x,5,4,3,2,1,0">
                X, 5, 4, 3, 2, 1, 0
              </Select.Option>
            </Select>
          </Form.Item>
          <Button type="primary" block htmlType="submit">
            Edzés létrehozása
          </Button>
        </Form>
      </Card>
    </div>
  );
};
