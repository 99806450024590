import React from "react";

import "./Numpad.less";
import { ArrowLeftOutlined, CheckOutlined } from "@ant-design/icons";

export const Numpad = ({ onType, onDelete, onSubmit }) => {
  const clickHandler = (value) => {
    navigator.vibrate(100);

    if (value === "delete") {
      onDelete();
    } else if (value === "submit") {
      onSubmit();
    } else {
      onType(value);
    }
  };

  return (
    <div className={"numpad-container"}>
      <div className={"numpad"}>
        <div className={"digit-row"}>
          <div className={"digit"} onClick={() => clickHandler(7)}>
            7
          </div>
          <div className={"digit"} onClick={() => clickHandler(8)}>
            8
          </div>
          <div className={"digit"} onClick={() => clickHandler(9)}>
            9
          </div>
        </div>
        <div className={"digit-row"}>
          <div className={"digit"} onClick={() => clickHandler(4)}>
            4
          </div>
          <div className={"digit"} onClick={() => clickHandler(5)}>
            5
          </div>
          <div className={"digit"} onClick={() => clickHandler(6)}>
            6
          </div>
        </div>
        <div className={"digit-row"}>
          <div className={"digit"} onClick={() => clickHandler(1)}>
            1
          </div>
          <div className={"digit"} onClick={() => clickHandler(2)}>
            2
          </div>
          <div className={"digit"} onClick={() => clickHandler(3)}>
            3
          </div>
        </div>
        <div className={"digit-row"}>
          <div className={"digit"} onClick={() => clickHandler("delete")}>
            <ArrowLeftOutlined />
          </div>
          <div className={"digit"} onClick={() => clickHandler(0)}>
            0
          </div>
          <div className={"digit"} onClick={() => clickHandler("submit")}>
            <CheckOutlined />
          </div>
        </div>
      </div>
    </div>
  );
};
