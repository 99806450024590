import React from "react";

import { FrownOutlined } from "@ant-design/icons";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    console.log("didcatch");
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className={"error-boundary"}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FrownOutlined style={{ fontSize: "70px", margin: "40px" }} />
          <h1>Hiba történt!</h1>
          <h1>Kérlek frissítsd az oldalt és próbáld újra!</h1>
        </div>
      );
    }

    return this.props.children;
  }
}
