import React from "react";
import { Menu, Grid, Divider } from "antd";
import { useQuery } from "react-query";
import { getTournaments } from "../api/Tournament";
import { Link } from "react-router-dom";
import { useBreakpoint } from "artemis-shared/utils/Breakpoint";
import _ from "lodash";
const SubMenu = Menu.SubMenu;

export const LeftMenu = ({ onClose }) => {
  const { menu } = useBreakpoint();

  const { data: tournaments } = useQuery("tournaments", getTournaments);

  return (
    <Menu
      selectable={false}
      theme={"dark"}
      mode={!menu ? "horizontal" : "inline"}
    >
      <Menu.Item key="2">
        <Link
          id="competitions-menu-button"
          to="/competitions"
          onClick={onClose}
        >
          Versenyek
        </Link>
      </Menu.Item>
      <SubMenu key="3" title="Kupák">
        {tournaments &&
          _.entries(_.groupBy(tournaments, "group"))
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([groupName, groupedTournaments]) => {
              console.log("groupedTournaments", groupedTournaments);
              const latestTournament = _.orderBy(
                groupedTournaments,
                "year",
                "desc"
              )[0];
              return (
                <Menu.Item key={groupName}>
                  <Link
                    to={`/tournament/${latestTournament.id}/${latestTournament.slug}`}
                    onClick={onClose}
                  >
                    {groupName}
                  </Link>
                </Menu.Item>
              );
            })}
        <Divider />
        <Menu.Item key="8">
          <Link to="/qualification" onClick={onClose}>
            Minősítések
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="4">
        <Link to="/ranking" onClick={onClose}>
          Ranglista
        </Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link to="/clubs" onClick={onClose}>
          Egyesületek
        </Link>
      </Menu.Item>
      {menu && (
        <Menu.Item key="6">
          <Link to="/scorecard" onClick={onClose}>
            Beírólap
          </Link>
        </Menu.Item>
      )}
      {menu && (
        <Menu.Item key="7">
          <Link to="/training" onClick={onClose}>
            Edzés
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="8">
        <Link to="/tools" onClick={onClose}>
          Eszközök
        </Link>
      </Menu.Item>
    </Menu>
  );
};
