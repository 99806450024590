import React, { useState } from "react";
import { useQuery } from "react-query";
import { getClubStats } from "../api/ClubStats";
import { Card, Divider, Input, PageHeader, Space } from "antd";
import { SearchOutlined, TrophyTwoTone } from "@ant-design/icons";
import _ from "lodash";
import { NavLink } from "react-router-dom";

export const ClubList = () => {
  const { data } = useQuery("clubstats", getClubStats);
  const [search, setSearch] = useState("");

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/")}
        title={"Egyesületek"}
        extra={[
          <Input
            key={"search"}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={"Keresés..."}
            addonAfter={<SearchOutlined />}
          />,
        ]}
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {data &&
          _.sortBy(data, ["competitors", "entries"])
            .filter((club) => {
              return search
                ? club.clubName.toLowerCase().indexOf(search.toLowerCase()) > -1
                : true;
            })
            .reverse()

            .map((stats) => (
              <NavLink key={stats.clubId} to={stats.competitors > 0 && `/club/${stats.clubId}`}>
                <Card
                  key={stats.clubId}
                  style={{ width: 300, margin: "32px 8px 32px 8px" }}
                  cover={
                    <div
                      style={{
                        height: "158px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "4px",
                      }}
                    >
                      <img
                        style={{ maxHeight: "150px", maxWidth: "280px" }}
                        src={`/images/club_${stats.clubId}.png`}
                        onError={(e) => {
                          e.target.style = "display: none";
                        }}
                      />
                    </div>
                  }
                  actions={[
                    <div key={"first"}>
                      <TrophyTwoTone twoToneColor={"#ffce52"} />{" "}
                      {stats.firstPlace}
                    </div>,
                    <div key={"second"}>
                      <TrophyTwoTone twoToneColor={"#949494"} />{" "}
                      {stats.secondPlace}
                    </div>,
                    <div key={"third"}>
                      <TrophyTwoTone twoToneColor={"#925408"} />{" "}
                      {stats.thirdPlace}
                    </div>,
                  ]}
                >
                  <Card.Meta
                    title={stats.clubName}
                    description={
                      <Space split={<Divider type="vertical" />}>
                        <div>{stats.competitors} versenyző</div>
                        <div>{stats.entries} eredmény</div>
                      </Space>
                    }
                  />
                </Card>
              </NavLink>
            ))}
      </div>
    </>
  );
};
