import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Divider, Space, Tabs } from "antd";
import moment from "moment";
import { ApplicationForm } from "./ApplicationForm";
import { EntriesTable } from "./EntriesTable";
import { Description } from "./Description";
import { resolveCompetitionType } from "../utils/CompetitionType";
import { TeamedEntriesList } from "./TeamedEntriesList";
import { EntriesList } from "./EntriesList";
import { Reviews } from "./Reviews";
import { Targets } from "./Targets";
import { PublicPlacementTab } from "./PublicPlacementTab";
import { MiszBanner } from "./MiszBanner";
import {useTranslation} from "react-i18next";
import {ResultsBanner} from "./ResultsBanner";
import DOMPurify from "dompurify";

const { TabPane } = Tabs;

export const CompetitionXS = ({
  competition,
  entries,
  form,
  onSubmitEntry,
  userData,
  clubs,
  isLoading,
  isFetchingEntries,
  highlightedEntry,
  ownEntries,
  reviews,
  entryToModify,
  entryEditor,
  getProfileInput,
  permitRequired,
  miszApprovedFilter,
  multiRound,
  multiRoundFinished
}) => {
  const { t } = useTranslation();
  const bottomOfEntriesRef = useRef(null);
  const [activeTabKey, setActiveTabKey] = useState("1");

  const hasTeams = competition.teamsPublished;

  useEffect(() => {
    if (highlightedEntry) {
      setActiveTabKey("3");
      setTimeout(() => {
        let top = bottomOfEntriesRef.current.getBoundingClientRect().top;
        console.log("top", top);
        window.scrollTo({
          top: top,
          behavior: "smooth",
        });
      }, 1);
    }
  }, [highlightedEntry]);

  return (
    <>
      {competition.externalScoreUrl && <div style={{ margin: "0 10px 0 10px" }}>
        <ResultsBanner competition={competition} />
      </div>}
      {!miszApprovedFilter && competition.miszApproved && (
        <div style={{ margin: "0 10px 0 10px" }}>
          <MiszBanner competition={competition} />
        </div>
      )}

      {competition.stage !== "APPLICATION" && (
        <>
          <Col xs={24} md={0}>
            <Space
              split={<Divider type="vertical" />}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <span>{resolveCompetitionType(competition.type)}</span>
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`https://www.google.com/maps/search/?api=1&query=${competition.lat},${competition.lng}`}
              >
                {competition.location}
              </a>
              <span>{competition.startDate}</span>
            </Space>
          </Col>
          <Col xs={24} md={0}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {competition.organizerClub.name}
            </div>
          </Col>
        </>
      )}
      <Col xs={24} md={0}>
        {competition.headerText !== null && <Card>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(competition.headerText),
            }}
          ></div>
        </Card>}
        {competition.stage === "SCORED" && entries && (!multiRound || multiRoundFinished) && (
          <>
            <PublicPlacementTab
              localEntries={entries}
              localCompetitionData={competition}
            />
            <Reviews reviews={reviews} />
            {competition.targets.length > 0 && entries && (
              <>
                <Targets competition={competition} entries={entries} />
                <br />
              </>
            )}
            <Card title={t("competition.description")}>
              <Description competition={competition} />
            </Card>
          </>
        )}

        {competition.stage === "SCORED" && entries && multiRound && !multiRoundFinished && (

            <Tabs
                defaultActiveKey="1"
                centered
                tabBarGutter={16}
                activeKey={activeTabKey}
                onChange={setActiveTabKey}
            >
              <TabPane tab={t("competition.results")} key="1">
                <PublicPlacementTab
                    localEntries={entries}
                    localCompetitionData={competition}
                />
              </TabPane>
              <TabPane tab={t("competition.teams")} key="2">
                <Col xs={24} sm={0}>
                  {competition.teamsPublished && <TeamedEntriesList
                      entries={entries}
                      isFetchingEntries={isFetchingEntries}
                      competition={competition}
                  />}

                  {!competition.teamsPublished && <Card>Még készül a csapatbeosztás a következő napra!</Card>}
                </Col>
              </TabPane>
              <TabPane tab={t("competition.description")} key="3">
                <Description competition={competition} />
              </TabPane>
            </Tabs>
        )}

        {competition.stage === "INFO" && (
          <Card title={t("competition.description")}>
            <Description competition={competition} />
          </Card>
        )}
        {competition.stage === "APPLICATION" && (
          <div className={"competition-xs"}>
            <Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: " 16px",
                }}
              >
                <Space split={<Divider type="vertical" />}>
                  <span>{resolveCompetitionType(competition.type)}</span>
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${competition.lat},${competition.lng}`}
                  >
                    {competition.location}
                  </a>
                  <span>{competition.startDate}</span>
                </Space>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: " 16px",
                }}
              >
                <span>{competition.organizerClub.name}</span>
              </div>
            </Card>
            <Card>
              <Tabs
                defaultActiveKey="1"
                centered
                tabBarGutter={16}
                activeKey={activeTabKey}
                onChange={setActiveTabKey}
              >
                <TabPane tab={t("competition.applicationCardTitle")} key="1">
                  {competition.stage === "APPLICATION" &&
                    ownEntries &&
                    ownEntries.length > 0 && (
                      <Col xs={24}>{entries && entryEditor}</Col>
                    )}

                  <div className={"application-deadline"}>
                    <Space split={<Divider type="vertical" />}>
                      {competition.maxParticipants < 999 && (
                        <span>
                          <strong>{t("competition.maxParticipants")}:</strong>{" "}
                          {competition.maxParticipants}
                        </span>
                      )}
                      <span>
                        <strong>{t("competition.applicationDeadline")}:</strong>{" "}
                        {moment(competition.applicationDeadline).format(
                          "YYYY.MM.DD. - HH:mm"
                        )}
                      </span>
                    </Space>
                  </div>
                  <Divider type={"horizontal"}></Divider>

                  <ApplicationForm
                    form={form}
                    onSubmitEntry={onSubmitEntry}
                    userData={userData}
                    competition={competition}
                    clubs={clubs}
                    isLoading={isLoading}
                    entriesNumber={entries ? entries.length : 0}
                    entryToModify={entryToModify}
                    getProfileInput={getProfileInput}
                    permitRequired={permitRequired}
                    misz={miszApprovedFilter}
                  />
                </TabPane>
                <TabPane tab={t("competition.description")} key="2">
                  <Description competition={competition} />
                </TabPane>
                <TabPane
                  tab={`${t("competition.participantsShort")} (${entries ? entries.length : "-"})`}
                  key="3"
                >
                  <Col xs={24} sm={0}>
                    {hasTeams && (
                      <TeamedEntriesList
                        entries={entries}
                        isFetchingEntries={isFetchingEntries}
                        competition={competition}
                      />
                    )}
                    {!hasTeams && (
                      <EntriesList
                        entries={entries}
                        isFetchingEntries={isFetchingEntries}
                        highlightedEntry={highlightedEntry}
                        competition={competition}
                      />
                    )}
                  </Col>
                  <Col xs={0} sm={24}>
                    <EntriesTable
                      entries={entries}
                      isFetchingEntries={isFetchingEntries}
                      competition={competition}
                      highlightedEntry={highlightedEntry}
                    />
                  </Col>
                  <div ref={bottomOfEntriesRef}></div>
                </TabPane>
              </Tabs>
            </Card>
          </div>
        )}
      </Col>
    </>
  );
};
