import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getTournaments = async () => {
  const { data } = await axios(`${API_URL}/tournament`);
  return data;
};

export const getTournament = async (key, id) => {
  const { data } = await axios(`${API_URL}/tournament/${id}`);
  return data;
};

export const getTournamentEntries = async (key, id) => {
  const { data } = await axios(`${API_URL}/tournament/${id}/entry`);
  return data;
};

export const updateEntries = async ({ tournamentId, entries }) => {
  const { data } = await axios.put(
    `${API_URL}/tournament/${tournamentId}/entry`,
    entries
  );
  return data;
};
