import React from "react";
import {Button, Card, Col, Image, Row, Typography} from "antd";
import {useMutation} from "react-query";
import {accountRemoval} from "../api/AccountRemoval";
import Swal from "sweetalert2";

const { Title } = Typography;

export const AccountRemoval = () => {

  const [removalMutate, { isLoading }] = useMutation(accountRemoval, {onSuccess: () => Swal.fire({
      title: "A fiók törlésének kérelme sikeresen beérkezett.",
      icon: "success",
      timer: 2000,
      showConfirmButton: false,
    }), onError: () => Swal.fire({
      title: "Hiba történt. Próbáld újra.",
      icon: "error",
      timer: 2000,
      showConfirmButton: false,
    })});

  return (
    <Row>
      <Col xs={{ span: 24, offset: 0 }} xxl={{ span: 20, offset: 2 }}>
        <Title level={3}>Felhasználói fiók törlése</Title>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Card bordered={false}>
              <div className={"item"}>
                <p>
                  Az alábbi gomb megnyomásával kérheted az aktuálisan bejelentkezett felhasználói fiók, és a hozzá
                  tartozó összes rögzített adat törlését.<br/><br/>
                  Ide tartozik a használt emailcím/jelszó, az emailrecímre kiküldött emailek, a rögzített profilok,
                  naptárbejegyzések.<br/><br/>

                  A fiók törlési igények manuálisan kerülnek feldolgozásra 3 munkanapon belül.<br/><br/>

                  A versenyeken elért eredmények <b>NEM</b> kerülnek törlésre. <br/><br/>
                </p>
              </div>
              <Button
                key="submit"
                type="primary"
                loading={isLoading}
                onClick={() => removalMutate()}
                >Fiók törlésének kérelmezése</Button>
            </Card>
          </Col>
        </Row>
      </Col>
      <div style={{ clear: "both" }}></div>
    </Row>
  );
};
