import React, { useMemo, useState } from "react";
import { GenderInput } from "artemis-shared/entryForm/GenderInput";
import { CategoryInput } from "artemis-shared/entryForm/CategoryInput";
import { AgeGroupInput } from "artemis-shared/entryForm/AgeGroupInput";
import {Col, Input, InputNumber, Row, Table, Card, Popconfirm, Space, Tooltip} from "antd";
import _ from "lodash";
import { EntryForm } from "artemis-shared/entryForm/EntryForm";

import "./EntryTab.less";
import { TeamFilter } from "../TeamFilter/TeamFilter";
import {
    CheckCircleOutlined,
    CheckCircleTwoTone,
    DeleteTwoTone, InfoCircleOutlined,
    QuestionCircleOutlined,
} from "@ant-design/icons";
import { FeeSummaryModal } from "../entryTab/FeeSummaryModal";
import { hasTeams } from "../ManagerUtils";
import {useTranslation} from "react-i18next";
import {getArrowCount} from "artemis-shared/utils/CompetitionUtils";

const { Column } = Table;

export const EntryTab = ({
  localCompetitionData,
  localEntries,
  localClubs,
  updateEntry,
  updateFullEntry,
  deleteEntry,
  createEntry,
  getLocalEntries,
  calculateAndSetPlacement,
  clearSelection,
  createNewTeam,
  broadcastChannel,
  filter,
  setFilter,
  filterExpression,
  setFilterExpression,
  teamFilter,
  setTeamFilter,
    sk,
}) => {
    const { t } = useTranslation();
  const handleFocus = (event) => event.target.select();

  const onInputChange = async (entryId, property, value) => {
    console.log("oninputchange");
    await updateEntry(entryId, property, value);
    let local = await getLocalEntries();
    calculateAndSetPlacement(local);
  };

  const shouldCellUpdate = (record, prevRecord) => {
    return !_.isEqual(record, prevRecord);
  };

  const filterChangeDebounced = _.debounce((e) => {
    setFilterExpression(e.target.value);
  }, 300);

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

  console.log("filter", filter);

  const rowKeyFn = useMemo(() => (row) => row.id, []);

  return (
    <div className={"entry-tab"}>
      <TeamFilter
        localCompetitionData={localCompetitionData}
        localEntries={localEntries}
        teamFilter={teamFilter}
        setTeamFilter={setTeamFilter}
        setFilterExpression={setFilterExpression}
        createNewTeam={createNewTeam}
        checkProperty={"checkedIn"}
      />
      <Row justify="start" align="middle" gutter={[16, 16]}>
        <Col>
          <Input
            id={"filter-expression-input"}
            placeholder={t("manager.search")}
            onChange={(e) => {
              setTeamFilter(null);
              setFilter({});
              setFilterExpression(e.target.value);
            }}
            onFocus={handleFocus}
            onPressEnter={() => {
              let scoreInput = document.querySelector(
                ".score-row-0 .score-col-0 input"
              );
              if (scoreInput) {
                scoreInput.focus();
              }
            }}
            value={filterExpression}
            style={{ width: 250 }}
          />
        </Col>
      </Row>
      <Table
        dataSource={localEntries}
        size={"small"}
        rowKey={rowKeyFn}
        rowClassName={(record, index) => "score-row score-row-" + index}
        pagination={
          teamFilter ? false : { pageSizeOptions: [10, 20, 100, 999] }
        }
        onChange={(paginate, filters) => setFilter(filters)}
      >
        <Column
          title=""
          dataIndex="checkedIn"
          key="checkedIn"
          width={50}
          render={(colValue, entry) => {
              let entryFee = null;

              if(_.isNumber(entry.entryFee)) {
                  entryFee = entry.entryFee;
              } else {
                  if(!colValue) {
                      if(entry.rounds > 0) {
                          entryFee = localCompetitionData.singleRoundEntryFee;
                      } else {
                          entryFee = entry.ageGroup.entryFee;
                      }
                  }
              }
              return (
                  <div
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                          let checkedIn = !colValue;
                          await updateFullEntry(entry.id, {
                              ...entry,
                              checkedIn: checkedIn,
                              selected: checkedIn,
                              entryFee: entryFee,
                              lunchFee: _.isNumber(entry.lunchFee)
                                  ? entry.lunchFee
                                  : checkedIn
                                      ? entry.lunch * localCompetitionData.lunchFee
                                      : null,
                          });
                          getLocalEntries();
                          broadcastChannel.postMessage({ selectChanged: true });
                      }}
                  >
                      {colValue ? (
                          <CheckCircleTwoTone
                              twoToneColor={"#52c41a"}
                              style={{ fontSize: "22px" }}
                          />
                      ) : (
                          <CheckCircleOutlined style={{ fontSize: "22px" }} />
                      )}
                  </div>
              )
          }}
          filters={[
            {
              text: t("manager.checkedIn"),
              value: true,
            },
            {
              text: t("manager.notCheckedIn"),
              value: false,
            },
          ]}
          filteredValue={filter.checkedIn}
          onFilter={(value, record) =>
            value ? record.checkedIn === true : !record.checkedIn
          }
          shouldCellUpdate={shouldCellUpdate}
        />
        <Column
          title={t("manager.idShort")}
          dataIndex="id"
          key="id"
          width={50}
          render={(colValue, record) => {
          return (
            "" +
            getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, record.category.id, record.ageGroup.ageGroup.id) +
            localCompetitionData.targetCount +
            colValue
          );
          }}
          onFilter={(value, record) => {
              return (
                parseInt(
                  "" +
                    getArrowCount(localCompetitionData.arrowCount, localCompetitionData.arrowCountExceptions, record.category.id, record.ageGroup.ageGroup.id) +
                    localCompetitionData.targetCount +
                    record.id
                ) === parseInt(value)
              );
          }}
          filteredValue={isNumeric(filterExpression) ? [filterExpression] : []}
          shouldCellUpdate={shouldCellUpdate}
        />
        {hasTeams(localEntries) && (
          <Column
            title={t("manager.teamShort")}
            dataIndex={"team"}
            width={20}
            onFilter={(value, record) => {
              return record.team === parseInt(value);
            }}
            filteredValue={isNumeric(teamFilter) ? [teamFilter] : []}
            render={(colValue, entry, index) => (
              <InputNumber
                style={{ width: "40px" }}
                id={`score-col-${entry.id}-team`}
                defaultValue={colValue}
                onFocus={handleFocus}
                min={0}
                max={localCompetitionData.teamCount}
                onChange={_.debounce((value) => {
                  onInputChange(entry.id, "team", value);
                }, 300)}
              />
            )}
          />
        )}
        <Column
          title={t("manager.name")}
          width={230}
          dataIndex="name"
          key="name"
          sorter={(a, b) => a.name.localeCompare(b.name)}
          defaultSortOrder={"ascend"}
          onFilter={(value, record) => {
            return record.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
          }}
          filteredValue={!isNumeric(filterExpression) ? [filterExpression] : []}
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry, index) => (
            <div className={"multi-row"}>
                <Input
                  id={`score-col-${entry.id}-name`}
                  defaultValue={colValue}
                  onFocus={handleFocus}
                  onChange={_.debounce((event) => {
                    onInputChange(entry.id, "name", event.target.value);
                  }, 300)}
                />
                {localCompetitionData?.international && <div className={"sub-data"}>{entry.country && t("country." + entry.country) || "-"}</div>}
                {!localCompetitionData?.international && <div className={"sub-data"}>{entry.club.name}</div>}
                {!entry.club.name && <div className={"sub-data"}>&nbsp;</div> }
            </div>
          )}
        />
        <Column
          title={t("manager.permitShort")}
          width={75}
          dataIndex="permitNumber"
          key="permitNumber"
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => (
            <>
            {!sk && <InputNumber
                  id={`score-col-${entry.id}-permit`}
                  style={{ width: "75px", color: colValue > 90000 || colValue < 1000 ? "#ff0000" : "#000000" }}
                  value={colValue}
                  onFocus={handleFocus}
                  onChange={_.debounce((value) => {
                    onInputChange(entry.id, "permitNumber", value);
                  }, 300)}
                />}
                {sk && <Input
                    id={`score-col-${entry.id}-permit`}
                    style={{ width: "90px"}}
                    defaultValue={colValue}
                    onFocus={handleFocus}
                    onChange={_.debounce((event) => {
                        onInputChange(entry.id, "permitNumber", event.target.value);
                    }, 300)}
                />}
            </>
          )}
        />
        <Column
          title={t("manager.categoryShort")}
          dataIndex="category"
          width={75}
          key="category"
          sorter={{
            compare: (a, b) => a.category.name.localeCompare(b.category.name),
            multiple: 3,
          }}
          filters={localCompetitionData.categories.map((category) => ({
            text: category.name,
            value: category.name,
          }))}
          filteredValue={filter.category}
          onFilter={(value, record) => record.category.name === value}
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => (
            <CategoryInput
              categories={localCompetitionData.categories}
              value={colValue.id}
              onChange={(value) =>
                onInputChange(
                  entry.id,
                  "category",
                  localCompetitionData.categories.find(
                    (category) => category.id === value
                  )
                )
              }
            />
          )}
        />
        <Column
          title={t("manager.ageGroupShort")}
          dataIndex="ageGroup"
          key="ageGroup"
          sorter={{
            compare: (a, b) =>
              a.ageGroup.ageGroup.name.localeCompare(b.ageGroup.ageGroup.name),
            multiple: 2,
          }}
          filters={localCompetitionData.ageGroups.map((ageGroup) => ({
            text: ageGroup.ageGroup.name,
            value: ageGroup.ageGroup.name,
          }))}
          filteredValue={filter.ageGroup}
          onFilter={(value, record) => record.ageGroup.ageGroup.name === value}
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => (
            <AgeGroupInput
              ageGroups={localCompetitionData.ageGroups}
              value={colValue.id}
              onChange={(value) =>
                onInputChange(
                  entry.id,
                  "ageGroup",
                  localCompetitionData.ageGroups.find(
                    (ageGroup) => ageGroup.id === value
                  )
                )
              }
            />
          )}
        />
        <Column
          title={t("manager.gender")}
          dataIndex="gender"
          key="gender"
          sorter={{
            compare: (a, b) => a.gender.localeCompare(b.gender),
            multiple: 1,
          }}
          filters={[
            {
              text: t("manager.man"),
              value: "MALE",
            },
            {
              text: t("manager.woman"),
              value: "FEMALE",
            },
          ]}
          filteredValue={filter.gender}
          onFilter={(value, record) => record.gender === value}
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => (
            <GenderInput
              value={colValue}
              onChange={(value) => onInputChange(entry.id, "gender", value)}
            />
          )}
        />
        <Column
          title={t("manager.entryFee")}
          width={70}
          dataIndex="entryFee"
          key="entryFee"
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => (
            <div className={"multi-row"}>
                <InputNumber
                  id={`score-col-${entry.id}-entryFee`}
                  style={{ width: "70px" }}
                  value={colValue}
                  placeholder={entry.rounds > 0 ? localCompetitionData.singleRoundEntryFee : entry.ageGroup.entryFee}
                  onFocus={handleFocus}
                  onChange={_.debounce((value) => {
                    onInputChange(entry.id, "entryFee", value);
                  }, 300)}
                />
                {entry.roundEntries?.length > 0 && entry.roundEntries[0].active && !entry.roundEntries[1].active && <div className={"sub-data alert"}>Csak 1. nap</div>}
                {entry.roundEntries?.length > 0 && !entry.roundEntries[0].active && entry.roundEntries[1].active && <div className={"sub-data alert"}>Csak 2. nap</div>}
                {((entry.roundEntries?.length > 0 && entry.roundEntries[0].active && entry.roundEntries[1].active) || !entry.roundEntries || entry.roundEntries?.length === 0) && <div className={"sub-data"}>&nbsp;</div>}
            </div>
          )}
        />
        {localCompetitionData.lunch && (
          <Column
            title={t("manager.lunch")}
            dataIndex={"lunch"}
            width={20}
            render={(colValue, entry, index) => (
              <InputNumber
                style={{ width: "40px" }}
                id={`score-col-${entry.id}-lunch`}
                defaultValue={colValue}
                onFocus={handleFocus}
                min={0}
                onChange={_.debounce((value) => {
                  onInputChange(entry.id, "lunch", value);
                }, 300)}
              />
            )}
          />
        )}
        {localCompetitionData.lunch && (
          <Column
            title={t("manager.lunchFee")}
            width={60}
            dataIndex="lunchFee"
            key="lunchFee"
            shouldCellUpdate={shouldCellUpdate}
            render={(colValue, entry) => (
              <InputNumber
                id={`score-col-${entry.id}-lunchFee`}
                style={{ width: "60px" }}
                value={colValue}
                placeholder={entry.lunch * localCompetitionData.lunchFee}
                onFocus={handleFocus}
                onChange={_.debounce((value) => {
                  onInputChange(entry.id, "lunchFee", value);
                }, 300)}
              />
            )}
          />
        )}
        <Column
          title={t("manager.comment")}
          dataIndex="adminComment"
          key="adminComment"
          shouldCellUpdate={shouldCellUpdate}
          render={(colValue, entry) => (
              <div className={"multi-row"}>
                <Input
                  id={`score-col-${entry.id}-adminComment`}
                  defaultValue={colValue}
                  onFocus={handleFocus}
                  onChange={_.debounce((event) => {
                    onInputChange(entry.id, "adminComment", event.target.value);
                  }, 300)}
                />
                  {localCompetitionData.additionalData?.length > 0 && (entry.additionalData0 || entry.additionalData1 || entry.additionalData2 || entry.additionalData3 || entry.additionalData4) && <div className={"sub-data"} style={{cursor: "pointer"}}>
                      <Tooltip placement="left" title={
                          <div>
                              {localCompetitionData.additionalData?.[0] && <div>{localCompetitionData.additionalData?.[0]?.label}: {entry.additionalData0 || "-"}</div>}
                              {localCompetitionData.additionalData?.[1] && <div>{localCompetitionData.additionalData?.[1]?.label}: {entry.additionalData1 || "-"}</div>}
                              {localCompetitionData.additionalData?.[2] && <div>{localCompetitionData.additionalData?.[2]?.label}: {entry.additionalData2 || "-"}</div>}
                              {localCompetitionData.additionalData?.[3] && <div>{localCompetitionData.additionalData?.[3]?.label}: {entry.additionalData3 || "-"}</div>}
                              {localCompetitionData.additionalData?.[4] && <div>{localCompetitionData.additionalData?.[4]?.label}: {entry.additionalData4 || "-"}</div>}
                          </div>
                      }>
                      <Space><InfoCircleOutlined />{t('manager.additionalData')}</Space>
                      </Tooltip>
                  </div>}
                  {localCompetitionData.additionalData?.length === 0 || !(entry.additionalData0 || entry.additionalData1 || entry.additionalData2 || entry.additionalData3 || entry.additionalData4) && <div className={"sub-data"}>&nbsp;</div> }
              </div>
          )}
        />
        <Column
          title={""}
          width={20}
          render={(colValue, entry, index) => (
            <Popconfirm
              title={t("manager.deleteEntryConfirm")}
              okText={t("manager.yes")}
              cancelText={t("manager.cancel")}
              onConfirm={() => deleteEntry(entry.id)}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <DeleteTwoTone twoToneColor="#ff0000" />
            </Popconfirm>
          )}
        />
      </Table>
      <Card title={t("manager.newEntry")}>
        <EntryForm
          competition={localCompetitionData}
          clubs={localClubs}
          onSubmit={createEntry}
          withTeam={hasTeams(localEntries) && localCompetitionData.teamCount}
          sk={sk}
        />
      </Card>

      {_.filter(localEntries, { selected: true }).length > 0 && (
        <FeeSummaryModal
          entries={_.filter(localEntries, { selected: true })}
          onClose={() => clearSelection()}
        />
      )}
    </div>
  );
};
