import React, { useState } from "react";
import {
  Card,
  Table,
  Grid,
  Affix,
  Space,
  BackTop,
  Statistic,
  Divider,
  Button,
  Checkbox,
} from "antd";
import "./PlacementTab.less";
import { groupEntries } from "artemis-shared/utils/CompetitionEntryUtils";
import { CheckCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import _ from "lodash";
import { useBreakpoint } from "artemis-shared/utils/Breakpoint";
import jsPDF from "jspdf";
import { downloadFile } from "../../utils/DownloadFile";
import {useTranslation} from "react-i18next";
import {scorePercentage} from "artemis-shared/utils/CompetitionUtils";
import moment from "moment";
import XLSX from "xlsx";

const { Column } = Table;

export const PlacementTab = ({
  localCompetitionData,
  localEntries,
  displayScoringValues,
    sk
}) => {
  const { t } = useTranslation();
  const { xl } = useBreakpoint();

  const [sideScroll, setSideScroll] = useState(
    localCompetitionData.rounds.length > 0
  );

  let nonFinalRounds = _.filter(localCompetitionData?.rounds || [], {finals: false});
  let manyRounds = nonFinalRounds.length > 3;

  let groupedEntries = groupEntries(localEntries);
  let sortedGroupEntries = Object.entries(groupedEntries).sort((a, b) => {
    if (a[1].category.name.localeCompare(b[1].category.name) == 1) return 1;
    if (a[1].category.name.localeCompare(b[1].category.name) == -1) return -1;

    return a[1].ageGroup.fromAge - b[1].ageGroup.fromAge;
  });

  const unionScoring = () => {
    let acc = [];
    localCompetitionData.rounds.forEach(
      (round) => (acc = [...acc, ...round.scoring])
    );
    return _.uniq(acc);
  };

  const getEntryComponent = (key, group) => (
    <div
      key={key}
      className={"category"}
      onClick={() => document.getElementById(key).scrollIntoView()}
    >
      <Space>
        {group.entries.length === 1 ||
        _.every(group.entries, (entry) => entry.score > 0) ? (
          _.some(group.entries, { tie: true }) ? (
            <CheckCircleTwoTone twoToneColor={"#ff0000"} />
          ) : (
            <CheckCircleTwoTone twoToneColor={"#52c41a"} />
          )
        ) : (
          <CheckCircleOutlined />
        )}
        <span>
          {group.category.name} {group.ageGroup.ageGroup.name}{" "}
          {group.gender === "MALE" ? t("manager.man") : t("manager.woman")}
        </span>
      </Space>
    </div>
  );

  const generatePodiumExcel = () => {
    let flatEntries = sortedGroupEntries.reduce((acc, current) => {
      acc.push(...current[1].entries)
      return acc;
      }, []).filter(entry => entry.placement <= 3).map((entry) => {
      let newEntry = {
        name: entry.name,
        category: entry.category.name,
        ageGroup: entry.ageGroup.ageGroup.name,
        gender: entry.gender === "MALE" ? t("genderTag.MALE") : t("genderTag.FEMALE"),
        score: entry.score,
        placement: entry.placement,
      };

      return newEntry;
    });
    let workBook = XLSX.utils.book_new();
    let headers = [
      "name",
      "category",
      "ageGroup",
      "gender",
      "placement",
      "score",
    ];

    let workSheet = XLSX.utils.json_to_sheet(flatEntries, {
      header: headers,
    });
    XLSX.utils.book_append_sheet(workBook, workSheet, "Dobogosok");
    XLSX.writeFile(workBook, localCompetitionData.name + "_dobogosok.xls");
  };

  const generatePdf = (podium) => {

    const international = !!(localCompetitionData?.international);

    const tOptions = international ? {lng: "en"} : {};

    let multiRound = localCompetitionData.rounds.length > 0;

    var doc = new jsPDF(multiRound && !manyRounds ? "landscape" : "portrait");

    let threedGp = multiRound && localCompetitionData.type === "THREED";

    doc.setFont("Roboto-Regular");
    doc.setFontSize(16);
    doc.text(localCompetitionData.name, doc.internal.pageSize.width / 2, 18, {align: "center"});
    doc.setFontSize(10);
    doc.text(moment().format("yyyy-MM-DD"), doc.internal.pageSize.width / 2, 24, {align: "center"});
    localCompetitionData.location !== "Location" && doc.text(localCompetitionData.location, doc.internal.pageSize.width / 2, 30, {align: "center"});


    Object.entries(sortedGroupEntries).forEach(([key, v], index) => {
      let value = v[1];

      let columns = [
        {
          value: entry => entry.placement,
          head: {
            content: `${t(value.category.name, tOptions)} ${t(value.ageGroup.ageGroup.name, tOptions)} ${t(value.gender, tOptions)}`,
            colSpan: sk ? 2 : 3,
            styles: {halign: "left", fontSize: 14},
          },
          styles: {
            cellWidth: 10
          }
        },
        {
          value: entry =>  sk ? `${entry.name}\n${localCompetitionData.international ? entry.country && t("country." + entry.country, tOptions) || "-" : entry.club.name}` : `${entry.name}`
        }
      ];

      if(!sk) {
        columns.push({
          value: entry => localCompetitionData.international ? entry.country && t("country." + entry.country, tOptions) || "-" : entry.club.name,
          styles: {
            cellWidth: 40,
            overflow: "ellipsize"
          }
        });
      }

      if (multiRound) {
        nonFinalRounds.forEach((round) => {

          if(nonFinalRounds.length <= 3) {
            let scoring = [...round.scoring];
            scoring.reverse();
            scoring.forEach(sv => {
              columns.push({
                value: entry => entry.roundEntries[round.round]["s" + sv] || "-",
                head: {
                  content: sv,
                  styles: {
                    halign: "center"
                  }
                },
                styles: {
                  cellWidth: 7,
                  halign: "center"
                }
              });
            });
          }

          columns.push({
            value: entry => entry.roundEntries[round.round]["score"] || "-",
            head: {
              content: manyRounds ? round.name : round.round + 1 + ".",
              styles: {
                halign: "center"
              }
            },
            styles: {
              cellWidth: manyRounds ? 14 : 10,
              halign: "center"
            }
          });
        });

        columns.push({
          value: entry => {
            let qualification = 0;
            _.filter(localCompetitionData.rounds, {finals: false}).forEach((round) => {
              qualification += entry.roundEntries[round.round].score;
            });

            return qualification;
          },
          head: {
            content: "Sum",
            styles: {
              halign: "center"
            }
          },
          styles: {
            cellWidth: 10,
            halign: "center"
          }
        });

        if(localCompetitionData.type === "HISTORICAL") {
          columns.push({
            value: entry => {
              return scorePercentage(localCompetitionData, entry);
            },
            head: {
              content: "%",
              styles: {
                halign: "center"
              }
            },
            styles: {
              cellWidth: 10,
              halign: "center"
            }
          });
        }

        let finalRoundIndex = _.find(localCompetitionData.rounds, {finals: true})?.round;

        if(finalRoundIndex) {
          columns.push({
            value: entry => entry.roundEntries[finalRoundIndex].score || "-",
            head: {
              content: "Fin",
              styles: {
                halign: "center"
              }
            },
            styles: {
              cellWidth: 10,
              halign: "center"
            }
          });
          columns.push({
            value: entry => entry.score,
            head: {
              content: "Total",
              styles: {
                halign: "center"
              }
            },
            styles: {
              cellWidth: 10,
              halign: "center"
            }
          });
        }
      } else {
        if(localCompetitionData.subtype != "FS") {
          [...localCompetitionData.scoring]
              .sort((a, b) => b - a)
              .filter((sv) => displayScoringValues.indexOf(sv) > -1).forEach(sv => {
            columns.push({
              value: entry => entry["s" + sv],
              head: {
                content: sv
              },
              styles: {
                cellWidth: 10
              }
            });
          });
        }

        columns.push({
          value: entry => entry.score,
          head: {
            content: "Total",
            styles: {
              halign: "center"
            }
          },
          styles: {
            cellWidth: 10,
            halign: "center"
          }
        });

        if(localCompetitionData.subtype != "FS") {
          columns.push({
            value: entry => {
              return scorePercentage(localCompetitionData, entry);
            },
            head: {
              content: "%",
              styles: {
                halign: "center"
              }
            },
            styles: {
              cellWidth: 10,
              halign: "center"
            }
          });
        }
      }

      console.log("columns", columns);


      doc.autoTable({
        pageBreak: multiRound ? "auto" : "avoid",
        rowPageBreak: "avoid",
        bodyStyles: { font: "Roboto-Regular", valign: "middle" },
        headStyles: { font: "Roboto-Bold" },
        columnStyles: columns.map(column => column.styles),
        head: [columns.map(column => column.head).filter(head => head)],
        body: value.entries.filter(entry => podium ? entry.placement <= 3 : true).map((entry) => {
          return columns.map(column => column.value(entry));
        }),
        styles: { cellPadding: {top: 2, bottom: 2, left: 0.7, right: 0.7} },
        startY: index === 0 ? 40 : null
      });
    });

    downloadFile(
      doc.output("blob"),
      `${localCompetitionData.slug}_eredmenyek.pdf`
    );
  };

  const getMedalCount = (placement) => {
    return _.filter(
      Object.values(groupedEntries),
      (category) => category.entries.length >= placement
    ).length;
  };

  const getBestBySight = (hasSight) => {
    return _.orderBy(
      _.filter(
        localEntries,
        (entry) => entry.score && entry.category.sight === hasSight
      ),
      "score",
      "desc"
    )[0];
  };

  const bestWithSight = getBestBySight(true);
  const bestWithoutSight = getBestBySight(false);

  const finalsType = localCompetitionData.type === "HISTORICAL" ? "SCORE" : "HITS";

  const roundColumns = localCompetitionData.rounds.length > 0 &&
        localCompetitionData.rounds.map((roundData) => {
          let scoreFinalsColumns = [0,1,2,3,4,5].map(arrowIndex => {
            return <Column
                title={arrowIndex + 1 + "."}
                dataIndex={[
                  "roundEntries",
                  roundData.round,
                  "finals",
                ]}
                key={roundData.round + "h" + arrowIndex}
                responsive={["sm"]}
                width={35}
                render={(value) => value?.split(";")[arrowIndex] || "-"}
            />;
          });
          let scorePropertyColumns = [...roundData.scoring]
              .reverse()
              .filter((sv) => displayScoringValues.indexOf(sv) > -1)
              .map((scoreProperty) => (
                  <Column
                      title={scoreProperty}
                      dataIndex={[
                        "roundEntries",
                        roundData.round,
                        "s" + scoreProperty,
                      ]}
                      key={roundData.round + "s" + scoreProperty}
                      responsive={["sm"]}
                      width={35}
                      render={(value) => value || 0}
                  />
              ));
          let scoreColumn = (
              <Column
                  title={t("manager.score")}
                  dataIndex={["roundEntries", roundData.round, "score"]}
                  key={roundData.round + "score"}
                  responsive={["sm"]}
                  width={50}
                  render={(value) => value || "-"}
              />
          );

          return (
              <Column
                  key={"round" + roundData.round}
                  title={roundData.name}
              >
                {[...(roundData.finals && finalsType === "SCORE" ? scoreFinalsColumns : scorePropertyColumns), scoreColumn]}
              </Column>
          );
        });

  const summaryColumns = localCompetitionData.rounds.length > 0 && (
        <Column key={"summ"} title={t("manager.sum")}>
          {localCompetitionData && !manyRounds &&
              unionScoring()
                  .reverse()
                  .filter((sv) => displayScoringValues.indexOf(sv) > -1)
                  .map((scoreProperty) => (
                      <Column
                          title={scoreProperty}
                          dataIndex={"s" + scoreProperty}
                          key={"s" + scoreProperty}
                          responsive={["sm"]}
                          width={35}
                      />
                  ))}
          <Column
              title={t("manager.score")}
              dataIndex={"score"}
              key={"score"}
              width={50}
          />
        </Column>
    );

  const scoringColumns = localCompetitionData.rounds.length === 0 && (
        <>
          {localCompetitionData &&
              [...localCompetitionData.scoring]
                  .reverse()
                  .map((scoreProperty) => (
                      <Column
                          title={scoreProperty}
                          dataIndex={"s" + scoreProperty}
                          key={"s" + scoreProperty}
                          width={50}
                          render={(value) => value || 0}
                      />
                  ))}
          <Column
              title={t("manager.score")}
              dataIndex={"score"}
              key={"score"}
              width={50}
          />
        </>
    );

  return (
    <div className={"placement-tab"}>
      {!sideScroll && (
        <div className={"categories"}>
          <Affix offsetTop={10}>
            <div className={"category-list"}>
              <div>
                {sortedGroupEntries
                  .slice(0, 27)
                  .map(([key, group]) => getEntryComponent(key, group))}
              </div>
              <div>
                {sortedGroupEntries
                  .slice(27)
                  .map(([key, group]) => getEntryComponent(key, group))}
              </div>
            </div>
          </Affix>
        </div>
      )}
      <div className={"results"}>
        <Button onClick={() => generatePdf()}>{t("manager.print")}</Button>
        <Button onClick={() => generatePdf(true)}>{t("manager.printPodium")}</Button>
        <Button onClick={() => generatePodiumExcel()}>{t("manager.generatePodiumExcel")}</Button>
        <Checkbox
          checked={sideScroll}
          onChange={(e) => setSideScroll(e.target.checked)}
        >
          {t("manager.categoryList")}
        </Checkbox>
        <Card>
          <Space split={<Divider type="vertical" />}>
            <Statistic title={t("manager.goldMedal")} value={getMedalCount(1)} />
            <Statistic title={t("manager.silverMedal")} value={getMedalCount(2)} />
            <Statistic title={t("manager.bronzeMedal")} value={getMedalCount(3)} />
            {bestWithSight && (
              <Statistic
                title={t("manager.bestWithSight")}
                value={bestWithSight.name + " - " + bestWithSight.score}
              />
            )}
            {bestWithoutSight && (
              <Statistic
                title={t("manager.bestWoSight")}
                value={bestWithoutSight.name + " - " + bestWithoutSight.score}
              />
            )}
          </Space>
        </Card>
        {sortedGroupEntries.map(([key, value]) => (
          <Card
            key={key}
            id={key}
            title={`${value.category.name} ${value.ageGroup.ageGroup.name} ${
              value.gender === "MALE" ? t("manager.man") : t("manager.woman")
            }`}
            style={{ marginBottom: "16px" }}
            bodyStyle={{ padding: xl ? "16px" : 0 }}
          >
            <Table
              bordered
              dataSource={value.entries}
              pagination={false}
              size={"small"}
              rowClassName={(record) =>
                record.score
                  ? record.tie
                    ? "tie"
                    : "placement-" + record.placement
                  : ""
              }
            >
              <Column
                title="#"
                dataIndex="placement"
                key="placement"
                width={35}
              />
              <Column title={t("manager.name")} dataIndex="name" key="name" />
              {!sideScroll && !localCompetitionData.international && <Column
                title={t("manager.club")}
                dataIndex={["club", "name"]}
                key="club"
                ellipsis={true}
              />}
              {!sideScroll && localCompetitionData.international && <Column
                  title={t("manager.country")}
                  dataIndex={["country"]}
                  key="country"
                  ellipsis={true}
                  render={(text) => text && t("country." + text) || "-"}
              />}
              {roundColumns}

              {summaryColumns}

              {scoringColumns}
            </Table>
          </Card>
        ))}
      </div>
      <BackTop />
    </div>
  );
};
