import { Select } from "antd";
import React from "react";

const { Option } = Select;

export const LunchInput = ({ ...restProps }) => {
  return (
    <Select {...restProps}>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
        <Option key={num} value={num}>
          {num}
        </Option>
      ))}
    </Select>
  );
};
