import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form, Input, Select, InputNumber } from "antd";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { getClubs } from "artemis-shared/api/Club";
import { insertProfile } from "../api/Profile";
import Swal from "sweetalert2";
import { checkPermit, checkPermitAndName } from "artemis-shared/api/Permit";
const { Option } = Select;

export const CreateProfileModal = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const cache = useQueryCache();

  const { data: clubs } = useQuery("clubs", getClubs);

  const [insertProfileMutate, { isLoading }] = useMutation(insertProfile);

  const [permitNumber, setPermitNumber] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [invalidPermitNumber, setInvalidPermitNumber] = useState(false);
  const [nameHelper, setNameHelper] = useState(null);

  const { data: permitData } = useQuery(["permit", permitNumber], checkPermit, {
    enabled: permitNumber,
    retry: false,
    onSuccess: (data) => {
      form.setFieldsValue({
        name: data.fullName,
      });
      form.validateFields(["permitNumber"]);
      setInvalidPermitNumber(false);
    },
    onError: (err) => {
      if (err.response.status === 404) {
        form.setFieldsValue({
          name: null,
        });
        form.validateFields(["permitNumber"]);
        setInvalidPermitNumber(true);
      }
    },
  });

  const [mutateCheckPermitAndName] = useMutation(checkPermitAndName, {
    enabled: permitNumber && firstName && lastName,
    retry: false,
    onSuccess: (data) => {
        form.validateFields(["firstName", "lastName"]);
      setNameHelper(null);
    },
    onError: (err) => {
      if (err.response.status === 400) {
          form.validateFields(["firstName", "lastName"]);
        setNameHelper(err.response.data.message);
      }
    },
  });

  return (
    <Modal
      visible={visible}
      title="Új profil létrehozása"
      footer={[
        <Button key="back" onClick={onCancel}>
          Mégse
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={() => {
              let firstName = form.getFieldValue("firstName").trim().replace(/\s+/g, ' ');
              let lastName = form.getFieldValue("lastName").trim().replace(/\s+/g, ' ');

            form
              .validateFields()
              .then(async (values) => {
                let profileData = {
                  ...values,
                    name: `${lastName} ${firstName}`,
                    firstName: firstName,
                    lastName: lastName,
                  permitNumber: values.permitNumber || -1,
                };
                profileData.club = clubs.find(
                  (club) => club.id === profileData.club
                );
                let mutateResult = await insertProfileMutate(profileData);
                await cache.invalidateQueries(["userData"]);
                let newProfile = mutateResult.data;
                Swal.fire({
                  title: "Sikeres profil létrehozás!",
                  icon: "success",
                  timer: 2000,
                  showConfirmButton: false,
                });
                form.resetFields();
                console.log("newprof", newProfile);
                onCreate(newProfile);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          Létrehozás
        </Button>,
      ]}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        layout="horizontal"
        name="form_in_modal"
        requiredMark={false}
        initialValues={{
          firstName: "",
          lastName: "",
          permitNumber: "",
          //birthday: "",
          gender: "",
          club: "",
        }}
      >
        <Form.Item
          label="Versenyeng."
          name="permitNumber"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!permitNumber || (permitNumber && !invalidPermitNumber)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Nem létező engedélyszám!"));
              },
            }),
          ]}
        >
          <InputNumber
            style={{ width: "100%" }}
            onBlur={(e) => {
              setPermitNumber(e.target.value ? e.target.value : null);
              setNameHelper(null);
            }}
          />
        </Form.Item>
          <Form.Item
              label={"Vezetéknév"}
              name="lastName"
              rules={[
                  ({ getFieldValue }) => ({
                      validator(_, value) {
                          if (nameHelper) {
                              return Promise.reject(
                                  new Error(
                                      "Hibás név!"
                                  )
                              );
                          } else {
                              return Promise.resolve();
                          }
                      },
                  }),
                  {
                      required: true,
                      message: "Add meg a neved!",
                  },
              ]}
          >
              <Input
                  onBlur={(e) => {
                      if(form.getFieldValue("firstName") && form.getFieldValue("lastName")) {
                          setLastName(e.target.value ? e.target.value : null);
                          setNameHelper(null);
                          if (permitNumber) {
                              mutateCheckPermitAndName({
                                  permitNumber,
                                  name: `${e.target.value?.trim().replace(/\s+/g, " ")} ${form.getFieldValue("firstName").trim().replace(/\s+/g, " ")}`,
                              });
                          }
                      }
                  }}
              />
          </Form.Item>

          <Form.Item
              label={"Keresztnév"}
              name="firstName"
              rules={[
                  ({ getFieldValue }) => ({
                      validator(_, value) {
                          if (nameHelper) {
                              return Promise.reject(
                                  new Error(
                                      "Ehhez a veng. számhoz ez a név tartozik: " +
                                      nameHelper
                                  )
                              );
                          } else {
                              return Promise.resolve();
                          }
                      },
                  }),
                  {
                      required: true,
                      message: "Add meg a neved!",
                  },
              ]}
          >
              <Input
                  onBlur={(e) => {
                      if(form.getFieldValue("firstName") && form.getFieldValue("lastName")) {
                          setFirstName(e.target.value ? e.target.value : null);
                          setNameHelper(null);
                          if (permitNumber) {
                              mutateCheckPermitAndName({
                                  permitNumber,
                                  name: `${form.getFieldValue("lastName").trim().replace(/\s+/g, " ")} ${e.target.value?.trim().replace(/\s+/g, " ")}`,
                              });
                          }
                      }
                  }}
              />
          </Form.Item>

        {/*<Form.Item
          name="birthday"
          label="Születési dátum"
          rules={[
            {
              required: true,
              message: "Add meg a születési dátumot!",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>*/}
        <Form.Item
          label="Nem"
          name="gender"
          rules={[
            {
              required: true,
              message: "Válaszd ki a nemet!",
            },
          ]}
        >
          <Select>
            <Option value="MALE">Férfi</Option>
            <Option value="FEMALE">Nő</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Egyesület"
          name="club"
          rules={[
            {
              required: true,
              message: "Válaszd ki az egyesületet!",
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={999}>Egyéb</Option>
            {(clubs || []).map((club) => (
              <Option key={club.id} value={club.id}>
                {club.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CreateProfileModal.propTypes = {
  visible: PropTypes.bool,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
};
