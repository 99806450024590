import { v4 as uuidv4 } from "uuid";
import { db } from "../utils/TrainingDB";

export const createTraining = async (training) => {
  console.log(training);
  const id = uuidv4();
  await db.trainings.put({
    ...training,
    id,
    created: Date.now(),
    distances: [],
    scores: [...Array(training.targetCount)].map((e, index) => ({
      target: index,
      scores: [],
      distance: 5,
    })),
  });

  return id;
};
