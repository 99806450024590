import React, { useState } from "react";
import {
  Button,
  Space,
  Table,
  Input,
  Popconfirm,
  Form,
  List,
  Divider,
  Col,
  Skeleton,
  Select,
  InputNumber,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { GenderInput } from "artemis-shared/entryForm/GenderInput";
import { CategoryInput } from "artemis-shared/entryForm/CategoryInput";
import { AgeGroupInput } from "artemis-shared/entryForm/AgeGroupInput";
import { ClubInput } from "artemis-shared/entryForm/ClubInput";
import { LunchInput } from "artemis-shared/entryForm/LunchInput";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { getClubs } from "artemis-shared/api/Club";
import { deleteEntry, getOwnEntries, updateEntry } from "../api/Competition";
import { GenderTag } from "artemis-shared/tag/GenderTag";
import { CategoryTag } from "artemis-shared/tag/CategoryTag";
import { AgeGroupTag } from "artemis-shared/tag/AgeGroupTag";
import Swal from "sweetalert2";
import moment from "moment";
import _ from "lodash";
import {CountrySelect} from "artemis-shared/competition/CountrySelect";
import {useTranslation} from "react-i18next";

const EditableCell = ({ editing, dataIndex, children, ...restProps }) => {
  let inputNode;

  let dataIndexString = Array.isArray(dataIndex) ? dataIndex[0] : dataIndex;

  switch (dataIndexString) {
    case "gender":
      inputNode = (
        <Form.Item
          name="gender"
          rules={[
            {
              required: true,
              message: "Válassz nemet!",
            },
          ]}
        >
          <GenderInput />
        </Form.Item>
      );
      break;
    case "category":
      inputNode = (
        <Form.Item
          name="category"
          rules={[
            {
              required: true,
              message: "Válassz kategóriát!",
            },
          ]}
        >
          <CategoryInput categories={restProps.competition.categories} />
        </Form.Item>
      );
      break;
    case "ageGroup":
      inputNode = (
        <Form.Item
          name="ageGroup"
          rules={[
            {
              required: true,
              message: "Válassz korosztályt",
            },
          ]}
        >
          <AgeGroupInput ageGroups={restProps.competition.ageGroups} />
        </Form.Item>
      );
      break;
    case "club":
      inputNode = (
        <Form.Item
          name="club"
          rules={[
            {
              required: true,
              message: "Válaszd ki az egyesületed!",
            },
          ]}
        >
          <ClubInput clubs={restProps.clubs} />
        </Form.Item>
      );
      break;
    case "lunch":
      inputNode = (
        <Form.Item
          name="lunch"
          rules={[
            {
              required: true,
              message: "Add meg hány ebédet kérsz!",
            },
          ]}
        >
          <LunchInput />
        </Form.Item>
      );
      break;
    case "country":
      inputNode = (
          <Form.Item
              name={"country"}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]
              }
          >
            <CountrySelect />
          </Form.Item>
      );
      break;
    case "team":
      inputNode = (
        <Form.Item
          name="team"
          rules={[
            {
              required: true,
              message: "Válassz csapatot!",
            },
          ]}
        >
          <Select>
            {[...Array(restProps.competition?.teamCount + 1 || 21).keys()].map(
              (teamNum) => (
                <Select.Option key={teamNum} value={teamNum}>
                  {teamNum}
                </Select.Option>
              )
            )}
          </Select>
        </Form.Item>
      );
      break;
    case "additionalData0":
      inputNode = getAdditionalDataCell(0, restProps.competition);
      break;
    case "additionalData1":
      inputNode = getAdditionalDataCell(1, restProps.competition);
      break;
    case "additionalData2":
      inputNode = getAdditionalDataCell(2, restProps.competition);
      break;
    case "additionalData3":
      inputNode = getAdditionalDataCell(3, restProps.competition);
      break;
    case "additionalData4":
      inputNode = getAdditionalDataCell(4, restProps.competition);
      break;
    default:
      inputNode = (
        <Form.Item name={dataIndexString}>
          <Input />
        </Form.Item>
      );
  }

  return <td {...restProps}>{editing ? inputNode : children}</td>;
};

const getAdditionalDataCell = (pos, competition) => {
  const additionalData = _.find(competition?.additionalData, { pos: pos });
  if (additionalData) {
    return (
      <>
        {additionalData.type === "STRING" && (
          <Form.Item
            name={["additionalData" + additionalData.pos]}
            rules={
              additionalData.required && [
                {
                  required: true,
                  message: "Kötelező mező!",
                },
              ]
            }
          >
            <Input />
          </Form.Item>
        )}
        {additionalData.type === "NUMBER" && (
          <Form.Item
            name={["additionalData" + additionalData.pos]}
            rules={
              additionalData.required && [
                {
                  required: true,
                  message: "Kötelező mező!",
                },
              ]
            }
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        )}
        {additionalData.type === "SELECT" && (
            <Form.Item
                name={"additionalData" + additionalData.pos}
                rules={
                    additionalData.required && [
                      {
                        required: true,
                        message: "Kötelező mező!",
                      },
                    ]
                }
            >
              <Select>
                {additionalData.options.split(";").map(option => <Select.Option key={option} value={option}>{option}</Select.Option>)}
              </Select>
            </Form.Item>
        )}
      </>
    );
  } else {
    return "asda";
  }
};

export const EntryEditor = ({ entries, competition }) => {
  const {t} = useTranslation();
  const cache = useQueryCache();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const { data: clubs } = useQuery("clubs", getClubs);
  const { isFetching: isFetchingEntries } = useQuery(
    ["ownentries", competition.id],
    getOwnEntries
  );
  const [updateEntryMutate, { isLoading: isLoadingUpdateEntry }] = useMutation(
    updateEntry,
    {
      onSuccess: () => {
        Swal.fire({
          title: "Sikeres módosítás!",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        cache.invalidateQueries(["ownentries", competition.id]);
        cache.invalidateQueries(["entries", competition.id]);
      },
    }
  );

  const [deleteEntryMutate, { isLoading: isLoadingDeleteEntry }] = useMutation(
    deleteEntry,
    {
      onSuccess: () => {
        Swal.fire({
          title: "A nevezés sikeresen visszavonva!",
          icon: "success",
          timer: 1000,
        });
        cache.invalidateQueries(["entries", competition.id]);
        cache.invalidateQueries(["ownentries", competition.id]);
      },
    }
  );

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      category: record.category.id,
      ageGroup: record.ageGroup.id,
      club: record.club.id,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (id) => {
    try {
      await form.validateFields();

      const index = entries.findIndex((item) => id === item.id);
      const oldData = entries[index];

      let formValues = form.getFieldsValue();
      let updatedEntry = {
        ...oldData,
        ...formValues,
        category: competition.categories.find(
          (category) => category.id === formValues.category
        ),
        ageGroup: competition.ageGroups.find(
          (ageGroup) => ageGroup.id === formValues.ageGroup
        ),
        club: clubs.find((club) => club.id === formValues.club),
      };
      console.log("updatedentry", updatedEntry);
      await updateEntryMutate(updatedEntry);
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const categoryTag = (text) => <CategoryTag category={text} />;
  const ageGroupTag = (text) => <AgeGroupTag ageGroup={text} />;
  const genderTag = (text) => <GenderTag gender={text} />;
  const actionsComp = (_, record) => {
    const editable = isEditing(record);
    return editable ? (
      <Space>
        <Button onClick={cancel}>
          <CloseOutlined />
        </Button>
        <Popconfirm
          title="Biztos hogy módosítod a nevezésedet?"
          onConfirm={() => save(record.id)}
        >
          <Button>
            <CheckOutlined />
          </Button>
        </Popconfirm>
      </Space>
    ) : (
      <Space>
        <Button disabled={editingKey !== ""} onClick={() => edit(record)}>
          <EditOutlined />
        </Button>
        <Popconfirm
          title="Biztos hogy visszavonod a nevezésedet?"
          onConfirm={() => deleteEntryMutate(record.id)}
        >
          <Button danger disabled={editingKey !== ""}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      </Space>
    );
  };

  const columns = [
    {
      title: "Név",
      dataIndex: "name",
    },
    {
      title: "Veng.",
      dataIndex: "permitNumber",
      width: 70,
    },
    {
      title: "Kat.",
      dataIndex: ["category", "name"],
      editable: true,
      competition: competition,
      width: 70,
      render: categoryTag,
    },
    {
      title: "Korosztály",
      dataIndex: ["ageGroup", "ageGroup", "name"],
      editable: true,
      competition: competition,
      render: ageGroupTag,
      width: 70,
    },
    {
      title: "Nem",
      dataIndex: "gender",
      editable: true,
      render: genderTag,
      width: 50,
    }];

  if(competition.international) {
    columns.push({
      title: "Ország",
      dataIndex: ["country"],
      editable: true,
      render: (text) => text && t("country." + text) || "-"
    })
  } else {
    columns.push({
      title: "Egyesület",
      dataIndex: ["club", "name"],
      editable: true,
    })
  }

  columns.push(
    {
      title: "Megjegyzés",
      dataIndex: "comment",
      editable: true,
    });

  const additionalDataColumns =
    competition?.additionalData.map((ad) => ({
      title: ad.label,
      dataIndex: ["additionalData" + ad.pos],
      editable: true,
      competition: competition,
    })) || [];

  const actionColumns = [
    {
      title: "Műveletek",
      dataIndex: "operation",
      width: 130,
      render: moment().isBefore(moment(competition.unregisterDeadline)) && competition.editingEntryEnabled
        ? actionsComp
        : null,
    },
  ];

  if (competition.teamsPublished) {
    columns.splice(6, 0, {
      title: "Csapat",
      dataIndex: "team",
      render: (value) =>
        value,
      editable: true,
    });
  }

  const mergedColumns = [
    ...columns,
    ...additionalDataColumns,
    ...actionColumns,
  ].map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        competition: col.competition,
        clubs: clubs,
      }),
    };
  });
  return (
    <>
      <Col xs={24} md={0}>
        <div className={"competition-entries-list"}>
          <List
            dataSource={entries}
            loading={isFetchingEntries}
            renderItem={(entry) => (
              <List.Item>
                <Skeleton
                  avatar
                  title={false}
                  loading={isFetchingEntries}
                  active
                >
                  <List.Item.Meta
                    avatar={
                      competition.editingEntryEnabled && <Popconfirm
                        title="Biztos hogy visszavonod a nevezésedet?"
                        onConfirm={() => deleteEntryMutate(entry.id)}
                      >
                        <Button disabled={editingKey !== ""}>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    }
                    title={
                      <div className={"entry"}>
                        <div className={"entry-left"}>
                          <div className={"entry-name"}>
                            <div>{entry.name}</div>
                            <div className={"entry-club"}>
                              {entry.club.name}
                            </div>
                          </div>
                        </div>
                        <div className={"entry-right entry-editor"}>
                          <div>
                            <CategoryTag category={entry.category.name} />
                          </div>
                          <div>
                            <AgeGroupTag
                              ageGroup={entry.ageGroup.ageGroup.name}
                            />
                          </div>
                          <div>
                            <GenderTag gender={entry.gender} />
                          </div>
                        </div>
                      </div>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </div>
      </Col>
      <Col xs={0} md={24} lg={0}>
        <List
          itemLayout="horizontal"
          dataSource={entries}
          renderItem={(entry) => (
            <List.Item
              actions={[
                <Popconfirm
                  key={1}
                  title="Biztos hogy visszavonod a nevezésedet?"
                  onConfirm={() => deleteEntryMutate(entry.id)}
                >
                  <Button danger disabled={editingKey !== ""}>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>,
              ]}
            >
              <List.Item.Meta
                title={
                  <Space split={<Divider type={"vertical"} />}>
                    <span>{entry.name}</span>
                    <span>{entry.permitNumber}</span>
                    <span>{entry.club.name}</span>
                    <CategoryTag category={entry.category.name} />
                    <AgeGroupTag ageGroup={entry.ageGroup.ageGroup.name} />
                    <GenderTag gender={entry.gender} />
                    <span>Ebéd: {entry.lunch}</span>
                  </Space>
                }
                description={<span>Megjegyzés: {entry.comment}</span>}
              />
            </List.Item>
          )}
        />
      </Col>
      <Col xs={0} lg={24}>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={entries}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
            loading={
              isLoadingUpdateEntry || isFetchingEntries || isLoadingDeleteEntry
            }
          />
        </Form>
      </Col>
    </>
  );
};
