import React from "react";
import { Card } from "antd";
import "./MiszBanner.less";
import logo from "./misz.png";

export const MiszBanner = ({ competition }) => {
  return (
    <a
      href={`https://misz.hu/verseny/${competition.id}/${competition.slug}`}
      target={"_blank"}
      rel={"noreferrer"}
    >
      <Card className={"misz-banner"}>
        <div className={"misz-banner-content"}>
          <div className={"misz-logo"}>
            <img src={logo} />
          </div>
          <div className={"misz-banner-text"}>
            <h3>MISZ által szervezett és/vagy jóváhagyott verseny.</h3>
            <h4>
              Az itt leadott nevezés egyenértékű a MISZ honlapján leadottal.
            </h4>
          </div>
        </div>
      </Card>
    </a>
  );
};
