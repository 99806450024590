import React from "react";
import { Input } from "antd";
import { YearSelector } from "./YearSelector";
import { SearchOutlined } from "@ant-design/icons";

export const FilterRow = ({
  selectedYear,
  setSelectedYear,
  filterExpression,
  debouncedFilterExpressionChange,
}) => {
  return (
    <div className={"filter-row"}>
      <Input
        id={"filter-expression-input"}
        placeholder={"Keresés (verseny neve, helyszín) ..."}
        onChange={(e) => debouncedFilterExpressionChange(e.target.value)}
        defaultValue={filterExpression}
        style={{ width: 280, marginBottom: "16px" }}
        prefix={<SearchOutlined />}
        allowClear={true}
      />

      <YearSelector
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
      />
    </div>
  );
};
