import React from "react";
import { CompetitionTypeTag } from "artemis-shared/tag/CompetitionTypeTag";
import { Button, Table } from "antd";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import { getLatestScores } from "artemis-shared/api/Competition";
import { RightOutlined } from "@ant-design/icons";
import { CompetitionList } from "artemis-shared/competitions/CompetitionList";
import { useBreakpoint } from "artemis-shared/utils/Breakpoint";

const { Column } = Table;

export const LatestScores = () => {
  const { md, lg, xl } = useBreakpoint();
  const { data, isFetching, isLoading } = useQuery(
    "latest-scores",
    getLatestScores
  );

  return (
    <>
      {((md && !lg) || xl) && (
        <Table
          dataSource={data}
          loading={isFetching || isLoading}
          onSelect
          locale={{ emptyText: "Még nincs feltöltött eredmény." }}
          rowKey={(record) => record.id}
        >
          <Column
            title="Típus"
            dataIndex="type"
            key="type"
            render={(type) => <CompetitionTypeTag type={type} />}
          />
          <Column title="Név" dataIndex="name" key="name" />
          <Column
            title="Helyszín"
            dataIndex="location"
            key="location"
            responsive={["sm"]}
          />
          <Column
            title="Dátum"
            dataIndex="startDate"
            key="startDate"
            width={120}
            responsive={["sm"]}
          />
          <Column
            title=""
            key="action"
            align={"right"}
            render={(text, record) => (
              <>
                {record.stage === "SCORED" && (
                  <NavLink to={`/competition/${record.id}/${record.slug}`}>
                    <Button>
                      <RightOutlined />
                    </Button>
                  </NavLink>
                )}
                {record.stage === "EXTERNAL_SCORED" && (
                  <a
                    href={`${record.externalScoreUrl}`}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    <Button>
                      <RightOutlined />
                    </Button>
                  </a>
                )}
              </>
            )}
          />
        </Table>
      )}
      {!((md && !lg) || xl) && (
        <CompetitionList
          competitions={data ? data.slice(0, 5) : []}
          isFetching={isFetching}
        />
      )}
    </>
  );
};
