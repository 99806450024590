import React from "react";
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Popconfirm,
  Space,
} from "antd";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { getManagedCompetition, updateTargets } from "../api/Competition";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import Swal from "sweetalert2";

export const Targets = () => {
  let history = useHistory();
  const cache = useQueryCache();
  const [form] = Form.useForm();

  let { competitionId } = useParams();

  const { data: competition } = useQuery(
    ["managed-competition", parseInt(competitionId)],
    getManagedCompetition
  );

  const [mutate, { isLoading: loadingMutation }] = useMutation(updateTargets, {
    onSuccess: () => {
      cache.invalidateQueries(["managed-competition", parseInt(competitionId)]);

      Swal.fire({
        title: "Sikeres feltöltés!",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
    },
  });

  const onFinish = (values) => {
    mutate({
      targets: values.targets,
      competitionId: parseInt(competitionId),
    });
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/")}
        title={"Célok - " + (competition && competition.name)}
      />

      <Alert
        message={
          <span>
            <strong>FIGYELEM!</strong> A célok a feltöltés után azonnal
            elérhetővé válnak a versenyzők számára! Ne töltsd fel a verseny
            előtt a célokat!
          </span>
        }
        type="warning"
      />
      <br />
      {competition && (
        <Form
          form={form}
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={competition}
        >
          <Form.List name="targets">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "targetNumber"]}
                      fieldKey={[fieldKey, "targetNumber"]}
                      rules={[{ required: true, message: "" }]}
                    >
                      <InputNumber
                        placeholder="#"
                        min={1}
                        max={99}
                        style={{ width: "55px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "targetName"]}
                      fieldKey={[fieldKey, "targetName"]}
                      rules={[{ required: true, message: "" }]}
                    >
                      <Input placeholder="Cél neve" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "redStake"]}
                      fieldKey={[fieldKey, "redStake"]}
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Piros karó"
                        style={{ width: "90px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "blueStake"]}
                      fieldKey={[fieldKey, "blueStake"]}
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Input placeholder="Kék karó" style={{ width: "90px" }} />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    style={{ width: "435px" }}
                  >
                    Cél hozzáadása
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Popconfirm
              onConfirm={() => form.submit()}
              title="Biztos hogy publikálod a távolságokat?"
              okText="Igen"
              cancelText="Mégsem"
            >
              <Button type="primary" loading={loadingMutation}>
                Feltöltés és publikálás
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
