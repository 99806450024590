import React from "react";
import { ClubCompetitions } from "./ClubCompetitions";
import {Avatar, Button, Card, Divider, PageHeader, Space} from "antd";
import { NavLink, useParams } from "react-router-dom";
import { TrophyTwoTone } from "@ant-design/icons";

import "./ClubProfile.less";
import { useQuery } from "react-query";
import { getClubAthletes } from "../api/ClubStats";
import axios from "axios";


const API_URL = process.env.REACT_APP_API_URL;

export const ClubProfile = () => {
  let { clubId } = useParams();
  let clubIdNumber = parseInt(clubId);

  const { data: clubAthletes } = useQuery(
    ["clubathletes", clubIdNumber],
    getClubAthletes
  );

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.back()}
        title={clubAthletes && clubAthletes[0].clubName}
        extra={[
            <Button
                key="1"
                type="primary"
                onClick={() => downloadExcel(clubIdNumber)}
            >
                Eredmény excel
            </Button>,
            ]}
      />
      <div
        className={"club-profile"}
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {clubAthletes &&
          clubAthletes.map((clubAthlete) => (
            <NavLink key={123} to={`/athlete/${clubAthlete.permitNumber}`}>
              <Card
                key={123}
                style={{ width: 250, margin: "32px 8px 32px 8px" }}
                actions={[
                  <div key={"first"}>
                    <TrophyTwoTone twoToneColor={"#ffce52"} />{" "}
                    {clubAthlete.firstPlace}
                  </div>,
                  <div key={"second"}>
                    <TrophyTwoTone twoToneColor={"#949494"} />{" "}
                    {clubAthlete.secondPlace}
                  </div>,
                  <div key={"third"}>
                    <TrophyTwoTone twoToneColor={"#925408"} />{" "}
                    {clubAthlete.thirdPlace}
                  </div>,
                ]}
              >
                <Card.Meta title={clubAthlete.fullName} avatar={<Avatar />} />
              </Card>
            </NavLink>
          ))}
      </div>
      <ClubCompetitions clubId={clubIdNumber} />
    </>
  );
};

const downloadExcel = async (clubId) => {
    axios({
        url: `${API_URL}/club/${clubId}/resultexcel`,
        method: "GET",
        responseType: "blob",
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            response.headers["content-disposition"].substr(21)
        );
        document.body.appendChild(link);
        link.click();
    });
};
