import React from "react";
import { Card, Rate, Table, Typography } from "antd";
import { useQuery } from "react-query";
import { getClubCompetitions } from "../api/ClubCompetition";
import _ from "lodash";
import { Link } from "react-router-dom";

import "./ClubCompetitions.less";
import { useBreakpoint } from "artemis-shared/utils/Breakpoint";

const { Column } = Table;

export const ClubCompetitions = ({ clubId }) => {

  const { sm, lg, xxl } = useBreakpoint();

  const { data } = useQuery("clubcompetition", getClubCompetitions);

  const values = [
    {
      id: "parking",
      title: "Parkolás",
    },
    {
      id: "office",
      title: "Versenyiroda",
    },
    {
      id: "practiceField",
      title: "Belövőpálya",
    },
    {
      id: "competitionField",
      title: "Versenypálya",
    },
    {
      id: "targets",
      title: "Célok",
    },
    {
      id: "lunch",
      title: "Ebéd",
    },
    {
      id: "resultAnnouncement",
      title: "Eredmény",
    },
  ];

  return (
    <div className={"clubs-page"}>
      {data && (
        <Card title={"Rendezett versenyek"}>
          <Table
            key={"clubname"}
            size={"small"}
            dataSource={_.filter(data, { clubId: clubId })}
            pagination={false}
            scroll={lg ? { x: 1000 } : false}
            showHeader={sm}
          >
            <Column
              dataIndex={"competitionName"}
              width={220}
              title={"Verseny"}
              ellipsis={true}
              render={(value, entry) => (
                <Link to={"/competition/" + entry.competitionId}>{value}</Link>
              )}
              fixed={"left"}
            />
            <Column
              dataIndex={"startDate"}
              title={"Dátum"}
              width={100}
              fixed={"left"}
              responsive={["sm"]}
            />
            <Column
              dataIndex={"location"}
              title={"Helyszín"}
              width={120}
              ellipsis={true}
              fixed={"left"}
              responsive={["sm"]}
            />
            {values.map((value) => (
              <Column
                key={value.id}
                width={100}
                title={value.title}
                dataIndex={value.id}
                responsive={["lg"]}
                render={(value) => (
                  <div className={"stars"}>
                    {xxl && (
                      <>
                        <Rate
                          style={{ fontSize: "14px" }}
                          disabled
                          allowHalf
                          value={Math.round(value * 2) / 2}
                        />
                        <br />
                      </>
                    )}
                    <Typography.Text strong>
                      {value && value !== 0 ? value.toFixed(2) : "-"}
                    </Typography.Text>
                  </div>
                )}
              />
            ))}
          </Table>
        </Card>
      )}
    </div>
  );
};
