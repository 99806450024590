import React from "react";
import {
  Button,
  PageHeader,
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber,
  Switch,
  Radio,
  Skeleton,
  Space,
  Card,
  Checkbox,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CreateCompetition.less";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { getCategories } from "../api/Category";
import { getAgeGroups } from "../api/AgeGroup";
import { ClubInput } from "artemis-shared/entryForm/ClubInput";
import {
  getManagedCompetition,
  insertCompetition,
  updateCompetition,
} from "../api/Competition";
import { useHistory } from "react-router";
import { getClubs } from "artemis-shared/api/Club";
import { useParams } from "react-router-dom";
import { useAuth } from "../auth/Auth";
import { getUserData } from "../api/User";
import _ from "lodash";
import {ArrowCountExceptionInput} from "./ArrowCountExceptionInput";

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

let saveAsSwitch = false;

export const CreateCompetition = () => {
  const cache = useQueryCache();
  const auth = useAuth();

  let { competitionId } = useParams();

  const [form] = Form.useForm();
  const history = useHistory();

  const { data: userData } = useQuery(["userData"], getUserData, {
    enabled: auth.user,
  });

  const { data: categories } = useQuery("categories", getCategories);
  const { data: clubs } = useQuery("clubs", getClubs);

  const { data: ageGroups } = useQuery("agegroups", getAgeGroups);

  const { data: competition, isLoading } = useQuery(
    ["managed-competition", parseInt(competitionId)],
    getManagedCompetition,
    { enabled: competitionId }
  );

  const [mutateCreate, { isLoading: loadingCreate }] = useMutation(
    insertCompetition,
    {
      onSuccess: () => {
        cache.invalidateQueries(["competitions"]);
        form.resetFields();
      },
    }
  );

  const [mutateUpdate, { isLoading: loadingUpdate }] = useMutation(
    updateCompetition,
    {
      onSuccess: () => {
        cache.invalidateQueries(["competitions"]);
        cache.invalidateQueries([
          "managed-competition",
          parseInt(competitionId),
        ]);
        form.resetFields();
      },
    }
  );

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinish = async (values) => {
    const saveAs = saveAsSwitch;
    console.log("Success:", values, saveAs);

    let formValues = { ...values };

    formValues.arrowCountExceptions = values.arrowCountExceptions?.filter(ex => ex.arrowCount).map(ex => ({...ex, arrowCount: parseInt(ex.arrowCount)})) || [];

    formValues.ageGroups = (values.ageGroups || [])
      .filter((val) => val.ageGroup)
      .map((value) => {
        return {
          id: value.id,
          ageGroup: ageGroups.find((obj) => obj.id === value.ageGroup),
          fromAge: value.fromAge,
          toAge: value.toAge,
          entryFee: value.entryFee,
        };
      });
    formValues.categories = (values.categories || []).map((value) =>
      categories.find((obj) => obj.id === value)
    );
    formValues.organizerClub = clubs.find(
      (club) => club.id === values.organizerClub
    );
    formValues.startDate = values.startDate[0].utc(true);
    formValues.finishDate = values.startDate[1].utc(true);

    formValues.lunch = !!values.lunch;
    formValues.editingEntryEnabled = !!values.editingEntryEnabled;
    formValues.permitRequired = !!values.permitRequired;
    formValues.checkPermitExpired = !!values.checkPermitExpired;
    formValues.anonymized = !!values.anonymized;
    formValues.guestLunch = !!values.guestLunch;
    formValues.miszCalendar = !!values.miszCalendar;
    formValues.miszApproved = !!values.miszApproved;
    formValues.chooseRound = !!values.chooseRound;

    if(!formValues.permitRequired) {
      formValues.checkPermitLevel = 0;
    }

    if (formValues.rounds) {
      formValues.rounds.forEach((round, index) => {
        round.round = index;
        round.scoring.sort((a, b) => parseInt(a) - parseInt(b));
        round.finals = false;
      });
    }

    if (formValues.additionalData) {
      formValues.additionalData.forEach((ad, index) => {
        ad.pos = index;
        if (ad.type !== "SELECT") {
          ad.options = null;
        }
      });
    }

    if (formValues.scoring) {
      formValues.scoring.sort((a, b) => parseInt(a) - parseInt(b));
    }

    console.log("comp", formValues);

    let newCompetition = null;

    if (competitionId && !saveAs) {
      let competitionToSend = { ...competition, ...formValues };
      newCompetition = await mutateUpdate({
        competitionId,
        competition: competitionToSend,
      });
    } else if(competitionId && saveAs) {
      const saveAsComp = {...formValues};
      saveAsComp.id = null;
      saveAsComp.name = saveAsComp.name + " Másolata";
      saveAsComp.published = false;
      saveAsComp.miszApproved = false;
      saveAsComp.stage = "APPLICATION";

      const setIdNullFn = entity => {
        const newEntity = {...entity};
        delete newEntity.id;
        return newEntity;
      };

      saveAsComp.ageGroups = saveAsComp.ageGroups.map(setIdNullFn);

      if(saveAsComp.arrowCountExceptions ) {
        saveAsComp.arrowCountExceptions = saveAsComp.arrowCountExceptions.map(setIdNullFn);
      }

      if(saveAsComp.additionalData ) {
        saveAsComp.additionalData = saveAsComp.additionalData.map(setIdNullFn);
      }

      if(saveAsComp.rounds) {
        saveAsComp.rounds = saveAsComp.rounds.map(setIdNullFn);
      }

      newCompetition = await mutateCreate(saveAsComp);
    } else {
      newCompetition = await mutateCreate(formValues);
    }

    console.log("newcomp", newCompetition);

    if (newCompetition && !saveAs) {
      history.push(`/competition/${newCompetition.id}/${newCompetition.slug}`);
    } else if(newCompetition && saveAs) {
      history.push(`/competition/edit/${newCompetition.id}`);
      window.location.reload();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const convertCompetitionToFormInit = (competition) => {
    if (!competition) {
      return {
        maxParticipants: 999,
        detailLevel: 1,
        multiRound: false,
        editingEntryEnabled: true,
        permitRequired: false,
        checkPermitExpired: false,
        checkPermitLevel: 0,
        anonymized: false,
        description: "",
        published: true,
        international: false,
        arrowCountExceptions: []
      };
    }

    let result = { ...competition };
    result.ageGroups = (competition.ageGroups || []).map((ageGroup) => ({
      id: ageGroup.id,
      ageGroup: ageGroup.ageGroup.id,
      fromAge: ageGroup.fromAge,
      toAge: ageGroup.toAge,
      entryFee: ageGroup.entryFee,
    }));
    result.categories = (competition.categories || []).map(
      (category) => category.id
    );
    result.organizerClub = competition.organizerClub.id;
    result.applicationDeadline = competition.applicationDeadline
      ? moment(competition.applicationDeadline)
      : null;
    result.unregisterDeadline = competition.unregisterDeadline
      ? moment(competition.unregisterDeadline)
      : null;
    result.multiRound = !!competition.multiRound;
    result.editingEntryEnabled =
      competition.editingEntryEnabled == null
        ? true
        : competition.editingEntryEnabled;
    result.permitRequired =
        competition.permitRequired == null
            ? false
            : competition.permitRequired;
    result.checkPermitExpired =
        competition.checkPermitExpired == null
            ? false
            : competition.checkPermitExpired;
    result.international =
        competition.international == null
            ? false
            : competition.international;
    delete result.createdDate;
    result.startDate = [
      moment(competition.startDate),
      moment(competition.finishDate),
    ];

    console.log("result", result);

    return result;
  };

  return (
    <>
      {isLoading && <Skeleton active paragraph={{ rows: 10 }} />}
      {((competitionId && competition) || !competitionId) && (
        <Form
          form={form}
          {...layout}
          name="basic"
          initialValues={convertCompetitionToFormInit(competition)}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
          scrollToFirstError={true}
        >
          <PageHeader
            className="site-page-header"
            onBack={() =>
              history.push(
                competitionId ? "/manager-dashboard" : "/competitions"
              )
            }
            title={competitionId ? "Verseny módosítása" : "Verseny létrehozása"}
            extra={[
              userData.misz ? (
                <Form.Item key={121} name="category" noStyle={true}>
                  <Select style={{ marginRight: 20 }}>
                    <Option key={"REGIONAL"} value={"REGIONAL"}>
                      Regionális
                    </Option>
                    <Option key={"CENTRAL"} value={"CENTRAL"}>
                      Központi
                    </Option>
                    <Option key={"INTERNATIONAL"} value={"INTERNATIONAL"}>
                      Nemzetközi
                    </Option>
                  </Select>
                </Form.Item>
              ) : null,
              userData.misz || userData.admin ? (
                <Form.Item
                  key={11}
                  name="miszApproved"
                  valuePropName={"checked"}
                  noStyle={true}
                >
                  <Switch
                    id="miszapproved"
                    checkedChildren="MISZ által jóváhagyva"
                    unCheckedChildren="MISZ nem hagyta jóvá"
                    style={{ marginRight: 20 }}
                  />
                </Form.Item>
              ) : null,
              <Form.Item
                key={1}
                name="published"
                noStyle={true}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren="Nyilvános"
                  unCheckedChildren="Privát"
                />
              </Form.Item>,
              <Button
                type={"primary"}
                key="3"
                id={"submit"}
                htmlType="submit"
                loading={loadingCreate || loadingUpdate}
              >
                {competitionId ? "Verseny módosítása" : "Verseny létrehozása"}
              </Button>,
              competitionId ? <Button
                  type={"secondary"}
                  key="4"
                  id={"submit"}
                  htmlType="submit"
                  onClick={() => {
                    saveAsSwitch = true;
                    console.log("savea", saveAsSwitch);
                  }}
                  loading={loadingCreate || loadingUpdate}
              >
                Másolat létrehozása
              </Button> : null
            ]}
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={10}>
              <Card>
                <Form.Item name="detailLevel" label={" "} colon={false}>
                  <Radio.Group
                    disabled={competition && competition.detailLevel === 2}
                    buttonStyle="solid"
                    onChange={(event) => {
                      if (event.target.value === 1) {
                        form.setFieldsValue({
                          maxParticipants: null,
                          multiRound: false,
                          scoring: [],
                          tiebreaking: [],
                          ageGroups: [],
                          categories: [],
                          rounds: [],
                        });
                      }
                    }}
                  >
                    <Radio.Button value={1}>Csak versenykiírás</Radio.Button>
                    <Radio.Button value={2} id={"with-application"}>
                      Nevezéssel együtt
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  key={22}
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        name="multiRound"
                        label={"Formátum"}
                        colon={false}
                      >
                        <Radio.Group
                          disabled={
                            competition && competition.detailLevel === 2
                          }
                          buttonStyle="solid"
                          onChange={(event) => {
                            if (!event.target.value) {
                              form.setFieldsValue({
                                rounds: [],
                              });
                            }
                          }}
                        >
                          <Radio.Button value={false}>Normál</Radio.Button>
                          <Radio.Button value={true}>Több nap/táv</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  label="Verseny neve"
                  name="name"
                  rules={[
                    { required: true, message: "Add meg a verseny nevét!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Verseny típusa"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Válaszd ki a verseny típusát!",
                    },
                  ]}
                >
                  <Select
                    data-cy="type-select"
                    onChange={() => {
                      form.setFieldsValue({
                        ageGroups: undefined,
                        categories: undefined,
                      });
                    }}
                  >
                    <Option key={"THREED"} value={"THREED"}>
                      3D
                    </Option>
                    <Option key={"FIELD"} value={"FIELD"}>
                      Terep
                    </Option>
                    <Option key={"TARGET"} value={"TARGET"}>
                      Pálya
                    </Option>
                    <Option key={"HISTORICAL"} value={"HISTORICAL"}>
                      Történelmi
                    </Option>
                    <Option key={"FUN"} value={"FUN"}>
                      Örömíjász
                    </Option>
                    <Option key={"OTHER"} value={"OTHER"}>
                      Egyéb
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.detailLevel !== currentValues.detailLevel
                    }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                        <Form.Item
                            label="Nemzetközi"
                            name="international"
                            valuePropName={"checked"}
                        >
                          <Switch
                              id="international"
                              checkedChildren="Igen"
                              unCheckedChildren="Nem"
                          />
                        </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.type !== currentValues.type ||
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Kategóriák"
                        name="categories"
                        rules={[
                          {
                            required: true,
                            message: "Válaszd ki a kategóriákat!",
                          },
                        ]}
                      >
                        <Select
                          data-cy="categories-select"
                          mode="multiple"
                          allowClear
                          disabled={!getFieldValue("type")}
                          listHeight={320}
                        >
                          {categories &&
                            Object.entries(
                              _.groupBy(
                                categories.filter(
                                  (category) =>
                                    category.type === getFieldValue("type")
                                ),
                                "rulebook"
                              )
                            ).map(([key, value]) => (
                              <OptGroup label={key} key={key}>
                                {_.sortBy(value, "name").map((category) => (
                                  <Option key={category.id} value={category.id}>
                                    {category.name}{" "}
                                    {category.description
                                      ? "(" + category.description + ")"
                                      : ""}
                                  </Option>
                                ))}
                              </OptGroup>
                            ))}
                        </Select>
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.detailLevel !== currentValues.detailLevel ||
                        prevValues.type !== currentValues.type
                    }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 &&
                    getFieldValue("type") ? (
                        <Form.Item label={"Korosztályok"} required={true}>
                          <Form.List
                              name="ageGroups"
                              rules={[
                                {
                                  validator: (rule, value) => {
                                    let promise = new Promise((resolve, reject) => {
                                      if (!value) {
                                        reject("'ageGroups' is required");
                                      } else {
                                        resolve();
                                      }
                                    });
                                    return promise;
                                  },
                                },
                              ]}
                          >
                            {(fields, { add, remove }) => (
                                <>
                                  {fields.map((field) => (
                                      <div
                                          key={field.key}
                                          className={"age-group-row"}
                                      >
                                        <Space align="baseline">
                                          <Form.Item
                                              noStyle
                                              shouldUpdate={(prevValues, curValues) =>
                                                  prevValues.ageGroups !==
                                                  curValues.ageGroups
                                              }
                                          >
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, "ageGroup"]}
                                                    fieldKey={[
                                                      field.fieldKey,
                                                      "ageGroup",
                                                    ]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                            "Válassz kategória nevet",
                                                      },
                                                    ]}
                                                >
                                                  <Select
                                                      style={{ width: 90 }}
                                                      data-cy="agegroup-name-select"
                                                  >
                                                    {(
                                                        (ageGroups &&
                                                            ageGroups.filter(
                                                                (ag) =>
                                                                    ag.type ===
                                                                    getFieldValue("type")
                                                            )) ||
                                                        []
                                                    ).map((item) => (
                                                        <Option
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                          {item.name}
                                                        </Option>
                                                    ))}
                                                  </Select>
                                                </Form.Item>
                                            )}
                                          </Form.Item>
                                          <Form.Item
                                              {...field}
                                              label="Kor"
                                              name={[field.name, "fromAge"]}
                                              fieldKey={[field.fieldKey, "fromAge"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Add meg a minimum életkort",
                                                },
                                              ]}
                                          >
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                style={{ width: 60 }}
                                            />
                                          </Form.Item>
                                          <Form.Item
                                              {...field}
                                              colon={false}
                                              label="-"
                                              name={[field.name, "toAge"]}
                                              fieldKey={[field.fieldKey, "toAge"]}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: "Add meg a maximum életkort",
                                                },
                                              ]}
                                          >
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                style={{ width: 60 }}
                                            />
                                          </Form.Item>

                                          <MinusCircleOutlined
                                              onClick={() => remove(field.name)}
                                          />
                                        </Space>
                                        <Form.Item
                                            {...field}
                                            label="Nevezési díj"
                                            name={[field.name, "entryFee"]}
                                            fieldKey={[field.fieldKey, "entryFee"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Add meg a nevezési díjat",
                                              },
                                            ]}
                                        >
                                          <InputNumber
                                              min={0}
                                              style={{ width: "100%" }}
                                          />
                                        </Form.Item>
                                      </div>
                                  ))}

                                  <Form.Item>
                                    <Button
                                        id={"add-agegroup-button"}
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                      Korosztály hozzáadása
                                    </Button>
                                  </Form.Item>
                                </>
                            )}
                          </Form.List>
                        </Form.Item>
                    ) : null;
                  }}
                </Form.Item>

                <Form.Item
                  label="Verseny napja"
                  name="startDate"
                  rules={[
                    { required: true, message: "Add meg a verseny dátumát!" },
                  ]}
                >
                  <RangePicker
                    data-cy="startdate-select"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Nevezési határidő"
                        name="applicationDeadline"
                        rules={[
                          {
                            required: true,
                            message: "Add meg a nevezési határidőt!",
                          },
                        ]}
                      >
                        <DatePicker
                          data-cy="deadline-select"
                          showTime
                          style={{ width: "100%" }}
                          onChange={(value) => {
                            if (
                              value &&
                              form.getFieldValue("unregisterDeadline") == null
                            ) {
                              form.setFieldsValue({
                                unregisterDeadline: value.clone(),
                              });
                            }
                          }}
                        />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Nevezések módosítása"
                        name="editingEntryEnabled"
                        valuePropName={"checked"}
                      >
                        <Switch
                          id="editingEntryEnabled"
                          checkedChildren="Engedélyezve"
                          unCheckedChildren="Letíltva"
                        />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.detailLevel !== currentValues.detailLevel
                    }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                        <Form.Item
                            label="Versenyengedély"
                            name="permitRequired"
                            valuePropName={"checked"}
                        >
                          <Switch
                              id="permitRequired"
                              checkedChildren="Kötelező"
                              unCheckedChildren="Nem kötelező"
                          />
                        </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.detailLevel !== currentValues.detailLevel || prevValues.permitRequired !== currentValues.permitRequired
                    }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 && getFieldValue("permitRequired") === true ? (
                        <Form.Item
                            label="Lehetőségek"
                            name="checkPermitLevel"
                            rules={[
                              {
                                required: true,
                                message:
                                    "Válassz a nevezési lehetőségek közül!",
                              },
                            ]}
                        >
                          <Select style={{ marginRight: 20 }}>
                            <Option key={"0"} value={0}>
                              Csak érvényes engedély
                            </Option>
                            <Option key={"1"} value={1}>
                              Engedély + rajtengedély
                            </Option>
                            <Option key={"2"} value={2}>
                              Engedély + rajt + versenyen kívül
                            </Option>
                          </Select>
                        </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Nevezés módosítás határidő"
                        name="unregisterDeadline"
                        rules={[
                          {
                            required: true,
                            message:
                              "Add meg a nevezés lemondásának határidejét!",
                          },
                        ]}
                      >
                        <DatePicker showTime style={{ width: "100%" }} />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  label="Helyszín"
                  name="location"
                  rules={[
                    { required: true, message: "Add meg a helyszín nevét!" },
                  ]}
                >
                  <Input id={"location"} />
                </Form.Item>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  label={"GPS pozíció"}
                  required
                >
                  <Form.Item
                    name="lat"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 6px)",
                    }}
                    rules={[
                      { required: true, message: "Add meg a GPS szélességet!" },
                      { message: "Hibás formátum!", pattern: /^\d+\.\d+$/g },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder={"Szélesség"}
                      onChange={(event) => {
                        let value = event.currentTarget.value;
                        if (value.indexOf(",") > -1) {
                          let exploded = value.split(",");
                          form.setFieldsValue({
                            lat: exploded[0]
                              .trim()
                              .replace(/[^\d.-]/g, "")
                              .substr(0, 10),
                            lng: exploded[1]
                              .trim()
                              .replace(/[^\d.-]/g, "")
                              .substr(0, 10),
                          });
                        } else {
                          form.setFieldsValue({
                            lat: value
                              .trim()
                              .replace(/[^\d.-]/g, "")
                              .substr(0, 10),
                          });
                        }
                      }}
                    />
                  </Form.Item>
                  <span
                    style={{
                      display: "inline-block",
                      width: "12px",
                      lineHeight: "32px",
                      textAlign: "center",
                    }}
                  ></span>
                  <Form.Item
                    name="lng"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 6px)",
                    }}
                    rules={[
                      { required: true, message: "Add meg a GPS hosszúságot!" },
                      { message: "Hibás formátum!", pattern: /^\d+\.\d+$/g },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder={"Hosszúság"}
                      onChange={(event) => {
                        let value = event.currentTarget.value;
                        form.setFieldsValue({
                          lng: value
                            .trim()
                            .replace(/[^\d.-]/g, "")
                            .substr(0, 10),
                        });
                      }}
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label="Szervező egyesület"
                  name="organizerClub"
                  rules={[
                    {
                      required: true,
                      message: "Válaszd ki a szervező egyesületet!",
                    },
                  ]}
                >
                  <ClubInput clubs={clubs} />
                </Form.Item>
                <Form.Item
                  label="MISZ Naptárban"
                  name="miszCalendar"
                  valuePropName={"checked"}
                >
                  <Switch
                    id="miszcalendar"
                    checkedChildren="Jelenjen meg"
                    unCheckedChildren="Ne jelenjen meg"
                  />
                </Form.Item>
                {/*<Form.Item
                  key={23}
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.miszApproved !== currentValues.miszApproved
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("miszApproved") === true ? (
                      <Form.Item label="MISZ Nevezési Oldal" name="miszUrl">
                        <Input addonBefore="https://misz.hu/versenysite/" />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>*/}
                <Form.Item
                  key={22}
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Ebéd"
                        name="lunch"
                        valuePropName={"checked"}
                      >
                        <Switch
                          checkedChildren="Lesz"
                          unCheckedChildren="Nem lesz"
                        />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  key={24}
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Ebéd ára a nevezési díjban"
                        name="guestLunch"
                        valuePropName={"checked"}
                      >
                        <Switch
                          checkedChildren="Benne van"
                          unCheckedChildren="Nincs benne"
                        />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>

                <Form.Item
                  key={22}
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel ||
                    prevValues.lunch !== currentValues.lunch
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Ebéd/Vendégebéd ára"
                        name="lunchFee"
                        rules={[
                          {
                            required: getFieldValue("lunch"),
                            message: "Add meg az ebéd árát!",
                          },
                        ]}
                      >
                        <InputNumber min={0} style={{ width: "100%" }} />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Max résztvevők"
                        name="maxParticipants"
                        rules={[
                          {
                            required: true,
                            message: "Add meg a résztvevők maximális számát!",
                          },
                        ]}
                      >
                        <InputNumber min={0} style={{ width: "100%" }} />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Csapatok száma"
                        name="teamCount"
                        rules={[
                          {
                            required: true,
                            message: "Add meg a csapatok számát!",
                          },
                        ]}
                      >
                        <InputNumber
                          min={1}
                          max={80}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Célok száma"
                        name="targetCount"
                        rules={[
                          {
                            required: true,
                            message: "Add meg a célok számát!",
                          },
                        ]}
                      >
                        <InputNumber min={0} style={{ width: "100%" }} />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Célonkénti lövések száma"
                        name="arrowCount"
                        rules={[
                          {
                            required: true,
                            message: "Add meg a célonkénti lövések számát!",
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={99}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.categories !== currentValues.categories
                        || prevValues.ageGroups !== currentValues.ageGroups
                    }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? <Form.Item
                        noStyle
                        name="arrowCountExceptions">
                          {categories && <ArrowCountExceptionInput value={getFieldValue("exceptions")} onChange={(exceptions) => form.setFieldsValue({exceptions: exceptions})} categories={(getFieldValue("categories") || []).map((value) =>
                          categories.find((obj) => obj.id === value)
                      )} ageGroups={(getFieldValue("ageGroups") || []).map((value) => ageGroups?.find((obj) => obj.id === value?.ageGroup))} />}
                    </Form.Item> : null;
                  }}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Találati értékek"
                        name="scoring"
                        dependencies={["detailLevel"]}
                        rules={[
                          {
                            required: true,
                            message:
                              "Válaszd ki a lehetséges találati értékeket!",
                          },
                          {
                            validator: (rule, value) => {
                              if(value && value.indexOf("0") > -1) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject("Add hozzá a 0-t is!");
                              }
                            },
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          allowClear
                          data-cy="scoring-select"
                          virtual={false}
                        >
                          <Option key={"0"} value={"0"}>
                            0
                          </Option>
                          <Option key={"1"} value={"1"}>
                            1
                          </Option>
                          <Option key={"2"} value={"2"}>
                            2
                          </Option>
                          <Option key={"3"} value={"3"}>
                            3
                          </Option>
                          <Option key={"4"} value={"4"}>
                            4
                          </Option>
                          <Option key={"5"} value={"5"}>
                            5
                          </Option>
                          <Option key={"6"} value={"6"}>
                            6
                          </Option>
                          <Option key={"7"} value={"7"}>
                            7
                          </Option>
                          <Option key={"8"} value={"8"}>
                            8
                          </Option>
                          <Option key={"9"} value={"9"}>
                            9
                          </Option>
                          <Option key={"10"} value={"10"}>
                            10
                          </Option>
                          <Option key={"11"} value={"11"}>
                            11
                          </Option>
                          <Option key={"12"} value={"12"}>
                            12
                          </Option>
                          <Option key={"13"} value={"13"}>
                            13
                          </Option>
                          <Option key={"14"} value={"14"}>
                            14
                          </Option>
                          <Option key={"15"} value={"15"}>
                            15
                          </Option>
                          <Option key={"16"} value={"16"}>
                            16
                          </Option>
                          <Option key={"17"} value={"17"}>
                            17
                          </Option>
                          <Option key={"18"} value={"18"}>
                            18
                          </Option>
                          <Option key={"19"} value={"19"}>
                            19
                          </Option>
                          <Option key={"20"} value={"20"}>
                            20
                          </Option>
                          <Option key={"x"} value={"x"}>
                            X
                          </Option>
                        </Select>
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label="Pontegyenlőség esetén"
                        name="tiebreaking"
                        dependencies={["detailLevel"]}
                        rules={[
                          {
                            required: true,
                            message:
                              "Válaszd ki hogy pontegyenlőség esetén mi döntsön!",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          allowClear
                          data-cy="tiebreaker-select"
                          virtual={false}
                        >
                          <Option key={"T"} value={"T"}>
                            Szétlövés
                          </Option>
                          <Option key={"0"} value={"0"}>
                            Kevesebb 0
                          </Option>
                          <Option key={"1"} value={"1"}>
                            Több 1
                          </Option>
                          <Option key={"2"} value={"2"}>
                            Több 2
                          </Option>
                          <Option key={"3"} value={"3"}>
                            Több 3
                          </Option>
                          <Option key={"4"} value={"4"}>
                            Több 4
                          </Option>
                          <Option key={"5"} value={"5"}>
                            Több 5
                          </Option>
                          <Option key={"6"} value={"6"}>
                            Több 6
                          </Option>
                          <Option key={"7"} value={"7"}>
                            Több 7
                          </Option>
                          <Option key={"8"} value={"8"}>
                            Több 8
                          </Option>
                          <Option key={"9"} value={"9"}>
                            Több 9
                          </Option>
                          <Option key={"10"} value={"10"}>
                            Több 10
                          </Option>
                          <Option key={"11"} value={"11"}>
                            Több 11
                          </Option>
                          <Option key={"12"} value={"12"}>
                            Több 12
                          </Option>
                          <Option key={"13"} value={"13"}>
                            Több 13
                          </Option>
                          <Option key={"14"} value={"14"}>
                            Több 14
                          </Option>
                          <Option key={"15"} value={"15"}>
                            Több 15
                          </Option>
                          <Option key={"16"} value={"16"}>
                            Több 16
                          </Option>
                          <Option key={"17"} value={"17"}>
                            Több 17
                          </Option>
                          <Option key={"18"} value={"18"}>
                            Több 18
                          </Option>
                          <Option key={"19"} value={"19"}>
                            Több 19
                          </Option>
                          <Option key={"20"} value={"20"}>
                            Több 20
                          </Option>
                          <Option key={"x"} value={"x"}>
                            Több X
                          </Option>
                        </Select>
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel ||
                    prevValues.type !== currentValues.type ||
                    prevValues.multiRound !== currentValues.multiRound
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("multiRound") ? (
                      <Form.Item
                        label="Csak egy fordulóra jelentkezés"
                        name="chooseRound"
                        valuePropName={"checked"}
                      >
                        <Switch
                          checkedChildren="Lehetséges"
                          unCheckedChildren="Nem lehetséges"
                        />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.detailLevel !== currentValues.detailLevel ||
                        prevValues.type !== currentValues.type ||
                        prevValues.multiRound !== currentValues.multiRound ||
                        prevValues.chooseRound !== currentValues.chooseRound
                    }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("multiRound") && getFieldValue("chooseRound") ? (
                        <Form.Item
                            label="Egy fordulós nevezési díj"
                            name="singleRoundEntryFee"
                            rules={[
                              {
                                required: true,
                                message: "Add meg az egy fordulós nevezés díját!",
                              },
                            ]}
                        >
                          <InputNumber
                              style={{ width: "100%" }}
                          />
                        </Form.Item>
                    ) : null;
                  }}
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel ||
                    prevValues.type !== currentValues.type ||
                    prevValues.multiRound !== currentValues.multiRound
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("multiRound") ? (
                      <Form.Item label={"Fordulók"} required={true}>
                        <Form.List
                          name="rounds"
                          rules={[
                            {
                              validator: (rule, value) => {
                                let promise = new Promise((resolve, reject) => {
                                  if (!value) {
                                    reject("'rounds' is required");
                                  } else {
                                    resolve();
                                  }
                                });
                                return promise;
                              },
                            },
                          ]}
                        >
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field) => (
                                <div key={field.key} className={"round-row"}>
                                  <Space align="baseline">
                                    <Form.Item
                                      noStyle
                                      shouldUpdate={(prevValues, curValues) =>
                                        prevValues.rounds !== curValues.rounds
                                      }
                                    >
                                      {() => (
                                        <Form.Item
                                          {...field}
                                          label="Név"
                                          name={[field.name, "name"]}
                                          fieldKey={[field.fieldKey, "name"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Add meg a forduló nevét",
                                            },
                                          ]}
                                        >
                                          <Input style={{ width: 120 }} />
                                        </Form.Item>
                                      )}
                                    </Form.Item>
                                    <Form.Item
                                      {...field}
                                      label="Célok"
                                      name={[field.name, "arrowCount"]}
                                      fieldKey={[field.fieldKey, "arrowCount"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Add meg a célok számát",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        min={0}
                                        max={100}
                                        style={{ width: 60 }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      {...field}
                                      label="Versenyzők"
                                      name={[field.name, "topCount"]}
                                      fieldKey={[field.fieldKey, "topCount"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Add meg a versenyzők számát",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        min={0}
                                        max={999}
                                        style={{ width: 60 }}
                                      />
                                    </Form.Item>

                                    <MinusCircleOutlined
                                      onClick={() => remove(field.name)}
                                    />
                                  </Space>
                                  <Form.Item
                                    {...field}
                                    label="Találati értékek"
                                    name={[field.name, "scoring"]}
                                    fieldKey={[field.fieldKey, "scoring"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Válaszd ki a lehetséges találati értékeket!",
                                      },
                                    ]}
                                  >
                                    <Select mode="multiple" allowClear>
                                      <Option key={"0"} value={"0"}>
                                        0
                                      </Option>
                                      <Option key={"1"} value={"1"}>
                                        1
                                      </Option>
                                      <Option key={"2"} value={"2"}>
                                        2
                                      </Option>
                                      <Option key={"3"} value={"3"}>
                                        3
                                      </Option>
                                      <Option key={"4"} value={"4"}>
                                        4
                                      </Option>
                                      <Option key={"5"} value={"5"}>
                                        5
                                      </Option>
                                      <Option key={"6"} value={"6"}>
                                        6
                                      </Option>
                                      <Option key={"7"} value={"7"}>
                                        7
                                      </Option>
                                      <Option key={"8"} value={"8"}>
                                        8
                                      </Option>
                                      <Option key={"9"} value={"9"}>
                                        9
                                      </Option>
                                      <Option key={"10"} value={"10"}>
                                        10
                                      </Option>
                                      <Option key={"11"} value={"11"}>
                                        11
                                      </Option>
                                      <Option key={"12"} value={"12"}>
                                        12
                                      </Option>
                                      <Option key={"13"} value={"13"}>
                                        13
                                      </Option>
                                      <Option key={"14"} value={"14"}>
                                        14
                                      </Option>
                                      <Option key={"15"} value={"15"}>
                                        15
                                      </Option>
                                      <Option key={"16"} value={"16"}>
                                        16
                                      </Option>
                                      <Option key={"17"} value={"17"}>
                                        17
                                      </Option>
                                      <Option key={"18"} value={"18"}>
                                        18
                                      </Option>
                                      <Option key={"19"} value={"19"}>
                                        19
                                      </Option>
                                      <Option key={"20"} value={"20"}>
                                        20
                                      </Option>
                                      <Option key={"x"} value={"x"}>
                                        X
                                      </Option>
                                    </Select>
                                  </Form.Item>
                                </div>
                              ))}

                              <Form.Item>
                                <Button
                                  id={"add-round"}
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Forduló hozzáadása
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel ||
                    prevValues.type !== currentValues.type ||
                    prevValues.additionalData !== currentValues.additionalData
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 &&
                      getFieldValue("type") ? (
                      <Form.Item
                        label={"Egyéb adatok bekérése"}
                        required={true}
                      >
                        <Form.List name="additionalData">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field) => (
                                <div
                                  key={field.key}
                                  className={"age-group-row"}
                                >
                                  <Space align="baseline">
                                    <Form.Item
                                      {...field}
                                      label="Címke"
                                      name={[field.name, "label"]}
                                      fieldKey={[field.fieldKey, "label"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Add meg a címkét",
                                        },
                                      ]}
                                    >
                                      <Input width={210} />
                                    </Form.Item>

                                    <MinusCircleOutlined
                                      onClick={() => remove(field.name)}
                                    />
                                  </Space>
                                  <Space>
                                    <Form.Item
                                      {...field}
                                      label="Típus"
                                      name={[field.name, "type"]}
                                      fieldKey={[field.fieldKey, "type"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Válassz tipust",
                                        },
                                      ]}
                                    >
                                      <Select
                                        style={{ width: 105 }}
                                        data-cy="type-name-select"
                                      >
                                        <Option key={"STRING"} value={"STRING"}>
                                          Szöveg
                                        </Option>
                                        <Option key={"NUMBER"} value={"NUMBER"}>
                                          Szám
                                        </Option>
                                        <Option key={"SELECT"} value={"SELECT"}>
                                          Választó
                                        </Option>
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      {...field}
                                      label="Kötelező"
                                      name={[field.name, "required"]}
                                      fieldKey={[field.fieldKey, "required"]}
                                    >
                                      <Select
                                        style={{ width: 80 }}
                                        data-cy="type-name-select"
                                      >
                                        <Option key={"true"} value={true}>
                                          Igen
                                        </Option>
                                        <Option key={"false"} value={false}>
                                          Nem
                                        </Option>
                                      </Select>
                                    </Form.Item>
                                  </Space>
                                  {getFieldValue([
                                    "additionalData",
                                    field.name,
                                    "type",
                                  ]) === "SELECT" && (
                                    <>
                                      <div>
                                        Választható értékek pontosvesszővel
                                        elválasztva
                                      </div>
                                      <Form.Item
                                        {...field}
                                        name={[field.name, "options"]}
                                        fieldKey={[field.fieldKey, "options"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Add meg az értékeket",
                                          },
                                        ]}
                                      >
                                        <Input width={210} />
                                      </Form.Item>
                                    </>
                                  )}
                                </div>
                              ))}

                              <Form.Item>
                                <Button
                                  id={"add-additionaldata-button"}
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Mező hozzáadása
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>

                {userData && userData.admin && (
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.detailLevel !== currentValues.detailLevel
                    }
                  >
                    {({ getFieldValue }) => {
                      return getFieldValue("detailLevel") === 1 ? (
                        <Form.Item
                          label="Eredmény külső URL"
                          name="externalScoreUrl"
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      ) : null;
                    }}
                  </Form.Item>
                )}

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.detailLevel !== currentValues.detailLevel
                  }
                >
                  {({ getFieldValue }) => {
                    return getFieldValue("detailLevel") === 2 ? (
                      <Form.Item
                        label={"Figyelmeztető üzenet"}
                        name={"applicationInfoMessage"}
                      >
                        <Input.TextArea rows={3} />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
              </Card>
            </Col>
            <Col xs={24} xl={14}>
              <Card>
                <Form.Item
                  name="description"
                  rules={[
                    { required: true, message: "Add meg a versenykiírást!" },
                  ]}
                  wrapperCol={{ span: 24 }}
                >
                  <ReactQuill theme="snow" />
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};
