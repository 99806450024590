import { Select } from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

const { Option } = Select;

export const GenderInput = ({ ...restProps }) => {
  const { t } = useTranslation();
  return (
    <Select {...restProps}>
      <Option key={"MALE"} value="MALE">
          {t("genderInput.male")}
      </Option>
      <Option key={"FEMALE"} value="FEMALE">
          {t("genderInput.female")}
      </Option>
    </Select>
  );
};
